const initialState = {
    city: null,
    district1: null,
    cty: null,
    district: null,
    ward: null,
};

const addressReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CITY_SUCCESS':
            return {
                ...state,
                cty: action.payload,
            };
        case 'CITY1_SUCCESS':
            return {
                ...state,
                city: action.payload,
            };
        case 'CITY_FAILURE':
            return state;
        case 'DISTRICT_SUCCESS':
            return {
                ...state,
                district: action.payload,
            };
        case 'DISTRICT1_SUCCESS':
            return {
                ...state,
                district1: action.payload,
            };
        case 'DISTRICT_FAILURE':
            return state;
        case 'WARD_SUCCESS':
            return {
                ...state,
                ward: action.payload,
            };
        case 'WARD_FAILURE':
            return state;
        default:
            return state;
    }
};

export default addressReducer;
