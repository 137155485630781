import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Stack,
} from '@mui/material';
import classNames from 'classnames/bind';
import CloseIcon from '@mui/icons-material/Cancel';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import styles from './AddSurvey.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { add_survey, add_question_survey, show_alert } from '~/Store/Action/surveyActions';
import { useNavigate } from 'react-router-dom';
import Alert from '~/components/Alert';
import { toast } from 'react-toastify';
import SunEditor from 'suneditor-react';

const cx = classNames.bind(styles);

const AddSurvey = () => {
    const [visible, setVisible] = useState(false);

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const [questions, setQuestions] = useState([
        { question: '', type: 'single_choice', image: null, path: null, answers: [{ text: '', type: 'choose' }] }, // Một câu hỏi ban đầu với một đáp án rỗng
    ]);

    const [img, setImg] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    // const listCategory = useSelector((state) => state.category.list);

    const navigate = useNavigate();

    useEffect(() => {
        // dispatch(category());
    }, []);

    const showToast = (message, type = 0, options = {}) => {
        const defaultOptions = {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        };

        const toastOptions = { ...defaultOptions, ...options };

        if (type == 0) {
            toast.success(message, toastOptions);
        } else {
            toast.warning(message, toastOptions);
        }
    };

    const onAddSurvey = async () => {
        for (const question of questions) {
            if (!question.question.trim()) {
                console.log('Câu hỏi chưa được điền');
                showToast('Câu hỏi chưa được điền', 1);
                showToast('Điền đầy đủ thông tin trước khi gửi', 1);
                return; // Dừng việc thêm khảo sát nếu có câu hỏi chưa điền
            }

            if (question.type !== 'open_ended') {
                for (const answer of question.answers) {
                    if (answer.type == 'input') {
                        continue;
                    }
                    if (!answer.text.trim()) {
                        console.log('Đáp án chưa được điền');
                        showToast('Đáp án chưa được điền đầy đủ', 1);
                        showToast('Điền đầy đủ thông tin trước khi gửi', 1);
                        return; // Dừng việc thêm khảo sát nếu có đáp án chưa điền
                    }
                }
            }
        }

        const survey = await onAddQuestion();
        console.log(survey);

        const id = await survey.data.id;

        const formDataArray = questions.map((question) => {
            const formData = new FormData();
            formData.append('title', question.question);
            formData.append('type', question.type);

            if (question.type !== 'open_ended') {
                for (let i = 0; i < question.answers.length; i++) {
                    formData.append(`answers[${i}][text]`, question.answers[i].text);
                    formData.append(`answers[${i}][type]`, question.answers[i].type);
                }
            } else {
                // for (let i = 0; i < question.answers.length; i++) {
                formData.append(`answers[${0}][text]`, 'cau mo');
                formData.append(`answers[${0}][type]`, question.answers[0].type);
                // }
            }

            if (question.image) {
                formData.append('img_quest', question.image);
            }

            return formData;
        });

        console.log('123');
        const form = await formDataArray.map(async (form) => {
            for (const [key, value] of form) {
                console.log(`${key}:`, value);
            }
            await dispatch(add_question_survey(auth.token, form, id));
        });

        // Gửi
        navigate('/tai-khoan/quan-ly-khao-sat');
        dispatch(show_alert());
    };

    const onAddQuestion = async () => {
        var form_question = document.getElementById('form_question');
        var formData = new FormData(form_question);

        for (const [key, value] of formData) {
            console.log(`${key}:`, value);
        }

        const data = await dispatch(add_survey(auth.token, formData));
        return data;
        // if (await dispatch(add_course_online(auth.token, formData))) {
        //     navigate(`/tai-khoan/quan-ly-khoa-hoc-online`);
        // }
    };

    const addQuestion = () => {
        setQuestions([
            ...questions,
            { question: '', type: 'single_choice', image: null, path: null, answers: [{ text: '', type: 'choose' }] },
        ]);
    };

    const handleQuestionChange = (index, event) => {
        const newQuestions = [...questions];
        newQuestions[index].question = event.target.value;
        setQuestions(newQuestions);
    };

    const handleAnswerChange = (questionIndex, answerIndex, event) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].answers[answerIndex].text = event.target.value;
        setQuestions(newQuestions);
    };

    const handleAnswerChangeTypeInput = (questionIndex, answerIndex, event) => {
        const newQuestions = [...questions];
        console.log(event.target.value);
        newQuestions[questionIndex].answers[answerIndex].type = event.target.value;
        setQuestions(newQuestions);
    };

    const handleTypeChange = (questionIndex, event) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].type = event.target.value;
        setQuestions(newQuestions);
    };

    const handleTypeChangeInput = (questionIndex, event) => {
        const newQuestions = [...questions];
        console.log(event.target.value);
        newQuestions[questionIndex].answers[0].type = event.target.value;
        console.log(newQuestions);
        setQuestions(newQuestions);
    };

    const handleImageChange = (questionIndex, event) => {
        event.preventDefault();
        console.log(questionIndex);
        console.log(event);

        const files = event.target.files;
        console.log(files);

        const newQuestions = [...questions];
        newQuestions[questionIndex].image = files;
        const imageArray = Array.from(files).map((file) => URL.createObjectURL(file));
        newQuestions[questionIndex].path = imageArray;
        setQuestions(newQuestions);
    };

    const handleImg = (event) => {
        const files = event.target.files;
        console.log(files);
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImg(image);
    };

    const addAnswer = (questionIndex) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].answers.push({ text: '', type: 'choose' });
        setQuestions(newQuestions);
    };

    const addAnswerInput = (questionIndex) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].answers.push({ text: 'null', type: 'number' });
        // newQuestions[questionIndex].answers.push({ text: 'null', type: 'input' });
        setQuestions(newQuestions);
    };

    const removeQuestion = (questionIndex) => {
        const newQuestions = questions.filter((_, index) => index !== questionIndex);
        setQuestions(newQuestions);
    };

    const removeAnswer = (questionIndex, answerIndex) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].answers.splice(answerIndex, 1);
        setQuestions(newQuestions);
    };

    const handleBack = () => {
        console.log(questions);
        navigate('/tai-khoan/quan-ly-khao-sat');
    };

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Box className={cx('AddSurvey', 'container')}>
            <Box py="10px" className={cx('Breadcumb')}>
                <Typography sx={{ paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/">Trang chủ</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan">Tài khoản</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khao-sat">Quản lý chuyên gia đối tác</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khao-sat">Quản lý khảo sát</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="#">Thêm mới khảo sát</Link>
                </Typography>
            </Box>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ flexGrow: 1 }}
                className={cx('wrapper')}
            >
                <Grid className="sticky survey" pb={2} pr={2} xs={4}>
                    <div className="sticky">
                        <TitleUser className={cx('title-wp')} title="Thêm mới khảo sát" />
                        <form id="form_question" method="post" enctype="multipart/form-data">
                            <FormControl sx={{ paddingTop: '10px', marginBottom: '20px' }} fullWidth>
                                <Stack spacing={2}>
                                    <TextField
                                        label="Tiêu đề khảo sát"
                                        variant="outlined"
                                        name="title"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <Box
                                        className={cx('content-wp') + ' wpp'}
                                        style={{
                                            backgroundImage: `url(${img})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        <label className={cx('content')} htmlFor="upload-img-btn">
                                            <TextField
                                                label="Chọn ảnh"
                                                type="file"
                                                accept="image/*"
                                                onChange={(event) => handleImg(event)}
                                                name="img"
                                                style={{ display: 'none' }}
                                                id="upload-img-btn"
                                            />
                                            <Box className={cx('wp-file')}>
                                                <img src={images.camera} alt="chọn ảnh" />
                                            </Box>
                                        </label>
                                        <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>
                                            Tải ảnh lên (4 x 2)
                                        </Typography>
                                    </Box>
                                    {/* <TextField
                                        multiline
                                        label="Mô tả"
                                        variant="outlined"
                                        name="description"
                                        rows={10}
                                        maxRows={16}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-2%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                background: '#fff',
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    /> */}
                                    <SunEditor
                                        name="description"
                                        defaultValue="Nhập nội dung mô tả"
                                        height="500"
                                        setOptions={{
                                            buttonList: [
                                                // ['undo', 'redo'],
                                                ['font', 'fontSize', 'formatBlock'],
                                                // ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                                ['bold', 'underline', 'italic', 'strike'],
                                                ['removeFormat'],
                                                ['outdent', 'indent'],
                                                // ['align', 'horizontalRule', 'list', 'table'],
                                                ['fontColor', 'hiliteColor'],
                                                // ['link', 'image'],
                                                // ['fullScreen', 'showBlocks', 'codeView'],
                                                // ['preview', 'print'],
                                            ],
                                        }}
                                    />
                                </Stack>
                            </FormControl>
                        </form>
                        <div className="sticky">
                            <Button
                                className={cx('btn_submit')}
                                variant="contained"
                                style={{ background: 'rgba(119, 119, 119, 1)' }}
                                onClick={handleBack}
                            >
                                HỦY TẠO
                            </Button>
                            <Button className={cx('btn_submit')} variant="contained" onClick={addQuestion}>
                                THÊM CÂU HỎI
                            </Button>
                            <Button className={cx('btn_submit')} variant="contained" onClick={handleOpenAlert}>
                                HOÀN THÀNH
                            </Button>
                        </div>

                        <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                            <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                                Thông báo
                            </Typography>
                            <Typography sx={{ fontSize: '16px' }}>Xác nhận tạo khảo sát</Typography>
                            <div className="row position-relative pt-4">
                                <button
                                    onClick={() => handleCancel()}
                                    style={{
                                        display: 'block',
                                        background: '#D9D9D9!important',
                                        color: '#333',
                                        fontFamily: 'sans-serif',
                                        fontWeight: 700,
                                    }}
                                    className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                                >
                                    Hủy bỏ
                                </button>
                                <button
                                    onClick={() => onAddSurvey()}
                                    style={{
                                        display: 'block',
                                        right: 0,
                                        background: '#27AB60!important',
                                        color: '#fff',
                                        fontFamily: 'sans-serif',
                                        fontWeight: 700,
                                    }}
                                    className="col-6 position-absolute btn btn-g-reverse p-3"
                                >
                                    Đồng ý
                                </button>
                            </div>
                        </Alert>
                    </div>
                </Grid>
                <Grid pt={9} pr={2} xs={8}>
                    {questions.map((question, questionIndex) => (
                        <Grid
                            key={questionIndex}
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            sx={{ flexGrow: 1 }}
                            className={cx('wrapper-right')}
                        >
                            <CloseIcon className={cx('close-icon')} onClick={() => removeQuestion(questionIndex)} />
                            <Grid pr={2} xs={6}>
                                <FormControl sx={{ paddingTop: '10px', marginBottom: '20px' }} fullWidth>
                                    <Stack spacing={2}>
                                        <TextField
                                            label="Tiêu đề"
                                            variant="outlined"
                                            value={question.question}
                                            onChange={(event) => handleQuestionChange(questionIndex, event)}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    padding: '11.5px 0px',
                                                    // Bỏ hết border trừ lại border-bottom
                                                    border: 'none',
                                                    borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-10%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Bỏ border của fieldset
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel
                                                sx={{
                                                    fontSize: '16px',
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    left: '-14px',
                                                }}
                                                id="demo-simple-select-label"
                                            >
                                                Loại câu khảo sát
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Loại câu khảo sát"
                                                onChange={(event) => handleTypeChange(questionIndex, event)}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        fontSize: '16px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '11.5px 0px',
                                                    },
                                                    '& .MuiInputLabel-formControl': {
                                                        color: '#333',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                        top: '-3%',
                                                        left: '-14px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        span: {
                                                            fontSize: '12px',
                                                        },
                                                        borderRadius: 0,
                                                        borderLeft: 'none', // Bỏ border trái của notchedOutline
                                                        borderRight: 'none', // Bỏ border phải của notchedOutline
                                                        borderTop: 'none', // Bỏ border trên của notchedOutline
                                                    },
                                                }}
                                            >
                                                <MenuItem value="single_choice">Chọn 1 đáp án</MenuItem>
                                                <MenuItem value="multiple_choice">Chọn nhiều đáp án</MenuItem>
                                                {/* <MenuItem value="open_ended">Câu trả lời tự điền</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                        {question.type !== 'open_ended' ? (
                                            question.answers.map((answer, answerIndex) => (
                                                <div className="w-100 position-relative www">
                                                    {answer.type == 'choose' ? (
                                                        <>
                                                            <TextField
                                                                label={
                                                                    answer.type == 'choose'
                                                                        ? 'Đáp án ' + (answerIndex + 1)
                                                                        : 'Đáp án nhập'
                                                                }
                                                                variant="outlined"
                                                                key={answerIndex}
                                                                onChange={(event) =>
                                                                    handleAnswerChange(
                                                                        questionIndex,
                                                                        answerIndex,
                                                                        event,
                                                                    )
                                                                }
                                                                sx={{
                                                                    '.MuiFormControl-root': {
                                                                        width: '100%',
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontSize: '16px',
                                                                        fontWeight: 'bold',
                                                                        padding: '11.5px 0px',
                                                                        border: 'none',
                                                                        borderBottom: '1px solid #ccc',
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: '#333',
                                                                        width: '100%',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '16px',
                                                                        top: '-10%',
                                                                        left: '-14px',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            border: 'none',
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <CloseIcon
                                                                className={cx('close-icon-answer')}
                                                                onClick={() => removeAnswer(questionIndex, answerIndex)}
                                                            />
                                                        </>
                                                    ) : (
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    color: '#333',
                                                                    fontWeight: 'bold',
                                                                    left: '-14px',
                                                                }}
                                                                id="demo-simple-select-label"
                                                            >
                                                                Loại câu đáp án nhập
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Loại câu khảo sát"
                                                                onChange={(event) =>
                                                                    handleAnswerChangeTypeInput(
                                                                        questionIndex,
                                                                        answerIndex,
                                                                        event,
                                                                    )
                                                                }
                                                                defaultValue="number"
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        fontSize: '16px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        padding: '11.5px 0px',
                                                                    },
                                                                    '& .MuiInputLabel-formControl': {
                                                                        color: '#333',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '16px',
                                                                        top: '-3%',
                                                                        left: '-14px',
                                                                    },
                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                        span: {
                                                                            fontSize: '12px',
                                                                        },
                                                                        borderRadius: 0,
                                                                        borderLeft: 'none', // Bỏ border trái của notchedOutline
                                                                        borderRight: 'none', // Bỏ border phải của notchedOutline
                                                                        borderTop: 'none', // Bỏ border trên của notchedOutline
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value="number">Câu trả lời số</MenuItem>
                                                                <MenuItem value="text">Câu trả lời chữ và số</MenuItem>
                                                                <MenuItem value="date">
                                                                    Câu trả lời ngày tháng năm
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <>
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '16px',
                                                            color: '#333',
                                                            fontWeight: 'bold',
                                                            left: '-14px',
                                                        }}
                                                        id="demo-simple-select-label"
                                                    >
                                                        Loại câu đáp án nhập
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Loại câu khảo sát"
                                                        onChange={(event) =>
                                                            handleTypeChangeInput(questionIndex, event)
                                                        }
                                                        defaultValue="number"
                                                        sx={{
                                                            '& .MuiInputBase-input': {
                                                                fontSize: '16px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '11.5px 0px',
                                                            },
                                                            '& .MuiInputLabel-formControl': {
                                                                color: '#333',
                                                                fontWeight: 'bold',
                                                                fontSize: '16px',
                                                                top: '-3%',
                                                                left: '-14px',
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                span: {
                                                                    fontSize: '12px',
                                                                },
                                                                borderRadius: 0,
                                                                borderLeft: 'none', // Bỏ border trái của notchedOutline
                                                                borderRight: 'none', // Bỏ border phải của notchedOutline
                                                                borderTop: 'none', // Bỏ border trên của notchedOutline
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value="number">Câu trả lời số</MenuItem>
                                                        <MenuItem value="text">Câu trả lời chữ và số</MenuItem>
                                                        <MenuItem value="date">Câu trả lời ngày tháng năm</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                        {question.type !== 'open_ended' && (
                                            <div className={cx('add-answer')}>
                                                <span onClick={() => addAnswer(questionIndex)}>Thêm đáp án</span>
                                                {/* <span onClick={() => addAnswerInput(questionIndex)}>
                                                    Thêm đáp án nhập
                                                </span> */}
                                            </div>
                                        )}
                                    </Stack>
                                </FormControl>
                            </Grid>
                            <Grid pt={7} xs={6}>
                                <Box
                                    className={cx('content-wp') + ' wpp'}
                                    style={{
                                        backgroundImage: `url(${question.path})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    <label className={cx('content')} htmlFor={'upload-img-btn-' + questionIndex}>
                                        <TextField
                                            label="Chọn ảnh"
                                            type="file"
                                            accept="image/*"
                                            onChange={(event) => handleImageChange(questionIndex, event)}
                                            style={{ display: 'none' }}
                                            id={'upload-img-btn-' + questionIndex}
                                        />
                                        <Box className={cx('wp-file')}>
                                            <img src={images.camera} alt="chọn ảnh" />
                                        </Box>
                                    </label>
                                    <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>
                                        Tải ảnh lên (4 x 2)
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                    {/* <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ flexGrow: 1 }}
                        className={cx('wrapper-right')}
                    >
                        <CloseIcon className={cx('close-icon')} />
                        <Grid pr={2} xs={6}>
                            <FormControl sx={{ paddingTop: '10px', marginBottom: '20px' }} fullWidth>
                                <Stack spacing={2}>
                                    <TextField
                                        label="Tiêu đề"
                                        variant="outlined"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                padding: '11.5px 0px',
                                                // Bỏ hết border trừ lại border-bottom
                                                border: 'none',
                                                borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                                left: '-14px',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Bỏ border của fieldset
                                                    border: 'none',
                                                },
                                            },
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold', left: '-14px' }}
                                            id="demo-simple-select-label"
                                        >
                                            Loại câu khảo sát
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Loại câu khảo sát"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '11.5px 0px',
                                                },
                                                '& .MuiInputLabel-formControl': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-3%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    span: {
                                                        fontSize: '12px',
                                                    },
                                                    borderRadius: 0,
                                                    borderLeft: 'none', // Bỏ border trái của notchedOutline
                                                    borderRight: 'none', // Bỏ border phải của notchedOutline
                                                    borderTop: 'none', // Bỏ border trên của notchedOutline
                                                },
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </FormControl>
                        </Grid>
                        <Grid pt={7} xs={6}>
                            <Box className={cx('content-wp')}>
                                <label className={cx('content')} htmlFor="upload-img-btn">
                                    <TextField
                                        label="Chọn ảnh"
                                        type="file"
                                        accept="image/*"
                                        // onChange={(e) => handleImageChange(e)}
                                        onClick={(event) => {
                                            event.target.value = null;
                                        }}
                                        style={{ display: 'none' }}
                                        id="upload-img-btn"
                                    />
                                    <Box className={cx('wp-file')}>
                                        <img src={images.camera} alt="chọn ảnh" />
                                    </Box>
                                </label>
                                <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>
                                    Tải ảnh lên (4 x 2)
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ flexGrow: 1 }}
                        className={cx('wrapper-right')}
                    >
                        <CloseIcon className={cx('close-icon')} />
                        <Grid pr={2} xs={6}>
                            <FormControl sx={{ paddingTop: '10px', marginBottom: '20px' }} fullWidth>
                                <Stack spacing={2}>
                                    <TextField
                                        label="Tiêu đề"
                                        variant="outlined"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                padding: '11.5px 0px',
                                                // Bỏ hết border trừ lại border-bottom
                                                border: 'none',
                                                borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                                left: '-14px',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Bỏ border của fieldset
                                                    border: 'none',
                                                },
                                            },
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold', left: '-14px' }}
                                            id="demo-simple-select-label"
                                        >
                                            Loại câu khảo sát
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Loại câu khảo sát"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '11.5px 0px',
                                                },
                                                '& .MuiInputLabel-formControl': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-3%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    span: {
                                                        fontSize: '12px',
                                                    },
                                                    borderRadius: 0,
                                                    borderLeft: 'none', // Bỏ border trái của notchedOutline
                                                    borderRight: 'none', // Bỏ border phải của notchedOutline
                                                    borderTop: 'none', // Bỏ border trên của notchedOutline
                                                },
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Đáp án 1"
                                        variant="outlined"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                padding: '11.5px 0px',
                                                // Bỏ hết border trừ lại border-bottom
                                                border: 'none',
                                                borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                                left: '-14px',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Bỏ border của fieldset
                                                    border: 'none',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Đáp án 2"
                                        variant="outlined"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                padding: '11.5px 0px',
                                                // Bỏ hết border trừ lại border-bottom
                                                border: 'none',
                                                borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                                left: '-14px',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Bỏ border của fieldset
                                                    border: 'none',
                                                },
                                            },
                                        }}
                                    />
                                    <div className={cx('add-answer')}>
                                        <span>Thêm đáp án</span>
                                        <span>Thêm đáp án nhập</span>
                                    </div>
                                </Stack>
                            </FormControl>
                        </Grid>
                        <Grid pt={7} xs={6}>
                            <Box className={cx('content-wp')}>
                                <label className={cx('content')} htmlFor="upload-img-btn">
                                    <TextField
                                        label="Chọn ảnh"
                                        type="file"
                                        accept="image/*"
                                        // onChange={(e) => handleImageChange(e)}
                                        onClick={(event) => {
                                            event.target.value = null;
                                        }}
                                        style={{ display: 'none' }}
                                        id="upload-img-btn"
                                    />
                                    <Box className={cx('wp-file')}>
                                        <img src={images.camera} alt="chọn ảnh" />
                                    </Box>
                                </label>
                                <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>
                                    Tải ảnh lên (4 x 2)
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ flexGrow: 1 }}
                        className={cx('wrapper-right')}
                    >
                        <CloseIcon className={cx('close-icon')} />
                        <Grid pr={2} xs={6}>
                            <FormControl sx={{ paddingTop: '10px', marginBottom: '20px' }} fullWidth>
                                <Stack spacing={2}>
                                    <Stack spacing={2}>
                                        <TextField
                                            label="Tiêu đề"
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    padding: '11.5px 0px',
                                                    // Bỏ hết border trừ lại border-bottom
                                                    border: 'none',
                                                    borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-10%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Bỏ border của fieldset
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel
                                                sx={{
                                                    fontSize: '16px',
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    left: '-14px',
                                                }}
                                                id="demo-simple-select-label"
                                            >
                                                Loại câu khảo sát
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Loại câu khảo sát"
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        fontSize: '16px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '11.5px 0px',
                                                    },
                                                    '& .MuiInputLabel-formControl': {
                                                        color: '#333',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                        top: '-3%',
                                                        left: '-14px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        span: {
                                                            fontSize: '12px',
                                                        },
                                                        borderRadius: 0,
                                                        borderLeft: 'none', // Bỏ border trái của notchedOutline
                                                        borderRight: 'none', // Bỏ border phải của notchedOutline
                                                        borderTop: 'none', // Bỏ border trên của notchedOutline
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label="Đáp án 1"
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    padding: '11.5px 0px',
                                                    // Bỏ hết border trừ lại border-bottom
                                                    border: 'none',
                                                    borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-10%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Bỏ border của fieldset
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Đáp án 2"
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    padding: '11.5px 0px',
                                                    // Bỏ hết border trừ lại border-bottom
                                                    border: 'none',
                                                    borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-10%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Bỏ border của fieldset
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <div className={cx('add-answer')}>
                                            <span>Thêm đáp án</span>
                                            <span>Thêm đáp án nhập</span>
                                        </div>
                                    </Stack>
                                    <Stack spacing={2}>
                                        <TextField
                                            label="Tiêu đề"
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    padding: '11.5px 0px',
                                                    // Bỏ hết border trừ lại border-bottom
                                                    border: 'none',
                                                    borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-10%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Bỏ border của fieldset
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel
                                                sx={{
                                                    fontSize: '16px',
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    left: '-14px',
                                                }}
                                                id="demo-simple-select-label"
                                            >
                                                Loại câu khảo sát
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Loại câu khảo sát"
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        fontSize: '16px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '11.5px 0px',
                                                    },
                                                    '& .MuiInputLabel-formControl': {
                                                        color: '#333',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                        top: '-3%',
                                                        left: '-14px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        span: {
                                                            fontSize: '12px',
                                                        },
                                                        borderRadius: 0,
                                                        borderLeft: 'none', // Bỏ border trái của notchedOutline
                                                        borderRight: 'none', // Bỏ border phải của notchedOutline
                                                        borderTop: 'none', // Bỏ border trên của notchedOutline
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label="Đáp án 1"
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    padding: '11.5px 0px',
                                                    // Bỏ hết border trừ lại border-bottom
                                                    border: 'none',
                                                    borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-10%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Bỏ border của fieldset
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Đáp án 2"
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    padding: '11.5px 0px',
                                                    // Bỏ hết border trừ lại border-bottom
                                                    border: 'none',
                                                    borderBottom: '1px solid #ccc', // Đặt border-bottom theo ý muốn
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-10%',
                                                    left: '-14px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Bỏ border của fieldset
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <div className={cx('add-answer')}>
                                            <span>Thêm đáp án</span>
                                            <span>Thêm đáp án nhập</span>
                                        </div>
                                    </Stack>
                                </Stack>
                            </FormControl>
                        </Grid>
                        <Grid pt={7} xs={6}>
                            <Box className={cx('content-wp')}>
                                <label className={cx('content')} htmlFor="upload-img-btn">
                                    <TextField
                                        label="Chọn ảnh"
                                        type="file"
                                        accept="image/*"
                                        // onChange={(e) => handleImageChange(e)}
                                        onClick={(event) => {
                                            event.target.value = null;
                                        }}
                                        style={{ display: 'none' }}
                                        id="upload-img-btn"
                                    />
                                    <Box className={cx('wp-file')}>
                                        <img src={images.camera} alt="chọn ảnh" />
                                    </Box>
                                </label>
                                <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>
                                    Tải ảnh lên (4 x 2)
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddSurvey;
