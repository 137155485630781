import classNames from 'classnames/bind';
import styles from '../Home.module.scss';
import slugify from 'slugify';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Section from '~/components/Section/Section';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Alert from '~/components/Alert';
import { event } from '~/Store/Action/eventActions';
import { joinEvent, interestedEvent } from '~/Store/Action/eventActions';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const cx = classNames.bind(styles);

function Event({ events }) {
    let navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/su-kien');
    };

    const [visible, setVisible] = useState(false);

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const [visible1, setVisible1] = useState(false);

    const [alertState1, setAlertState1] = useState({
        visible: false,
    });

    const [id, setId] = useState(null);

    const dispatch = useDispatch();

    const list = useSelector((state) => state.event.list);

    useEffect(() => {
        dispatch(event()); // Dispatch action để lấy dữ liệu khi component được mount
    }, []);

    const auth = useSelector((state) => state.auth);

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOpenAlert1 = (defaultKey) => {
        setVisible1(true);
        setAlertState1({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel1 = () => {
        setVisible1(false);
    };

    const onJoinEvent = async () => {
        if (await dispatch(joinEvent(auth.token, id))) {
            handleCancel();
        }
    };

    const onInterestedEvent = async () => {
        if (await dispatch(interestedEvent(auth.token, id))) {
            handleCancel1();
        }
    };

    const handleSetIdToEvent = async (id) => {
        setId(id);
        handleOpenAlert();
    };

    const handleSetIdToEvent1 = async (id) => {
        setId(id);
        handleOpenAlert1();
    };

    return (
        <Section className={cx('Event')} buttonTitle="Xem thêm" sectionTitle="Sự kiện" onClickButton={handleRedirect}>
            <div className={cx('Event_wrapper') + ' d-show-1'}>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={10}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{ delay: 2000 }}
                    className="w-100"
                >
                    {events?.data?.events.map((item, index) => (
                        <>
                            <SwiperSlide>
                                <div className={cx('event_left')}>
                                    <Link className="img-event" to={'/su-kien/' + slugify(item.title) + '/' + item.id}>
                                        <img style={{ maxHeight: '277px' }} src={item.image} alt={item.title} />
                                    </Link>
                                    <div className={cx('event_default')}>
                                        <div className={cx('event_text')}>
                                            <Link to={'/su-kien/' + slugify(item.title) + '/' + item.id}>
                                                {item.title}
                                            </Link>
                                            <div className={cx('event_join')}>
                                                <span>Chưa tham gia</span>
                                                <span>
                                                    {item.status == 1 && (
                                                        <span className="text-success">Đang diễn ra</span>
                                                    )}{' '}
                                                    {item.status == 0 && 'Chưa diễn ra'}{' '}
                                                    {item.status == -1 && 'Đã kết thúc'}
                                                </span>
                                            </div>
                                            <span className={cx('event_date')}>{item.created_at.substr(0, 10)}</span>
                                        </div>
                                        <div className={cx('event_action')}>
                                            <button onClick={() => handleSetIdToEvent1(item.id)}>Quan tâm</button>
                                            <button  onClick={() => handleSetIdToEvent(item.id)}>Tham gia</button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </>
                    ))}
                </Swiper>
            </div>
            <div className={cx('Event_wrapper') + ' d-show-2'}>
                {events?.data?.events.map((item, index) => (
                    <>
                        {/* {index == events?.data?.events.length - 1 && ( */}
                        {index == 0 && (
                            <div className={cx('event_left')}>
                                <Link to={'/su-kien/' + slugify(item.title) + '/' + item.id}>
                                    <img style={{ maxHeight: '277px' }} src={item.image} alt={item.title} />
                                </Link>
                                <div className={cx('event_default')}>
                                    <div className={cx('event_text')}>
                                        <Link to={'/su-kien/' + slugify(item.title) + '/' + item.id}>{item.title}</Link>
                                        <div className={cx('event_join')}>
                                            <span>Chưa tham gia</span>
                                            <span>
                                                {item.status == 1 && <span className="text-success">Đang diễn ra</span>}{' '}
                                                {item.status == 0 && 'Chưa diễn ra'}{' '}
                                                {item.status == -1 && 'Đã kết thúc'}
                                            </span>
                                        </div>
                                        <span className={cx('event_date')}>{item.created_at.substr(0, 10)}</span>
                                    </div>
                                    <div className={cx('event_action')}>
                                        <button onClick={() => handleSetIdToEvent1(item.id)}>Quan tâm</button>
                                        <button onClick={() => handleSetIdToEvent(item.id)}>Tham gia</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ))}
                <div className={cx('event_right')}>
                    {events?.data?.events.map((item, index) => (
                        <>
                            {index != 3 && (
                                <div className={cx('event_item')}>
                                    <div className={cx('event_text')}>
                                        <Link to={'/su-kien/' + slugify(item.title) + '/' + item.id}>{item.title}</Link>
                                        <div className={cx('event_join')}>
                                            <span>Chưa tham gia</span>
                                            <span>
                                                {item.status == 1 && <span className="text-success">Đang diễn ra</span>}{' '}
                                                {item.status == 0 && 'Chưa diễn ra'}{' '}
                                                {item.status == -1 && 'Đã kết thúc'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={cx('event_action')}>
                                        <button onClick={() => handleSetIdToEvent1(item.id)}>Quan tâm</button>
                                        <button onClick={() => handleSetIdToEvent(item.id)}>Tham gia</button>
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </div>

            <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Thông báo
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>Tham gia sự kiện này</Typography>
                <div className="row position-relative pt-4">
                    <button
                        onClick={() => handleCancel()}
                        style={{
                            display: 'block',
                            background: '#D9D9D9!important',
                            color: '#333',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                    >
                        Hủy bỏ
                    </button>
                    <button
                        onClick={() => onJoinEvent()}
                        style={{
                            display: 'block',
                            right: 0,
                            background: '#27AB60!important',
                            color: '#fff',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className="col-6 position-absolute btn btn-g-reverse p-3"
                    >
                        Đồng ý
                    </button>
                </div>
            </Alert>

            <Alert alertState={alertState1} visible={visible1} handleCancel={handleCancel1}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Thông báo
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>Quan tâm tới sự kiện này</Typography>
                <div className="row position-relative pt-4">
                    <button
                        onClick={() => handleCancel1()}
                        style={{
                            display: 'block',
                            background: '#D9D9D9!important',
                            color: '#333',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                    >
                        Hủy bỏ
                    </button>
                    <button
                        onClick={() => onInterestedEvent()}
                        style={{
                            display: 'block',
                            right: 0,
                            background: '#27AB60!important',
                            color: '#fff',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className="col-6 position-absolute btn btn-g-reverse p-3"
                    >
                        Đồng ý
                    </button>
                </div>
            </Alert>
        </Section>
    );
}

export default Event;
