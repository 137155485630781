import Cookies from 'js-cookie';

const api_url = process.env.REACT_APP_API_URL;

const initialState = {
    list: null,
    search: null,
    expert: null,
    history_keyword: Cookies.get('keyword') || [],
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HOME_SUCCESS':
            return {
                ...state,
                list: action.payload,
            };
        case 'EXPERTS_SUCCESS':
            return {
                ...state,
                expert: action.payload,
            };
        case 'HOME_SEARCH_SUCCESS':
            return {
                ...state,
                search: action.payload,
            };
        case 'KEYWORD_SEARCH_SUCCESS':
            const keywordsFromStorage = Cookies.get('keyword') ? JSON.parse(Cookies.get('keyword')) : [];
            // Thêm keyword mới vào mảng
            const updatedKeywords = [...keywordsFromStorage, action.payload];
            Cookies.set('keyword', JSON.stringify(updatedKeywords), { expires: 10.5 });
            console.log(updatedKeywords);
            console.log(JSON.stringify(Cookies.get('keyword')));

            return {
                ...state,
                history_keyword: Cookies.get('keyword'),
            };
        case 'HOME_FAILURE':
            return state;
        case 'KEYWORD_SEARCH_DELETE':
            const keyword = Cookies.get('keyword') ? JSON.parse(Cookies.get('keyword')) : [];
            const updatedKeyword = keyword.slice().reverse();
            updatedKeyword.splice(action.payload, 1);
            const arr = updatedKeyword.slice().reverse();

            Cookies.set('keyword', JSON.stringify(arr), { expires: 10.5 });

            return {
                ...state,
                history_keyword: JSON.stringify(arr), // Update the state with the updated cookie value
            };
        default:
            return state;
    }
};

export default homeReducer;
