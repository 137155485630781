import classNames from 'classnames/bind';
import styles from './SignInForm.module.scss';
import { Stack, TextField, FormGroup, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../Store/Action/authActions.js';

const cx = classNames.bind(styles);

const SignInForm = ({ onClickForgotPassword, handleCancel }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {}, [auth]);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const onLogin = await dispatch(login(username, password));
        if (onLogin) {
            handleCancel();
            await setTimeout(function () {
                window.location.reload();
            }, 2000);
        }
    };

    return (
        <>
            <p>Xin vui lòng đăng nhập tài khoản của bạn để trải nghiệm những dịch vụ tốt nhất của chúng tôi</p>
            <FormGroup>
                <Stack spacing={2}>
                    <TextField
                        className={cx('input-change')}
                        fullWidth
                        id="email"
                        label="Email của bạn"
                        value={username}
                        onChange={handleUsernameChange}
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px',
                            },
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px',

                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <TextField
                        className={cx('input-change')}
                        fullWidth
                        type="password"
                        id="re-password"
                        label="Mật khẩu"
                        value={password}
                        onChange={handlePasswordChange}
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px',
                            },
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px',
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <span className={cx('forgot_password')} onClick={onClickForgotPassword}>
                        Quên mật khẩu ?
                    </span>
                    <Button onClick={handleSubmit} className={cx('button-change')} variant="contained">
                        ĐĂNG NHẬP
                    </Button>
                </Stack>
            </FormGroup>
        </>
    );
};

export default SignInForm;
