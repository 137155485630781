import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const type_event = (type, token, page) => {
    return async (dispatch) => {
        try {
            // Gọi API sự kiện
            console.log(token);
            const response = await api.get(
                'api/events/my?opentime_sort=true',
                {
                    type: type,
                    page: page,
                },
                {
                    // headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                    // },
                },
            );
            dispatch({ type: 'EVENT_TYPE_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'EVENT_FAILURE', payload: error.message });
        }
    };
};

export const event = (type, page) => {
    return async (dispatch) => {
        try {
            // Gọi API sự kiện
            const response = await api.get(
                'api/events?opentime_sort=true',
                {
                    type: type,
                    page: page,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            dispatch({ type: 'EVENT_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'EVENT_FAILURE', payload: error.message });
        }
    };
};

export const myEvent = (user) => {
    return async (dispatch) => {
        try {
            // Gọi API sự kiện
            const response = await api.get('api/events?owner_id=' + user.id, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'MY_EVENT_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'EVENT_FAILURE', payload: error.message });
        }
    };
};

export const add_event = (token, formData) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/events', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã thêm 1 sự kiện mới');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const edit_event = (token, id, formData) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/events/' + id, formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã sửa sự kiện thành công');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const delete_event = (token, id) => {
    return async (dispatch) => {
        try {
            const response = await api.delete(
                'api/events/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status === 200) {
                showToast('Đã xoá sự kiện thành công');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const joinEvent = (token, id) => {
    return async (dispatch) => {
        try {
            var formData = new FormData();
            formData.append('type', 'registered');

            const response = await api.post('api/events/' + id + '/register', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã gửi yêu cầu tham gia sự kiện thành công');
                return true;
            } else {
                const message = 'Gửi yêu cầu tham gia thất bại vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const interestedEvent = (token, id) => {
    return async (dispatch) => {
        try {
            var formData = new FormData();
            formData.append('type', 'interested');

            const response = await api.post('api/events/' + id + '/register', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Cảm ơn bạn đã quan tâm tới sự kiện');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};
