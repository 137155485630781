import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Breadcumb from '~/components/Breadcrumb';
import images from '~/assets/images';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { detailBook } from '~/Store/Action/detailBookActions';
import { detailCourseVideo, checkBuyCourse } from '~/Store/Action/detailCourseActions';
import { rating } from '~/Store/Action/courseActions';
import { add_cart, get_cart } from '~/Store/Action/cartActions';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const formatCurrency = (value) => {
    // Chuyển đổi số thành định dạng tiền tệ
    const formattedValue = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);

    return formattedValue;
};

const DetailCourseware = () => {
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [item, setItem] = useState({});
    const video = searchParams.get('video');
    console.log(video);

    const auth = useSelector((state) => state.auth);
    const data = useSelector((state) => state.detailCourse?.data?.data);
    const check = useSelector((state) => state.detailCourse?.check?.data);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
            load();
            async function load() {
                await dispatch(checkBuyCourse(id, auth.token));
            }
        }
        dispatch(detailCourseVideo(id));
    }, [auth, id]);

    useEffect(() => {
        if (data?.videos.length > 0) {
            const d = data?.videos?.find((item, index) => item?.id == video);

            setItem(d);
        }
    }, [video, data]);

    const generateYoutubeEmbedUrl = (url) => {
        const regex =
            /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        if (match) {
            const youtubeVideoId = match[1];
            return `https://www.youtube.com/embed/${youtubeVideoId}`;
        }
        return null;
    };
    return (
        <div className="container">
            <Breadcumb title="Xem video" />

            <div className="row mt-3">
                <div className="card p-0 border-0" style={{ background: 'none' }}>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="w-100 text-center mt-3">
                                {check != null && check == true ? (
                                    item?.file ? (
                                        <iframe
                                            className="w-100 img-thumbnail"
                                            src={generateYoutubeEmbedUrl(item?.file)}
                                            style={{ minHeight: '355px' }}
                                            title=""
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    ) : (
                                        <img
                                            style={{ height: '330px', maxHeight: '330px' }}
                                            className="img-fluid img-thumbnail"
                                            src={images.ItemDetailEvent}
                                        />
                                    )
                                ) : (
                                    <div className="alert alert-warning">Bạn cần mua khoá học này để xem</div>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="card-body">
                                <div className="row ">
                                    <h3 style={{ paddingBottom: '20px' }}>Danh sách chương</h3>
                                    <div className="col-12">
                                        <ul>
                                            {data?.videos.map((item, index) => (
                                                <li
                                                    className="d-flex bg-light border p-2 align-items-center mb-3"
                                                    key={index}
                                                >
                                                    <Link
                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                        to={
                                                            '/khoa-hoc-video/xem-video/' +
                                                            item?.course_id +
                                                            '?video=' +
                                                            item?.id
                                                        }
                                                    >
                                                        <div className="p-1 me-3">
                                                            {check != null && check == true ? (
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <rect
                                                                        width="24"
                                                                        height="24"
                                                                        rx="5"
                                                                        fill="#777777"
                                                                    />
                                                                    <path
                                                                        d="M15.8854 10.8393L9.46873 7.21913C9.25918 7.09937 9.02164 7.03726 8.78029 7.0391C8.53894 7.04095 8.30239 7.10668 8.09469 7.22963C7.89234 7.34536 7.72423 7.51258 7.60743 7.71432C7.49063 7.91605 7.42931 8.1451 7.42969 8.37821V15.6185C7.42931 15.8517 7.49063 16.0807 7.60743 16.2824C7.72423 16.4842 7.89234 16.6514 8.09469 16.7671C8.30241 16.89 8.53896 16.9557 8.78029 16.9576C9.02163 16.9594 9.25916 16.8973 9.46873 16.7776L15.8854 13.1575C16.0923 13.0428 16.2648 12.8749 16.3849 12.671C16.505 12.4672 16.5684 12.235 16.5684 11.9984C16.5684 11.7618 16.505 11.5295 16.3849 11.3257C16.2648 11.1219 16.0923 10.9539 15.8854 10.8393Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    className="rounded-3"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <rect
                                                                        width="24"
                                                                        height="24"
                                                                        rx="5"
                                                                        fill="#777777"
                                                                    />
                                                                    <path
                                                                        d="M15.7057 10.7631V9.11604C15.7057 7.05722 14.0586 5.41016 11.9998 5.41016C9.94095 5.41016 8.29389 7.05722 8.29389 9.11604V10.7631C7.59389 10.7631 7.05859 11.2984 7.05859 11.9984V17.3513C7.05859 18.0102 7.59389 18.5866 8.29389 18.5866H15.7057C16.4057 18.5866 16.9409 18.0102 16.9409 17.3513V11.9984C16.9409 11.2984 16.4057 10.7631 15.7057 10.7631ZM9.11742 9.11604C9.11742 7.51016 10.3939 6.23369 11.9998 6.23369C13.6057 6.23369 14.8821 7.51016 14.8821 9.11604V10.7631H9.11742V9.11604ZM12.4115 15.2102V16.116C12.4115 16.3631 12.2468 16.5278 11.9998 16.5278C11.7527 16.5278 11.588 16.3631 11.588 16.116V15.2102C11.0939 15.0455 10.7645 14.5925 10.7645 14.0572C10.7645 13.3572 11.2998 12.8219 11.9998 12.8219C12.6998 12.8219 13.2351 13.3572 13.2351 14.0572C13.2351 14.5925 12.9057 15.0455 12.4115 15.2102Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <span>{item?.title}</span>
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailCourseware;
