import classNames from 'classnames/bind';
import styles from './AddVideo.module.scss';
import { Modal as AntdModal } from 'antd';

const cx = classNames.bind(styles);

const AddVideo = ({visible, handleCancel, children}) => {
    
    return (
        <AntdModal
            visible={visible}
            title={null}
            onCancel={handleCancel}
            className={cx('AddVideo')}
        >
            <div className={cx('AddVideo-wrapper')}>
                {children}
            </div>
        </AntdModal>
    );
}

export default AddVideo;