import Cookies from 'js-cookie';

const api_url = process.env.REACT_APP_API_URL;

const initialState = {
    list: null,
    listBookCategory: null,
    listBookCategoryId: null,
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CATEGORY_SUCCESS':
            return {
                ...state,
                list: action.payload,
            };
        case 'CATEGORY_BOOK_SUCCESS':
            return {
                ...state,
                listBookCategory: action.payload,
            };
        case 'CATEGORY_FAILURE':
            return state;

        case 'CATEGORYID_SUCCESS':
            return {
                ...state,
                list: action.payload,
            };
        case 'CATEGORYID_BOOK_SUCCESS':
            return {
                ...state,
                listBookCategoryId: action.payload,
            };
        case 'CATEGORYID_FAILURE':
            return state;
        default:
            return state;
    }
};

export default categoryReducer;
