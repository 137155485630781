import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import Section from '~/components/HistoryOrder/Section';
import styles from './Complete.module.scss';
import { my_order } from '~/Store/Action/historyActions';
import { format } from 'date-fns';
import Pagination from '~/components/Pagination';
const cx = classNames.bind(styles);

const Complete = () => {
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const list = useSelector((state) => state.history.listOrder?.data.orders);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(my_order(JSON.parse(auth.user).id, auth.token, 'completed',1));
    }, [auth]);
    let page = list?.current_page;
    // console.log(page);
    let totalPage = list?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(my_order(JSON.parse(auth.user).id, auth.token, 'completed', prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(my_order(JSON.parse(auth.user).id, auth.token, 'completed', nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(my_order(JSON.parse(auth.user).id, auth.token, 'completed', pageNumber));
    };
    return (
        <Section>
            {list?.data?.length > 0 ? (
                <>
                {list?.data?.map((item, index) => (
                    <Grid className={cx('item-wp')} pb={1} pr={2} item xs={12} sm={12} md={6}>
                        <Link to={'/tai-khoan/lich-su-don-hang/' + item.id} className={cx('item')}>
                            <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
                                Mã đơn: {item?.ref}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                                <Typography sx={{ color: '#27AB60', fontSize: '16px' }}>{item?.user_name}</Typography>
                                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
                                    {item?.order_items.length} sản phẩm
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy')}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: item?.status == 'Đang chờ' ? 'rgba(249, 171, 92, 1)' : '#27AB60',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {item?.status === 'pending' && 'Đang chờ'}
                                    {item?.status === 'cancelled' && 'Đã hủy'}
                                    {item?.status === 'confirmed' && 'Đang giao'}
                                    {item?.status === 'completed' && 'Hoàn thành'}
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                ))}
                <Pagination
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    handlePageChange={handlePageChange}
                    page={list?.current_page}
                    totalPage={list?.total}
                />
            </>
            ) : (
                <h2>Hiện tại không có lịch sử nào!</h2>
            )}
        </Section>
    );
};

export default Complete;
