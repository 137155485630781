const images = {
    // Icons
    noImage: require('~/assets/images/Icons/no-image.png'),
    logo: require('~/assets/images/Icons/logo.png'),
    tbct: require('~/assets/images/home/tbct.jpg'),
    iconLogin: require('~/assets/images/Icons/icon-login.png'),
    iconSearch: require('~/assets/images/Icons/icon-search.png'),
    iconCart: require('~/assets/images/Icons/icon-cart.png'),
    iconNotification: require('~/assets/images/Icons/icon-notification.png'),
    iconSuccessOrder: require('~/assets/images/Icons/icon-success-notification.png'),
    iconArrowDown: require('~/assets/images/Icons/icon-arrowdown.png'),
    user_profile: require('~/assets/images/Icons/user_profile.svg').default,
    manage_profile: require('~/assets/images/Icons/manage_profile.svg').default,
    upgra_profile: require('~/assets/images/Icons/upgra_profile.svg').default,
    history_profile: require('~/assets/images/Icons/history_profile.svg').default,
    service_profile: require('~/assets/images/Icons/service_profile.svg').default,
    evaluate_profile: require('~/assets/images/Icons/evaluate_profile.svg').default,
    course_profile: require('~/assets/images/Icons/course_profile.svg').default,
    book_profile: require('~/assets/images/Icons/book_profile.svg').default,
    lock_profile: require('~/assets/images/Icons/lock_profile.svg').default,
    upgrade_icon: require('~/assets/images/Icons/upgrade_icon.svg').default,
    icon_logout: require('~/assets/images/Icons/icon_logout.svg').default,
    icon_info: require('~/assets/images/Icons/icon_info.png'),
    code_share: require('~/assets/images/Icons/code.svg').default,

    camera: require('~/assets/images/Icons/camera.svg').default,
    file: require('~/assets/images/Icons/file.svg').default,
    cartOv: require('~/assets/images/Icons/cartOv.svg').default,
    CartPer: require('~/assets/images/Icons/CartPer.svg').default,
    // Home
    Banner: require('~/assets/images/home/banner.png'),
    post_featered: require('~/assets/images/home/post_featered.png'),
    category: require('~/assets/images/home/category.png'),
    event: require('~/assets/images/home/event.png'),
    lesson: require('~/assets/images/home/lesson.png'),
    avatar: require('~/assets/images/home/avatar.png'),
    course_data: require('~/assets/images/home/course_data.png'),
    // About
    Item: require('~/assets/images/about/item.png'),
    Item1: require('~/assets/images/about/item-1.png'),
    Item2: require('~/assets/images/about/item-2.png'),
    ItemHot: require('~/assets/images/about/item-hot.png'),
    AboutUs: require('~/assets/images/about/AboutUs.png'),
    AboutUsContent: require('~/assets/images/about/AboutUsContent.png'),
    // Post
    ItemPost: require('~/assets/images/post/item.png'),
    DetailCourseHistory: require('~/assets/images/post/DetailCourseHistory.png'),
    // Survay
    DoSurvay: require('~/assets/images/survay/DoSurvay.png'),
    survay: require('~/assets/images/survay/survay.png'),
    // Event
    ItemEvent: require('~/assets/images/event/eventItem.png'),
    ItemDetailEvent: require('~/assets/images/event/itemDetailEvent.png'),
    // Auth
    banner_auth: require('~/assets/images/auth/banner-auth.png'),
    AvatarName: require('~/assets/images/auth/avatarName.png'),
    Avatar: require('~/assets/images/auth/avatar.png'),
    review1: require('~/assets/images/auth/review-1.png'),
    course1: require('~/assets/images/auth/course-1.png'),
    logoBanking: require('~/assets/images/auth/logoBanking.png'),
    upgrade: require('~/assets/images/auth/upgrade.png'),
    chooseFile: require('~/assets/images/auth/choose-file.png'),
    productUser: require('~/assets/images/auth/product-user.png'),
    pdf: require('~/assets/images/auth/img_pdf.png'),
    reviewImg: require('~/assets/images/auth/review_img.png'),
    // Cart
    cart_item: require('~/assets/images/cart/item.png'),
    // Service
    ItemService: require('~/assets/images/dịch vụ học thuật.jpg'),
    Upload: require('~/assets/images/service/upload.png'),

    //footer
    footerPayment: require('~/assets/images/payment/pay.png'),
    footerPayment2: require('~/assets/images/payment/baaokim.jpg'),

    qr: require('~/assets/images/install/qr.png'),
    googlePlay: require('~/assets/images/install/googlePlay.png'),
    appStore: require('~/assets/images/install/appStore.png'),

    avatarDefault: require('~/assets/images/user/avatar.png'),

    loading: require('~/assets/images/loading/Loading.gif'),
    loading_1: require('~/assets/images/loading/Loading_1.gif'),
    loading_2: require('~/assets/images/loading/Loading_2.gif'),
};

export default images;
