const initialState = {
    data: null,
    check: null,
    check1: null,
    file: null,
};

const surveyDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DETAIL_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'RESET_DETAIL_SUCCESS':
            return {
                ...state,
                data: null,
                check: null,
                check1: null,
            };
        case 'CHECK_DETAIL1_SUCCESS':
            console.log('check 1', action.payload);
            return {
                ...state,
                check: action.payload,
            };
        case 'DETAIL_FILE_SUCCESS':
            return {
                ...state,
                file: action.payload,
            };
        case 'CHECK_DETAIL_SUCCESS':
            console.log(action.payload);
            const data = action.payload.data;

            // var text = '';

            const newSurveyArray = data.survey.questions.map((question) => {
                const questionAnswers = question.answers.map((answer) => {
                    const answeredQuestion = data.answered.find((answered) => {
                        console.log(answered.survey_question_answer_id, answer.id);
                        return answered.survey_question_answer_id === answer.id;
                    });
                    console.log('answer', answer);
                    console.log('answeredQuestion', answeredQuestion);
                    return {
                        ...answer,
                        check: answeredQuestion ? true : false,
                    };
                });
                console.log(questionAnswers);

                const answeredQuestion1 = data.answered.find((answered) => answered.survey_question_id === question.id);
                const text = answeredQuestion1.survey_question_answer_text;

                console.log(answeredQuestion1);
                return {
                    ...question,
                    text: text,
                    answers: questionAnswers,
                };
            });

            console.log(newSurveyArray);

            return {
                ...state,
                // check: action.payload,
                check1: newSurveyArray,
            };
        case 'DETAIL_FAILURE':
            return state;
        default:
            return state;
    }
};

export default surveyDetailReducer;
