import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Breadcumb from '~/components/Breadcrumb';
import { surveyDetail, doSurvey } from '~/Store/Action/surveyDetailActions';
import { format } from 'date-fns';
import images from '~/assets/images';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

const DoSurvay = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    const [surveyAnswers, setSurveyAnswers] = useState([]);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        if (id) {
            dispatch(surveyDetail(id, auth.token));
        }
    }, [auth, id]);

    const data = useSelector((state) => state.surveyDetail?.data?.data);
    const check1 = useSelector((state) => state.surveyDetail?.check1);
    const check = useSelector((state) => state.surveyDetail?.check);

    const handleAnswerChange = (type, questionId, answerId, answerText) => {
        const existingAnswerIndex = surveyAnswers.findIndex((answer) => answer.survey_question_id === questionId);

        console.log(answerText);
        if (existingAnswerIndex !== -1) {
            const existingAnswer = { ...surveyAnswers[existingAnswerIndex] };
            const existingAnswerIds = [...existingAnswer.survey_question_answer_ids];

            if (answerText) {
                existingAnswer.survey_question_answer_text = answerText;
            }

            const answerIndex = existingAnswerIds.indexOf(answerId);

            if (answerIndex === -1) {
                existingAnswerIds.push(answerId);
                console.log('them', answerText);
            } else {
                existingAnswerIds.splice(answerIndex, 1);
                console.log('loai', answerText);
            }

            existingAnswer.survey_question_answer_ids = existingAnswerIds;

            const updatedAnswers = [...surveyAnswers];
            updatedAnswers[existingAnswerIndex] = existingAnswer;

            setSurveyAnswers(updatedAnswers);
        } else {
            // Tạo mới câu trả lời
            const newAnswer = {
                survey_question_id: questionId,
                survey_question_type: type,
                survey_question_answer_ids: [answerId],
                survey_question_answer_text: answerText,
            };

            setSurveyAnswers([...surveyAnswers, newAnswer]);
        }
    };

    const handleAnswerChange1 = (type, questionId, answerId, answerText) => {
        const existingAnswerIndex = surveyAnswers.findIndex((answer) => answer.survey_question_id === questionId);

        console.log(answerText);
        if (existingAnswerIndex !== -1) {
            const existingAnswer = { ...surveyAnswers[existingAnswerIndex] };
            const existingAnswerIds = [...existingAnswer.survey_question_answer_ids];

            if (answerText) {
                existingAnswer.survey_question_answer_text = answerText;
            }

            const answerIndex = existingAnswerIds.indexOf(answerId);

            if (answerIndex === -1) {
                existingAnswerIds.push(answerId);
                console.log('them', answerText);
            } else {
                existingAnswerIds.splice(answerIndex, 1);
                console.log('loai', answerText);
            }

            existingAnswer.survey_question_answer_ids = existingAnswerIds;
            existingAnswer.survey_question_type = type;

            const updatedAnswers = [...surveyAnswers];
            updatedAnswers[existingAnswerIndex] = existingAnswer;

            setSurveyAnswers(updatedAnswers);
        } else {
            // Tạo mới câu trả lời
            const newAnswer = {
                survey_question_id: questionId,
                survey_question_type: type,
                survey_question_answer_ids: [answerId],
                survey_question_answer_text: answerText,
            };

            setSurveyAnswers([...surveyAnswers, newAnswer]);
        }

        const updatedCollapseStates = {
            ...collapseStates,
            [questionId]: !collapseStates[questionId],
        };
        setCollapseStates(updatedCollapseStates);
    };

    const handleSurveySubmit = async () => {
        console.log(surveyAnswers);
        const formattedAnswers = await surveyAnswers.map((answer) => {
            const cleanedAnswer = {
                survey_question_id: answer.survey_question_id,
                survey_question_type: answer.survey_question_type,
            };

            if (Array.isArray(answer.survey_question_answer_ids) && answer.survey_question_answer_ids.length > 0) {
                if (answer.survey_question_type == 'single_choice') {
                    const uniqueLastElementArray = answer.survey_question_answer_ids
                        .filter((item) => item !== undefined)
                        .filter((item) => item !== null) // Loại bỏ các giá trị undefined
                        .slice(-1);
                    console.log(uniqueLastElementArray);
                    const arr = uniqueLastElementArray.join(',');
                    cleanedAnswer.survey_question_answer_ids = arr;
                    console.log(arr);
                } else {
                    if (answer.survey_question_answer_ids.join(',') != '') {
                        const uniqueLastElementArray = answer.survey_question_answer_ids
                            .filter((item) => item !== undefined)
                            .filter((item) => item !== null);
                        const text = uniqueLastElementArray.join(',');
                        const formattedText = text.endsWith(',') ? text.slice(0, -1) : text;
                        const cleanedString = formattedText.trim().replace(/,+/g, ',');

                        const finalString = cleanedString.replace(/^,|,$/g, '');

                        const cleanedString1 = finalString.replace(/,+/g, ',');

                        const finalString1 = cleanedString1.replace(/^,|,$/g, '');

                        // // Loại bỏ khoảng trắng trước và sau mỗi dấu phẩy
                        // const cleanedString = input.replace(/\s*,\s*/g, ',');

                        // // Loại bỏ dấu phẩy thừa liên tiếp
                        // const finalString = cleanedString.replace(/,+/g, ',');

                        // // Loại bỏ dấu phẩy ở đầu và cuối chuỗi
                        // return finalString.replace(/^,|,$/g, '');
                        cleanedAnswer.survey_question_answer_ids = finalString1;
                    }
                }
            }

            if (answer.survey_question_answer_text != '') {
                cleanedAnswer.survey_question_answer_text = answer.survey_question_answer_text;
            } else {
                cleanedAnswer.survey_question_answer_text = null;
            }

            return cleanedAnswer;
        });

        const surveyData = {
            answers: formattedAnswers,
        };

        console.log(surveyData);

        await dispatch(doSurvey(id, auth.token, surveyData));
    };

    const initializeCollapseStates = (questions) => {
        const initialStates = {};
        questions.forEach((question) => {
            initialStates[question.id] = false; // Ban đầu đóng Collapse
        });
        return initialStates;
    };

    const [open, setOpen] = useState(false);
    const [collapseStates, setCollapseStates] = useState(initializeCollapseStates(data?.questions || []));

    return (
        <div className="container">
            <Breadcumb title="Khảo sát" slug={data?.title} />

            <div className="row mt-3">
                <div className=" col- col-sm- col-md- col-lg-4 col-xl-3 col-xxl-3">
                    <div className="mt-4">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="text-dark fw-bold mb-5 mb-4" style={{ fontFamily: 'sans-serif' }}>
                                    {data?.title}
                                </h3>
                                <div className="d-flex justify-content-between" style={{ paddingBottom: '10px' }}>
                                    <span className="p">Số câu hỏi:</span>
                                    <span className="p">{data?.questions?.length} câu hỏi</span>
                                </div>
                                <div className="d-flex justify-content-between" style={{ paddingBottom: '10px' }}>
                                    <span className="p">Thời gian đăng: </span>
                                    <span className="p">
                                        {data?.created_at ? format(new Date(data?.created_at), 'dd/M/yyyy') : ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {check && check?.data?.answered?.length > 0 ? (
                    <div className="col- col-sm- col-md- col-lg-8 col-xl-9 col-xxl-9">
                        <div className="mt-4"></div>
                        {check1?.map((item, index) => (
                            <div key={index} className="card mb-4">
                                <div className="card-body">
                                    <h3 className="text-dark fw-bold mb-5" style={{ fontFamily: 'sans-serif' }}>
                                        Câu hỏi {index + 1} :{item?.title}
                                    </h3>
                                    {item.type == 'open_ended' ? (
                                        <div className="row mt-4">
                                            <div className="col-12 col-lg-6">
                                                {item?.answers?.map((item_child, index) => (
                                                    <div
                                                        className="mb-4 p-2 d-flex"
                                                        style={{ background: 'rgba(245, 245, 245, 1)' }}
                                                    >
                                                        <div className="col-12">
                                                            <textarea
                                                                class="form-control enter_text"
                                                                placeholder="Nhập đáp án"
                                                                name=""
                                                                data-id={item_child?.id}
                                                                id=""
                                                                rows="4"
                                                                value={item?.text}
                                                                defaultValue={
                                                                    surveyAnswers.find(
                                                                        (answer) =>
                                                                            answer.survey_question_id === item.id,
                                                                    )?.survey_question_answer_text || ''
                                                                }
                                                                onChange={(e) =>
                                                                    handleAnswerChange(item.id, '', e.target.value)
                                                                }
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                {item?.image && <img src={item?.image} className="img-fluid w-100" />}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row mt-4">
                                            <div className="col-12 col-lg-6">
                                                {item?.answers?.map((item_child, index) => (
                                                    <div
                                                        className="mb-4 p-2 d-flex flex-wrap"
                                                        style={{ background: 'rgba(245, 245, 245, 1)' }}
                                                        key={index}
                                                    >
                                                        {item_child.type == 'choose' || item_child.type == null ? (
                                                            <>
                                                                <input
                                                                    data-id={item_child?.id}
                                                                    type={
                                                                        item.type == 'single_choice'
                                                                            ? 'radio'
                                                                            : 'checkbox'
                                                                    }
                                                                    name={'id' + item.id}
                                                                    checked={item_child?.check}
                                                                    style={{ width: '20px', height: '20px' }}
                                                                    onChange={() => {
                                                                        handleAnswerChange(item.id, item_child.id, '');
                                                                    }}
                                                                />
                                                                <label
                                                                    className="ms-3 text-dark"
                                                                    style={{ fontWeight: 600 }}
                                                                >
                                                                    {item_child?.text}
                                                                </label>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <input
                                                                    data-id={item_child?.id}
                                                                    type={
                                                                        item.type == 'single_choice'
                                                                            ? 'radio'
                                                                            : 'checkbox'
                                                                    }
                                                                    name={'id' + item.id}
                                                                    checked={item_child?.check}
                                                                    style={{ width: '20px', height: '20px' }}
                                                                    onChange={() => {
                                                                        handleAnswerChange(item.id, item_child.id, '');
                                                                    }}
                                                                />
                                                                <label
                                                                    className="ms-3 text-dark"
                                                                    style={{ fontWeight: 600 }}
                                                                >
                                                                    Đáp án khác
                                                                </label>
                                                                {/* <Collapse in={}> */}
                                                                <textarea
                                                                    className="form-control enter_text w-100 mt-2"
                                                                    placeholder="Nhập đáp án"
                                                                    name=""
                                                                    data-id={item_child?.id}
                                                                    id=""
                                                                    rows="4"
                                                                    defaultValue={
                                                                        item?.text ?? ''
                                                                        // surveyAnswers.find(
                                                                        //     (answer) =>
                                                                        //         answer.survey_question_id ===
                                                                        //         item.id,
                                                                        // )?.survey_question_answer_text || ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleAnswerChange(item.id, '', e.target.value)
                                                                    }
                                                                ></textarea>
                                                                {/* </Collapse> */}
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                {item?.image && <img src={item?.image} className="img-fluid w-100" />}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}

                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="p-fw-bold">1/{data?.questions?.length}</h3>
                            <button type="button" className="btn-survay px-5">
                                Khảo sát đã được làm
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="col- col-sm- col-md- col-lg-8 col-xl-9 col-xxl-9">
                        <div className="mt-4"></div>
                        {data?.questions?.map((item, index) => (
                            <div key={index} className="card mb-4">
                                <div className="card-body">
                                    <h3 className="text-dark fw-bold mb-5" style={{ fontFamily: 'sans-serif' }}>
                                        Câu hỏi {index + 1} :{item?.title}
                                    </h3>
                                    {item.type == 'open_ended' ? (
                                        <div className="row mt-4">
                                            <div className="col-12 col-lg-6">
                                                {item?.answers?.map((item_child, index) => (
                                                    <div
                                                        key={index}
                                                        className="mb-3 p-2"
                                                        style={{ background: 'rgba(245, 245, 245, 1)' }}
                                                    >
                                                        <div className="col-0">
                                                            {/* {item_child?.type} */}
                                                            {/* <textarea
                                                                class="form-control enter_text"
                                                                placeholder="Nhập đáp án"
                                                                name=""
                                                                data-id={item_child?.id}
                                                                id=""
                                                                rows="4"
                                                                defaultValue={
                                                                    surveyAnswers.find(
                                                                        (answer) =>
                                                                            answer.survey_question_id === item.id,
                                                                    )?.survey_question_answer_text || ''
                                                                }
                                                                onChange={(e) =>
                                                                    handleAnswerChange(
                                                                        item.type,
                                                                        item.id,
                                                                        '',
                                                                        e.target.value,
                                                                    )
                                                                }
                                                            ></textarea> */}
                                                            {item_child?.type == 'text' && (
                                                                <textarea
                                                                    className="form-control enter_text w-100 mt-2"
                                                                    placeholder="Nhập đáp án"
                                                                    name=""
                                                                    data-id={item_child?.id}
                                                                    id=""
                                                                    rows="4"
                                                                    defaultValue={
                                                                        surveyAnswers.find(
                                                                            (answer) =>
                                                                                answer.survey_question_id === item.id,
                                                                        )?.survey_question_answer_text || ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleAnswerChange(
                                                                            item.type,
                                                                            item.id,
                                                                            '',
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                ></textarea>
                                                            )}
                                                            {item_child?.type == 'number' && (
                                                                <input
                                                                    data-id={item_child?.id}
                                                                    type="number"
                                                                    placeholder="Nhập số"
                                                                    className="form-control enter_text w-100 mt-2 wppppc"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                    }}
                                                                    defaultValue={
                                                                        surveyAnswers.find(
                                                                            (answer) =>
                                                                                answer.survey_question_id === item.id,
                                                                        )?.survey_question_answer_text || ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleAnswerChange(
                                                                            item.type,
                                                                            item.id,
                                                                            '',
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            {item_child?.type == 'date' && (
                                                                <input
                                                                    data-id={item_child?.id}
                                                                    type="date"
                                                                    className="form-control enter_text w-100 mt-2 wppppc"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                    }}
                                                                    defaultValue={
                                                                        surveyAnswers.find(
                                                                            (answer) =>
                                                                                answer.survey_question_id === item.id,
                                                                        )?.survey_question_answer_text || ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleAnswerChange(
                                                                            item.type,
                                                                            item.id,
                                                                            '',
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                {item?.image && <img src={item?.image} className="img-fluid w-100" />}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row mt-4">
                                            <div className="col-12 col-lg-6">
                                                {item?.answers?.map((item_child, index) => (
                                                    <div
                                                        className="mb-4 p-2 d-flex flex-wrap"
                                                        style={{ background: 'rgba(245, 245, 245, 1)' }}
                                                    >
                                                        {item_child.type == 'choose' || item_child.type == null ? (
                                                            <>
                                                                <input
                                                                    data-id={item_child?.id}
                                                                    type={
                                                                        item.type == 'single_choice'
                                                                            ? 'radio'
                                                                            : 'checkbox'
                                                                    }
                                                                    name={'id' + item.id}
                                                                    checked={item_child?.check}
                                                                    style={{ width: '20px', height: '20px' }}
                                                                    onChange={() => {
                                                                        handleAnswerChange(
                                                                            item.type,
                                                                            item.id,
                                                                            item_child.id,
                                                                            '',
                                                                        );
                                                                    }}
                                                                />
                                                                <label
                                                                    className="ms-3 text-dark"
                                                                    style={{ fontWeight: 600 }}
                                                                >
                                                                    {item_child?.text}
                                                                </label>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <input
                                                                    data-id={item_child?.id}
                                                                    type={
                                                                        item.type == 'single_choice'
                                                                            ? 'radio'
                                                                            : 'checkbox'
                                                                    }
                                                                    name={'id' + item.id}
                                                                    checked={item_child?.check}
                                                                    style={{ width: '20px', height: '20px' }}
                                                                    onChange={() => {
                                                                        handleAnswerChange1(
                                                                            item.type,
                                                                            item.id,
                                                                            item_child.id,
                                                                            '',
                                                                        );
                                                                        // setOpen(!open);
                                                                    }}
                                                                />
                                                                <label
                                                                    className="ms-3 text-dark"
                                                                    style={{ fontWeight: 600 }}
                                                                >
                                                                    Đáp án khác
                                                                </label>
                                                                <Collapse in={collapseStates[item.id]}>
                                                                    <>
                                                                        {item_child?.type == 'text' && (
                                                                            <textarea
                                                                                className="form-control enter_text w-100 mt-2"
                                                                                placeholder="Nhập đáp án"
                                                                                name=""
                                                                                data-id={item_child?.id}
                                                                                id=""
                                                                                rows="4"
                                                                                defaultValue={
                                                                                    surveyAnswers.find(
                                                                                        (answer) =>
                                                                                            answer.survey_question_id ===
                                                                                            item.id,
                                                                                    )?.survey_question_answer_text || ''
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleAnswerChange(
                                                                                        item.type,
                                                                                        item.id,
                                                                                        '',
                                                                                        e.target.value,
                                                                                    )
                                                                                }
                                                                            ></textarea>
                                                                        )}
                                                                        {item_child?.type == 'number' && (
                                                                            <input
                                                                                data-id={item_child?.id}
                                                                                type="number"
                                                                                placeholder="Nhập số"
                                                                                className="form-control enter_text w-100 mt-2 wppppc"
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                }}
                                                                                defaultValue={
                                                                                    surveyAnswers.find(
                                                                                        (answer) =>
                                                                                            answer.survey_question_id ===
                                                                                            item.id,
                                                                                    )?.survey_question_answer_text || ''
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleAnswerChange(
                                                                                        item.type,
                                                                                        item.id,
                                                                                        '',
                                                                                        e.target.value,
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                        {item_child?.type == 'date' && (
                                                                            <input
                                                                                data-id={item_child?.id}
                                                                                type="date"
                                                                                className="form-control enter_text w-100 mt-2 wppppc"
                                                                                style={{
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                }}
                                                                                defaultValue={
                                                                                    surveyAnswers.find(
                                                                                        (answer) =>
                                                                                            answer.survey_question_id ===
                                                                                            item.id,
                                                                                    )?.survey_question_answer_text || ''
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleAnswerChange(
                                                                                        item.type,
                                                                                        item.id,
                                                                                        '',
                                                                                        e.target.value,
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                </Collapse>
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                {item?.image && <img src={item?.image} className="img-fluid w-100" />}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}

                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="p-fw-bold">1/{data?.questions?.length}</h3>
                            {data?.status == 'stop' ? (
                                <button type="button" className="btn-survay px-5">
                                    Khảo sát đã được dừng
                                </button>
                            ) : (
                                <button type="button" className="btn-survay px-5" onClick={handleSurveySubmit}>
                                    Gửi khảo sát
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DoSurvay;
