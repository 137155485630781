import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import Information from '~/components/Infomation';
import images from '~/assets/images';
import styles from './Service.module.scss';
import ModalAuth from '~/components/ModalAuth/ModalAuth';
import { EKeyStateModalAuth } from '~/components/ModalAuth/ModalAuth.enums';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

function Service() {
    const auth = useSelector((state) => state.auth);
    const [visible, setVisible] = useState(false);

    const [modalAuthState, setModalAuthState] = useState({
        visible: false,
    });

    const handleOpenModalAuth = (defaultKey) => {
        setVisible(true);
        setModalAuthState({ ...modalAuthState, visible: true, defaultKey });
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Box className={cx('Service', 'container')}>
            <Breadcumb title="Dịch vụ" />
            <Box className={cx('content')}>
                <Box className={cx('left') + " pb-5"}>
                    {auth.token ? (
                        <>
                            <Information className={cx('info-sidebar')} />
                            <Box
                                sx={{ border: '1px solid #D9D9D9', background: '#ffffff', borderRadius: '5px' }}
                                mt={2}
                            >
                                <Stack p={2}>
                                    <Typography pb={2} sx={{ fontSize: '16px', textAlign: 'center' }}>
                                        Tài khoản của bạn chưa nâng cấp VIP Vui lòng nâng cấp để đăng ký và thực hiện
                                        dịch vụ!
                                    </Typography>
                                    {JSON.parse(auth.user)?.account_type != 'CUSTOMER' && (
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                color: '#27AB60',
                                                fontSize: '14px',
                                                borderRadius: '31px',
                                                padding: '10px',
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <Link
                                                class="d-block w-100"
                                                style={{ color: '#27AB60' }}
                                                to="/tai-khoan/dich-vu"
                                            >
                                                NHẬN DỊCH VỤ
                                            </Link>
                                        </Button>
                                    )}
                                    <Button
                                        className="wow animate__animated animate__swing"
                                        variant="contained"
                                        sx={{
                                            background: '#27AB60',
                                            color: '#fff',
                                            fontSize: '14px',
                                            borderRadius: '31px',
                                            padding: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        <Link
                                            class="d-block w-100"
                                            style={{ color: '#fff' }}
                                            to="/tai-khoan/dat-dich-vu"
                                        >
                                            ĐẶT DỊCH VỤ
                                        </Link>
                                    </Button>
                                </Stack>
                            </Box>
                        </>
                    ) : (
                        <div className={cx('no-login')}>
                            <p>Bạn cần đăng nhập để sử dụng dịch vụ từ chúng tôi</p>
                            <div className={cx('action')}>
                                <button onClick={() => handleOpenModalAuth(EKeyStateModalAuth.SIGN_UP)}>
                                    Đăng nhập
                                </button>
                                <button onClick={() => handleOpenModalAuth(EKeyStateModalAuth.SIGN_IN)}>Đăng ký</button>
                            </div>
                        </div>
                    )}
                </Box>
                <Box className={cx('right')}>
                    {/* <h1>Thông tin dịch vụ</h1> */}
                    <img className={cx('img-fluid')} src={images.ItemService} alt="" />
                    <Typography sx={{ fontSize: '14px' }}>
                        {/* <h1>Sứ mệnh của Viện Khoa học Xã hội và Nhân văn</h1> */}
                        <p className="mt-4">
                            Để phát huy vai trò của Khoa học Xã hội – Nhân văn trong tiến trình phát triển đời sống xã
                            hội Việt Nam, Viện đã xác định bốn lĩnh vực hoạt động chính:
                        </p>

                        <ol>
                            <li>
                                <h2>Nghiên cứu:</h2>
                                <p>Tập trung vào bốn lĩnh vực chính:</p>
                                <ul>
                                    <li>
                                        Nghiên cứu hệ thống phúc lợi và khả năng tiếp cận của những người yếu thế trong
                                        xã hội (Công nhân, người nhập cư…)
                                    </li>
                                    <li>Đánh giá chất lượng và dự báo nguồn nhân lực</li>
                                    <li>
                                        Nghiên cứu mức độ sẵn sàng của các doanh nghiệp trong bối cảnh chuyển đổi mô
                                        hình sản xuất theo hướng công nghệ cao, tự động hóa
                                    </li>
                                    <li>Nghiên cứu và bảo tồn di sản đời sống xã hội Việt Nam đương đại</li>
                                </ul>
                            </li>
                            <li>
                                <h2>Đào tạo:</h2>
                                <p>
                                    Tập trung vào đào tạo ngắn hạn nhằm nâng cao năng lực nghiên cứu khoa học và nghiệp
                                    vụ mang tính ứng dụng nghề nghiệp cho những người trẻ.
                                </p>
                            </li>
                            <li>
                                <h2>Truyền thông – xuất bản:</h2>
                                <p>
                                    Chuyên xuất bản sách giáo trình, chuyên khảo, dịch thuật dành cho giới nghiên cứu và
                                    đại học. Mục đích là giới thiệu tri thức nền tảng và chuyên sâu của khoa học xã hội
                                    và nhân văn trên thế giới, tạo điều kiện cho sự phát triển nghiên cứu ở đại học.
                                </p>
                            </li>
                            <li>
                                <h2>Phụng sự cộng đồng với tinh thần phi lợi nhuận:</h2>
                                <p>
                                    Phát triển đi đôi với trách nhiệm xã hội với cộng đồng địa phương và người lao động.
                                    SocialLife dấn thân cho hoạt động phụng sự đồng đồng trong nhiềm năm với tư cách là
                                    đơn vị cầu nối cho các bên liên quan thực hiện trách nhiệm xã hội với những người
                                    yếu thế và cộng đồng địa phương.
                                </p>
                            </li>
                            <li>
                                <h2>Dịch vụ học thuật:</h2>
                                <p>
                                    Kênh kết nối các nhà khoa học và các cá nhân doanh nghiệp có nhu cầu liên quan đến
                                    dịch vụ khoa học công nghệ nhằm kết nối các bên cung – cầu dịch vụ học thuật, thương
                                    mại hóa kết quả nghiên cứu, hỗ trợ các tổ chức, cá nhân giới thiệu, đăng tin, xúc
                                    tiến giao dịch các sản phẩm học thuật, hàng hóa KHCN.
                                </p>
                                <p>
                                    Các dịch vụ hiện Không gian học tập và nghiên cứu mở đang cung cấp và kết nối các
                                    chuyên gia:
                                </p>
                                <ul className="ul">
                                    <li>
                                        <b>Dịch vụ tra cứu dữ liệu</b>
                                    </li>
                                    <li>
                                        <b>Dịch vụ khảo sát xã hội</b>
                                    </li>
                                    <li>
                                        <b>Dịch vụ tư vấn phân tích dữ liệu</b>
                                    </li>
                                    <li>
                                        <b>Dịch vụ phản biện, đánh giá độc lập</b>
                                    </li>
                                    <li>
                                        <b>Dịch vụ biên soạn tài liệu</b>
                                    </li>
                                    <li>
                                        <b>Và các dịch vụ khác đang được chúng tôi cập nhật.</b>
                                    </li>
                                </ul>
                                <h2 className="co">
                                    Nếu quí vị là nhà nghiên cứu, hãy nâng tài khoản cá nhân thành tài khoản chuyên gia/
                                    đối tác để có thể nhận dịch vụ học thuật của chúng tôi!
                                </h2>
                            </li>
                        </ol>
                    </Typography>
                </Box>
            </Box>
            <ModalAuth
                modalAuthState={modalAuthState}
                visible={visible}
                handleCancel={handleCancel}
                handleOk={handleOk}
            />
        </Box>
    );
}

export default Service;
