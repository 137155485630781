import classNames from 'classnames/bind';
import styles from './ModalAuth.module.scss';
import { Modal as AntdModal, Row, Col } from 'antd';
import images from '~/assets/images';
import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';
import SignAuth from './SignAuth';

const cx = classNames.bind(styles);

function ModalAuth({ visible, handleCancel, modalAuthState }) {
    return (
        <AntdModal
            visible={visible}
            title={null}
            onCancel={handleCancel}
            className={cx('ModalAuth')}
            // style={{ width: '856px' }}
        >
            <div className={cx('Modal-wrapper')}>
                <Row>
                    <Col span={12} className={cx('Modal-content-left')}>
                        <img src={images.banner_auth} alt="" />
                    </Col>
                    <Col span={12} className={cx('Modal-content-right')}>
                        <SignAuth modalAuthState={modalAuthState} handleCancel={handleCancel} />
                    </Col>
                </Row>
            </div>
        </AntdModal>
    );
}

export default ModalAuth;
