import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const courseOnline = (page, params) => {
    return async (dispatch) => {
        try {
            const ob1 = {
                course_type_id: 1,
                with_revenue: true,
                page: page,
            };
            let object;
            if (params) {
                object = Object.assign({}, params, ob1);
            } else {
                object = ob1;
            }
            const response = await api.get('api/courses', object, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'COURSE_ONLINE_SUCCESS', payload: response.data });
            console.log(response);
        } catch (error) {
            dispatch({ type: 'COURSE_ONLINE_FAILURE', payload: error.message });
        }
    };
};

export const courseVideo = (page, params) => {
    return async (dispatch) => {
        try {
            const ob1 = {
                course_type_id: 2,
                with_revenue: true,
                page: page,
            };
            let object;
            if (params) {
                object = Object.assign({}, params, ob1);
            } else {
                object = ob1;
            }
            const response = await api.get('api/courses', object, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'COURSE_VIDEO_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'COURSE_VIDEO_FAILURE', payload: error.message });
        }
    };
};

export const myCourseVideo = (user) => {
    return async (dispatch) => {
        try {
            console.log(user);
            console.log(user.id);
            const response = await api.get(
                'api/courses?owner_id=' + user.id + '&course_type_id=2&with_revenue=true',
                {},
            );
            dispatch({ type: 'MY_COURSE_VIDEO_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'MY_COURSE_VIDEO_FAILURE', payload: error.message });
        }
    };
};

export const myCourseOnline = (user) => {
    return async (dispatch) => {
        try {
            console.log(user);
            console.log(user.id);
            const response = await api.get(
                'api/courses?owner_id=' + user.id + '&course_type_id=1&with_revenue=true',
                {},
            );
            dispatch({ type: 'MY_COURSE_ONLINE_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'MY_COURSE_ONLINE_FAILURE', payload: error.message });
        }
    };
};

export const add_course_online = (token, formData) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/courses', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã thêm 1 khoá học online, chờ admin duyệt');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const edit_course_online = (token, formData, id) => {
    return async (dispatch) => {
        if (id == null) {
            return;
        }
        try {
            const response = await api.post('api/courses/' + id, formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã sửa khoá học online thành công');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const delete_course_online = (token, id) => {
    return async (dispatch) => {
        if (id == null) {
            return;
        }
        try {
            const response = await api.post(
                'api/courses/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status === 200) {
                showToast('Đã sửa khoá học online thành công');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const add_course_video = (token, formData) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/courses', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã thêm 1 khoá học video, chờ admin duyệt');
                return response;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const edit_course_video = (token, formData, id) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/courses/' + id, formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã sửa khoá học video thành công');
                return response;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const add_chapter = (token, formData, id) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/courses/' + id + '/videos', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã thêm 1 video mới');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const rating = (id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/courses/' + id + '/ratings');
            dispatch({ type: 'RATING_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'RATING_FAILURE', payload: error.message });
        }
    };
};
