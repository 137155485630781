import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const get_payment = (token) => {
    return async (dispatch) => {
        try {
            console.log('get payment')
            // Gọi API
            const response = await api.get(
                'api/my/deposit',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            console.log(response.data)
            dispatch({ type: 'PAYMENT_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'PAYMENT_FAILURE', payload: error.message });
        }
    };
};

export const add_order = (token, form) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.post('api/orders', form, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });
            dispatch({ type: 'ORDER_SUCCESS', payload: response.data });
            if (response.data.status == 200) {
                showToast(response.data.data);
            } else {
                showToast(response.data.messages, 1);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'ORDER_FAILURE', payload: error.message });
        }
    };
};

export const pay_ship = (token, form) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.post('api/orders/estimate-shipping-cost', form, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });
            // dispatch({ type: '_SUCCESS', payload: response.data });
            // showToast('Đặt hàng thành công');
            return response.data;
        } catch (error) {
            // dispatch({ type: 'ORDER_FAILURE', payload: error.message });
            // showToast('Đã có lỗi xảy ra, tải lại trang và thử lại.', 1);
            return false;
        }
    };
};
