import { Box, Typography, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './ContentRight.module.scss';

const cx = classNames.bind(styles);

const ContentRight = () => {
    return (
        <Box className={cx('content-right')}>
            <h2>Tin tức nổi bật</h2>
            <Box className={cx('list')}>
                <Box className={cx('item')}>
                    <Link to=""><img src={images.ItemHot} alt='' /></Link>
                    <Box className={cx('content-hot')}>
                        <Link to="">Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào </Link>
                        <Typography>20/11/2022</Typography>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Link to=""><img src={images.ItemHot} alt='' /></Link>
                    <Box className={cx('content-hot')}>
                        <Link to="">Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào </Link>
                        <Typography>20/11/2022</Typography>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Link to=""><img src={images.ItemHot} alt='' /></Link>
                    <Box className={cx('content-hot')}>
                        <Link to="">Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào </Link>
                        <Typography>20/11/2022</Typography>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Link to=""><img src={images.ItemHot} alt='' /></Link>
                    <Box className={cx('content-hot')}>
                        <Link to="">Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào </Link>
                        <Typography>20/11/2022</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ContentRight;
