import { useState } from "react";
import { Box, Typography, Button, Stack, TextField } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import classNames from 'classnames/bind';
import styles from './DetailInformation.module.scss';
import images from "~/assets/images";
import ModalUser from "~/components/ModalUser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import StarIcon from '@mui/icons-material/Star';


const cx = classNames.bind(styles);

function DetailInformation() {

    const [visible, setVisible] = useState(false);

    const [modalUserState, setModalUserState] = useState({
        visible: false,
    });

    const handleOpenModalUser = (defaultKey) => {
        setVisible(true);
        setModalUserState({ ...modalUserState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <Box className={cx('DetailInformation')}>
                <TitleUser title="Thông tin của bạn" />
                <Box className={cx('box-wp')}>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Tên khách hàng</Typography>
                        <Typography>Hoang Huy</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Đơn vị công tác</Typography>
                        <Typography>ABC</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Mã dịch vụ</Typography>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>ABC</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian đăng</Typography>
                        <Typography>08:00 - 21/12/2022</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Trạng thái dịch vụ</Typography>
                        <Typography sx={{ color: '#F9AB5C' }}>Đang chờ</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Số tiền</Typography>
                        <Typography sx={{ color: '#27AB60' }}>+ 120.000 vnđ</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Loại dịch vụ</Typography>
                        <Typography>Dịch vụ ABC</Typography>
                    </Box>
                    <Box className={cx('box-des')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Mô tả dịch vụ</Typography>
                        <Typography sx={{ fontWeight: '300!important' }}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </Typography>
                    </Box>
                    <Box className={cx('box-file')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>File đính kèm</Typography>
                        <Box className={cx('list-file')}>
                            <Box className={cx('file')}>
                                <img src={images.pdf} alt='' />
                                <Typography>file 1</Typography>
                            </Box>
                            <Box className={cx('file')}>
                                <img src={images.pdf} alt='' />
                                <Typography>file 1</Typography>
                            </Box>
                            <Box className={cx('file')}>
                                <img src={images.pdf} alt='' />
                                <Typography>file 1</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian nhận</Typography>
                        <Typography>08:00 - 21/12/2022</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian bắt đầu</Typography>
                        <Typography>08:00 - 21/12/2022</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian kết thúc</Typography>
                        <Typography>08:00 - 21/12/2022</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thanh toán</Typography>
                        <Typography>Đã thanh toán</Typography>
                    </Box>
                </Box>
                <Button onClick={handleOpenModalUser} className={cx('btn_submit')} variant='contained'>Xác nhận hoàn thành dịch vụ</Button>
                <ModalUser modalUserState = {modalUserState} visible={visible} handleCancel={handleCancel}>
                    <div className={cx('header-modal-back')}>
                        <button className={cx('btn-back')}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <h4>Xác thực OTP</h4>
                        <FontAwesomeIcon className={cx('close')} icon={faClose} />
                    </div>
                    <div className={cx('content-modal')}>
                        <div className={cx('star')}>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                        </div>
                        <Typography sx={{ fontSize: '16px' }}>Chọn mức đánh giá của bạn</Typography>
                        <Stack spacing={3} m={3}>
                            <TextField
                                multiline
                                rows={5}
                                maxRows={16}
                                required
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            fontSize: '16px',
                                        },
                                    },
                                }}
                                type="text"
                                label="Nội dung"
                                // {...getFieldProps('content')}
                                // error={Boolean(touched.content && errors.content)}
                                // helperText={touched.content && errors.content}
                            />
                        </Stack>
                        <Box sx={{ padding: '16px 11px' }}>
                            <label className={cx('content')} htmlFor="upload-img-btn">
                                <TextField
                                    label="Họ và tên"
                                    type="file"
                                    style={{ display: 'none' }}
                                    id="upload-img-btn"
                                />
                                <Button sx={{ borderRadius: '8px' }} className={cx('choose-image')} variant="outlined" component="span">Tải hình ảnh</Button>
                            </label>
                        </Box>
                        <Box className={cx('list-img')}>
                            <img src={images.reviewImg} alt="" />
                            <img src={images.reviewImg} alt="" />
                            <img src={images.reviewImg} alt="" />
                            <img src={images.reviewImg} alt="" />
                        </Box>
                        <Button sx={{ marginBottom: '10px', width: '470px' }} className={cx('btn_submit')} variant='contained'>GỬI ĐÁNH GIÁ</Button>
                    </div>
                </ModalUser>
            </Box>
        </>
    );
}

export default DetailInformation;