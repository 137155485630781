import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '~/components/Alert';
import images from '~/assets/images';
import Breadcumb from '~/components/Breadcrumb';
import { eventDetail } from '~/Store/Action/eventDetailActions';
import { format } from 'date-fns';
import { Box, Typography, Stack, Button, FormGroup, TextField } from '@mui/material';
import styles from './DetailEvent.module.scss';
import classNames from 'classnames/bind';
import { joinEvent, interestedEvent } from '~/Store/Action/eventActions';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const cx = classNames.bind(styles);

function DetailEvent() {
    const [visible, setVisible] = useState(false);

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const [visible1, setVisible1] = useState(false);

    const [alertState1, setAlertState1] = useState({
        visible: false,
    });

    const { id } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(eventDetail(id)); // Dispatch action để lấy dữ liệu khi component được mount
    }, []);

    const auth = useSelector((state) => state.auth);

    const data = useSelector((state) => state.eventDetail?.data?.data);

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOpenAlert1 = (defaultKey) => {
        setVisible1(true);
        setAlertState1({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel1 = () => {
        setVisible1(false);
    };

    const onJoinEvent = async (id) => {
        if (await dispatch(joinEvent(auth.token, id))) {
            await dispatch(eventDetail(id));
            handleCancel();
        }
    };

    const onInterestedEvent = async (id) => {
        if (await dispatch(interestedEvent(auth.token, id))) {
            await dispatch(eventDetail(id));
            handleCancel1();
        }
    };

    const checkAndRenderOpeningTime = (openingTime) => {
        if (!openingTime) {
            return 'Chưa diễn ra';
        }
        const currentDate = moment().tz('Asia/Ho_Chi_Minh');
        const targetDate = moment(openingTime);

        const targetDate1Year = moment(openingTime).add(0.6, 'year');
        const targetDate2Years = moment(openingTime).add(2, 'years');

        const diffInYears = targetDate.diff(currentDate, 'years', true);

        if (currentDate.isBefore(targetDate)) {
            return 'Chưa diễn ra';
        } else if (currentDate.isBefore(targetDate1Year)) {
            return 'Đang diễn ra';
        } else {
            return 'Đã kết thúc';
        }
    };

    return (
        <div className="container">
            <Breadcumb title="Sự kiện" slug={data?.title} />

            <div className="row mt-5">
                <div className="card p-0" style={{ border: 'none' }}>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="w-100 pt-2">
                                <img
                                    style={{ height: '330px', maxHeight: '330px' }}
                                    className="img-fluid w-100"
                                    src={data?.image}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="card-body">
                                <div className="mt-3">
                                    <h2 className="text-dark fw-bold">{data?.title}</h2>
                                    <div className="d-flex justify-content-between mt-2">
                                        <span style={{ fontSize: '16px' }}>
                                            {data?.opening_time
                                                ? format(new Date(data?.opening_time), 'dd/M/yyyy')
                                                : ''}
                                        </span>
                                        <span style={{ fontSize: '16px' }} className="text-success">
                                            {checkAndRenderOpeningTime(data?.opening_time)}
                                        </span>
                                    </div>
                                    <div className="mt-3 abs-card">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="text-center">
                                                    <div
                                                        className="price-success fw-700"
                                                        style={{ fontFamily: 'sans-serif' }}
                                                    >
                                                        {data?.interested}
                                                    </div>
                                                    <div className="fw-700">Đã tham gia</div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-center">
                                                    <div
                                                        className="price-warning fw-700"
                                                        style={{ fontFamily: 'sans-serif' }}
                                                    >
                                                        {data?.registered}
                                                    </div>
                                                    <div className="fw-700">Quan tâm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div
                                                className="d-flex align-items-center justify-content-between pb-4"
                                                style={{ borderBottom: '4px solid #F7FAFB' }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width="40"
                                                        height="40"
                                                        src={data?.owner?.image}
                                                        className="border border-2 rounded-circle"
                                                        alt="..."
                                                    />
                                                    <span className="ms-2 fs-4">{data?.owner?.name}</span>
                                                </div>
                                                <Link className="" to={`/thong-tin-nguoi-dang/${data?.owner.id}/event`}>
                                                    <span className="c-success">Xem thông tin</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="row position-relative">
                                            <button
                                                className="col-6 btn btn-g pt-3 pb-3 fw-bold"
                                                style={{ fontFamily: 'sans-serif' }}
                                                onClick={() => handleOpenAlert()}
                                            >
                                                THAM GIA
                                            </button>
                                            <button
                                                className="col-7 position-absolute btn btn-l end-0 pt-3 pb-3 fw-bold"
                                                style={{ fontFamily: 'sans-serif' }}
                                                onClick={() => handleOpenAlert1()}
                                            >
                                                QUAN TÂM
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <h2 className="pb-3 fw-bold" style={{ fontFamily: 'sans-serif' }}>
                        Mô tả sự kiện
                    </h2>
                    <div>
                        <p className="p-dess" dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                    </div>
                </div>
            </div>

            <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Thông báo
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>Tham gia sự kiện này</Typography>
                <div className="row position-relative pt-4">
                    <button
                        onClick={() => handleCancel()}
                        style={{
                            display: 'block',
                            background: '#D9D9D9!important',
                            color: '#333',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                    >
                        Hủy bỏ
                    </button>
                    <button
                        onClick={() => onJoinEvent(id)}
                        style={{
                            display: 'block',
                            right: 0,
                            background: '#27AB60!important',
                            color: '#fff',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className="col-6 position-absolute btn btn-g-reverse p-3"
                    >
                        Đồng ý
                    </button>
                </div>
            </Alert>

            <Alert alertState={alertState1} visible={visible1} handleCancel={handleCancel1}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Thông báo
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>Quan tâm tới sự kiện này</Typography>
                <div className="row position-relative pt-4">
                    <button
                        onClick={() => handleCancel1()}
                        style={{
                            display: 'block',
                            background: '#D9D9D9!important',
                            color: '#333',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                    >
                        Hủy bỏ
                    </button>
                    <button
                        onClick={() => onInterestedEvent(id)}
                        style={{
                            display: 'block',
                            right: 0,
                            background: '#27AB60!important',
                            color: '#fff',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className="col-6 position-absolute btn btn-g-reverse p-3"
                    >
                        Đồng ý
                    </button>
                </div>
            </Alert>
        </div>
    );
}

export default DetailEvent;
