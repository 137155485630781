import { Box, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import classNames from 'classnames/bind';
import styles from './Dropdown.module.scss';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function Dropdown() {
    const navigate = useNavigate();

    const routeChange = () => {
        let path = `/tai-khoan/lich-su-dich-vu`;
        navigate(path);
    };

    return (
        <Box className={cx('Dropdown', 'container')} onClick={routeChange}>
            <Typography sx={{ fontSize: '14px' }}>Lịch sử dịch vụ</Typography>
            <ArrowRightIcon />
        </Box>
    );
}

export default Dropdown;
