import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './Section.module.scss';

const cx = classNames.bind(styles);

const Section = ({ children }) => {
    return (
        <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ flexGrow: 1 }}
            className={cx('wrapper')}
        >
            {children}
        </Grid>
    );
};

export default Section;
