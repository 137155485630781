import { Box, Typography, Stack, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import styles from './About.module.scss';
import { getInformation } from '~/Store/Action/informationActions';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

function About() {
    // const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    // const information = useSelector((state) => state.information.get);

    useEffect(() => {
        // dispatch(getInformation(3));
    }, []);

    return (
        <Box className={cx('About', 'container')}>
            <Breadcumb title="Chính sách đổi trả" />
            <Box className={cx('content')}>
                <div className="mt-3 w-100 d-block content-web">
                    <h1 className="fw-bold mb-2">CHÍNH SÁCH ĐỔI TRẢ </h1>
                    <div>
                        <div>
                            Để nâng cao chất lượng dịch vụ và trải nghiệm mua sắm của khách hàng, Social Life chúng tôi
                            có những chính sách phù hợp khi khách hàng có nhu cầu đổi/ trả/ hoàn tiền sản phẩm. Chúng
                            tôi luôn coi trọng và bảo vệ quyền lợi của người tiêu dùng với mong muốn mang đến cho quý
                            khách chất lượng phục vụ tốt nhất
                        </div>
                        <div className="my-3"><b>*** Đối Với Khách Hàng Mua Online***</b></div>
                        <div><b>♥ Hướng dẫn các bước đổi/ trả hàng</b></div>
                        <ul className="ms-3">
                            <li>
                                - Bước 1: Vui lòng liên hệ Hotline 0767265396 hay gửi email về địa chỉ
                                lienhe@sociallife.vn để thông báo việc yêu cầu đổi/ trả hàng
                            </li>
                            <li>
                                - Bước 2: Nhân viên chăm sóc khách hàng sẽ liên hệ với bạn để xác nhận; kiểm tra & tiếp
                                nhận hàng được yêu cầu đổi/ trả hàng (chỉ áp dụng đối với các trường hợp đổi/ trả hàng
                                do lỗi xuất phát từ chúng tôi). (Trường hợp đổi hàng theo nhu cầu bạn vui lòng liên hệ
                                0767265396 để được hướng dẫn cụ thể)
                            </li>
                            <li>
                                - Bước 3: Khi yêu cầu đổi trả được giải quyết, quý khách vui lòng gửi sản phẩm như hiện
                                trạng khi nhận hàng ban đầu về địa chỉ văn phòng Công ty TNHH Viện Đại hoc Social Life
                                bao gồm sản phẩm và đầy đủ phụ kiện, giấy tờ chứng từ kèm theo (nếu có).
                            </li>
                        </ul>

                        <div>
                            Chúng tôi sẽ giao sản phẩm thay thế hoặc tiến hành hoàn tiền (trường hợp trả hàng) trong
                            vòng 5-7 ngày tùy khu vực kể từ ngày xác nhận việc đổi/ trả hàng.
                        </div>

                        <div>
                            <i>Trường hợp được đổi/ trả hàng/ hoàn tiền</i>
                        </div>
                        <ul className="ms-3">
                            <li>• Giao nhầm sản phẩm: tựa sách, hình thức bìa, giá bìa, v.v…,</li>
                            <li>
                                • Hàng hoá không đúng như mô tả trên website (màu sắc, kiểu dáng, chức năng) hoặc hàng
                                hóa bị hư hỏng trong quá trình giao hàng.
                            </li>
                            <li>
                                • Đơn hàng đã thanh toán trước nhưng sản phẩm của đơn hàng đã hết trên hệ thống
                                sociallife.vn
                            </li>
                        </ul>
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default About;
