import { Box } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import ReviewItem from './ReviewItem';
import CourseItem from './CourseItem';
import classNames from 'classnames/bind';
import styles from './ReviewHistory.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Pagination from '~/components/Pagination';

const api_url = process.env.REACT_APP_API_URL;

const showToast = (message, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    toast.error(message, toastOptions);
};

const cx = classNames.bind(styles);

const ReviewHistory = () => {
    const [data, setData] = useState([]);
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        fetchData(1);
    }, [auth]);

    // useEffect(() => {
    //     fetchData(1);
    // }, [auth]);

    let page = data?.current_page;
    // console.log(page);
    let totalPage = data?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            fetchData(prevPage);
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            fetchData(nextPage);
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchData(pageNumber);
    };

    const fetchData = async (page = 1) => {
        try {
            const data = await axios.get(api_url + 'api/my/ratings?page=' + page ?? 1, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                },
            });
            console.log(data.data.data.data);
            await setData(data.data.data);
            if (data.data.status != 200) {
                throw new Error(data.data.message);
            }
            return data;
        } catch (e) {
            console.log(e);
            showToast('Đã có lỗi xảy ra vui lòng thử lại');

            throw e;
        }
    };

    return (
        <Box>
            <TitleUser title="Tất cả đánh giá" />
            <Box className={cx('list')} py="10px">
                {data?.data?.length > 0 ? (
                    <>
                        {data?.data?.map((item, index) => (
                            <Box className={cx('item')}>
                                <ReviewItem key={index} data={item} />
                                <CourseItem key={index} data={item} />
                            </Box>
                        ))}
                        <Pagination
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            handlePageChange={handlePageChange}
                            page={data?.current_page}
                            totalPage={data?.total}
                        />
                    </>
                ) : (
                    <h2>Hiện tại không có đánh giá nào!</h2>
                )}
            </Box>
        </Box>
    );
};

export default ReviewHistory;
