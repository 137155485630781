import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import DropCartTippy from '@tippyjs/react/headless';
import { useNavigate } from 'react-router-dom';
import styles from './DropCart.module.scss';
import images from '~/assets/images';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PaymentXu from './PaymentXu';
import { Checkbox } from 'antd';
import PaymentVND from './PaymentVND';
import { get_cart, delete_cart } from '~/Store/Action/cartActions';
import { toast } from 'react-toastify';

const cx = classNames.bind(styles);

const formatCurrency = (value) => {
    // Chuyển đổi số thành định dạng tiền tệ
    const formattedValue = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);

    return formattedValue;
};
const DropCart = () => {
    const [showOpenCart, setShowOpenCart] = useState(false);
    const [tabIndex, setTabIndex] = useState(1);
    const [checkAll, setCheckAll] = useState(false);
    const [check, setCheck] = useState(false);
    const [allId, setAllId] = useState();
    const [idCart, setIdCart] = useState();
    const [check_pay, setCheckpay] = useState(true);

    const handleShowOpenCart = () => {
        setShowOpenCart(!showOpenCart);
    };

    const handleHideCart = () => {
        setShowOpenCart(false);
    };

    let navigate = useNavigate();

    const showToast = (message, options = {}) => {
        const defaultOptions = {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        };

        const toastOptions = { ...defaultOptions, ...options };

        toast.error(message, toastOptions);
    };

    const handleRedirectPayment = async () => {
        let user = null;
        let diff = 0;

        if (listCart?.data.length == 0) {
            showToast('Cần có tối thiểu 1 sản phẩm để mua', 1);
            return;
        }

        if (listCart?.data.length == 1) {
            setShowOpenCart(false);
            let url = '/gio-hang/thanh-toan';
            if (check_pay == true) {
                url += '?pay=coin';
            } else {
                url += '?pay=vnd';
            }
            navigate(url);
        } else {
            await listCart?.data?.forEach(function (item) {
                if (item?.book) {
                    if (user == null) {
                        user = item.book.owner_id;
                    }
                } else {
                    if (user == null) {
                        user = item.course.owner_id;
                    }
                }
            });
            console.log(user);

            await listCart?.data?.forEach(function (item) {
                if (item?.book) {
                    if (user != item.book.owner_id) {
                        diff++;
                        console.log(item.book.owner_id);
                    }
                } else {
                    if (user != item.course.owner_id) {
                        diff++;
                        console.log(item.course.owner_id);
                    }
                }
            });
            console.log(diff);
            if ((await diff) == 0) {
                setShowOpenCart(false);
                let url = '/gio-hang/thanh-toan';
                if (check_pay == true) {
                    url += '?pay=coin';
                } else {
                    url += '?pay=vnd';
                }
                navigate(url);
            } else {
                showToast('Mỗi đơn hàng chỉ được mua từ 1 người bán', 1);
                showToast('Bạn đang có nhiều hơn 2 sản phẩm khác người bán', 1);
                return;
            }
        }
    };

    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);
    const listCart = useSelector((state) => state.cart.list);

    var totalXu = 0;
    var totalVnđ = 0;
    var count = 0;

    // console.log(listCart.data);

    listCart?.data?.forEach(function (item) {
        if (item?.book) {
            totalXu += item.book.coin_price * item.quantity;
            totalVnđ += item.book.price * item.quantity;
        } else {
            totalXu += item.course.coin_price * item.quantity;
            totalVnđ += item.course.price * item.quantity;
        }
        count += item.quantity;
    });

    // console.log(localStorage.getItem('cart'));

    let fieldCart = {
        product_type: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).product_type : '',
        course_id: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).course_id : '',
        quantity: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).quantity : '',
        payment_method: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).payment_method : '',
    };

    localStorage.setItem('orders', JSON.stringify(fieldCart));

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(get_cart(auth.token));
    }, [auth]);

    const handleDelete = async () => {
        const status = await dispatch(delete_cart(allId ? allId : idCart, auth.token));
        if (status) {
            await dispatch(get_cart(auth.token));
            setIdCart();
            setCheckAll(false);
        }
    };

    const handleTab = (tab) => {
        if (tab == 1) {
            setCheckpay(true);
        } else {
            setCheckpay(false);
        }
        setTabIndex(tab);
    };

    const handleCheckAllChange = () => {
        const idsString = listCart?.data.map((item) => item.id).join(',');
        // console.log(idsString);
        setAllId(idsString);
        setCheckAll(!checkAll);
    };

    const handleIdCart = (id) => {
        setCheck(!check);
        setIdCart(id);
    };

    // console.log(check);

    return (
        <div className={cx('DropCart') + ' div-cart'}>
            <DropCartTippy
                className="cart-shop"
                interactive
                visible={showOpenCart}
                placement="bottom-end"
                render={(attrs) => (
                    <div
                        className={cx('DropCart-list') + ' wow animate__animated animate__bounceIn'}
                        tabIndex="-1"
                        {...attrs}
                    >
                        <div className={cx('DropCart-top')}>
                            <h2>Giỏ hàng</h2>
                            <span onClick={handleDelete}>Xóa</span>
                        </div>
                        <Tabs className={cx('DropCart-tab')}>
                            <TabList>
                                <Tab onClick={() => handleTab(1)}>Thanh toán qua xu</Tab>
                                <Tab onClick={() => handleTab(2)}>Thanh toán qua vnđ</Tab>
                            </TabList>
                            <TabPanel>
                                <PaymentXu
                                    checkAll={checkAll}
                                    check={idCart}
                                    handleIdCart={(id) => handleIdCart(id)}
                                    listCart={listCart}
                                />
                            </TabPanel>
                            <TabPanel>
                                <PaymentVND
                                    checkAll={checkAll}
                                    check={idCart}
                                    handleIdCart={(id) => handleIdCart(id)}
                                    listCart={listCart}
                                />
                            </TabPanel>
                        </Tabs>
                        <div className={cx('Payment-check')}>
                            <Checkbox checked={checkAll} onChange={handleCheckAllChange} />
                            <span className={cx('choose-all')}>Chọn tất cả</span>
                        </div>
                        <div className={cx('Payment-total')}>
                            {tabIndex === 1 ? (
                                <strong>Tổng: {totalXu} xu</strong>
                            ) : (
                                <strong>Tổng: {formatCurrency(totalVnđ)}</strong>
                            )}
                            <button onClick={handleRedirectPayment}>Thanh toán</button>
                        </div>
                    </div>
                )}
                onClickOutside={handleHideCart}
            >
                <div className={cx('cart')}>
                    <img src={images.iconCart} alt="Giỏ hàng" onClick={handleShowOpenCart} />
                    <span className={cx('number-cart')}>{count}</span>
                </div>
            </DropCartTippy>
        </div>
    );
};

export default DropCart;
