import React from 'react';
import styles from './Copyright.module.scss';
import classNames from 'classnames/bind';
import images from '~/assets/images';

const cx = classNames.bind(styles);

const Copyright = () => {
    return (
        <>
            <hr />
            <div className="container">
                <div className="">
                    <div className="mt-4">
                        <div class="row">
                            <div class="col-12 col-md-8">
                                <h3 className="fw-bold font-family-initial">CÔNG TY TNHH VIỆN ĐẠI HỌC SOCIAL LIFE</h3>
                                <p className="mb-2 mt-2">
                                    <b className="font-family-initial">MST: </b>
                                    <b className="text-primary">0314207478</b> - Được cấp ngày 17/01/2017 tại Sở Kế
                                    Hoạch Và Đầu Tư Thành Phố Hồ Chí Minh
                                </p>
                                <p className="mb-2 mt-2">
                                    <b className="font-family-initial">Địa chỉ: </b>
                                    51 Quốc Hương, Phường Thảo Điền, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam
                                </p>
                            </div>
                            <div class="col-12 col-md-4">
                                <div>
                                    <b className="font-family-initial">Hotline: </b>
                                    <b className="text-primary"> 0767 265 396</b>
                                </div>
                                <div>
                                    <b className="font-family-initial">Email: </b>
                                    <b className="text-primary"> lienhe@sociallife.vn</b>
                                </div>
                                <div>
                                    <img width="120" src={images.tbct} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 text-center">
                        {/* <p className={cx('text-footer', 'mb-4')}>
                            Địa chỉ: 51 Quốc Hương, Phường Thảo Điền, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam
                        </p> */}
                    </div>
                    <div className="d-flex justify-content-center mb-2 pt-1">
                        <a className="me-2 ms-2 a" href="#" target="_blank">
                            <i className="fab fa-facebook fs-1"></i>
                        </a>
                        <a className="me-2 ms-2 a" href="#" target="_blank">
                            <i className="fab fa-youtube fs-1"></i>
                        </a>
                        <a className="me-2 ms-2 a" href="#" target="_blank">
                            <i className="fab fa-instagram fs-1"></i>
                        </a>
                        <a className="me-2 ms-2 a" href="#" target="_blank">
                            <i className="fab fa-tiktok fs-1"></i>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Copyright;
