import React from 'react';
import classNames from 'classnames/bind';
import styles from '../Notification.module.scss';
import images from '~/assets/images';
import { format } from 'date-fns';

const cx = classNames.bind(styles);

const  Item = ({ list }) => {
    // console.log(list?.data?.data);

    return (
        <>
            {list?.data?.data?.length > 0 ? (
                list?.data?.data?.map((item, index) => (
                    <div className={cx('Notification-item')}>
                        <div className={cx('Notification-title')}>
                            <img width={30} src={images.iconSuccessOrder} alt="" />
                            <span>{item?.title}</span>
                        </div>
                        <p className={cx('Notification-content')}>
                            {item?.content}
                        </p>
                        <span className={cx('Notification-date')}>{format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy')}</span>
                    </div>
                ))
            ) : (
                <h2 style={{ textAlign: 'center' }}>Hiện tại không có thông báo nào!</h2>
            )}
        </>
    );
}

export default Item;
