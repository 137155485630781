import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcumb from '~/components/Breadcrumb';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { detailCourseVideo, checkBuyCourse } from '~/Store/Action/detailCourseActions';
import { rating } from '~/Store/Action/courseActions';
import { add_cart, get_cart } from '~/Store/Action/cartActions';
import { useNavigate } from 'react-router-dom';
import Alert from '~/components/Alert';
import { Box, Typography } from '@mui/material';
import styles from './AllEvent.module.scss';
import classNames from 'classnames/bind';
import axios from 'axios';
import { toast } from 'react-toastify';

const api_url = process.env.REACT_APP_API_URL;

const cx = classNames.bind(styles);

const formatCurrency = (value) => {
    // Chuyển đổi số thành định dạng tiền tệ
    const formattedValue = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);

    return formattedValue;
};

const DetailCourseware = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);
    const check = useSelector((state) => state.detailCourse?.check?.data);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
            load();
            async function load() {
                await dispatch(checkBuyCourse(id, auth.token));
            }
        }
    }, [auth]);
    useEffect(() => {
        dispatch(rating(id));
        dispatch(detailCourseVideo(id)); // Dispatch action để lấy dữ liệu khi component được mount
    }, []);
    const data = useSelector((state) => state.detailCourse?.data?.data);
    const rates = useSelector((state) => state.course.rating?.data?.ratings);
    // console.log(data);

    const addCart = async () => {
        let cart = {
            product_type: 'course',
            course_id: data?.id,
            quantity: 1,
            payment_method: 'vnd',
        };
        localStorage.setItem('cart', JSON.stringify(cart));
        let fieldCart = {
            product_type: JSON.parse(localStorage.getItem('cart')).product_type,
            course_id: JSON.parse(localStorage.getItem('cart')).course_id,
            quantity: JSON.parse(localStorage.getItem('cart')).quantity,
            payment_method: JSON.parse(localStorage.getItem('cart')).payment_method,
        };
        await dispatch(add_cart(auth.token, fieldCart));
        dispatch(get_cart(auth.token));
    };

    const goEdit = (id) => {
        navigate(`/tai-khoan/quan-ly-khoa-hoc/sua-khoa-hoc-video/${id}`);
    };

    const goDelete = async (id) => {
        axios
            .delete(`${api_url}api/courses/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                },
            })
            .then((response) => {
                console.log('Item deleted successfully:', response);
                showToast('Đã xoá khoá học video thành công');
                navigate(`/tai-khoan/quan-ly-khoa-hoc-video`);
            })
            .catch((error) => {
                console.error('Error deleting item:', error);
                // Handle error cases
            });
    };

    const [visible, setVisible] = useState(false);

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const showToast = (message, type = 0, options = {}) => {
        const defaultOptions = {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        };

        const toastOptions = { ...defaultOptions, ...options };

        if (type == 0) {
            toast.success(message, toastOptions);
        } else {
            toast.error(message, toastOptions);
        }
    };

    const open_course = () => {
        const data_video = data?.videos
        if(data_video[0]) {
            let url = '/khoa-hoc-video/xem-video/' + data_video[0]?.course_id +  '?video=' + data_video[0]?.id;

            window.open(url, "_blank", "noreferrer");
        } else {
            showToast('Đã có lỗi xảy ra', 1);
        }
    };

    return (
        <div className="container">
            <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Thông báo
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>Xoá sản phẩm này</Typography>
                <div className="row position-relative pt-4">
                    <button
                        onClick={() => handleCancel()}
                        style={{
                            display: 'block',
                            background: '#D9D9D9!important',
                            color: '#333',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                    >
                        Hủy bỏ
                    </button>
                    <button
                        onClick={() => goDelete(id)}
                        style={{
                            display: 'block',
                            right: 0,
                            background: '#27AB60!important',
                            color: '#fff',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className="col-6 position-absolute btn btn-g-reverse p-3"
                    >
                        Đồng ý
                    </button>
                </div>
            </Alert>

            <Breadcumb title="Khóa học video" slug={data?.title} />

            <div className="row mt-3">
                <div className="card p-0 border-0">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="w-100">
                                <img
                                    style={{ height: '330px', maxHeight: '330px' }}
                                    className="img-fluid w-100"
                                    src={data?.image}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="card-body">
                                <div className="mt-2">
                                    <h2 className="text-dark fw-bold hh1">{data?.title}</h2>
                                    <div className="d-flex justify-content-between mt-5 mb-4">
                                        <span className="pp" style={{ fontSize: '16px' }}>
                                            120 đã bán
                                        </span>
                                        <span className="pp" style={{ fontSize: '16px', color: '#000000' }}>
                                            Tạp chí
                                        </span>
                                    </div>
                                    <div className="mt- abs-card">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="text-center">
                                                    <div className="price-success fw-700">
                                                        {formatCurrency(data?.discount_price)}
                                                    </div>
                                                    <div className="price-secondary fw-700">
                                                        {formatCurrency(data?.price)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-center">
                                                    <div className="price-warning fw-700">
                                                        {data?.discount_coin_price} Xu
                                                    </div>
                                                    <div className="price-secondary fw-700">{data?.coin_price} Xu</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <div className="row">
                                            <div
                                                className="d-flex align-items-center justify-content-between pb-4"
                                                style={{ borderBottom: '4px solid #F7FAFB' }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width="40"
                                                        height="40"
                                                        src={data?.owner.image}
                                                        className="border border-2 rounded-circle"
                                                        alt="..."
                                                    />
                                                    <span className="ms-2 fs-4">{data?.owner.name}</span>
                                                </div>
                                                <Link
                                                    className=""
                                                    to={`/thong-tin-nguoi-dang/${data?.owner.id}/videos`}
                                                >
                                                    <span className="c-success">Xem thông tin</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        {user && user?.id && user?.id == data?.owner.id ? (
                                            <div className="row position-relative">
                                                <button
                                                    className="col-6 btn btn-g pt-3 pb-3 fw-bold"
                                                    style={{ fontFamily: 'sans-serif' }}
                                                    onClick={() => goEdit(data?.id)}
                                                >
                                                    SỬA SẢN PHẨM
                                                </button>
                                                <button
                                                    onClick={handleOpenAlert}
                                                    className="col-7 position-absolute btn btn-l end-0 pt-3 pb-3 fw-bold"
                                                    style={{ fontFamily: 'sans-serif' }}
                                                >
                                                    XOÁ SẢN PHẨM
                                                </button>
                                            </div>
                                        ) : check != null && check == true ? (
                                            <div className="row position-relative">
                                                {/* <button
                                                    className="col-6 btn btn-g pt-3 pb-3 fw-bold"
                                                    style={{ fontFamily: 'sans-serif' }}
                                                    onClick={addCart}
                                                >
                                                    Mở bài giảng
                                                </button> */}
                                                <button
                                                    onClick={open_course}
                                                    className="col-7 w-100 position-absolute btn btn-g end-0 pt-3 pb-3 fw-bold"
                                                    style={{ fontFamily: 'sans-serif' }}
                                                >
                                                    Mở bài giảng
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="row position-relative">
                                                <button
                                                    className="col-6 btn btn-g pt-3 pb-3 fw-bold"
                                                    style={{ fontFamily: 'sans-serif' }}
                                                    onClick={addCart}
                                                >
                                                    MUA HÀNG
                                                </button>
                                                <button
                                                    onClick={addCart}
                                                    className="col-7 position-absolute btn btn-l end-0 pt-3 pb-3 fw-bold"
                                                    style={{ fontFamily: 'sans-serif' }}
                                                >
                                                    THÊM GIỎ HÀNG
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <Tabs>
                        <TabList className="event-tab Event-tab mb-4">
                            <Tab>Mô tả</Tab>
                            <Tab>Bài giảng</Tab>
                            <Tab>Đánh giá</Tab>
                        </TabList>
                        <TabPanel>
                            <div>
                                <p className="p-dess pp-1" dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="row ">
                                <div className="col-12">
                                    <ul>
                                        {data?.videos.map((item, index) => (
                                            <li
                                                className="d-flex bg-light border p-2 align-items-center mb-3"
                                                key={index}
                                            >
                                                <Link
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                    to={
                                                        '/khoa-hoc-video/xem-video/' +
                                                        item?.course_id +
                                                        '?video=' +
                                                        item?.id
                                                    }
                                                >
                                                    <div className="p-1 me-3">
                                                        {check != null && check == true ? (
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect width="24" height="24" rx="5" fill="#777777" />
                                                                <path
                                                                    d="M15.8854 10.8393L9.46873 7.21913C9.25918 7.09937 9.02164 7.03726 8.78029 7.0391C8.53894 7.04095 8.30239 7.10668 8.09469 7.22963C7.89234 7.34536 7.72423 7.51258 7.60743 7.71432C7.49063 7.91605 7.42931 8.1451 7.42969 8.37821V15.6185C7.42931 15.8517 7.49063 16.0807 7.60743 16.2824C7.72423 16.4842 7.89234 16.6514 8.09469 16.7671C8.30241 16.89 8.53896 16.9557 8.78029 16.9576C9.02163 16.9594 9.25916 16.8973 9.46873 16.7776L15.8854 13.1575C16.0923 13.0428 16.2648 12.8749 16.3849 12.671C16.505 12.4672 16.5684 12.235 16.5684 11.9984C16.5684 11.7618 16.505 11.5295 16.3849 11.3257C16.2648 11.1219 16.0923 10.9539 15.8854 10.8393Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="rounded-3"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect width="24" height="24" rx="5" fill="#777777" />
                                                                <path
                                                                    d="M15.7057 10.7631V9.11604C15.7057 7.05722 14.0586 5.41016 11.9998 5.41016C9.94095 5.41016 8.29389 7.05722 8.29389 9.11604V10.7631C7.59389 10.7631 7.05859 11.2984 7.05859 11.9984V17.3513C7.05859 18.0102 7.59389 18.5866 8.29389 18.5866H15.7057C16.4057 18.5866 16.9409 18.0102 16.9409 17.3513V11.9984C16.9409 11.2984 16.4057 10.7631 15.7057 10.7631ZM9.11742 9.11604C9.11742 7.51016 10.3939 6.23369 11.9998 6.23369C13.6057 6.23369 14.8821 7.51016 14.8821 9.11604V10.7631H9.11742V9.11604ZM12.4115 15.2102V16.116C12.4115 16.3631 12.2468 16.5278 11.9998 16.5278C11.7527 16.5278 11.588 16.3631 11.588 16.116V15.2102C11.0939 15.0455 10.7645 14.5925 10.7645 14.0572C10.7645 13.3572 11.2998 12.8219 11.9998 12.8219C12.6998 12.8219 13.2351 13.3572 13.2351 14.0572C13.2351 14.5925 12.9057 15.0455 12.4115 15.2102Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <span>{item?.title}</span>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
                                    <div className="card" style={{ border: 'none' }}>
                                        <div className="card-body p-5 card-rating">
                                            <div className="d-flex justify-content-center align-items-center flex-column">
                                                <h1 className="fw-bold">4.0</h1>
                                                <div className="d-flex flex-wrap">
                                                    <span className="text-warning-1 me-2">
                                                        <svg
                                                            width="28"
                                                            height="26"
                                                            viewBox="0 0 28 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M13.0423 0.927616C13.4355 0.136167 14.5645 0.136166 14.9577 0.927615L18.1775 7.40785C18.3332 7.72126 18.6324 7.93865 18.9786 7.9899L26.1366 9.04954C27.0108 9.17896 27.3597 10.2527 26.7285 10.8713L21.5604 15.9359C21.3105 16.1809 21.1962 16.5326 21.2544 16.8777L22.4586 24.0128C22.6057 24.8843 21.6923 25.5479 20.9089 25.1387L14.4951 21.7886C14.1849 21.6266 13.8151 21.6266 13.5049 21.7886L7.09107 25.1387C6.30774 25.5479 5.39435 24.8843 5.54141 24.0128L6.74559 16.8777C6.80383 16.5326 6.68954 16.1809 6.43958 15.9359L1.27146 10.8713C0.640268 10.2527 0.989153 9.17896 1.86338 9.04954L9.0214 7.9899C9.36759 7.93865 9.66681 7.72126 9.82253 7.40785L13.0423 0.927616Z"
                                                                fill="#777777"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span className="text-warning-1 me-2">
                                                        <svg
                                                            width="27"
                                                            height="26"
                                                            viewBox="0 0 27 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12.6829 0.927616C13.0761 0.136167 14.2051 0.136166 14.5984 0.927615L17.8181 7.40785C17.9738 7.72126 18.273 7.93865 18.6192 7.9899L25.7772 9.04954C26.6515 9.17896 27.0004 10.2527 26.3692 10.8713L21.201 15.9359C20.9511 16.1809 20.8368 16.5326 20.895 16.8777L22.0992 24.0128C22.2463 24.8843 21.3329 25.5479 20.5496 25.1387L14.1358 21.7886C13.8256 21.6266 13.4557 21.6266 13.1455 21.7886L6.7317 25.1387C5.94837 25.5479 5.03497 24.8843 5.18204 24.0128L6.38621 16.8777C6.44445 16.5326 6.33016 16.1809 6.08021 15.9359L0.91209 10.8713C0.280893 10.2527 0.629778 9.17896 1.50401 9.04954L8.66202 7.9899C9.00822 7.93865 9.30744 7.72126 9.46316 7.40785L12.6829 0.927616Z"
                                                                fill="#FFAB09"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span className="text-warning-1 me-2">
                                                        <svg
                                                            width="27"
                                                            height="26"
                                                            viewBox="0 0 27 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12.6829 0.927616C13.0761 0.136167 14.2051 0.136166 14.5984 0.927615L17.8181 7.40785C17.9738 7.72126 18.273 7.93865 18.6192 7.9899L25.7772 9.04954C26.6515 9.17896 27.0004 10.2527 26.3692 10.8713L21.201 15.9359C20.9511 16.1809 20.8368 16.5326 20.895 16.8777L22.0992 24.0128C22.2463 24.8843 21.3329 25.5479 20.5496 25.1387L14.1358 21.7886C13.8256 21.6266 13.4557 21.6266 13.1455 21.7886L6.7317 25.1387C5.94837 25.5479 5.03497 24.8843 5.18204 24.0128L6.38621 16.8777C6.44445 16.5326 6.33016 16.1809 6.08021 15.9359L0.91209 10.8713C0.280893 10.2527 0.629778 9.17896 1.50401 9.04954L8.66202 7.9899C9.00822 7.93865 9.30744 7.72126 9.46316 7.40785L12.6829 0.927616Z"
                                                                fill="#FFAB09"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span className="text-warning-1 me-2">
                                                        <svg
                                                            width="27"
                                                            height="26"
                                                            viewBox="0 0 27 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12.6829 0.927616C13.0761 0.136167 14.2051 0.136166 14.5984 0.927615L17.8181 7.40785C17.9738 7.72126 18.273 7.93865 18.6192 7.9899L25.7772 9.04954C26.6515 9.17896 27.0004 10.2527 26.3692 10.8713L21.201 15.9359C20.9511 16.1809 20.8368 16.5326 20.895 16.8777L22.0992 24.0128C22.2463 24.8843 21.3329 25.5479 20.5496 25.1387L14.1358 21.7886C13.8256 21.6266 13.4557 21.6266 13.1455 21.7886L6.7317 25.1387C5.94837 25.5479 5.03497 24.8843 5.18204 24.0128L6.38621 16.8777C6.44445 16.5326 6.33016 16.1809 6.08021 15.9359L0.91209 10.8713C0.280893 10.2527 0.629778 9.17896 1.50401 9.04954L8.66202 7.9899C9.00822 7.93865 9.30744 7.72126 9.46316 7.40785L12.6829 0.927616Z"
                                                                fill="#FFAB09"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span className="text-warning-1 me-2">
                                                        <svg
                                                            width="27"
                                                            height="26"
                                                            viewBox="0 0 27 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12.6829 0.927616C13.0761 0.136167 14.2051 0.136166 14.5984 0.927615L17.8181 7.40785C17.9738 7.72126 18.273 7.93865 18.6192 7.9899L25.7772 9.04954C26.6515 9.17896 27.0004 10.2527 26.3692 10.8713L21.201 15.9359C20.9511 16.1809 20.8368 16.5326 20.895 16.8777L22.0992 24.0128C22.2463 24.8843 21.3329 25.5479 20.5496 25.1387L14.1358 21.7886C13.8256 21.6266 13.4557 21.6266 13.1455 21.7886L6.7317 25.1387C5.94837 25.5479 5.03497 24.8843 5.18204 24.0128L6.38621 16.8777C6.44445 16.5326 6.33016 16.1809 6.08021 15.9359L0.91209 10.8713C0.280893 10.2527 0.629778 9.17896 1.50401 9.04954L8.66202 7.9899C9.00822 7.93865 9.30744 7.72126 9.46316 7.40785L12.6829 0.927616Z"
                                                                fill="#FFAB09"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {rates?.data.length > 0 ? (
                                    <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-9">
                                        <h2 className="text-dark fw-bold mb-4">{rates?.total} Đánh giá</h2>
                                        {rates?.data.map((item, index) => (
                                            <div className="card mb-4" key={index}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-1">
                                                            <a href="">
                                                                <img
                                                                    width={50}
                                                                    height={50}
                                                                    className="border rounded-circle overflow-hidden"
                                                                    src={item?.owner?.image}
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="col-8">
                                                            <div style={{ width: '85%' }}>
                                                                <h3 className="fw-bold d-inline">
                                                                    {item?.owner?.name}
                                                                </h3>
                                                                <span className="float-end price-warning fs-4">
                                                                    10/01/2023
                                                                </span>
                                                            </div>
                                                            <div style={{ width: '85%' }}>
                                                                <p>
                                                                    Lorem Ipsum is simply dumm Lorem Ipsum is simply...
                                                                </p>
                                                            </div>
                                                            <div className="d-flex w-75 flex-wrap">
                                                                {item?.attachments.map((itm, i) => (
                                                                    <img className="border w-25 me-3" src={itm.image} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="d-flex flex-wrap text-end justify-content-end">
                                                                {item?.rating == 1 && (
                                                                    <>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </>
                                                                )}

                                                                {item?.rating == 2 && (
                                                                    <>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </>
                                                                )}

                                                                {item?.rating == 3 && (
                                                                    <>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </>
                                                                )}

                                                                {item?.rating == 4 && (
                                                                    <>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.33712 0.873811C8.15887 0.515038 7.64707 0.515038 7.46881 0.873812L5.67487 4.48441C5.60428 4.62648 5.46864 4.72503 5.31171 4.74826L1.32347 5.33866C0.927172 5.39733 0.769018 5.88408 1.05515 6.16448L3.93467 8.98635C4.04798 9.09739 4.09979 9.25684 4.07339 9.41327L3.40246 13.3888C3.33579 13.7838 3.74984 14.0846 4.10494 13.8991L7.67852 12.0326C7.81914 11.9591 7.9868 11.9591 8.12742 12.0326L11.701 13.8991C12.0561 14.0846 12.4701 13.7838 12.4035 13.3888L11.7325 9.41327C11.7061 9.25684 11.758 9.09739 11.8713 8.98635L14.7508 6.16448C15.0369 5.88408 14.8788 5.39733 14.4825 5.33866L10.4942 4.74826C10.3373 4.72503 10.2017 4.62648 10.1311 4.48441L8.33712 0.873811Z"
                                                                                    fill="#777777"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </>
                                                                )}

                                                                {item?.rating == 5 && (
                                                                    <>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-warning-1 me-2">
                                                                            <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewBox="0 0 15 14"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M8.36837 0.873811C8.19012 0.515038 7.67832 0.515038 7.50006 0.873812L5.70612 4.48441C5.63553 4.62648 5.49989 4.72503 5.34296 4.74826L1.35472 5.33866C0.958422 5.39733 0.800268 5.88408 1.0864 6.16448L3.96592 8.98635C4.07923 9.09739 4.13104 9.25684 4.10464 9.41327L3.43371 13.3888C3.36704 13.7838 3.78109 14.0846 4.13619 13.8991L7.70977 12.0326C7.85039 11.9591 8.01805 11.9591 8.15867 12.0326L11.7322 13.8991C12.0873 14.0846 12.5014 13.7838 12.4347 13.3888L11.7638 9.41327C11.7374 9.25684 11.7892 9.09739 11.9025 8.98635L14.782 6.16448C15.0682 5.88408 14.91 5.39733 14.5137 5.33866L10.5255 4.74826C10.3685 4.72503 10.2329 4.62648 10.1623 4.48441L8.36837 0.873811Z"
                                                                                    fill="#FFAB09"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-9">
                                        <h3>Hiện tại không có đánh giá nào!</h3>
                                    </div>
                                )}
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default DetailCourseware;
