import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Breadcumb from '~/components/Breadcrumb';
import Pagination from '~/components/Pagination';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import slugify from 'slugify';
import { category } from '~/Store/Action/categoryActions';
import { courseOnline } from '~/Store/Action/courseActions';
import { add_cart, get_cart } from '~/Store/Action/cartActions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Collapse from 'react-bootstrap/Collapse';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CourseOnline = () => {
    const dispatch = useDispatch();

    const list = useSelector((state) => state.category.list);
    const listCourseOnline = useSelector((state) => state.course.listCourseOnline);

    let page = listCourseOnline?.data?.current_page;
    // console.log(page);
    let totalPage = listCourseOnline?.data?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    const [user, setUser] = useState(null);
    const [key, setKey] = useState(null);

    const auth = useSelector((state) => state.auth);

    const [open, setOpen] = useState(window.innerWidth > 800);

    useEffect(() => {
        const handleResize = () => {
            setOpen(window.innerWidth > 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    useEffect(() => {
        // Dispatch action để lấy dữ liệu khi component được mount
        dispatch(courseOnline(1));
        dispatch(category());
    }, []);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(courseOnline(prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(courseOnline(nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(courseOnline(pageNumber));
    };

    const [checkedItems, setCheckedItems] = useState([]);
    const [targetCategory, setCategory] = useState(null);

    const handleCheckboxChange = async (event, itemId) => {
        if (event.target.checked) {
            await setCheckedItems((prevCheckedItems) => [...prevCheckedItems, itemId]);
        } else {
            await setCheckedItems((prevCheckedItems) => prevCheckedItems.filter((id) => id !== itemId));
        }

        const updatedCheckedItems = event.target.checked
            ? [...checkedItems, itemId]
            : checkedItems.filter((id) => id !== itemId);

        const params = {
            course_type_id: 1,
            specialization_id: updatedCheckedItems.join(','),
            ...(targetCategory && { course_category_id: targetCategory.id }),
        };

        dispatch(courseOnline(currentPage ?? 1, params));
    };

    const handleCategoryChange = async (event, item) => {
        if (targetCategory && item.id == targetCategory.id) {
            setCategory(null);

            const params = {
                course_type_id: 1,
                specialization_id: checkedItems.join(','),
            };

            dispatch(courseOnline(currentPage ?? 1, params));
            return;
        }
        setCategory(item);

        const params = {
            course_type_id: 1,
            specialization_id: checkedItems.join(','),
            course_category_id: item.id,
        };

        dispatch(courseOnline(currentPage ?? 1, params));
    };

    const handleSearch = async (event) => {
        console.log(event);
        console.log(event.key);
        if (event.key == 'Enter') {
            const keyword = event.target.value;
            await setKey(keyword);
            const params = {
                course_type_id: 1,
                specialization_id: checkedItems.join(','),
                ...(targetCategory && { course_category_id: targetCategory.id }),
                keyword: keyword,
            };

            console.log(params);

            await dispatch(courseOnline(currentPage ?? 1, params));
        }
    };

    const handleFilter = async () => {
        var keyw = document.getElementById('search');
        const params = {
            course_type_id: 1,
            specialization_id: checkedItems.join(','),
            ...(targetCategory && { course_category_id: targetCategory.id }),
            keyword: keyw.value ?? '',
        };

        console.log(params);

        await dispatch(courseOnline(currentPage ?? 1, params));
    };

    const addCart = async (id) => {
        let cart = {
            product_type: 'course',
            course_id: id,
            quantity: 1,
            payment_method: 'vnd',
        };
        localStorage.setItem('cart', JSON.stringify(cart));
        let fieldCart = {
            product_type: JSON.parse(localStorage.getItem('cart')).product_type,
            course_id: JSON.parse(localStorage.getItem('cart')).course_id,
            quantity: JSON.parse(localStorage.getItem('cart')).quantity,
            payment_method: JSON.parse(localStorage.getItem('cart')).payment_method,
        };
        await dispatch(add_cart(auth.token, fieldCart));
        dispatch(get_cart(auth.token));
    };

    const formatDate = (dateString) => {
        if (!dateString || dateString === '0000-00-00 00:00:00') {
            return ''; // Or any default value you prefer for invalid dates
        }

        // Extract date components from the date string
        const year = parseInt(dateString.substring(0, 4), 10);
        const month = parseInt(dateString.substring(5, 7), 10) - 1; // Month is 0-based in JavaScript
        const day = parseInt(dateString.substring(8, 10), 10);
        const hour = parseInt(dateString.substring(11, 13), 10);
        const minute = parseInt(dateString.substring(14, 16), 10);
        const second = parseInt(dateString.substring(17, 19), 10);

        // Create a new Date object using the extracted components
        const dateObj = new Date(year, month, day, hour, minute, second);

        // Format the Date object as "dd/M/yyyy"
        return format(dateObj, 'dd/M/yyyy');
    };

    return (
        <div className="container">
            <Breadcumb title="Khóa học online" />

            <div className="row mt-3">
                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="mt-4">
                        <h3 className="text-dark fw-bold mb-4" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                            Danh mục
                        </h3>
                        <div className="">
                            {list?.data?.course_categories.map((item, index) => (
                                <div
                                    className="mb-3"
                                    key={index}
                                    onClick={(event) => handleCategoryChange(event, item)}
                                >
                                    <Link
                                        className={`w-100 d-flex align-items-center category card-hover ${
                                            targetCategory && targetCategory.id == item.id ? 'target' : ''
                                        }`}
                                        to=""
                                    >
                                        <div>
                                            <img height="70" width="70" src={item.image} />
                                        </div>
                                        <div className="ms-3">
                                            <p className="m-0 p-0">{item.name}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                            <div className="mt-4 mb-5 position-relative">
                                <input
                                    placeholder="Tìm kiếm"
                                    id="search"
                                    className="form-control p-3 fs-4 border-input-search"
                                    onKeyDown={(event) => handleSearch(event)}
                                />
                                <span className="position-absolute btn-g-search">
                                    <i className="fas fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h3
                            className="text-dark fw-bold c-s"
                            style={{ fontFamily: 'sans-serif', marginBottom: '20px' }}
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            Lọc chuyên ngành
                            <FontAwesomeIcon className="ms-3" icon={faAngleDown} />
                        </h3>
                        <div>
                            <Collapse in={open}>
                                <div id="example-collapse-text">
                                    {list?.data?.specializations.map((item, index) => (
                                        <div className="form-check mb-4" key={index}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${item.id}`}
                                                onChange={(event) => handleCheckboxChange(event, item.id)}
                                                checked={checkedItems.includes(item.id)}
                                            />
                                            <label
                                                style={{ cursor: 'pointer' }}
                                                htmlFor={`checkbox-${item.id}`}
                                                className="form-check-label text-dark fw-bold"
                                            >
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <button
                                className="btn border rounded-pill w-100 bg-main btn-filter"
                                onClick={() => handleFilter()}
                            >
                                Lọc ngay
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                    <h3 className="text-dark fw-bold mt-4 mb-4" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                        {targetCategory ? targetCategory.name + ' / ' : ''} Khóa học online
                    </h3>
                    <div>
                        <div className="row">
                            {listCourseOnline?.data?.data?.length > 0 ? (
                                <>
                                    {listCourseOnline?.data?.data?.map((item, index) => (
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4" key={index}>
                                            <div className="wow animate__animated animate__flipInX">
                                                <div className="card card-hover">
                                                    <Link
                                                        className="w-100"
                                                        to={'/khoa-hoc-online/' + slugify(item.title) + '/' + item.id}
                                                    >
                                                        <LazyLoadImage
                                                            src={item.image}
                                                            style={{ height: '144px' }}
                                                            effect="blur"
                                                            className="card-img-top"
                                                            alt="Image Alt"
                                                        />
                                                    </Link>
                                                    <div className="card-body">
                                                        <Link
                                                            className="w-100"
                                                            to={
                                                                '/khoa-hoc-online/' +
                                                                slugify(item.title) +
                                                                '/' +
                                                                item.id
                                                            }
                                                            style={{ display: 'block', height: '50px' }}
                                                        >
                                                            <p
                                                                className="card-title h3 text-dark fw-bold"
                                                                style={{
                                                                    fontFamily: 'sans-serif',
                                                                    textDecoration: 'none',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    display: '-webkit-box',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item?.title}
                                                            </p>
                                                        </Link>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                width="30"
                                                                height="30"
                                                                src={item?.owner?.image}
                                                                className="border border-2 rounded-circle"
                                                                alt="..."
                                                            />
                                                            <span className="ms-2 fs-4">{item?.owner.name}</span>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className="text-dark fs-4">
                                                                {item?.view} đã đăng ký
                                                            </span>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className="text-success fs-4">
                                                                Khai giảng:{' '}
                                                                {item?.opening_date
                                                                    ? formatDate(item?.opening_date)
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="position-absolute add-card">
                                                        <img
                                                            onClick={() => addCart(item?.id)}
                                                            src={images.cartOv}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <Pagination
                                        handlePrevPage={handlePrevPage}
                                        handleNextPage={handleNextPage}
                                        handlePageChange={handlePageChange}
                                        page={listCourseOnline?.data?.current_page}
                                        totalPage={listCourseOnline?.data?.total}
                                    />
                                </>
                            ) : (
                                <h2>Hiện tại không có khóa học nào!</h2>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseOnline;
