
const initialState = {
    data: null,
};
  

const historyDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'DETAIL_SUCCESS':
        return {
          ...state,
          data: action.payload,
        };
      case 'DETAIL_FAILURE':
        return state;
      default:
        return state;
    }
  };
  
  export default historyDetailReducer;
