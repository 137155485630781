import classNames from 'classnames/bind';
import 'bootstrap/dist/css/bootstrap.css';
import styles from '../Home.module.scss';
import LessonItem from '~/components/LessonItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import Section from '~/components/Section/Section';

const cx = classNames.bind(styles);

function Lesson({ courses }) {
    let navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/khoa-hoc-video');
    };

    return (
        <Section
            className={cx('Lesson')}
            buttonTitle="Xem thêm"
            sectionTitle="Khóa học - Bài giảng"
            onClickButton={handleRedirect}
        >
            <div>
                <div className={cx('list-lesson')} style={{ paddingTop: '25px' }}>
                    <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {courses?.data?.courses.map((item, index) => (
                            <SwiperSlide>
                                <LessonItem
                                    className="wow animate__animated animate__flipInY"
                                    data={item}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </Section>
    );
}

export default Lesson;
