import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { FormControl, TextField, Stack, MenuItem, InputLabel, Select, Button, Grid } from '@mui/material';
import classNames from 'classnames/bind';
import FormInput from './FormInput';
import Breadcumb from '~/components/Breadcrumb';
import TitleUser from '~/components/TitleUser';
import styles from './Payment.module.scss';
import { add_order, pay_ship, get_payment } from '~/Store/Action/orderActions';
import Collapse from 'react-bootstrap/Collapse';
import { city, cty, getDistrict, getWard } from '~/Store/Action/addressActions';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
const cx = classNames.bind(styles);

const Payment = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [paymentMethod, setPaymentMethod] = useState('wallet');

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const [open, setOpen] = useState(false);
    const [check, setCheck] = useState(true);

    // console.log(paymentMethod);
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile?.list?.data);

    const [user, setUser] = useState(null);

    const listCart = useSelector((state) => state.cart.list);
    const [ship_payment, setShip] = useState(0);
    const [district_vn, setDistrictvn] = useState(0);
    const [cty_vn, setCty_vn] = useState(0);

    const [type, setType] = useState(true);
    let user_data = null;

    // var totalXu = 0;
    // var totalVnđ = 0;
    // var count = 0;
    

    const calculateCartValues = useMemo(() => {
        var totalXu = 0;
        var totalVnđ = 0;
        var count = 0;
        listCart?.data?.forEach(function (item) {
            if (item?.book) {
                totalXu += item.book.coin_price * item.quantity;
                totalVnđ += item.book.price * item.quantity;
                if (item?.book?.type == 'pdf') {
                    if (check == true) {
                        setCheck(false);
                    }
                }
                if (item?.book?.type == 'print_book') {
                    if (check == true) {
                        setType(false);
                    }
                }
            } else {
                totalXu += item.course.coin_price * item.quantity;
                totalVnđ += item.course.price * item.quantity;
                if (check == true) {
                    setCheck(false);
                }
            }
            count += item.quantity;
        });

        return {
            totalXu,
            totalVnđ,
            count,
        };
    }, [listCart, type]);

    useEffect(() => {
        const pay = searchParams.get('pay');
        if (pay == 'coin' || pay == '') {
            if (type == true) {
                setType(true);
            }
        } else {
            setType(false);
        }
    }, [type]);

    useEffect(() => {
        // console.log(auth);
        if (auth.user) {
            console.log(JSON.parse(auth.user));

            if (JSON.parse(auth.user)?.phone == null) {
                showToast('Bạn cần cập nhập số điện thoại trước khi đặt hàng', 1);
            }
            // user_data = auth.user
            setUser(JSON.parse(auth.user));
        }
        console.log(user);
    }, [auth]);

    // console.log(paymentMethod);

    const handleOrder = async () => {
        if (user != null) {
            console.log(user?.phone);
            if (user?.phone == null || user?.phone == '') {
                showToast('Bạn cần cập nhập số điện thoại trước khi đặt hàng', 1);
            }
        }

        let items = [];

        listCart?.data?.forEach(function (item) {
            if (item?.course) {
                items.push({
                    cart_item_id: item?.id,
                    product_type: item?.product_type,
                    course_id: item?.course.id,
                    quantity: item?.quantity,
                });
            } else {
                items.push({
                    cart_item_id: item?.id,
                    product_type: item?.product_type,
                    book_id: item?.book.id,
                    quantity: item?.quantity,
                });
            }
        });
        console.log(listCart?.data);
        if (items.length == 0) {
            showToast('Giỏ hàng trống', 1);
            return;
        }
        const orders = {
            user_name: name ?? JSON.parse(auth.user).name,
            user_email: JSON.parse(auth.user).email,
            user_phone_number: phone ?? JSON.parse(auth.user).phone,
            province: province ?? JSON.parse(auth.user).province,
            district: district ?? JSON.parse(auth.user).district,
            ward: ward ?? JSON.parse(auth.user).ward,
            address: address ?? JSON.parse(auth.user).address,
            des: note ?? 'Gói hàng cẩn thận',
            payment_method: paymentMethod ?? 'cod',
            payment_currency: type == true ? 'coin' : 'vnd',
            shipping_fee: ship_payment ?? 0,
            items: items,
        };
        // console.log(orders);
        // return;
        await dispatch(add_order(auth.token, orders));
    };

    const list = useSelector((state) => state.address.city);
    const district_data = useSelector((state) => state.address.district);
    const [province, setProvince] = useState(user?.province || '');
    const [district, setDistrict] = useState(user?.district || '');
    const [ward, setWard] = useState(user?.ward || '');
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [address, setAddress] = useState(null);
    const [note, setNote] = useState(null);

    const ward_data = useSelector((state) => state.address.ward);

    const showToast = (message, type = 0, options = {}) => {
        const defaultOptions = {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        };

        const toastOptions = { ...defaultOptions, ...options };

        if (type == 0) {
            toast.success(message, toastOptions);
        } else {
            toast.error(message, toastOptions);
        }
    };

    useEffect(() => {
        // console.log(profile);
        // if (profile != null) {
        //     if (profile?.phone) {
        //         showToast('Bạn cần cập nhập số điện thoại trước khi đặt hàng', 1);
        //     }
        // }
    }, []);

    useEffect(() => {
        // dispatch(district());
        async function load() {
            // profile

            if (user) {
                setProvince(user?.province);
                setDistrict(user?.district);
                setWard(user?.ward);
                setAddress(user?.address);
                setPhone(user?.phone);
                setName(user?.name);
            }

            const cityList = await dispatch(city());

            if (cityList && user) {
                console.log(user);
                const city = await cityList.find((city) => city.PROVINCE_NAME === user?.province);

                const p = await dispatch(getDistrict(city.PROVINCE_ID));
                console.log(p);
                await setCty_vn(city.PROVINCE_ID);
                const d = await p.find((x) => x.DISTRICT_NAME === user?.district);
                console.log(d);
                await setDistrictvn(d.DISTRICT_ID);

                await dispatch(getWard(d.DISTRICT_ID));

                const form = await new FormData();
                var owner = null;
                // totalVnđ
                let resulttotalXu = 0;
                await listCart?.data?.forEach(function (item) {
                    if (item?.book) {
                        resulttotalXu += item.book.coin_price * item.quantity;
                        if (owner == null) {
                            owner = item.book.owner_id;
                        }
                    } else {
                        resulttotalXu += item.course.coin_price * item.quantity;
                        if (owner == null) {
                            owner = item.course.owner_id;
                        }
                    }
                });
                if (owner == null) {
                    // alert('Danh sách trống');
                    return;
                }
                console.log(resulttotalXu);
                await form.append('amount', resulttotalXu ?? 0);
                await form.append('payment_method', paymentMethod ?? 'wallet');
                await form.append('owner_id', owner);
                await form.append('receiver_province_id', city.PROVINCE_ID);
                await form.append('receiver_district_id', d.DISTRICT_ID);
                await form.append('payment_currency', 'coin');

                for (let pair of form.entries()) {
                    console.log(pair[0] + ': ' + pair[1]);
                }

                if (paymentMethod == 'cod') {
                    const ship = await dispatch(pay_ship(auth.token, form));
                    await setShip(ship.data.total);
                    console.log(ship.data.total);
                    console.log(123);
                }
            }
        }
        load();
    }, [user, listCart, paymentMethod]);

    const handleProvinceChange = async (e) => {
        const name = e.target.value;
        console.log(name);
        await setProvince(e.target.value);

        const city = list?.data?.find((city) => city.PROVINCE_NAME === name);
        console.log(city);
        await setWard('');

        await setCty_vn(city.PROVINCE_ID);

        await dispatch(getDistrict(city.PROVINCE_ID));

        const form = await new FormData();
        // totalVnđ
        let resulttotalXu = 0;
        await listCart?.data?.forEach(function (item) {
            if (item?.book) {
                resulttotalXu += item.book.coin_price * item.quantity;
            } else {
                resulttotalXu += item.course.coin_price * item.quantity;
            }
        });
        // console.log(resulttotalXu);
        // await form.append('amount', resulttotalXu ?? 0);
        // await form.append('payment_method', paymentMethod ?? 'wallet');
        // await form.append('owner_id', user?.id);
        // await form.append('receiver_province_id', cty_vn);
        // await form.append('receiver_district_id', district_vn);
        // await form.append('payment_currency', 'coin');

        // for (let pair of form.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }
        // const ship = await dispatch(pay_ship(auth.token, form));
        // await setShip(ship.data.total);
        // console.log(ship.data.total);
    };

    const handleDistrictChange = async (e) => {
        const name = e.target.value;
        console.log(name);
        setDistrict(e.target.value);

        const d = district_data?.data?.find((x) => x.DISTRICT_NAME === name);
        console.log(d);
        setWard('');

        await setDistrictvn(d.DISTRICT_ID);

        await dispatch(getWard(d.DISTRICT_ID));

        const form = await new FormData();
        // totalVnđ
        var owner = null;
        // totalVnđ
        let resulttotalXu = 0;
        await listCart?.data?.forEach(function (item) {
            if (item?.book) {
                resulttotalXu += item.book.coin_price * item.quantity;
                if (owner == null) {
                    owner = item.book.owner_id;
                }
            } else {
                resulttotalXu += item.course.coin_price * item.quantity;
                if (owner == null) {
                    owner = item.course.owner_id;
                }
            }
        });
        if ((await owner) == null) {
            alert('Danh sách trống');
            return;
        }
        console.log(resulttotalXu);
        await form.append('amount', resulttotalXu ?? 0);
        await form.append('payment_method', paymentMethod ?? 'wallet');
        await form.append('owner_id', owner);
        await form.append('receiver_province_id', cty_vn);
        await form.append('receiver_district_id', d.DISTRICT_ID);
        await form.append('payment_currency', 'coin');

        console.log(resulttotalXu);
        // await form.append('amount', resulttotalXu ?? 0);
        // await form.append('payment_method', paymentMethod ?? 'wallet');
        // await form.append('owner_id', user?.id);
        // await form.append('receiver_province_id', cty_vn);
        // await form.append('receiver_district_id', d.DISTRICT_ID);
        // await form.append('payment_currency', 'coin');

        for (let pair of form.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }
        if (paymentMethod == 'cod') {
            const ship = await dispatch(pay_ship(auth.token, form));
            await setShip(ship.data.total);
        }
    };

    const handleWardChange = (e) => {
        setWard(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    const formatCurrency = (amount, currency) => {
        const formattedAmount = amount.toLocaleString('vi-VN', {
            style: 'currency',
            currency: currency,
        });
        return formattedAmount.replace(/\s/g, '\u00a0'); // Replace spaces with non-breaking spaces
    };

    return (
        <Box className={cx('Payment', 'container')}>
            <Breadcumb title="Giỏ hàng" slug="Thanh toán" />
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ flexGrow: 1 }}
                className={cx('wrapper')}
            >
                <Grid className={cx('item-wp')} pb={2} pr={2} item xs={12} sm={12} md={8}>
                    <TitleUser className={cx('title-wp')} title="Thông tin của bạn" />
                    <Box className={cx('info')}>
                        <Typography sx={{ fontSize: '16px' }}>Địa chỉ</Typography>
                        {/* <FormInput user={JSON.parse(auth.user)} /> */}
                        {user && (
                            <FormControl sx={{ paddingTop: '10px' }} fullWidth>
                                <Stack spacing={2}>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                        sx={{ flexGrow: 1 }}
                                        className={cx('wrapper')}
                                    >
                                        <Grid className={cx('item-wp')} pb={2} pr={2} item xs={12} sm={12} md={6}>
                                            <Stack spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    label="Họ và tên"
                                                    variant="outlined"
                                                    defaultValue={user?.name}
                                                    onChange={handleNameChange}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            padding: '11.5px 14px',
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#333',
                                                            fontWeight: 'bold',
                                                            fontSize: '16px',
                                                            top: '-10%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '40px',
                                                            '& fieldset': {
                                                                fontSize: '16px',
                                                            },
                                                        },
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Số điện thoại"
                                                    variant="outlined"
                                                    defaultValue={user?.phone}
                                                    onChange={handlePhoneChange}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            padding: '11.5px 14px',
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#333',
                                                            fontWeight: 'bold',
                                                            fontSize: '16px',
                                                            top: '-10%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '40px',
                                                            '& fieldset': {
                                                                fontSize: '16px',
                                                            },
                                                        },
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Địa chỉ cụ thể"
                                                    variant="outlined"
                                                    onChange={handleAddressChange}
                                                    defaultValue={user?.address}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            padding: '11.5px 14px',
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#333',
                                                            fontWeight: 'bold',
                                                            fontSize: '16px',
                                                            top: '-10%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '40px',
                                                            '& fieldset': {
                                                                fontSize: '16px',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid className={cx('item-wp')} pb={2} pr={2} item xs={12} sm={12} md={6}>
                                            <Stack spacing={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '16px',
                                                            color: '#333',
                                                            fontWeight: 'bold',
                                                            top: '-4px',
                                                        }}
                                                        id="demo-simple-select-label"
                                                    >
                                                        Tỉnh thành
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue={user?.province}
                                                        label="Tỉnh thành"
                                                        onChange={handleProvinceChange}
                                                        sx={{
                                                            '& .MuiSelect-root': {
                                                                color: 'blue',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontSize: '16px',
                                                                padding: '11px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: '#333',
                                                                fontWeight: 'bold',
                                                                fontSize: '16px',
                                                                top: '-3%',
                                                            },
                                                            '&.MuiInputBase-root': {
                                                                borderRadius: '40px!important',
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                span: {
                                                                    fontSize: '12px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {list?.data?.map((item, index) => (
                                                            <MenuItem key={index} value={item?.PROVINCE_NAME}>
                                                                {item?.PROVINCE_NAME}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '16px',
                                                            color: '#333',
                                                            fontWeight: 'bold',
                                                            top: '-4px',
                                                        }}
                                                        id="demo-simple-select-label"
                                                    >
                                                        Quận huyện
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue={user?.district}
                                                        label="Quận huyện"
                                                        onChange={handleDistrictChange}
                                                        sx={{
                                                            '& .MuiSelect-root': {
                                                                color: 'blue',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontSize: '16px',
                                                                padding: '11px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: '#333',
                                                                fontWeight: 'bold',
                                                                fontSize: '16px',
                                                                top: '-3%',
                                                            },
                                                            '&.MuiInputBase-root': {
                                                                borderRadius: '40px!important',
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                span: {
                                                                    fontSize: '12px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {district_data?.data?.map((item, index) => (
                                                            <MenuItem key={index} value={item?.DISTRICT_NAME}>
                                                                {item?.DISTRICT_NAME}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '16px',
                                                            color: '#333',
                                                            fontWeight: 'bold',
                                                            top: '-4px',
                                                        }}
                                                        id="demo-simple-select-label"
                                                    >
                                                        Phường xã
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue={user?.ward}
                                                        label="Phường xã"
                                                        onChange={handleWardChange}
                                                        sx={{
                                                            '& .MuiSelect-root': {
                                                                color: 'blue',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontSize: '16px',
                                                                padding: '11px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: '#333',
                                                                fontWeight: 'bold',
                                                                fontSize: '10px',
                                                                top: '-4%',
                                                            },
                                                            '&.MuiInputBase-root': {
                                                                borderRadius: '40px!important',
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                span: {
                                                                    fontSize: '12px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {ward_data?.data?.map((item, index) => (
                                                            <MenuItem key={index} value={item?.WARDS_NAME}>
                                                                {item?.WARDS_NAME}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </FormControl>
                        )}
                    </Box>
                    <Box className={cx('tran-payment')}>
                        <Typography sx={{ fontSize: '16px', paddingBottom: '15px' }}>Phương thức thanh toán</Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className={cx('form-radio')}
                                onChange={handlePaymentChange}
                                value={paymentMethod}
                            >
                                {check && (
                                    <FormControlLabel
                                        sx={{ marginRight: '20px' }}
                                        control={<Radio />}
                                        value="cod"
                                        label="Thanh toán tại nhà"
                                    />
                                )}

                                <FormControlLabel
                                    sx={{ marginRight: '20px' }}
                                    control={<Radio />}
                                    value="wallet"
                                    label="Thanh toán online"
                                />
                                {/* <FormControlLabel
                                    sx={{ marginRight: '20px' }}
                                    control={<Radio />}
                                    value="baokim"
                                    label="Thanh toán Bảo kim"
                                /> */}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box className={cx('payment')}>
                        <Typography sx={{ fontSize: '16px', paddingBottom: '15px' }}>Ghi chú đơn hàng</Typography>
                        <Typography
                            sx={{ fontSize: '14px', color: 'rgba(39, 171, 96, 1)', cursor: 'pointer' }}
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            Nhập nội dung ghi chú
                        </Typography>
                        <Collapse in={open}>
                            <div id="example-collapse-text">
                                <div class="form-group mt-3">
                                    {/* <label for=""></label> */}
                                    <textarea
                                        placeholder="Gói hàng cẩn thận, giao đúng hẹn, ..."
                                        class="form-control"
                                        onChange={handleNoteChange}
                                        value={note}
                                        name=""
                                        id=""
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                        </Collapse>
                    </Box>
                    <TitleUser title="Danh sách sản phẩm" />
                    <Box className={cx('ListProduct', 'container')}>
                        {listCart?.data?.map((item, index) => (
                            <Box className={cx('item')}>
                                <img
                                    style={{ width: '162px', height: '83px' }}
                                    src={item?.course?.image || item?.book?.image}
                                    alt=""
                                />
                                <Box className={cx('content')}>
                                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '16px' }}>
                                        {item?.course?.title || item?.book?.name}
                                    </Typography>
                                    <Box className={cx('price')}>
                                        <Typography sx={{ color: '#F9AB5C', fontSize: '16px' }}>
                                            {type == true
                                                ? (item?.book ? item?.book?.coin_price : item?.course?.coin_price) +
                                                  ' xu'
                                                : item?.book
                                                ? formatCurrency(item?.book?.price, 'VND')
                                                : formatCurrency(item?.course?.price, 'VND')}
                                        </Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                                            x{item?.quantity}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Grid>
                <Grid className={cx('item-wp')} pb={2} pr={2} item xs={12} sm={12} md={4}>
                    <TitleUser className={cx('title-wp')} title="Thanh toán" />
                    <Box className={cx('infoOrder-list')}>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Tổng tiền sản phẩm</Typography>
                            <Typography sx={{ fontSize: '16px' }}>
                                {type == true
                                    ? (calculateCartValues.totalXu ?? 0) + ' xu'
                                    : (calculateCartValues.totalVnđ ?? 0) + ' VNĐ'}
                            </Typography>
                        </Box>
                        {paymentMethod == 'cod' && (
                            <Box className={cx('infoOrder-item')}>
                                <Typography sx={{ fontSize: '16px' }}>Phí vận chuyển</Typography>
                                <Typography sx={{ fontSize: '16px' }}>{ship_payment} VNĐ</Typography>
                            </Box>
                        )}

                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Tổng tiền</Typography>
                            <Typography sx={{ fontSize: '16px', color: 'rgba(39, 171, 96, 1)' }}>
                                {type == true
                                    ? (calculateCartValues.totalXu ?? 0) + ' xu'
                                    : (calculateCartValues.totalVnđ ? calculateCartValues.totalVnđ + ship_payment : 0) +
                                      ' VNĐ'}
                            </Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Button variant="contained" className={cx('cancel-order')} onClick={handleOrder}>
                                ĐẶT HÀNG
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Payment;
