const initialState = {
    list: null,
    data: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_VIP_SUCCESS':
            return {
                ...state,
                list: action.payload,
            };
        case 'GET_PACKGET_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;
