import classNames from 'classnames/bind';
import styles from './ToggleMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import images from '~/assets/images';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import ModalAuth from '~/components/ModalAuth/ModalAuth';
import { EKeyStateModalAuth } from '~/components/ModalAuth/ModalAuth.enums';
import { logout } from '~/Store/Action/authActions.js';
import Cookies from 'js-cookie';

const cx = classNames.bind(styles);

function ToggleMenu(props) {
    const { action } = props;

    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        console.log(auth);
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    const [visible, setVisible] = useState(false);

    const [modalAuthState, setModalAuthState] = useState({
        visible: false,
    });

    const handleOpenModalAuth = (defaultKey) => {
        setVisible(true);
        setModalAuthState({ ...modalAuthState, visible: true, defaultKey });
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleLogout = async () => {
        await Cookies.remove('token');
        await Cookies.remove('user');
        await dispatch(logout(auth.token));
    };

    return (
        <>
            <div className={cx('mySidenav', 'sidenav')}>
                <div className={cx('inner')}>
                    <Link to="/" className={cx('logo-link')}>
                        <img className="d-none d-sm-block" src={images.logo} alt="" />
                    </Link>
                </div>
                <div className={cx('menu mt-5')}>
                    <nav>
                        <div className={cx('group')}>
                            <div className={cx('title')}>
                                <Link to="/" onClick={action}>
                                    TRANG CHỦ
                                </Link>
                            </div>
                        </div>
                        <div className={cx('group')}>
                            <div
                                className={cx('title')}
                                onClick={() => setOpen1(!open1)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open1}
                            >
                                <Link to="">GIỚI THIỆU</Link>
                                <FontAwesomeIcon icon={faAngleDown} />
                            </div>
                            <Collapse in={open1}>
                                <div id="example-collapse-text">
                                    <ul className={cx('menuLv2')}>
                                        <li>
                                            <Link to="/gioi-thieu/lich-su-hinh-thanh" onClick={action}>
                                                Lịch sử hình thành
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/gioi-thieu/tam-nhin-su-mang" onClick={action}>
                                                Tầm nhìn - sứ mạng
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/gioi-thieu/ton-chi-hoat-dong" onClick={action}>
                                                Tôn chỉ hoạt động
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link to="" onClick={action}>
                                                Sơ đồ tổ chức
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link to="/gioi-thieu/linh-vuc-hoat-dong" onClick={action}>
                                                Lĩnh vực hoạt động
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/gioi-thieu/du-an-da-hoan-thanh" onClick={action}>
                                                Dự án đã hoàn thành
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className={cx('group')}>
                            <div
                                className={cx('title')}
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                            >
                                <Link to="">SẢN PHẨM</Link>
                                <FontAwesomeIcon icon={faAngleDown} />
                            </div>
                            <Collapse in={open}>
                                <div id="example-collapse-text">
                                    <ul className={cx('menuLv2')}>
                                        <li>
                                            <Link onClick={action} to="/khoa-hoc-online">
                                                Khóa học trực tiếp – trực tuyến
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={action} to="/khoa-hoc-video">
                                                Khóa học video
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="/sach-pdf">Sách PDF</a>
                                        </li>
                                        <li>
                                            <a href="/sach-giay">Sách giấy</a>
                                        </li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className={cx('group')}>
                            <div className={cx('title')}>
                                <Link to="/dich-vu" onClick={action}>
                                    DỊCH VỤ
                                </Link>
                            </div>
                        </div>
                        <div className={cx('group')}>
                            <div className={cx('title')}>
                                <Link to="/su-kien" onClick={action}>
                                    Sự kiện
                                </Link>
                            </div>
                        </div>
                        <div className={cx('group')}>
                            <div className={cx('title')}>
                                <a href="https://slo.vn" target="_blank" onClick={action}>
                                    TIN TỨC
                                </a>
                            </div>
                        </div>

                        {user ? (
                            <>
                                <div className={cx('group')}>
                                    <div className={cx('title')}>
                                        <Link to="/tai-khoan/thong-tin-ca-nhan" onClick={action}>
                                            THÔNG TIN CÁ NHÂN
                                        </Link>
                                    </div>
                                </div>
                                <div className={cx('group')}>
                                    <div className={cx('title')}>
                                        <Link
                                            to="/"
                                            onClick={() => {
                                                action();
                                                handleLogout();
                                            }}
                                        >
                                            ĐĂNG XUẤT
                                        </Link>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className={cx('group')}>
                                <div className={cx('title')}>
                                    <Link
                                        to=""
                                        onClick={() => {
                                            action();
                                            handleOpenModalAuth(EKeyStateModalAuth.SIGN_UP);
                                        }}
                                    >
                                        ĐĂNG NHẬP
                                    </Link>
                                </div>
                            </div>
                        )}
                    </nav>
                </div>
            </div>

            <ModalAuth
                modalAuthState={modalAuthState}
                visible={visible}
                handleCancel={handleCancel}
                handleOk={handleOk}
            />
        </>
    );
}

export default ToggleMenu;
