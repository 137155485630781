import classNames from 'classnames/bind';
import Header from '~/layouts/components/Header';
import Footer from '~/layouts/components/Footer';
import Copyright from '~/layouts/components/Copyright'
import styles from './DefaultLayout.module.scss';
import '../../assets/css/index.css';
import HeaderTop from '../components/Header-top';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    return (
        <div className={cx('wrapper')}>
            <HeaderTop />
            <Header />
            <div className={cx('container')}>
                <div className={cx('content')}>{children}</div>
            </div>
            <Footer />
            
        </div>
    );
}

export default DefaultLayout;
