const initialState = {
    list: null,
    myEvent: null,
    type: null,
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EVENT_SUCCESS':
            return {
                ...state,
                list: action.payload,
            };
        case 'EVENT_TYPE_SUCCESS':
            return {
                ...state,
                type: action.payload,
            };
        case 'MY_EVENT_SUCCESS':
            return {
                ...state,
                myEvent: action.payload,
            };
        case 'EVENT_FAILURE':
            return state;
        default:
            return state;
    }
};

export default eventReducer;
