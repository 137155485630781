const initialState = {
  listEvaluate: null,
  listOrder: null,
};

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HISTORY_EVALUATE_SUCCESS':
        return {
          ...state,
          listEvaluate: action.payload,
        };
      case 'HISTORY_EVALUATE_FAILURE':
        return state;
      case 'HISTORY_ORDER_SUCCESS':
          return {
            ...state,
            listOrder: action.payload,
          };
      case 'HISTORY_ORDER_FAILURE':
          return state;
      default:
        return state;
    }
  };
  
  export default historyReducer;
