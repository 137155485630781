import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Pagination from '~/components/Pagination';
import classNames from 'classnames/bind';
import styles from './List.module.scss';
import { ListReceiveService } from '~/Store/Action/serviceAction';

const cx = classNames.bind(styles);

const List = ({ handleDetailService }) => {

    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const list = useSelector(state => state.service.list);

    // console.log(list);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(ListReceiveService(auth.token));
    }, [auth]);

    return (
        <Grid
            width="100%"
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ flexGrow: 1 }}
            className={cx('wrapper')}
        >
            {list?.data.map((item, index) => (
                <Grid xs={12} key={index}>
                    <Link to="" className={cx('item')} onClick={() => handleDetailService(item?.id)}>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>{item?.service?.title}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                            <Typography sx={{ color: '#27AB60', fontSize:'16px' }}>{item?.user_name}</Typography>
                            <Typography sx={{ fontSize:'16px' }}>{item?.estimate_complete_date} ngày</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ fontSize:'16px' }}>{item?.budget} vnđ</Typography>
                            <Typography sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize:'16px' }}>{item?.status == "pending" ? "Đang chờ" : "Thành công"}</Typography>
                        </Box>
                        <Typography sx={{ fontSize:'14px', paddingTop: '7px' }}>{item?.approved_at}</Typography>
                    </Link>
                </Grid>
            ))}
            {/* <Pagination /> */}
        </Grid>
    );
};

export default List;
