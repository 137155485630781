import api from './api';

export const category = () => {
    return async (dispatch) => {
        try {
            // Gọi API trang chủ
            const response = await api.get('api/courses/form/fields', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'CATEGORY_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'CATEGORY_FAILURE', payload: error.message });
        }
    };
};


export const bookCategory = () => {
    return async (dispatch) => {
        try {
            // Gọi API trang chủ
            const response = await api.get('api/books/form/fields', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'CATEGORY_BOOK_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'CATEGORY_FAILURE', payload: error.message });
        }
    };
};

export const bookCategoryId = (book_classify_id) => {
    return async (dispatch) => {
        try {
            // Gọi API trang chủ
            const response = await api.get('api/book-categories',{
                book_classify_id: book_classify_id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'CATEGORYID_BOOK_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'CATEGORYID_FAILURE', payload: error.message });
        }
    };
};