import api from './api';

export const detailBook = (id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/books/' + id);
            dispatch({ type: 'DETAIL_BOOK_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'DETAIL_BOOK_FAILURE', payload: error.message });
        }
    };
};

export const checkBuyBook = (id, token) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get(
                'api/books/' + id + '/is_bought',
                {},
                {
         
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                  
                },
            );
            dispatch({ type: 'DETAIL_BOOK_CHECK_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'DETAIL_BOOK_FAILURE', payload: error.message });
        }
    };
};
