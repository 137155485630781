import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './Header-top.module.scss';
import ModalAuth from '~/components/ModalAuth/ModalAuth';
import Profile from '~/components/Profile/Profile';
import { EKeyStateModalAuth } from '~/components/ModalAuth/ModalAuth.enums';
import { useDispatch, useSelector } from 'react-redux';

const cx = classNames.bind(styles);

function HeaderTop() {
    const [visible, setVisible] = useState(false);

    const auth = useSelector((state) => state.auth);

    const [modalAuthState, setModalAuthState] = useState({
        visible: false,
    });

    const handleOpenModalAuth = (defaultKey) => {
        setVisible(true);
        setModalAuthState({ ...modalAuthState, visible: true, defaultKey });
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    return (
        <>
            <div className={cx('wrapper')}>
                <div className={cx('wrapper__top')}>
                    <p className={cx('address')}>
                        51 Quốc Hương - P. Thảo Điền - Q. Thành phố Hồ Chí Minh | lienhe@sociallife.vn
                    </p>
                    <div className={cx('login')}>
                        {auth.token ? (
                            <Profile className={cx('login')} />
                        ) : (
                            <>
                                <img
                                    onClick={() => handleOpenModalAuth(EKeyStateModalAuth.SIGN_UP)}
                                    src={images.iconLogin}
                                    alt=""
                                />
                                <span onClick={() => handleOpenModalAuth(EKeyStateModalAuth.SIGN_UP)}>Đăng nhập</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ModalAuth
                modalAuthState={modalAuthState}
                visible={visible}
                handleCancel={handleCancel}
                handleOk={handleOk}
            />
        </>
    );
}

export default HeaderTop;
