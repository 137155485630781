const initialState = {
    listBookPrint: null,
    listmyBookPrint: null,
    listBookPdf: null,
    listmyBookPdf: null,
    listSocialPdf: null,
    listSocialPrint: null,
    listSocialMemory: null,
};

const bookReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SOCIAL_MEMORY':
            return {
                ...state,
                listSocialMemory: action.payload,
            };
        case 'BOOK_PRINT_SUCCESS':
            return {
                ...state,
                listBookPrint: action.payload,
            };
        case 'BOOK_PRINT_FAILURE':
            return state;
        case 'BOOK_PDF_SUCCESS':
            return {
                ...state,
                listBookPdf: action.payload,
            };
        case 'MY_BOOK_PDF_SUCCESS':
            return {
                ...state,
                listmyBookPdf: action.payload,
            };
        case 'MY_BOOK_PRINT_SUCCESS':
            return {
                ...state,
                listmyBookPrint: action.payload,
            };
        case 'BOOK_PDF_FAILURE':
            return state;

        case 'SOCIAL_MEMORY_PDF_SUCCESS':
            return {
                ...state,
                listSocialPdf: action.payload,
            };
        case 'SOCIAL_MEMORY_PDF_FAILURE':
            return state;

        case 'SOCIAL_MEMORY_PRINT_SUCCESS':
            return {
                ...state,
                listSocialPrint: action.payload,
            };
        case 'SOCIAL_MEMORY_PRINT_FAILURE':
            return state;
        default:
            return state;
    }
};

export default bookReducer;
