import { Box, Typography, Stack, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import styles from './About.module.scss';
import { getInformation } from '~/Store/Action/informationActions';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

function About() {
    // const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const information = useSelector((state) => state.information.get);

    useEffect(() => {
        dispatch(getInformation(4));
    }, []);

    return (
        <Box className={cx('About', 'container')}>
            <Breadcumb title="Tôn chỉ hoạt động" />
            <Box className={cx('content')}>
                <div
                    className="mt-3 w-100 d-block"
                    dangerouslySetInnerHTML={{ __html: information ? information.content : '' }}
                />
            </Box>
        </Box>
    );
}

export default About;
