import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { regex } from '~/common/constants';
import styles from './VerifyOtpForm.module.scss';
import { useDispatch } from 'react-redux';
import { check_otp } from '../../../Store/Action/authActions.js';

const cx = classNames.bind(styles);
const VerifyOtpForm = ({ disabled, onChange, onSubmit, onVerifyForgotSuccess, onClickSignUp, num }) => {
    const dispatch = useDispatch();
    const initPasscode = {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
    };
      
  const [passcode, setPasscode] = useState(initPasscode);
  const [fullPassCode, setFullPasscode] = useState({});

  const email = localStorage.getItem('email');

  const inputCodeRef0 = useRef(null);
  const inputCodeRef1 = useRef(null);
  const inputCodeRef2 = useRef(null);
  const inputCodeRef3 = useRef(null);
  const inputCodeRef4 = useRef(null);
  const inputCodeRef5 = useRef(null);

  const inputCodeRef = {
    0: inputCodeRef0,
    1: inputCodeRef1,
    2: inputCodeRef2,
    3: inputCodeRef3,
    4: inputCodeRef4,
    5: inputCodeRef5,
  };

  const handlePressKeyboard = (e, codePosition) => {
    const value = e.key;
    const notAtTheLastPosition = codePosition < Object.keys(passcode).length - 1;
    const notAtTheFirstPosition = codePosition > 0;
  
    if (regex.numeric.test(value)) {
      setPasscode(prevPasscode => ({ ...prevPasscode, [codePosition]: value }));
  
      if (notAtTheLastPosition) {
        inputCodeRef[codePosition + 1]?.current?.focus();
      }
    } else if (value === 'Backspace' && notAtTheFirstPosition) {
      if (passcode[codePosition]) {
        setPasscode(prevPasscode => ({ ...prevPasscode, [codePosition]: '' }));
      } else {
        setPasscode(prevPasscode => ({ ...prevPasscode, [codePosition - 1]: '' }));
        inputCodeRef[codePosition - 1]?.current?.focus();
      }
    }
  
    const fullPasscode = Object.values({ ...passcode, [codePosition]: value }).join('');
    setFullPasscode(fullPasscode);
  };
  

  useEffect(() => {
    onChange?.(Object.values(passcode).join(''));

    const isFillCodeComplete = Object.values(passcode).every((code) => code);
    if (isFillCodeComplete) {
      const finalPasscode = Object.values(passcode).join('');
      onSubmit?.(finalPasscode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passcode]);

  useEffect(() => {
    document.getElementById('inputCode-0')?.focus();
  }, []);

//   console.log(fullPassCode);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(fullPassCode);
    const status = await dispatch(check_otp(email, fullPassCode));
    if (status) {
      if(num === 1){
        onClickSignUp();
      }else{
        onVerifyForgotSuccess();
      }
    }
   };

    return (
        <div className={cx('verifyOtpForm')}>
            <p>Xin vui lòng nhập mã OTP được gửi đến email <span className={cx('otp-email')}>{email}</span></p>
            <form onSubmit={handleSubmit}>
                <div className={cx('Passcode')}>
                    {Object.keys(passcode).map((key) => (
                        <div key={key} className={cx('Passcode-item', { 'has-value': passcode[+key] })}>
                            <input
                            className={cx('Passcode-item-input')}
                            id={`inputCode-${key}`}
                            ref={inputCodeRef[+key]}
                            value={passcode[+key]}
                            disabled={disabled}
                            onKeyDown={(e) => handlePressKeyboard(e, +key)}
                            maxLength={1}
                            />
                        </div>
                    ))}
                </div>
                <div
                    className={cx('btn_sendTo')}
                    >
                    Không nhận được mã?
                    <span style={{ paddingRight: 12 }}>
                        Gửi lại
                    </span>
                    {/* {resendLoading && <Loading />} */}
                </div>
                <button className={cx('btn-complete')} type="submit">HOÀN THÀNH</button>
            </form>
        </div>
    );
}

export default VerifyOtpForm;