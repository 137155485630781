import React from 'react';
import { Link } from "react-router-dom";
import { Checkbox } from 'antd';
import { Box, Typography, Grid, Button, TextField, MenuItem, Select, FormControl, InputLabel, Stack } from '@mui/material';
import classNames from 'classnames/bind';
import CloseIcon from '@mui/icons-material/Cancel';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import styles from './MyDetailSurvey.module.scss';


const cx = classNames.bind(styles);

const MyDetailSurvey = () => {
  

    return (
        <Box className={cx('MyDetailSurvey','container')}>
            <Box py="10px" className={cx('Breadcumb')}>
                <Typography sx={{ paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/">Trang chủ</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khao-sat">Tài khoản</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khao-sat">Quản lý chuyên gia đối tác</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khao-sat">Chi tiết khảo sát</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="#">Khảo sát 1</Link>
                </Typography>
            </Box>
            <div className="row mt-3">
                <div className=" col- col-sm- col-md- col-lg-4 col-xl-3 col-xxl-3">
                    <div className="mt-4">
                        <div className="card">
                            <div className="card-body" style={{ padding: 0 }}>
                                <div className={cx('info-ques')}>
                                    <h3 className="text-dark fw-bold mb-3" style={{ fontFamily: 'sans-serif' }}>Khảo sát 1</h3>
                                    <div className="d-flex justify-content-between" style={{ paddingBottom: '10px' }}>
                                        <span className="p">Số câu hỏi:</span>
                                        <span className="p">10 câu hỏi</span>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ paddingBottom: '10px' }}>
                                        <span className="p">Thời gian đăng: </span>
                                        <span className="p">22/7/2023</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between" style={{ padding: '10px', borderBottom: '1px solid rgba(216, 217, 219, 1)' }}>
                                    <span className="p">Số người tham gia: </span>
                                    <span className="p">100</span>
                                </div>
                                <div className="d-flex justify-content-between" style={{ padding: '10px', borderBottom: '1px solid rgba(216, 217, 219, 1)' }}>
                                    <span className="p">Trạng thái: </span>
                                    <span className="p">Đang diễn ra</span>
                                </div>
                                <div className="d-flex justify-content-between" style={{ padding: '10px', borderBottom: '1px solid rgba(216, 217, 219, 1)' }}>
                                    <span className="p">Thống kê tổng quan: </span>
                                    <span className="p" style={{ color: 'rgba(39, 171, 96, 1)', textDecoration: 'underline' }}>Xuất file</span>
                                </div>
                                <div className="d-flex justify-content-between" style={{ padding: '10px' }}>
                                    <span className="p">Thống kê chi tiết: </span>
                                    <span className="p" style={{ color: 'rgba(39, 171, 96, 1)', textDecoration: 'underline' }}>Xuất file</span>
                                </div>
                            </div>
                        </div>
                        <div className="btn-stop-survay px-4 cursor-pointer">Dừng khảo sát</div>
                    </div>
                </div>
                <div className="col- col-sm- col-md- col-lg-8 col-xl-9 col-xxl-9">
                    <div className="mt-4">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h3 className="text-dark fw-bold mb-5" style={{ fontFamily: 'sans-serif' }}>
                                    Câu hỏi 1 :Khảo sát 1
                                </h3>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <img src={images.DoSurvay} className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body">
                                <h3 className="text-dark fw-bold mb-5" style={{ fontFamily: 'sans-serif' }}>
                                    Câu hỏi 1 :Khảo sát 1
                                </h3>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                        <div className="mb-4 p-2 d-flex" style={{ background: 'rgba(245, 245, 245, 1)' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <label className="ms-3 text-dark" style={{ fontWeight: 600 }}>Đáp án 1</label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <img src={images.DoSurvay} className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body">
                                <h3 className="text-dark fw-bold mb-5 mb-3" style={{ fontFamily: 'sans-serif' }}>
                                    Câu hỏi 1 : Khóa học Socialife ứng dụng nghề nghiệp Khóa học Socialife ứng dụng nghề
                                    nghiệp
                                </h3>
                                <div className="row">
                                    <div className="col-12">
                                        <textarea
                                            class="form-control enter_text"
                                            placeholder="Nhập đáp án"
                                            name=""
                                            id=""
                                            rows="4"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="p-fw-bold">1/10</h3>
                        <div className="btn-survay px-5">Tiếp theo</div>
                    </div>
                </div>
            </div>
        </Box>
    );
}

export default MyDetailSurvey;