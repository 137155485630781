import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Stack,
} from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import styles from './AddBookPrint.module.scss';
import { bookCategory } from '~/Store/Action/categoryActions';
import { useDispatch, useSelector } from 'react-redux';
import { add_book_print, add_chapter, edit_book_print } from '~/Store/Action/bookActions';
import SunEditor from 'suneditor-react';
import { edit_course_online } from '~/Store/Action/courseActions';
import { detailBook } from '~/Store/Action/detailBookActions';
import { useNavigate } from 'react-router-dom';
import { category } from '~/Store/Action/categoryActions';
import { useParams } from 'react-router-dom';

const cx = classNames.bind(styles);

function AddBookPrint() {
    const { id } = useParams();
    const [visible, setVisible] = useState(false);

    const [addVideoState, setAddVideoState] = useState({
        visible: false,
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const listBookCategory = useSelector((state) => state.category.listBookCategory);
    // const listCourseVideo = useSelector((state) => state.course.listCourseVideo);
    const auth = useSelector((state) => state.auth);

    const [img, setImg] = useState(null);

    const handleImg = (event) => {
        const files = event.target.files;
        console.log(files);
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImg(image);
    };

    const data = useSelector((state) => state.detailBook?.data?.data);

    useEffect(() => {
        dispatch(bookCategory());
        dispatch(detailBook(id));
    }, []);

    const onAdd = async () => {
        var form_question = document.getElementById('form_data');
        var formData = new FormData(form_question);

        // xem trong form có những gì
        for (const [key, value] of formData) {
            console.log(`${key}:`, value);
        }

        const data = await dispatch(edit_book_print(auth.token, formData, id));
    };

    const onAddBook = async () => {
        // event.preventDefault();
        var form_question = document.getElementById('form_data');
        var formData = new FormData(form_question);

        // xem trong form có những gì
        for (const [key, value] of formData) {
            console.log(`${key}:`, value);
        }

        const data = await dispatch(add_book_print(auth.token, formData));
        return data;
    };

    return (
        <Box className={cx('AddBookPrint', 'container')}>
            <Box py="10px" className={cx('Breadcumb')}>
                <Typography sx={{ paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/">Trang chủ</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-sach-giay">Tài khoản</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-sach-giay">Quản lý chuyên gia đối tác</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-sach-giay">Quản lý sách giấy</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="#">Sửa sách giấy</Link>
                </Typography>
            </Box>
            {data && (
                <form id="form_data" method="post" enctype="multipart/form-data">
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ flexGrow: 1 }}
                        className={cx('wrapper')}
                    >
                        <Grid pb={2} pr={2} xs={6}>
                            <TitleUser className={cx('title-wp')} title="Sửa sách" />
                            <Box
                                className={cx('content-wp') + ' wpp'}
                                style={{
                                    backgroundImage: `url(${img ?? data?.image})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}
                            >
                                <label className={cx('content')} htmlFor="upload-img-btn">
                                    <TextField
                                        label="Chọn ảnh"
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={(event) => handleImg(event)}
                                        style={{ display: 'none' }}
                                        id="upload-img-btn"
                                    />
                                    <Box className={cx('wp-file')}>
                                        <img src={images.camera} alt="chọn ảnh" />
                                    </Box>
                                </label>
                                <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>
                                    Tải ảnh lên (4 x 2)
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid pt={8} pb={2} pr={2} xs={6} className="wow animate__animated animate__zoomInDown">
                            <FormControl sx={{ paddingTop: '10px' }} fullWidth>
                                <Stack spacing={2}>
                                    <input name="book_classify_id" value="1" type="hidden" />
                                    <input name="type" value="print_book" type="hidden" />
                                    <input name="trending" value="1" type="hidden" />
                                    <TextField
                                        label="Tiêu đề"
                                        variant="outlined"
                                        name="name"
                                        defaultValue={data?.name}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                            id="demo-simple-select-label"
                                        >
                                            Chọn danh mục
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="book_category_id"
                                            label="Chọn danh mục"
                                            defaultValue={data?.book_category?.id}
                                            // onChange={handleChange}
                                            sx={{
                                                '& .MuiSelect-root': {
                                                    color: 'blue',
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    padding: '14px',
                                                    background: '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-3%',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    //   borderColor: 'orange',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    span: {
                                                        fontSize: '12px',
                                                    },
                                                },
                                            }}
                                        >
                                            {listBookCategory?.data?.book_categories.map((item, index) => (
                                                <MenuItem value={item.id} key={index}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Tiền (vnđ)"
                                        variant="outlined"
                                        name="price"
                                        defaultValue={data?.price}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Tiền sau giảm giá (vnđ)"
                                        variant="outlined"
                                        name="discount_price"
                                        defaultValue={data?.discount_price}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Tiền xu"
                                        variant="outlined"
                                        name="coin_price"
                                        defaultValue={data?.coin_price}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Tiền sau giảm giá xu (xu)"
                                        name="discount_coin_price"
                                        defaultValue={data?.discount_coin_price}
                                        variant="outlined"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                            id="demo-simple-select-label"
                                        >
                                            Chọn chuyên nghành
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={age}
                                            name="specialization_id"
                                            label="Chọn chuyên nghành"
                                            defaultValue={data?.specialization?.id}
                                            // onChange={handleChange}
                                            sx={{
                                                '& .MuiSelect-root': {
                                                    color: 'blue',
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    padding: '14px',
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    background: '#fff',
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    top: '-3%',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    //   borderColor: 'orange',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    span: {
                                                        fontSize: '12px',
                                                    },
                                                },
                                            }}
                                        >
                                            {listBookCategory?.data?.specializations.map((item, index) => (
                                                <MenuItem value={item.id} key={index}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Kích thước"
                                        variant="outlined"
                                        name="size"
                                        defaultValue={data?.size}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Loại bìa"
                                        variant="outlined"
                                        name="cover_type"
                                        defaultValue={data?.cover_type}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Số trang"
                                        variant="outlined"
                                        name="page_number"
                                        defaultValue={data?.page_number}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Nhà xuất bản"
                                        variant="outlined"
                                        name="publisher"
                                        defaultValue={data?.publisher}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Năm xuất bản"
                                        variant="outlined"
                                        name="publishing_at"
                                        defaultValue={data?.publishing_at}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                background: '#fff',
                                                fontWeight: 'bold',
                                                padding: '11.5px 14px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-10%',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    fontSize: '16px',
                                                },
                                            },
                                        }}
                                    />
                                    <SunEditor
                                        name="description"
                                        defaultValue={data?.description}
                                        height="500"
                                        setOptions={{
                                            buttonList: [
                                                ['undo', 'redo'],
                                                ['font', 'fontSize', 'formatBlock'],
                                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                                ['bold', 'underline', 'italic', 'strike'],
                                                ['removeFormat'],
                                                ['outdent', 'indent'],
                                                ['align', 'horizontalRule', 'list', 'table'],
                                                ['fontColor', 'hiliteColor'],
                                                ['link', 'image'],
                                                ['fullScreen', 'showBlocks', 'codeView'],
                                                ['preview', 'print'],
                                            ],
                                        }}
                                    />
                                </Stack>
                            </FormControl>
                        </Grid>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Button className={cx('btn_submit')} variant="contained" onClick={onAdd}>
                                XÁC NHẬN SỬA
                            </Button>
                        </div>
                    </Grid>
                </form>
            )}
        </Box>
    );
}

export default AddBookPrint;
