const initialState = {
  error: null,
  list: null,
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_CART_SUCCESS':
        return {
          ...state,
          error: null,
        };
      case 'ADD_CART_FAILURE':
        return {
          ...state,
          error: action.payload,
      };
      case 'UPDATE_CART_SUCCESS':
        return {
          ...state,
          error: null,
        };
      case 'UPDATE_CART_FAILURE':
        return {
          ...state,
          error: action.payload,
      };
      case 'GET_CART_SUCCESS':
        return {
          ...state,
          list: action.payload,
        };
      case 'GET_CART_FAILURE':
        return state;
      default:
        return state;
    }
  };
  
  export default cartReducer;
