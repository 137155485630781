import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, Stack, TextField } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import classNames from 'classnames/bind';
import styles from './CompleteService.module.scss';
import images from '~/assets/images';
import ModalUser from '~/components/ModalUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import StarIcon from '@mui/icons-material/Star';
import { ReceiveIdService, ReceiveIdServiceComfirm } from '~/Store/Action/serviceAction';
import { format } from 'date-fns';

const cx = classNames.bind(styles);

const CompleteService = ({ idServiceComplete }) => {
    const [visible, setVisible] = useState(false);

    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const item = useSelector((state) => state.service.item);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        if (idServiceComplete) {
            dispatch(ReceiveIdService(auth.token, idServiceComplete));
        }
    }, [auth]);

    const [modalUserState, setModalUserState] = useState({
        visible: false,
    });

    const handleOpenModalUser = async (defaultKey) => {
        const data = await dispatch(ReceiveIdServiceComfirm(auth.token, idServiceComplete));

        await setVisible(true);
        await setModalUserState({ ...modalUserState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <Box className={cx('CompleteService')}>
                <TitleUser title="Thông tin của bạn" />
                <Box className={cx('box-wp')}>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Tên khách hàng</Typography>
                        <Typography>{item?.user_name}</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Đơn vị công tác</Typography>
                        <Typography>{item?.organization}</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Mã dịch vụ</Typography>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>{item?.ref}</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian đăng</Typography>
                        <Typography>
                            {item?.created_at ? format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy') : ''}
                        </Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Trạng thái dịch vụ</Typography>
                        <Typography sx={{ color: '#F9AB5C' }}>
                            {item?.status === 'pending' && 'Đang chờ'}
                            {item?.status === 'doing' && 'Đang thực hiện'}
                            {item?.status === 'completed' && 'Hoàn thành'}
                            {item?.status === 'cancelled' && 'Đã huỷ bỏ'}
                        </Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Số tiền</Typography>
                        <Typography sx={{ color: '#27AB60' }}>+ {item?.budget} vnđ</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Loại dịch vụ</Typography>
                        <Typography>{item?.service?.title}</Typography>
                    </Box>
                    <Box className={cx('box-des')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Mô tả dịch vụ</Typography>
                        <Typography sx={{ fontWeight: '300!important' }}>{item?.service?.description}</Typography>
                    </Box>
                    <Box className={cx('box-file')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>File đính kèm</Typography>
                        <Box className={cx('list-file')}>
                            {item?.attachments?.length > 0 ? (
                                <Box className={cx('file')}>
                                    <img src={images.pdf} alt="" />
                                    <Typography>file 1</Typography>
                                </Box>
                            ) : (
                                <h2 style={{ fontSize: '13px', paddingTop: '7px' }}>
                                    Hiện tại không có file đính kèm nào!
                                </h2>
                            )}
                        </Box>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian nhận</Typography>
                        <Typography>{item?.approved_at}</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian bắt đầu</Typography>
                        <Typography>
                            {item?.created_at ? format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy') : ''}
                        </Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian kết thúc</Typography>
                        <Typography>08:00 - 21/12/2022</Typography>
                    </Box>
                    <Box className={cx('box-info')}>
                        <Typography sx={{ fontFamily: 'sans-serif' }}>Thanh toán</Typography>
                        <Typography>{item?.payment_status != null ? 'Đã thanh toán' : 'Chưa thanh toán'}</Typography>
                    </Box>
                </Box>
                {item?.status === 'pending' && (
                    <Button className={cx('btn_submit')} variant="contained">
                        Dịch vụ đang chờ admin duyệt
                    </Button>
                )}
                {item?.status === 'doing' && (
                    <Button onClick={handleOpenModalUser} className={cx('btn_submit')} variant="contained">
                        Xác nhận hoàn thành dịch vụ
                    </Button>
                )}
                {item?.status === 'completed' && (
                    <Button className={cx('btn_submit')} variant="contained">
                        Dịch vụ đã hoàn thành
                    </Button>
                )}
                {item?.status === 'cancelled' && (
                    <Button className={cx('btn_submit')} variant="contained">
                        Dịch vụ đã bị huỷ
                    </Button>
                )}

                <ModalUser modalUserState={modalUserState} visible={visible} handleCancel={handleCancel}>
                    <div className={cx('header-modal-back')}>
                        <button className={cx('btn-back')}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <h4>Xác thực OTP</h4>
                        <FontAwesomeIcon className={cx('close')} icon={faClose} />
                    </div>
                    <div className={cx('content-modal')}>
                        <div className={cx('star')}>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                        </div>
                        <Typography sx={{ fontSize: '16px' }}>Chọn mức đánh giá của bạn</Typography>
                        <Stack spacing={3} m={3}>
                            <TextField
                                multiline
                                rows={5}
                                maxRows={16}
                                required
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            fontSize: '16px',
                                        },
                                    },
                                }}
                                type="text"
                                label="Nội dung"
                                // {...getFieldProps('content')}
                                // error={Boolean(touched.content && errors.content)}
                                // helperText={touched.content && errors.content}
                            />
                        </Stack>
                        <Box sx={{ padding: '16px 11px' }}>
                            <label className={cx('content')} htmlFor="upload-img-btn">
                                <TextField
                                    label="Họ và tên"
                                    type="file"
                                    style={{ display: 'none' }}
                                    id="upload-img-btn"
                                />
                                <Button
                                    sx={{ borderRadius: '8px' }}
                                    className={cx('choose-image')}
                                    variant="outlined"
                                    component="span"
                                >
                                    Tải hình ảnh
                                </Button>
                            </label>
                        </Box>
                        <Box className={cx('list-img')}>
                            <img src={images.reviewImg} alt="" />
                            <img src={images.reviewImg} alt="" />
                            <img src={images.reviewImg} alt="" />
                            <img src={images.reviewImg} alt="" />
                        </Box>
                        <Button
                            sx={{ marginBottom: '10px', width: '470px' }}
                            className={cx('btn_submit')}
                            variant="contained"
                        >
                            GỬI ĐÁNH GIÁ
                        </Button>
                    </div>
                </ModalUser>
            </Box>
        </>
    );
};

export default CompleteService;
