import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};
export const history_evaluate = (token, currency) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get(
                'api/my/transactions',
                {
                    currency: currency,
                },
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'HISTORY_EVALUATE_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'HISTORY_EVALUATE_FAILURE', payload: error.message });
        }
    };
};

export const history_order = (status, token, page) => {
    return async (dispatch) => {
        try {
            // Gọi API
            if (status == null) {
                const response = await api.get(
                    'api/orders',
                    {
                        page: page ?? 1,
                    },
                    {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                );
                dispatch({ type: 'HISTORY_ORDER_SUCCESS', payload: response.data });
            } else {
                const response = await api.get(
                    'api/orders',
                    {
                        status: status,
                        page: page ?? 1,
                    },
                    {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                );
                dispatch({ type: 'HISTORY_ORDER_SUCCESS', payload: response.data });
            }
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'HISTORY_ORDER_FAILURE', payload: error.message });
        }
    };
};

export const my_order = (id, token, status, page) => {
    return async (dispatch) => {
        try {
            // Gọi API
            let data = {};
            if (!status || status == null) {
                data = { page: page ?? 1 };
            } else {
                data = {
                    status: status,
                    page: page ?? 1,
                };
            }

            const response = await api.get('api/management/orders/partner?owner_id=' + id, data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });
            dispatch({ type: 'HISTORY_ORDER_SUCCESS', payload: response.data });
            console.log(response);
        } catch (error) {
            dispatch({ type: 'HISTORY_ORDER_FAILURE', payload: error.message });
        }
    };
};

export const cancel_order = (id, token) => {
    return async (dispatch) => {
        try {
            console.log(token);
            // Gọi API
            const response = await api.get(
                'api/orders/' + id + '/canceled',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'CANCEL_ORDER_SUCCESS', payload: response.data });
            showToast('Hủy đơn hàng thành công');
            return true;
        } catch (error) {
            dispatch({ type: 'CANCEL_ORDER_FAILURE', payload: error.message });
        }
    };
};

export const confirm_order = (id, token) => {
    return async (dispatch) => {
        try {
            console.log(token);
            // Gọi API
            const response = await api.post(
                'api/orders/' + id + '/confirmed',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            // dispatch({ type: 'CANCEL_ORDER_SUCCESS', payload: response.data });
            showToast('Xác nhận đơn hàng thành công');
            return true;
        } catch (error) {
            dispatch({ type: 'CANCEL_ORDER_FAILURE', payload: error.message });
        }
    };
};
