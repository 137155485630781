import Cookies from 'js-cookie';

const api_url = process.env.REACT_APP_API_URL;

const initialState = {
    token: Cookies.get('token') || null,
    user: localStorage.getItem('user') || null,
    error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            Cookies.set('token', action.payload, { expires: 1.5 });

            return {
                ...state,
                token: action.payload,
                error: null,
            };
        case 'LOGIN_FAILURE':
            return {
                ...state,
                token: null,
                error: action.payload,
            };
        case 'SET_USER':
            // Cookies.set('user', action.payload, { expires: 1.5 });
            localStorage.setItem('user', action.payload);

            return {
                ...state,
                user: action.payload,
            };
        case 'LOGOUT_SUCCESS':
            Cookies.remove('token');
            Cookies.remove('user');

            return {
                ...state,
                token: null,
                user: null,
                error: null,
            };
        case 'LOGOUT_FAILURE':
            return {
                ...state,
                token: null,
                error: null,
            };
        case 'REGISTER_SUCCESS':
            return {
                ...state,
                error: null,
            };
        case 'REGISTER_FAILURE':
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;
