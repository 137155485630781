import { Box } from '@mui/material';
import CourseDataItem from '~/components/CourseDataItem';
import TitleUser from '~/components/TitleUser';
import Pagination from '~/components/Pagination';
import classNames from 'classnames/bind';
import styles from './MyDocument.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { add_cart, get_cart } from '~/Store/Action/cartActions';
import images from '~/assets/images';
import { format } from 'date-fns';
// import Pagination from '@/components/Pagination';
import slugify from 'slugify';

const api_url = process.env.REACT_APP_API_URL;

const showToast = (message, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    toast.error(message, toastOptions);
};

const cx = classNames.bind(styles);

const MyDocument = () => {
    const [data, setData] = useState([]);
    const auth = useSelector((state) => state.auth);
    const [list, setList] = useState(null);
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchData(1);
    }, [auth]);
    let page = list?.current_page;
    // console.log(page);
    let totalPage = list?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            fetchData(prevPage);
        }
    };

    function formatDate(input) {
        const date = new Date(input);
        if (isNaN(date)) {
            return '0/0/0000';
        }

        const formattedDate = format(date, 'dd/M/yyyy');
        return formattedDate;
    }

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            fetchData(nextPage);
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchData(pageNumber);
    };

    const fetchData = async (page = 1) => {
        try {
            const data = await axios.get(api_url + 'api/my/books?page=' + page ?? 1, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                },
            });
            await setList(data.data.data);
            console.log(data.data.data.data);
            await setData(data.data.data.data);
            if (data.data.status != 200) {
                throw new Error(data.data.message);
            }
            return data;
        } catch (e) {
            console.log(e);
            showToast('Đã có lỗi xảy ra vui lòng thử lại');

            throw e;
        }
    };

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);
    const addCart = async (id) => {
        let cart = {
            product_type: 'book',
            book_id: id,
            quantity: 1,
            payment_method: 'vnd',
        };
        localStorage.setItem('cart', JSON.stringify(cart));
        let fieldCart = {
            product_type: JSON.parse(localStorage.getItem('cart')).product_type,
            book_id: JSON.parse(localStorage.getItem('cart')).book_id,
            quantity: JSON.parse(localStorage.getItem('cart')).quantity,
            payment_method: JSON.parse(localStorage.getItem('cart')).payment_method,
        };
        await dispatch(add_cart(auth.token, fieldCart));
        if (user != null) {
            dispatch(get_cart(auth.token));
        }
    };

    return (
        <Box>
            <TitleUser title="Tất cả tài liệu" />
            <Box className={cx('list')}>
                {data?.length > 0 ? (
                    <>
                        {data?.map((item, index) =>
                            // <CourseDataItem key={index} data={item} className={cx('myDocument')} />
                            item?.book != null ? (
                                <Box className={cx('item', cx('myDocument'))}>
                                    <Link to={'/sach-giay/' + slugify(item?.book?.name) + '/' + item?.book_id}>
                                        <img
                                            style={{ height: '283px', width: '100%' }}
                                            className="img-fluid"
                                            src={item?.book?.image}
                                            alt=""
                                        />
                                    </Link>
                                    <Box className={cx('CourseData-content')}>
                                        <Box className={cx('CourseData-content-top')}>
                                            <Link
                                                to={
                                                    '/sach-giay/' +
                                                    slugify(item?.book?.name) +
                                                    '/' +
                                                    item?.book?.book_id
                                                }
                                            >
                                                {item?.book?.name}
                                            </Link>
                                            <Box className={cx('avatar')}>
                                                <img
                                                    style={{ width: '28px', height: '28px', borderRadius: '50%' }}
                                                    className="img-fluid"
                                                    src={item?.owner_image ?? item?.book?.owner?.image}
                                                    alt=""
                                                />
                                                <Typography>{item?.owner_name ?? item?.book?.owner?.name}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={cx('cart')}>
                                            {/* <img onClick={() => addCart(item?.id)} src={images.CartPer} alt="" /> */}
                                        </Box>
                                        <Box className={cx('count_CourseData')}>
                                            <Typography>{item?.view} bài học</Typography>
                                            <Typography>
                                                {item?.book?.created_at ? formatDate(item?.book?.created_at) : ''}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box className={cx('item', cx('myDocument'))}>
                                    <Link to="#">
                                        <img
                                            style={{ height: '283px', width: '100%' }}
                                            className="img-fluid"
                                            src={item?.book?.image}
                                            alt=""
                                        />
                                    </Link>
                                    <Box className={cx('CourseData-content')}>
                                        <Box className={cx('CourseData-content-top')}>
                                            <Link to="#">{item?.book?.name}</Link>
                                            <Box className={cx('avatar')}>
                                                {/* <img
                                                    style={{ width: '28px', height: '28px', borderRadius: '50%' }}
                                                    className="img-fluid"
                                                    src={item?.owner_image ?? item?.owner?.image}
                                                    alt=""
                                                /> */}
                                                <Typography>
                                                    <span className="text-danger">Sản phẩm đã bị xoá</span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className={cx('cart')}>
                                            {/* <img onClick={() => addCart(item?.id)} src={images.CartPer} alt="" /> */}
                                        </Box>
                                        <Box className={cx('count_CourseData')}>
                                            {/* <Typography>{item?.view} bài học</Typography> */}
                                            {/* <Typography>
                                                {item?.book?.created_at ? formatDate(item?.book?.created_at) : ''}
                                            </Typography> */}
                                        </Box>
                                    </Box>
                                </Box>
                            ),
                        )}
                        {/* <Pagination /> */}
                        <Pagination
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            handlePageChange={handlePageChange}
                            page={list?.current_page}
                            totalPage={list?.total}
                        />
                    </>
                ) : (
                    <h2>Hiện tại không có tài liệu nào!</h2>
                )}
            </Box>
        </Box>
    );
};

export default MyDocument;
