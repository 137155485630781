const initialState = {
    error: null,
    list: null,
    payment: null,
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ORDER_SUCCESS':
            return {
                ...state,
                error: null,
            };
        case 'PAYMENT_SUCCESS':

            return {
                ...state,
                payment: action.payload,
            };
        case 'ORDER_FAILURE':
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default orderReducer;
