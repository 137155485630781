import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const getInformation = (id) => {
    return async (dispatch) => {
        console.log(id);
        if (id == null) {
            const message = 'Có lỗi vui lòng thử lại';

            showToast(message, 1);
            return;
        }
        try {
            const response = await api.get(
                'api/about-us/detail/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                dispatch({ type: 'GET_INFORMATION_SUCCESS', payload: response.data.data });
                return true;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            return false;
        }
    };
};
