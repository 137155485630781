import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Stack,
} from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import styles from './EditEvent.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { edit_event } from '~/Store/Action/eventActions';
import { useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import { useParams } from 'react-router-dom';
import { eventDetail } from '~/Store/Action/eventDetailActions';

const cx = classNames.bind(styles);

function EditEvent() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const onEditEvent = async (event) => {
        event.preventDefault();
        var formData = new FormData(event.target);

        // xem trong form có những gì
        for (const [key, value] of formData) {
            console.log(`${key}:`, value);
        }

        if (await dispatch(edit_event(auth.token, id, formData))) {
            navigate(`/tai-khoan/quan-ly-su-kien`);
        }
    };

    const [img, setImg] = useState(null);

    const handleImg = (event) => {
        const files = event.target.files;
        console.log(files);
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImg(image);
    };

    const data = useSelector((state) => state.eventDetail?.data?.data);

    const formatDate = (date) => {
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        // Đảm bảo định dạng mm/dd
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }

        return `${year}-${month}-${day}`;
    };

    const [openingTime, setOpeningTime] = useState(new Date().toISOString().split('T')[0]); // Lấy ngày hiện tại và định dạng thành chuỗi YYYY-MM-DD

    console.log(data);

    useEffect(() => {
        async function load() {
            await dispatch(eventDetail(id));
        }
        load();

        if (data) {
            const targetDate = new Date(data?.opening_time);
            const formattedDate = formatDate(targetDate);
            setOpeningTime(formattedDate);
        }
    }, []);

    useEffect(() => {
        if (data) {
            const targetDate = new Date(data?.opening_time);
            const formattedDate = formatDate(targetDate);
            setOpeningTime(formattedDate);
        }
    }, [data]);

    return (
        <Box className={cx('EditEvent', 'container')}>
            <Box py="10px" className={cx('Breadcumb')}>
                <Typography sx={{ paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/">Trang chủ</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-su-kien">Tài khoản</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-su-kien">Quản lý chuyên gia đối tác</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-su-kien">Quản lý sự kiện</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="#">Sửa sự kiện</Link>
                </Typography>
            </Box>
            <form id="form_event" method="post" enctype="multipart/form-data" onSubmit={onEditEvent}>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ flexGrow: 1 }}
                    className={cx('wrapper')}
                >
                    <Grid pb={2} pr={2} xs={6} className="wow animate__animated animate__lightSpeedInLeft">
                        <TitleUser className={cx('title-wp')} title="Sửa sự kiện" />
                        <Box
                            className={cx('content-wp') + ' wpp'}
                            style={{
                                backgroundImage: `url(${img ?? data?.image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                        >
                            <label className={cx('content')} htmlFor="upload-img-btn">
                                <TextField
                                    label="Chọn ảnh"
                                    type="file"
                                    name="image"
                                    style={{ display: 'none' }}
                                    onChange={(event) => handleImg(event)}
                                    id="upload-img-btn"
                                />
                                <Box className={cx('wp-file')}>
                                    <img src={images.camera} alt="chọn ảnh" />
                                </Box>
                            </label>
                            <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>Tải ảnh lên (4 x 2)</Typography>
                        </Box>
                        <FormControl sx={{ paddingTop: '10px' }} fullWidth>
                            <Stack spacing={2}>
                                <TextField
                                    label="Tiêu đề"
                                    variant="outlined"
                                    name="title"
                                    defaultValue={data?.title}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Giờ diễn ra"
                                    value={data?.opening_time}
                                    variant="outlined"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Ngày diễn ra"
                                    variant="outlined"
                                    type="date"
                                    name="opening_time"
                                    value={openingTime}
                                    // value={data?.opening_time}
                                    onChange={(event) => setOpeningTime(event.target.value)}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Mã đăng ký"
                                    variant="outlined"
                                    name="code"
                                    value={data?.code}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Link tham gia"
                                    variant="outlined"
                                    name="link"
                                    value={data?.link}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                            </Stack>
                        </FormControl>
                    </Grid>
                    <Grid pt={6} pr={2} xs={6} className="wow animate__animated animate__lightSpeedInRight">
                        <div className={cx('list-chapper')}>
                            <TitleUser className={cx('title-wp') + ' pb-0'} title="Mô tả sự kiện" />
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-12 col-lg-12 mb-4">
                                <SunEditor
                                    name="description"
                                    defaultValue={data?.description}
                                    height="500"
                                    setOptions={{
                                        buttonList: [
                                            ['undo', 'redo'],
                                            ['font', 'fontSize', 'formatBlock'],
                                            // ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['bold', 'underline', 'italic', 'strike'],
                                            ['removeFormat'],
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'table'],
                                            ['fontColor', 'hiliteColor'],
                                            ['link', 'image'],
                                            ['fullScreen', 'showBlocks', 'codeView'],
                                            // ['preview', 'print'],
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button className={cx('btn_submit')} type="submit" variant="contained">
                            Xác nhận sửa
                        </Button>
                    </div>
                </Grid>
            </form>
        </Box>
    );
}

export default EditEvent;
