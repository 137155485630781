import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#27AB60',
        },
        secondary: {
            main: '#E2DDA5',
        },
        warning: {
            main: '#F9AB5C',
        },
    },
    typography: {
        fontFamily: "'Lexend','sans-serif'"
    },
});

export default theme;
