import thunk from 'redux-thunk';
// import UserReducer from './Reducer/UserReducer';
import authReducer from './Reducer/authReducer';
import homeReducer from './Reducer/homeReducer';
import eventReducer from './Reducer/eventReducer';
import notificationReducer from './Reducer/notificationReducer';
import cartReducer from './Reducer/cartReducer';
import profileReducer from './Reducer/profileReducer';
import orderReducer from './Reducer/orderReducer';
import historyReducer from './Reducer/historyReducer';
import surveyReducer from './Reducer/surveyReducer';
import eventDetailReducer from './Reducer/eventDetailReducer';
import surveyDetailReducer from './Reducer/surveyDetailReducer';
import historyDetailReducer from './Reducer/historyDetailReducer';
import categoryReducer from './Reducer/categoryReducer';
import courseReducer from './Reducer/courseReducer';
import bookReducer from './Reducer/bookReducer';
import addressReducer from './Reducer/addressReducer';
import VipBenefitsReducer from './Reducer/VipBenefitsReducer';
import detailCourseReducer from './Reducer/detailCourseReducer';
import detailBookReducer from './Reducer/detailBookReducer';
import informationReducer from './Reducer/informationReducer';
import serviceReducer from './Reducer/serviceReducer';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

const initialState = {};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    combineReducers({
        // users: UserReducer,
        auth: authReducer,
        vip_benefits: VipBenefitsReducer,
        home: homeReducer,
        information: informationReducer,
        event: eventReducer,
        notification: notificationReducer,
        cart: cartReducer,
        profile: profileReducer,
        order: orderReducer,
        history: historyReducer,
        survey: surveyReducer,
        eventDetail: eventDetailReducer,
        surveyDetail: surveyDetailReducer,
        historyDetail: historyDetailReducer,
        category: categoryReducer,
        course: courseReducer,
        book: bookReducer,
        address: addressReducer,
        detailCourse: detailCourseReducer,
        detailBook: detailBookReducer,
        service: serviceReducer,
    }),
    initialState,
    composeEnhancer(applyMiddleware(thunk)),
);

export default store;
