import config from '~/config';

// Layouts
import DefaultLayout from '~/layouts';
// Layouts
import { DefaultFullBannerHomeLayout } from '~/layouts';

// Pages
import Home from '~/pages/Home';
import About from '~/pages/About';
import AboutUs from '~/pages/AboutUs';
import Post from '~/pages/Post';
import Event from '~/pages/Event';
import DetailEvent from '~/pages/DetailEvent';
import DetailPost from '~/pages/DetailPost';
import Service from '~/pages/Service';
import GetServiceInfo from '~/components/Service/GetServiceInfo';
import User from '~/pages/User';
import InfoUser from '~/pages/InfoUser';
import DetailOrderUser from '~/pages/DetailOrderUser';
import DetailOrderUserManage from '~/pages/DetailOrderUserManage';
import InfoCustomer from '~/pages/InfoCustomer';
import AddCourseVideo from '~/pages/AddCourseVideo';
import AddCourseOnline from '~/pages/AddCourseOnline';
import EditCourseOnline from '~/pages/EditCourseOnline';
import AddBookPdf from '~/pages/AddBookPdf';
import EditBookPdf from '~/pages/EditBookPdf';
import AddBookPrint from '~/pages/AddBookPrint';
import EditBookPrint from '~/pages/EditBookPrint';
import AddBookPdfMemory from '~/pages/AddBookPdfMemory';
import EditBookPdfMemory from '~/pages/EditBookPdfMemory';
import AddBookPrintMemory from '~/pages/AddBookPrintMemory';
import EditBookPrintMemory from '~/pages/EditBookPrintMemory';
import AddEvent from '~/pages/AddEvent';
import EditEvent from '~/pages/EditEvent';
import AddSurvey from '~/pages/AddSurvey';
import MyDetailSurvey from '~/pages/MyDetailSurvey';
import Product from '~/pages/Product';
import Courseware from '~/pages/Courseware';
import DetailCourseware from '~/pages/DetailCourseware';
import CourseVideo from '~/pages/CourseVideo';
import WatchVideo from '~/pages/WatchVideo';
import ReadBook from '~/pages/ReadBook';
import DetailCourseVideo from '~/pages/DetailCourseVideo';
import EditCourseVideo from '~/pages/EditCourseVideo';
import CourseOnline from '~/pages/CourseOnline';
import DetailCourseOnline from '~/pages/DetailCourseOnline';
import Survay from '~/pages/Survay';
import SurvayDetail from '~/pages/SurvayDetail';
import DoSurvay from '~/pages/DoSurvay';
import Payment from '~/pages/Payment';
import tamnhinsumang from '~/pages/TamNhinXuMang';
import LichSuHinhThanh from '~/pages/LichSuHinhThanh';
import LinhVucHoatDong from '~/pages/LinhVucHoatDong';
import XuatBan from '~/pages/XuatBan';
import TonChiHoatDong from '~/pages/TonChiHoatDong';
import SoDoToChuc from '~/pages/SoDoToChuc';
import BoQuyTacDaoDuc from '~/pages/BoQuyTacDaoDuc';
import DieuKhoanChinhSach from '~/pages/DieuKhoanChinhSach';
import InforExperts from '~/pages/InforExperts/Infor';
import Experts from '~/pages/InforExperts/Experts';
import CourceVideo from '~/pages/InforExperts/CourceVideo';
import CodeShare from '~/pages/CodeShare';
import Security from '~/pages/Security';
import Ship from '~/pages/Ship';
import Guarantee from '~/pages/Guarantee';
import PayMethod from '~/pages/PayMethod';
// import tamnhinsumang from '~/pages/TamNhinXuMang';

// Public routes
const publicRoutes = [
    { path: '', component: Home, layout: DefaultFullBannerHomeLayout },
    // { path: config.routes.codeShare, component: CodeShare },
    { path: config.routes.phuongthucthanhtoan, component: PayMethod },
    { path: config.routes.chinhsachdoitra, component: Guarantee },
    { path: config.routes.chinhsachvanchuyen, component: Ship },
    { path: config.routes.chinhsachbaomat, component: Security },
    { path: config.routes.infoUserEx, component: CourceVideo },
    { path: config.routes.inforUser, component: InforExperts },
    { path: config.routes.experts, component: Experts },
    { path: config.routes.about, component: About },
    { path: config.routes.aboutUs, component: AboutUs },
    { path: config.routes.tamnhinsumang, component: tamnhinsumang },
    { path: config.routes.lichsuhinhthanh, component: LichSuHinhThanh },
    { path: config.routes.tonchihoatdong, component: TonChiHoatDong },
    { path: config.routes.boquytacdaoduc, component: BoQuyTacDaoDuc },
    { path: config.routes.sodotochuc, component: SoDoToChuc },
    { path: config.routes.DieuKhoanChinhSach, component: DieuKhoanChinhSach },
    { path: config.routes.xuatban, component: XuatBan },
    { path: config.routes.linhvuchoatdong, component: LinhVucHoatDong },
    { path: config.routes.post, component: Post },
    { path: config.routes.product, component: Product },
    { path: config.routes.courseware, component: Courseware },
    { path: config.routes.detailCourseware, component: DetailCourseware },
    { path: config.routes.courseVideo, component: CourseVideo },
    { path: config.routes.watchVideo, component: WatchVideo },
    { path: config.routes.readBook, component: ReadBook },
    { path: config.routes.infoUser, component: InfoUser },
    { path: config.routes.detailCourseVideo, component: DetailCourseVideo },
    { path: config.routes.courseOnline, component: CourseOnline },
    { path: config.routes.detailCourseOnline, component: DetailCourseOnline },
    { path: config.routes.survay, component: Survay },
    { path: config.routes.survayDetail, component: SurvayDetail },
    { path: config.routes.doSurvay, component: DoSurvay },
    { path: config.routes.payment, component: Payment },
    { path: config.routes.event, component: Event },
    { path: config.routes.detailEvent, component: DetailEvent },
    { path: config.routes.detailPost, component: DetailPost },
    { path: config.routes.service, component: Service },
    { path: config.routes.user, component: User },
    { path: config.routes.detailUser, component: DetailOrderUser },
    { path: config.routes.infoCustomer, component: InfoCustomer },
    { path: config.routes.addCourseVideo, component: AddCourseVideo },
    { path: config.routes.editCourseVideo, component: EditCourseVideo },
    { path: config.routes.addCourseOnline, component: AddCourseOnline },
    { path: config.routes.editCourseOnline, component: EditCourseOnline },
    { path: config.routes.addBookPdf, component: AddBookPdf },
    { path: config.routes.editBookPdf, component: EditBookPdf },
    { path: config.routes.editBookPrint, component: EditBookPrint },
    { path: config.routes.addBookPrint, component: AddBookPrint },
    { path: config.routes.addBookPdfMemory, component: AddBookPdfMemory },
    { path: config.routes.editBookPdfMemory, component: EditBookPdfMemory },
    { path: config.routes.addBookPrintMemory, component: AddBookPrintMemory },
    { path: config.routes.editBookPrintMemory, component: EditBookPrintMemory },
    { path: config.routes.addBookPrint, component: AddBookPrint },
    { path: config.routes.addEvent, component: AddEvent },
    { path: config.routes.editEvent, component: EditEvent },
    { path: config.routes.addSurvay, component: AddSurvey },
    { path: config.routes.getServiceInfo, component: GetServiceInfo },
    { path: config.routes.myDetailSurvay, component: MyDetailSurvey },
    { path: config.routes.detailManageOrder, component: DetailOrderUserManage },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
