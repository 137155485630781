import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add_cart, get_cart } from '~/Store/Action/cartActions';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './CourseDataItem.module.scss';
import { format } from 'date-fns';
import slugify from 'slugify';

const cx = classNames.bind(styles);

const CourseDataItem = ({ className, data }) => {
    const [check, setData] = useState(data.book ? true : false);

    const dispatch = useDispatch();

    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);
    const addCart = async (id) => {
        let cart = {
            product_type: 'book',
            book_id: id,
            quantity: 1,
            payment_method: 'vnd',
        };
        localStorage.setItem('cart', JSON.stringify(cart));
        let fieldCart = {
            product_type: JSON.parse(localStorage.getItem('cart')).product_type,
            book_id: JSON.parse(localStorage.getItem('cart')).book_id,
            quantity: JSON.parse(localStorage.getItem('cart')).quantity,
            payment_method: JSON.parse(localStorage.getItem('cart')).payment_method,
        };
        await dispatch(add_cart(auth.token, fieldCart));
        if (user != null) {
            dispatch(get_cart(auth.token));
        }
    };

    let bookId;

    if (data?.book) {
        bookId = data?.book?.id;
    } else {
        bookId = data?.id;
    }

    function formatDate(input) {
        // Check if the input is a valid date string
        const date = new Date(input);
        if (isNaN(date)) {
            return '0/0/0000';
        }

        // Format the valid date
        const formattedDate = format(date, 'dd/M/yyyy');
        return formattedDate;
    }

    function formatCurrency(number) {
        if (number == null || number == '') {
            return '0 đ';
        }
        return number.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
    }

    return (
        <>
            {check ? (
                <Box className={cx('item', className)}>
                    <Link to={'/sach-giay/' + slugify(data?.book?.name) + '/' + bookId}>
                        <img
                            style={{ height: '283px', width: '100%' }}
                            className="img-fluid"
                            src={data.book.image}
                            alt=""
                        />
                    </Link>
                    <Box className={cx('CourseData-content')}>
                        <Box className={cx('CourseData-content-top')}>
                            <Link to={'/sach-giay/' + slugify(data?.book?.name) + '/' + bookId}>
                                {data?.book?.name}
                            </Link>
                            <Box className={cx('avatar')}>
                                <img
                                    style={{ width: '28px', height: '28px', borderRadius: '50%' }}
                                    className="img-fluid"
                                    src={data?.book?.owner?.image ? data?.book?.owner?.image : images.avatarDefault}
                                    alt="avatar"
                                />
                                <Typography>{data?.book?.owner?.name}</Typography>
                            </Box>
                            <Box className="my-2 d-flex">
                                <Typography className="text-success fw-bold">
                                    {formatCurrency(data?.discount_price ?? data?.book?.discount_price)}
                                </Typography>
                                <Typography className="text-secondary text-decoration-line-through fs-5 fw-bold ms-3">
                                    {formatCurrency(data?.price ?? data?.book?.price)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={cx('cart')}>
                            <img onClick={() => addCart(data?.id)} src={images.CartPer} alt="" />
                        </Box>
                        <Box className={cx('count_CourseData')}>
                            <Typography>{data?.book?.view} bài học</Typography>
                            <Typography>{data?.book?.created_at ? formatDate(data.book?.created_at) : ''}</Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box className={cx('item', className)}>
                    <Link to={'/sach-giay/' + slugify(data?.name ?? data?.book?.name) + '/' + bookId}>
                        <img
                            style={{ height: '283px', width: '100%' }}
                            className="img-fluid"
                            src={data?.image}
                            alt=""
                        />
                    </Link>
                    <Box className={cx('CourseData-content')}>
                        <Box className={cx('CourseData-content-top')}>
                            <Link to={'/sach-giay/' + slugify(data?.name) + '/' + bookId}>{data?.name}</Link>
                            <Box className={cx('avatar')}>
                                <img
                                    style={{ width: '28px', height: '28px', borderRadius: '50%' }}
                                    className="img-fluid"
                                    src={data?.owner_image ?? data?.owner?.image}
                                    alt=""
                                />
                                <Typography>{data?.owner_name ?? data?.owner?.name}</Typography>
                            </Box>
                            <Box className="my-2 d-flex">
                                <Typography className="text-success fw-bold">
                                    {formatCurrency(data?.discount_price ?? data?.book?.discount_price)}
                                </Typography>
                                <Typography className="text-secondary text-decoration-line-through fs-5 fw-bold ms-3">
                                    {formatCurrency(data?.price ?? data?.book?.price)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={cx('cart')}>
                            <img onClick={() => addCart(data?.id)} src={images.CartPer} alt="" />
                        </Box>
                        <Box className={cx('count_CourseData')}>
                            <Typography>{data?.view} bài học</Typography>
                            <Typography>{data?.book?.created_at ? formatDate(data?.book?.created_at) : ''}</Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default CourseDataItem;
