import { Box, Typography, Grid } from '@mui/material';
import slugify from 'slugify';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './AllEvent.module.scss';
import Pagination from '~/components/Pagination';
import { event } from '~/Store/Action/eventActions';
import { joinEvent, interestedEvent } from '~/Store/Action/eventActions';
import Alert from '~/components/Alert';
import moment from 'moment-timezone';

const cx = classNames.bind(styles);

function AllEvent() {
    const [visible, setVisible] = useState(false);

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const [visible1, setVisible1] = useState(false);

    const [alertState1, setAlertState1] = useState({
        visible: false,
    });

    const [id, setId] = useState(null);

    const dispatch = useDispatch();

    const list = useSelector((state) => state.event.list);

    let page = list?.data?.current_page;
    // console.log(page);
    let totalPage = list?.data?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    useEffect(() => {
        dispatch(event(null, 1)); // Dispatch action để lấy dữ liệu khi component được mount
    }, []);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(event(null, prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(event(null, nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(event(null, pageNumber));
    };

    const auth = useSelector((state) => state.auth);

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOpenAlert1 = (defaultKey) => {
        setVisible1(true);
        setAlertState1({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel1 = () => {
        setVisible1(false);
    };

    const onJoinEvent = async () => {
        if (await dispatch(joinEvent(auth.token, id))) {
            handleCancel();
        }
    };

    const onInterestedEvent = async () => {
        if (await dispatch(interestedEvent(auth.token, id))) {
            handleCancel1();
        }
    };

    const handleSetIdToEvent = async (id) => {
        setId(id);
        handleOpenAlert();
    };

    const handleSetIdToEvent1 = async (id) => {
        setId(id);
        handleOpenAlert1();
    };

    const checkAndRenderOpeningTime = (openingTime) => {
        const currentDate = moment().tz('Asia/Ho_Chi_Minh');
        const targetDate = moment(openingTime);

        const targetDate1Year = moment(openingTime).add(0.6, 'year');
        const targetDate2Years = moment(openingTime).add(2, 'years');

        const diffInYears = targetDate.diff(currentDate, 'years', true);

        if (currentDate.isBefore(targetDate)) {
            return 'Chưa diễn ra';
        } else if (currentDate.isBefore(targetDate1Year)) {
            return 'Đang diễn ra';
        } else {
            return 'Đã kết thúc';
        }
    };

    return (
        <Box className={cx('AllEvent', 'container')}>
            <Box className={cx('list')}>
                {/* <div class="container text-center"> */}
                <div class="row">
                    {list?.data?.data?.map((item, index) => (
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <Box className={cx('item') + ' w-100'}>
                                <Box className={cx('wow animate__animated animate__zoomIn card-hover')}>
                                    <Box className={cx('wrapper')}>
                                        <Link to={'/su-kien/' + slugify(item.title) + '/' + item.id}>
                                            <img
                                                style={{ height: '196px', width: '100%' }}
                                                className={cx('img-fluid')}
                                                src={item.image}
                                                alt=""
                                            />
                                        </Link>
                                        <Box className={cx('content')}>
                                            <Link
                                                to={'/su-kien/' + slugify(item.title) + '/' + item.id}
                                                className={cx('title')}
                                            >
                                                {item.title}
                                            </Link>
                                            <Box className={cx('action')}>
                                                <Typography>
                                                    <span>{checkAndRenderOpeningTime(item.opening_time)}</span>
                                                </Typography>
                                                <Typography>
                                                    {/* {item.status == 1 && ( */}
                                                    <span className="text-success">
                                                        {checkAndRenderOpeningTime(item.opening_time)}
                                                    </span>
                                                    {/* )} */}
                                                    {/* {' '}
                                                    {item.status == 0 && 'Chưa diễn ra'}{' '}
                                                    {item.status == -1 && 'Đã kết thúc'} */}
                                                </Typography>
                                            </Box>
                                            <Typography>{item.opening_time.substr(0, 10)}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={cx('button-action')}>
                                        <Box className={cx('join')} onClick={() => handleSetIdToEvent(item.id)}>
                                            <Typography>Tham gia</Typography>
                                        </Box>
                                        <Box className={cx('care')} onClick={() => handleSetIdToEvent1(item.id)}>
                                            <Typography>Quan tâm</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    ))}
                </div>
                {/* </div> */}
            </Box>

            <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Thông báo
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>Tham gia sự kiện này</Typography>
                <div className="row position-relative pt-4">
                    <button
                        onClick={() => handleCancel()}
                        style={{
                            display: 'block',
                            background: '#D9D9D9!important',
                            color: '#333',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                    >
                        Hủy bỏ
                    </button>
                    <button
                        onClick={() => onJoinEvent()}
                        style={{
                            display: 'block',
                            right: 0,
                            background: '#27AB60!important',
                            color: '#fff',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className="col-6 position-absolute btn btn-g-reverse p-3"
                    >
                        Đồng ý
                    </button>
                </div>
            </Alert>

            <Alert alertState={alertState1} visible={visible1} handleCancel={handleCancel1}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Thông báo
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>Quan tâm tới sự kiện này</Typography>
                <div className="row position-relative pt-4">
                    <button
                        onClick={() => handleCancel1()}
                        style={{
                            display: 'block',
                            background: '#D9D9D9!important',
                            color: '#333',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                    >
                        Hủy bỏ
                    </button>
                    <button
                        onClick={() => onInterestedEvent()}
                        style={{
                            display: 'block',
                            right: 0,
                            background: '#27AB60!important',
                            color: '#fff',
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                        }}
                        className="col-6 position-absolute btn btn-g-reverse p-3"
                    >
                        Đồng ý
                    </button>
                </div>
            </Alert>

            <Pagination
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                handlePageChange={handlePageChange}
                page={list?.data?.current_page}
                totalPage={list?.data?.total}
            />
        </Box>
    );
}

export default AllEvent;
