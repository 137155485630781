import { Box, Stack, Typography, Button } from '@mui/material';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import images from '~/assets/images';
import styles from './Infomation.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
const cx = classNames.bind(styles);

const Infomation = ({ className }) => {
    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    const profile = useSelector((state) => state.profile?.list?.data);

    useEffect(() => {
        console.log(auth);
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    let navigate = useNavigate();

    const handleBanking = () => {
        navigate('/tai-khoan/nap-tien');
    };

    const handleHistory = () => {
        navigate('/tai-khoan/lich-su-giao-dich');
    };

    function formatMoney(amount) {
        if (!amount || amount == null) {
            return 0;
        }
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return (
        <Stack className={cx(className)}>
            {/* <Box className={cx('top')}>
                <img
                    style={{
                        width: '60px',
                        height: '60px',
                        border: '2px solid #dee2e6',
                        borderRadius: '50%',
                    }}
                    src={profile?.image ? profile?.image : images.avatarDefault}
                    alt=""
                />
                <Box className={cx('content')}>
                    <Box className={cx('name')}>{user ? user.name : 'user'}</Box>
                    <Box
                        component="button"
                        sx={{
                            background: '#F9AB5C',
                            borderRadius: '12px',
                            padding: '0 15px',
                            fontSize: '12px',
                            color: '#fff',
                        }}
                    >
                        {user?.account_type === 'CUSTOMER' && 'Tài khoản thường'}
                        {user?.account_type === 'PARTNER' && 'Đối tác'}
                        {user?.account_type === 'EXPERT' && 'Chuyên gia'}
                    </Box>
                </Box>
            </Box> */}
            <Box className={cx('body')} justifyContent="space-between">
                <Box>
                    <Typography className={cx('body-title')}>Ví của tôi</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                background: '#333',
                                color: '#fff',
                                padding: '1px 10px',
                                marginRight: '10px',
                                borderRadius: '10px',
                                height: '19px',
                            }}
                        >
                            Vnđ
                        </Typography>
                        <Typography sx={{ color: '#fff', fontSize: '18px' }} className={cx('price')}>
                            {profile ? formatMoney(profile.vnd) : 0}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography className={cx('body-title') + ' text-end'}>Điểm tích lũy</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            className={cx('price')}
                            sx={{ paddingRight: '10px', color: '#fff', fontSize: '18px' }}
                        >
                            {profile ? formatMoney(profile.coin) : 0}
                        </Typography>
                        <Typography
                            sx={{
                                background: '#333',
                                color: '#fff',
                                padding: '1px 10px',
                                marginRight: '10px',
                                borderRadius: '10px',
                                height: '19px',
                            }}
                        >
                            xu
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box pt={2} sx={{ position: 'relative' }} className="pt-0">
                <Button variant="contained" className={cx('recharge') + ' br-w-tr'} onClick={handleBanking}>
                    NẠP NGAY
                </Button>
                <Button variant="contained" className={cx('history')} onClick={handleHistory}>
                    Lịch sử
                </Button>
            </Box>
        </Stack>
    );
};

export default Infomation;
