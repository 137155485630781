import { Box, Typography, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './ContentRight.module.scss';

const cx = classNames.bind(styles);

const ContentRight = () => {
    return (
        <Box className={cx('content-right')}>
            <Typography sx={{ fontSize: '16px' }}>
                <span style={{ color: '#27AB60', fontWeight: 'bold!important', fontFamily: 'sans-serif' }}>Viện nghiên cứu Đời sống Xã hội (Social Life)</span> - tiền thân một nhóm giảng viên và sinh viên khoa nhân học Trường Đại học Khoa học xã hội và Nhân văn hình thành lên nhóm nghiên cứu và bảo tồn ký ức đời sống xã hội với trang web Bảo tảng – Ký ức xã hội – Bảo tàng ký ức xã hội (kyucxahoi.com) (hiện nay còn gọi là Di sản ký ức) do giảng viên trẻ Nguyễn Đức Lộc là người khởi sướng (8/5/2010). Trải qua hơn 10 năm hoạt động với các giải đoạn phát triển như:
                Trung tâm nghiên cứu văn hóa, giáo dục và đời sống xã hội do Chủ tịch Hội dân tộc học – Nhân học ra quyết định thành lập (2014)
            </Typography>
        </Box>
    );
};

export default ContentRight;
