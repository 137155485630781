import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Stack,
    FormGroup,
} from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import styles from './AddBookPdf.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { add_book_pdf, add_chapter } from '~/Store/Action/bookActions';
import AddVideo from '~/components/AddVideo';
import { faArrowLeft, faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bookCategory } from '~/Store/Action/categoryActions';
import { useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';

const cx = classNames.bind(styles);

function AddBookPdf() {
    const [visible, setVisible] = useState(false);

    const [addVideoState, setAddVideoState] = useState({
        visible: false,
    });

    const navigate = useNavigate();

    const [bookpdf, setBookpdf] = useState([]);

    const [img, setImg] = useState(null);
    const [path, setPath] = useState(null);

    const dispatch = useDispatch();
    const listBookCategory = useSelector((state) => state.category.listBookCategory);
    // const listCourseVideo = useSelector((state) => state.course.listCourseVideo);
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(bookCategory());
    }, []);

    const onAdd = async () => {
        const data = await onAddBook();
        console.log(data);

        const id = await data.data.data.id;
        await console.log(id);

        const formDataArray = bookpdf.map((item) => {
            const formData = new FormData();
            formData.append('title', item.title);
            formData.append('status', item.status);
            formData.append('description', item.link);

            if (item.image) {
                formData.append('file', item.image);
            }

            return formData;
        });

        console.log('123');
        const form = formDataArray.map((form) => {
            for (const [key, value] of form) {
                console.log(`${key}:`, value);
            }
            dispatch(add_chapter(auth.token, form, id));
        });

        // Gửi
        navigate('/tai-khoan/quan-ly-ki-uc-xa-hoi-pdf');
        // dispatch(show_alert());
    };

    const onAddBook = async () => {
        // event.preventDefault();
        var form_question = document.getElementById('form_data');
        var formData = new FormData(form_question);

        // xem trong form có những gì
        for (const [key, value] of formData) {
            console.log(`${key}:`, value);
        }

        const data = await dispatch(add_book_pdf(auth.token, formData));
        return data;
    };

    const addbookpdf = async () => {
        const data = await {
            title: chapter.title ?? '#',
            link: chapter.link ?? '#',
            image: chapter.image,
            path: chapter.path,
            status: chapter.status,
        };
        await setBookpdf([...bookpdf, data]);
        await console.log(bookpdf);
        await handleCancel();
        await setCount(false);
        await setChapter({ title: '', link: '', image: null, path: null, status: 0 });
    };

    const onLog = async () => {
        await console.log(bookpdf);
    };

    const [chapter, setChapter] = useState({ title: '', link: '', image: null, path: null, status: 0 });
    const [count, setCount] = useState(false);

    const handleChapterTitleChange = (event) => {
        const newChapter = chapter;
        newChapter.title = event.target.value;
        setChapter(newChapter);
    };

    const handleChapterLinkChange = (event) => {
        const newChapter = chapter;
        newChapter.link = event.target.value;
        setChapter(newChapter);
    };

    const handleChapterImageChange = async (event) => {
        await setCount(false);
        const newChapter = chapter;
        const files = event.target.files;
        console.log(files);
        newChapter.image = files[0];

        const imageArray = Array.from(files).map((file) => URL.createObjectURL(file));
        newChapter.path = imageArray;
        console.log(event.target);
        setChapter(newChapter);
        await setCount(true);
    };

    const handlebookpdftatusChange = (event) => {
        const newChapter = chapter;
        newChapter.status = event.target.value;
        setChapter(newChapter);
    };

    const handleOpenAddVideo = (defaultKey) => {
        setVisible(true);
        setAddVideoState({ ...addVideoState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleImg = (event) => {
        const files = event.target.files;
        console.log(files);
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImg(image);
        setPath(URL.createObjectURL(files));
    };

    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const day = now.getDate();
            const month = now.getMonth() + 1; // Tháng bắt đầu từ 0
            const year = now.getFullYear();

            // Định dạng thành chuỗi "h d-m-y"
            const formattedDateTime = `${hours}:${minutes} ${day}-${month}-${year}`;

            setCurrentDateTime(formattedDateTime);
        }, 1000); // Cập nhật mỗi giây

        // Xóa interval khi component bị hủy
        return () => clearInterval(interval);
    }, []);

    return (
        <Box className={cx('AddBookPdf', 'container')}>
            <Box py="10px" className={cx('Breadcumb')}>
                <Typography sx={{ paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/">Trang chủ</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-ki-uc-xa-hoi-pdf">Tài khoản</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-ki-uc-xa-hoi-pdf">Quản lý chuyên gia đối tác</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-ki-uc-xa-hoi-pdf">Quản lý ký ức xã hội sách PDF</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="#">Thêm mới sách</Link>
                </Typography>
            </Box>
            <form id="form_data" method="post" enctype="multipart/form-data">
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ flexGrow: 1 }}
                    className={cx('wrapper')}
                >
                    <Grid pb={2} pr={2} xs={6}>
                        <TitleUser className={cx('title-wp')} title="Thêm mới sách" />
                        <Box
                            className={cx('content-wp') + ' wpp'}
                            style={{
                                backgroundImage: `url(${img})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                        >
                            <label className={cx('content')} htmlFor="upload-img-btn">
                                <TextField
                                    label="Chọn ảnh"
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    onChange={(event) => handleImg(event)}
                                    style={{ display: 'none' }}
                                    id="upload-img-btn"
                                />
                                <Box className={cx('wp-file')}>
                                    <img src={images.camera} alt="chọn ảnh" />
                                </Box>
                            </label>
                            <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>Tải ảnh lên (4 x 2)</Typography>
                        </Box>
                        <FormControl sx={{ paddingTop: '10px' }} fullWidth>
                            <Stack spacing={2}>
                                <input name="book_classify_id" value="2" type="hidden" />
                                <input name="type" value="pdf" type="hidden" />
                                <input name="trending" value="1" type="hidden" />
                                <TextField
                                    label="Tiêu đề"
                                    variant="outlined"
                                    name="name"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel
                                        sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                        id="demo-simple-select-label"
                                    >
                                        Chọn danh mục
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="book_category_id"
                                        label="Chọn danh mục"
                                        // onChange={handleChange}
                                        sx={{
                                            '& .MuiSelect-root': {
                                                color: 'blue',
                                            },
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                padding: '14px',
                                                background: '#fff',
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-3%',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                //   borderColor: 'orange',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                span: {
                                                    fontSize: '12px',
                                                },
                                            },
                                        }}
                                    >
                                        {listBookCategory?.data?.book_categories.map((item, index) => (
                                            <MenuItem value={item.id} key={index}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Tiền (vnđ)"
                                    variant="outlined"
                                    name="price"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Tiền sau giảm giá (vnđ)"
                                    variant="outlined"
                                    name="discount_price"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Tiền xu"
                                    variant="outlined"
                                    name="coin_price"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Tiền sau giảm giá xu (xu)"
                                    variant="outlined"
                                    name="discount_coin_price"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel
                                        sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                        id="demo-simple-select-label"
                                    >
                                        Chọn chuyên nghành
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="specialization_id"
                                        label="Chọn chuyên nghành"
                                        // onChange={handleChange}
                                        sx={{
                                            '& .MuiSelect-root': {
                                                color: 'blue',
                                            },
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                padding: '14px',
                                                display: 'flex',
                                                alignContent: 'center',
                                                background: '#fff',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-3%',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                //   borderColor: 'orange',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                span: {
                                                    fontSize: '12px',
                                                },
                                            },
                                        }}
                                    >
                                        {listBookCategory?.data?.specializations.map((item, index) => (
                                            <MenuItem value={item.id} key={index}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Kích thước"
                                    variant="outlined"
                                    name="size"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Loại bìa"
                                    variant="outlined"
                                    name="cover_type"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Số trang"
                                    variant="outlined"
                                    name="page_number"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Nhà xuất bản"
                                    variant="outlined"
                                    name="publisher"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Năm xuất bản"
                                    variant="outlined"
                                    name="publishing_at"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <SunEditor
                                    name="description"
                                    defaultValue="Mô tả"
                                    height="500"
                                    setOptions={{
                                        buttonList: [
                                            ['undo', 'redo'],
                                            ['font', 'fontSize', 'formatBlock'],
                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['bold', 'underline', 'italic', 'strike'],
                                            ['removeFormat'],
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'table'],
                                            ['fontColor', 'hiliteColor'],
                                            ['link', 'image'],
                                            ['fullScreen', 'showBlocks', 'codeView'],
                                            ['preview', 'print'],
                                        ],
                                    }}
                                />
                            </Stack>
                        </FormControl>
                    </Grid>
                    <Grid pt={6} pr={2} xs={6}>
                        <div className={cx('list-chapper')}>
                            <TitleUser className={cx('title-wp')} title="Danh sách chương" />
                            <div className={cx('check')}>
                                <span style={{ paddingRight: '10px' }}>Chọn tất cả</span>
                                <Checkbox />
                            </div>
                        </div>
                        <div className="row mt-3">
                            {bookpdf.map((item, index) => (
                                <div
                                    className="col-12 col-sm-12 col-lg-12 mb-4  wow animate__animated animate__backInRight"
                                    key={index}
                                >
                                    <a className="card" href={item.path[0] ?? '#'} target="_blank">
                                        <div className="card-body">
                                            <h2
                                                className="text-dark"
                                                style={{ fontSize: '16px', fontFamily: 'sans-serif', marginBottom: 0 }}
                                            >
                                                <Checkbox />
                                                <span style={{ paddingLeft: '10px' }}>{item.title}</span>
                                            </h2>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    padding: '10px 0px',
                                                }}
                                            >
                                                <span style={{ color: 'rgba(119, 119, 119, 1)' }} className="p">
                                                    Đã tải lên
                                                </span>
                                                <span className="p" style={{ color: 'rgba(39, 171, 96, 1)' }}>
                                                    {item.status == 0 ? 'Mở' : 'khoá'}
                                                </span>
                                            </div>
                                            <div className="d-flex">
                                                <span className="p">{currentDateTime ?? ''}</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <Button className={cx('add_video')} onClick={handleOpenAddVideo}>
                            Thêm chương mới
                        </Button>
                    </Grid>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button className={cx('btn_submit')} variant="contained" onClick={onAdd}>
                            THÊM MỚI
                        </Button>
                        {/* <Button className={cx('btn_submit')} variant="contained" onClick={onLog}>
                            Log
                        </Button> */}
                    </div>
                </Grid>
            </form>
            <AddVideo addVideoState={addVideoState} visible={visible} handleCancel={handleCancel}>
                <div className={cx('header-modal-back')}>
                    <h4>Thêm mới chương</h4>
                    <FontAwesomeIcon className={cx('close')} icon={faClose} />
                </div>
                <FormGroup sx={{ padding: '18px', height: '600px' }}>
                    <Stack spacing={2}>
                        <TextField
                            className={cx('input-change')}
                            fullWidth
                            id="title"
                            label="Tiêu đề"
                            onChange={(event) => handleChapterTitleChange(event)}
                            variant="outlined"
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',

                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                        <select
                            style={{ padding: '13px', borderRadius: '40px' }}
                            onChange={(event) => handlebookpdftatusChange(event)}
                        >
                            <option value="0">Mở</option>
                            <option value="1">Không mở</option>
                        </select>
                        <TextField
                            className={cx('input-change')}
                            fullWidth
                            type="text"
                            id="link-video"
                            label="Link pdf"
                            onChange={(event) => handleChapterLinkChange(event)}
                            variant="outlined"
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                        <div>Hoặc tải file pdf lên</div>
                        <TextField
                            label="Chọn ảnh"
                            type="file"
                            accept="image/*"
                            onChange={(event) => handleChapterImageChange(event)}
                            style={{ display: 'none' }}
                            id="upload-img-btn1"
                        />
                        <label className={cx('file-upload')} htmlFor="upload-img-btn1">
                            <span>File đính kèm</span>
                            <div className={cx('upload')}>
                                <img src={images.Upload} alt="upload file" />
                            </div>
                        </label>
                        {count && (
                            <div>
                                Đã chọn: <span className="text-success">{chapter.image?.name}</span>
                            </div>
                        )}
                    </Stack>
                </FormGroup>
                <div style={{ textAlign: 'center', padding: '10px' }}>
                    <Button
                        sx={{ width: '90%', padding: '10px', marginBottom: '20px' }}
                        onClick={addbookpdf}
                        className={cx('btn_yes')}
                        variant="contained"
                    >
                        ĐỒNG Ý
                    </Button>
                </div>
            </AddVideo>
        </Box>
    );
}

export default AddBookPdf;
