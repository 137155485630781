import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import Header from '~/layouts/components/Header';
import Footer from '~/layouts/components/Footer';
import styles from './DefaultFullBannerHomeLayout.module.scss';
import { Navigation, Pagination, Autoplay } from 'swiper';
import HeaderTop from '../components/Header-top';
import '../../assets/css/index.css';
import images from '~/assets/images';
import { Swiper, SwiperSlide } from 'swiper/react';
import { home } from '~/Store/Action/homeActions';

const cx = classNames.bind(styles);

function DefaultFullBannerHomeLayout({ children }) {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.home.list);
    // console.log(list.data.banners);
    useEffect(() => {
        dispatch(home()); // Dispatch action để lấy dữ liệu khi component được mount
    }, []);
    return (
        <div className={cx('wrapper')}>
            <HeaderTop />
            <Header />
            <Box className={cx('banner')}>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{ delay: 2000 }}
                >
                    {list?.data?.banners.map((item) => (
                        <SwiperSlide>
                            <img src={item.image} alt="" />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
            <div className={cx('container')}>
                <div className={cx('content')}>{children}</div>
            </div>
            <Footer />
        </div>
    );
}

export default DefaultFullBannerHomeLayout;
