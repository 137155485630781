import { Box, Typography, Stack, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import styles from './About.module.scss';
import { getInformation } from '~/Store/Action/informationActions';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

function About() {
    // const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    // const information = useSelector((state) => state.information.get);

    useEffect(() => {
        // dispatch(getInformation(3));
    }, []);

    return (
        <Box className={cx('About', 'container')}>
            <Breadcumb title="Chính sách giao hàng" />
            <Box className={cx('content')}>
                <div className="mt-3 w-100 d-block content-web">
                    <h1 className="fw-bold mb-2">CHÍNH SÁCH VẬN CHUYỂN VÀ GIAO NHẬN</h1>
                    <div>
                        <div>
                            <strong>1. Đối tượng và phạm vi vận chuyển:</strong>
                        </div>
                        <div>
                            Chính sách giao nhận được áp dụng cho tất cả các khách hàng đặt hàng qua website
                            sociallife.vn. Chúng tôi sẽ thực hiện tất cả các yêu cầu vận chuyển cho khách hàng trên phạm
                            vi toàn quốc.
                        </div>
                        <div>
                            <strong>2. Hình thức vận chuyển:</strong>
                        </div>
                        <div>
                            <i>a. Giao hàng nội thành</i>
                        </div>
                        <div>
                            Nhân viên giao hàng sẽ liên lạc trước với những khách hàng không có yêu cầu về thời gian để
                            sắp xếp thời gian hợp lý với khách hàng. Với những khách hàng có yêu cầu về thời gian, nhân
                            viên giao hàng sẽ liên lạc và sắp xếp giao đúng giờ khách hàng yêu cầu Trừ những trường hợp
                            thời gian quá cấp bách, hoặc ngoài thời gian làm việc của cửa hàng, nhân viên sẽ liên lạc và
                            thỏa thuận lại với thời gian của khách hàng cho hợp lý. Quý khách vui lòng trực tiếp kiểm
                            tra kỹ sản phẩm ngay khi nhận được hàng từ nhân viên giao hàng, nếu có vấn đề liên quan tới
                            chủng loại, mẫu mã, chất lượng, số lượng hàng hoá không đúng như trong đơn đặt hàng, Quý
                            khách vui lòng báo ngay cho chúng tôi để phối hợp xử lý. Nếu không có bất cứ vấn đề gì, Quý
                            khách vui lòng nhận hàng và thanh toán đầy đủ.
                        </div>
                        <div>
                            Trường hợp vì một lý do nào đó nhân viên giao hàng không thể giao hàng kịp thời, chúng tôi
                            sẽ liên hệ lại và thông báo cho Quý khách được biết.
                        </div>
                        <div>
                            <i>b. Giao hàng liên tỉnh:</i>
                        </div>
                        <div>
                            Khi đặt hàng, Quý khách vui lòng điền đầy đủ và chính xác các thông tin cần thiết theo yêu
                            cầu để tạo điều kiện thuận lợi cho chúng tôi trong việc cung cấp hàng hóa và nhận thanh toán
                            nhanh chóng. Chúng tôi cũng không chịu trách nhiệm đối với những trường hợp giao hàng chậm
                            trễ hay thất lạc vì các thông tin do Quý khách cung cấp không chính xác. Thời gian giao hàng
                            Từ 2 đến 4 ngày (không tính Chủ Nhật hoặc những ngày Lễ, Tết). Những đơn hàng đặt vào Chủ
                            nhật, ngày nghỉ Lễ, Tết: thời gian giao hàng được tính từ 16 giờ ngày thứ Hai tuần sau hoặc
                            ngày làm việc đầu tiên sau ngày nghỉ Lễ,Tết. Quý khách vui lòng kiểm tra kỹ hàng hóa ngay
                            khi nhận hàng từ người chuyển phát hàng hóa, nếu có vấn đề liên quan tới chủng loại, chất
                            lượng, số lượng hàng hoá không đúng như trong đơn đặt hàng, Quý khách vui báo ngay cho chúng
                            tôi để phối hợp với đơn vị chuyển phát hàng hóa xử lý.
                        </div>
                        <div>
                            <i>c. Thay đổi, hủy bỏ giao dịch</i>
                        </div>
                        <div>– Trong những trường hợp sau đây quý khách có thể thay đổi hoặc hủy bỏ giao dịch</div>
                        <div>
                            Khi cửa hàng đã xác nhận được sản phẩm vẫn chưa được gửi đi. Nếu sản phẩm đã được gửi đi, có
                            thay đổi khách hàng phải chịu phí ship. Thông thường sau khi nhận được thông tin đặt hàng
                            chúng tôi sẽ xử lý đơn hàng trong vòng 24h và phản hồi lại thông tin cho khách hàng về việc
                            thanh toán và giao nhận. Thời gian giao hàng thường trong khoảng từ 2-4 ngày kể từ ngày chốt
                            đơn hàng hoặc theo thỏa thuận với khách khi đặt hàng.Tuy nhiên, cũng có trường hợp việc giao
                            hàng kéo dài hơn nhưng chỉ xảy ra trong những tình huống bất khả kháng như sau: Nhân viên
                            chúng tôi liên lạc với khách hàng qua điện thoại không được nên không thể giao hàng. Địa chỉ
                            giao hàng bạn cung cấp không chính xác hoặc khó tìm. Số lượng đơn hàng tăng đột biến khiến
                            việc xử lý đơn hàng bị chậm. Đối tác cung cấp hàng chậm hơn dự kiến khiến việc giao hàng bị
                            chậm lại hoặc đối tác vận chuyển giao hàng bị chậm. Về phí vận chuyển, chúng tôi sử dụng
                            dịch vụ vận chuyển ngoài nên cước phí vận chuyển sẽ được tính theo phí của các đơn vị vận
                            chuyển tùy vào vị trí và khối lượng của đơn hàng, khi liên hệ lại xác nhận đơn hàng với
                            khách sẽ báo mức phí cụ thể cho khách hàng.
                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default About;
