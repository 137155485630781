import { Box, Typography, Stack, Button } from '@mui/material';
import ContentLeft from './ContentLeft';
import ContentRight from './ContentRight';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import styles from './About.module.scss';

const cx = classNames.bind(styles);

function About() {
    return (
        <Box className={cx('About','container')}>
            <Breadcumb title="Giới thiệu" />
            <Box className={cx('content')}>
                <ContentLeft />
                <ContentRight />
            </Box>
        </Box>
    );
}

export default About;