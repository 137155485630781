import classNames from 'classnames/bind';
import CourseDataItem from '~/components/CourseDataItem';
import 'bootstrap/dist/css/bootstrap.css';
import styles from '../Home.module.scss';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import Section from '~/components/Section/Section';

const cx = classNames.bind(styles);

function CourseData({ book_categories }) {
    let navigate = useNavigate();

    // console.log(book_categories?.data?.book_categories);

    const handleRedirect = () => {
        navigate('/sach-pdf');
    };
    return (
        <Section
            className={cx('CourseData')}
            buttonTitle="Xem thêm"
            sectionTitle="Học liệu - Dữ liệu"
            onClickButton={handleRedirect}
        >
            <div className="wow animate__animated animate__bounceInRight">
                <div className={cx('list-CourseData')} style={{ paddingTop: '25px' }}>
                    <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {book_categories?.data?.book_dulieu.map((item, index) => (
                            <SwiperSlide>
                                <CourseDataItem data={item} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </Section>
    );
}

export default CourseData;
