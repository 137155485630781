import { Box, Grid, Typography } from '@mui/material';
import classNames from 'classnames/bind';
import Section from '~/components/HistoryEvaluate/Section';
import styles from './OrderXu.module.scss';
import { format } from 'date-fns';

const cx = classNames.bind(styles);

const OrderXu = ({ list }) => {
    return (
        <Section>
            {list?.data?.length > 0 ? (
                <Grid container>
                    {list?.data?.map((item, index) => (
                        <Grid sm={12} lg={5} className="w-100 mb-3 me-3">
                            <Box className={cx('item')}>
                                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
                                    {item?.description}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                                    <Typography sx={{ color: '#27AB60', fontSize: '16px' }}>#{item?.ref}</Typography>
                                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
                                        +{item?.amount} xu
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: item?.status == 'Đang chờ' ? 'rgba(249, 171, 92, 1)' : '#27AB60',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {item?.status}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <h2>Hiện tại không có lịch sử nào</h2>
            )}
        </Section>
    );
};

export default OrderXu;
