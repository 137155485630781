import api from './api';

export const notification = (token) => {
    return async (dispatch) => {
        try {
            if(token == null){
                return
            }
            // Gọi API
            const response = await api.get('api/my/notifications',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'NOTIFICATION_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'NOTIFICATION_FAILURE', payload: error.message });
        }
    };
};
