import axios from 'axios';
import { toast } from 'react-toastify';

const api_url = process.env.REACT_APP_API_URL;

const showToast = (message, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    toast.error(message, toastOptions);
};

const api = {
    post: async (url, params, headers = {}) => {
        try {
            console.log(url);
            const data = await axios.post(api_url + url, params, { headers: headers });
            console.log(data);
            if (data.data.status != 200) {
                throw new Error(data.data.message);
            }
            return data;
        } catch (e) {
            showToast(e.message);
            // bắt lỗi form từ serve trả về hay lỗi hệ thống theo code tùy xử lý .....
            throw e;
        }
    },
    get: async (url, params, headers = {}) => {
        try {
            console.log(url);
            const data = await axios.get(api_url + url, { headers: headers, params: params });
            if (data.data.status != 200) {
                throw new Error(data.data.message);
            }
            return data;
        } catch (e) {
            console.log(e);
            showToast('Đã có lỗi xảy ra vui lòng thử lại');
            showToast(e.message);

            throw e;
        }
    },
    update: async (url, params, headers = {}) => {
        try {
            const data = await axios.post(url, params, { headers: headers });
            return data;
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    delete: async (url, params, headers = {}) => {
        try {
            const data = await axios.delete(
                api_url + url,
                { headers: headers },
            );

            return data;
        } catch (e) {
            console.log(e);

            throw e;
        }
    },
};

export default api;

// example
// useEffect(() => {
//     const data = api.get('http://127.0.0.1:8000/api/admin/categories', {
//         'Content-Type': 'application/json',
//         Authorization:
//             'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjgwMDAvYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE2ODU5ODYxMjAsImV4cCI6MTY4NjA3MjUyMCwibmJmIjoxNjg1OTg2MTIwLCJqdGkiOiJsaFJKSVlRcHpDa09FYk5RIiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.BEp7Z_H7lHsSOttGhSpPldeqCgcR_jix0g3DPaVFpdU',
//     });
// }, []);
