import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from 'antd';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './PaymentXu.module.scss';
import { format } from 'date-fns';
import { update_cart, get_cart } from '~/Store/Action/cartActions';

const cx = classNames.bind(styles);

const PaymentXu = ({ handleIdCart, listCart, checkAll, check }) => {
    // console.log(check);
    const [quantities, setQuantities] = useState({});

    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    const handleDecrease = async (itemId, item) => {
        const updatedQuantities = { ...quantities };
        if (!updatedQuantities[itemId]) {
            updatedQuantities[itemId] = item.quantity; // Sử dụng giá trị mặc định từ item.quantity
        } else {
            updatedQuantities[itemId] -= 1;
        }
        setQuantities(updatedQuantities);
        await dispatch(update_cart(auth.token, updatedQuantities[itemId], itemId));
        dispatch(get_cart(auth.token));
    };

    const handleIncrease = async (itemId, item) => {
        const updatedQuantities = { ...quantities };
        if (!updatedQuantities[itemId]) {
            updatedQuantities[itemId] = item.quantity; // Sử dụng giá trị mặc định từ item.quantity
        } else {
            updatedQuantities[itemId] += 1;
        }
        setQuantities(updatedQuantities);
        await dispatch(update_cart(auth.token, updatedQuantities[itemId], itemId));
        dispatch(get_cart(auth.token));
    };
    //   console.log(listCart);
    return (
        <>
            {listCart?.data?.length > 0 ? (
                listCart?.data?.map((item, index) => (
                    <div className={cx('PaymentXu')}>
                        <div className={cx('PaymentXu-item')}>
                            <Link to="">
                                <img
                                    style={{ width: '120px', borderRadius: '20px' }}
                                    src={item?.course?.image || item?.book?.image}
                                    alt=""
                                />
                            </Link>
                            <div className={cx('PaymentXu-content')}>
                                <Link to="" className={cx('PaymentXu-title')}>
                                    {item?.course?.title || item?.book?.name}
                                </Link>
                                <div className={cx('PaymentXu-info') + ' d-flex flex-wrap w-100'}>
                                    <div className={cx('PaymentXu-author')}>
                                        <img src={item?.course?.owner?.image || item?.book?.owner?.image} alt="" />
                                        <span style={{ paddingLeft: '10px' }}>
                                            {item?.course?.owner?.name || item?.book?.owner?.name}
                                        </span>
                                    </div>
                                    <span>{format(new Date(item?.created_at), 'dd/M/yyyy')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={cx('PaymentXu-action')}>
                            <Checkbox
                                checked={checkAll || check == item?.id ? true : false}
                                onChange={() => handleIdCart(item?.id)}
                            />
                            <span>{item?.book ? item?.book?.coin_price : item?.course?.coin_price} xu</span>
                            <div className={cx('PaymentXu-quantity')}>
                                <FontAwesomeIcon onClick={() => handleDecrease(item.id, item)} icon={faMinus} />
                                <input type="text" value={quantities[item.id] || item.quantity || 0} readOnly />
                                <FontAwesomeIcon onClick={() => handleIncrease(item.id, item)} icon={faPlus} />
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <h2 style={{ textAlign: 'center', padding: '30px 0px', fontSize: '15px' }}>
                    Hiện tại không có khóa học trong giỏ!
                </h2>
            )}
        </>
    );
};

export default PaymentXu;
