import { FormControl, TextField, Stack, MenuItem, InputLabel, Select, Button } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './FormInput.module.scss';
import images from '~/assets/images';
import { useState, useEffect } from 'react';

const cx = classNames.bind(styles);
function FormInput() {
    const [user_name, setUserName] = useState('');
    const [user_phone_number, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [organization, setOrg] = useState('');
    // const [password, setPassword] = useState('');
    // const [password, setPassword] = useState('');
    // const [password, setPassword] = useState('');

    return (
        <FormControl sx={{ paddingTop: '10px' }} fullWidth>
            <Stack spacing={2}>
                <TextField
                    label="Họ và tên"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <TextField
                    label="Số điện thoại"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                    <TextField
                    label="Email"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                    <TextField
                    label="Địa chỉ"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <TextField
                    label="Đơn vị công tác"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }} id="demo-simple-select-label">Loại dịch vụ</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        label="Loại dịch vụ"
                        // onChange={handleChange}
                        sx={{
                            '& .MuiSelect-root': {
                                color: 'blue',
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                padding: '14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-3%'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                            //   borderColor: 'orange',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                'span': {
                                    fontSize: '12px',
                                },
                            },
                        }}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Thời gian mong muốn hoàn thành"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }} id="demo-simple-select-label">Loại tiền thanh toán</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        label="Loại tiền thanh toán"
                        // onChange={handleChange}
                        sx={{
                            '& .MuiSelect-root': {
                                color: 'blue',
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                padding: '14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-3%'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                            //   borderColor: 'orange',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                'span': {
                                    fontSize: '12px',
                                },
                            },
                        }}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Mô tả dịch vụ"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#333',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <input id="upload-img-btn" style={{ display: 'none' }} type="file" />
                <label className={cx('file-upload')} htmlFor="upload-img-btn">
                    <span>File đính kèm</span>
                    <div className={cx('upload')}>
                        <img src={images.Upload} alt="upload file" />
                    </div>
                </label>
            </Stack>
            <Button className={cx('btn_submit')} variant='contained'>GỬI YÊU CẦU</Button>
        </FormControl>
    );
}

export default FormInput;