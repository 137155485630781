import { Box, Typography, Stack, Button } from '@mui/material';
import Pagination from '~/components/Pagination';
import Breadcumb from '~/components/Breadcrumb';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import images from '~/assets/images';
import styles from './Post.module.scss';

const cx = classNames.bind(styles);

function Post() {
    return (
        <Box className={cx('Post', 'container')}>
            <Breadcumb title="Tin tức" />
            <h1 className={cx('title')}>Danh sách tin tức</h1>
            <Box className={cx('list')}>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={cx('item')}>
                    <Box className={cx('wrapper')}>
                        <Link to="/tin-tuc/hoi-thao-khoa-hoc">
                            <img className={cx('img-fluid')} src={images.ItemPost} alt="" />
                        </Link>
                        <Box className={cx('content')}>
                            <Link to="" className={cx('title')}>
                                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo trong
                            </Link>
                            <Typography sx={{ fontSize: '14px' }}>
                                Chiều 2/12, Phòng Khoa học và Công nghệ TP Thủ Đức phối hợp với Trung tâm Phát triển
                                Khoa học và{' '}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>20/11/2022</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Pagination />
        </Box>
    );
}

export default Post;
