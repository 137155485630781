import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ForgotPasswordForm.module.scss';
import { Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { forgot_password } from '../../../Store/Action/authActions.js';

const cx = classNames.bind(styles);

function ForgotPasswordForm({ onAuthForgotSuccess }) {
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const handleEmailChange = e => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const status = await dispatch(forgot_password(email));
        if (status) {
            localStorage.setItem('email', email);
            onAuthForgotSuccess();
        }
    };

    return (
        <div className={cx('ForgotPasswordForm')}>
            <p>Xin vui lòng nhập email đăng ký tài khoản của bạn</p>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <TextField 
                        className={cx('input-change')} 
                        fullWidth 
                        id="email" 
                        label="Email"
                        value={email}
                        onChange={handleEmailChange} 
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px',
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }} 
                    />
                    <button className={cx('btn-ToNext')} type="submit" id="nextButton">Tiếp theo</button>
                </Stack>
            </form>
        </div>
    );
}

export default ForgotPasswordForm;
