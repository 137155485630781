const initialState = {
    list: null,
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'NOTIFICATION_SUCCESS':
        return {
          ...state,
          list: action.payload,
        };
      case 'NOTIFICATION_FAILURE':
        return state;
      default:
        return state;
    }
  };
  
  export default notificationReducer;
