import { Box, Typography, Stack, Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './ContentLeft.module.scss';

const cx = classNames.bind(styles);

const ContentLeft = () => {
    return (
        <Box className={cx('content-left')}>
            <img className={cx('img-fluid')} src={images.AboutUsContent} alt="Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo" />
        </Box>
    );
};

export default ContentLeft;
