const routes = {
    home: '/',
    about: 'gioi-thieu',
    aboutUs: 'gioi-thieu/ve-chung-toi',
    tamnhinsumang: 'gioi-thieu/tam-nhin-su-mang',
    lichsuhinhthanh: 'gioi-thieu/lich-su-hinh-thanh',
    tonchihoatdong: 'gioi-thieu/ton-chi-hoat-dong',
    boquytacdaoduc: 'gioi-thieu/bo-quy-tac-dao-duc',
    chinhsachbaomat: 'gioi-thieu/chinh-sach-bao-mat',
    chinhsachvanchuyen: 'gioi-thieu/chinh-sach-van-chuyen',
    chinhsachbaohanh: 'gioi-thieu/chinh-sach-bao-hanh',
    chinhsachdoitra: 'gioi-thieu/chinh-sach-doi-tra',
    phuongthucthanhtoan: 'gioi-thieu/phuong-thuc-thanh-toan',
    DieuKhoanChinhSach: 'gioi-thieu/dieu-khoan-chinh-sach',
    sodotochuc: 'gioi-thieu/cam-ket-trach-nghiem-xa-hoi',
    xuatban: 'gioi-thieu/du-an-da-hoan-thanh',
    linhvuchoatdong: 'gioi-thieu/linh-vuc-hoat-dong',
    post: 'tin-tuc',
    detailPost: 'tin-tuc/:slug',
    product: 'san-pham',
    courseware: ':slug',
    detailCourseware: ':slug/:slug/:id',
    courseVideo: 'khoa-hoc-video',
    watchVideo: 'khoa-hoc-video/xem-video/:id',
    inforUser: 'user/:id',
    experts: 'experts',
    readBook: 'doc-sach/:id',
    infoUser: 'thong-tin-nguoi-dang/:slug/:type',
    infoUserEx: 'user/:slug/:type',
    detailCourseVideo: 'khoa-hoc-video/:slug/:id',
    detailCourseOnline: 'khoa-hoc-online/:slug/:id',
    courseOnline: 'khoa-hoc-online',
    event: 'su-kien',
    detailEvent: 'su-kien/:slug/:id',
    service: 'dich-vu',
    bookService: 'dat-dich-vu',
    getService: 'nhan-dich-vu',
    user: 'tai-khoan/:any',
    detailUser: 'tai-khoan/:any/:id',
    detailManageOrder: 'tai-khoan/quan-ly-lich-su-don-hang/:id',
    addSurvay: 'tai-khoan/quan-ly-khao-sat/them-moi-khao-sat',
    myDetailSurvay: 'tai-khoan/quan-ly-khao-sat/chi-tiet-khao-sat/:id',
    addCourseVideo: 'tai-khoan/quan-ly-khoa-hoc/them-moi-khoa-hoc-video',
    editCourseVideo: 'tai-khoan/quan-ly-khoa-hoc/sua-khoa-hoc-video/:id',
    codeShare: 'tai-khoan/ma-gioi-thieu',
    addBookPdf: 'tai-khoan/quan-ly-sach-pdf/them-moi-sach',
    editBookPdf: 'tai-khoan/quan-ly-sach-pdf/sua-sach/:id',
    addBookPrint: 'tai-khoan/quan-ly-sach-giay/them-moi-sach',
    editBookPrint: 'tai-khoan/quan-ly-sach-giay/sua-sach/:id',
    addBookPdfMemory: 'tai-khoan/quan-ly-ky-uc-xa-hoi-sach-pdf/them-moi-sach',
    editBookPdfMemory: 'tai-khoan/quan-ly-ky-uc-xa-hoi-sach-pdf/sua-sach/:id',
    editBookPrintMemory: 'tai-khoan/quan-ly-ky-uc-xa-hoi/sua-sach/sach-giay/:id',
    addBookPrintMemory: 'tai-khoan/quan-ly-ky-uc-xa-hoi-sach-giay/them-moi-sach',
    addEvent: 'tai-khoan/quan-ly-su-kien/them-moi-su-kien',
    addCourseOnline: 'tai-khoan/quan-ly-khoa-hoc/them-moi-khoa-hoc-online',
    editCourseOnline: 'tai-khoan/quan-ly-khoa-hoc/sua-khoa-hoc-online/:id',
    editEvent: 'tai-khoan/quan-ly-khoa-hoc/sua-sua-kien/:id',
    infoCustomer: 'tai-khoan/quan-ly-don-hang/thong-tin-khach-hang/:id',
    getServiceInfo: 'tai-khoan/dich-vu/nhan-thong-tin-dich-vu/:id',
    survay: 'tai-khoan/khao-sat',
    survayDetail: 'khao-sat/:slug/:id',
    doSurvay: 'khao-sat/do/:slug/:id',
    payment: 'gio-hang/thanh-toan',
    historyService: 'tai-khoan/lich-su-dich-vu',
};

export default routes;
