import classNames from 'classnames/bind';
import 'bootstrap/dist/css/bootstrap.css';
import styles from '../Home.module.scss';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import CourseDataItem from '~/components/CourseDataItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// Import Swiper styles
import 'swiper/css';
import Section from '~/components/Section/Section';

const cx = classNames.bind(styles);

function SocialMemory({ book_categories }) {
    let navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/sach-giay');
    };

    useEffect(() => {
        console.log(book_categories);
    }, []);

    return (
        <Section
            className={cx('SocialMemory')}
            buttonTitle="Xem thêm"
            sectionTitle="Dữ liệu ký ức xã hội"
            onClickButton={handleRedirect}
        >
            <div className="wow animate__animated animate__backInRight">
                <div className={cx('list-SocialMemory')}>
                    <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {/* {book_categories?.data?.book_categories[0].books.map((item, index) => ( */}
                        {book_categories?.data?.data.map((item, index) => (
                            <SwiperSlide>
                                <CourseDataItem data={item} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </Section>
    );
}

export default SocialMemory;
