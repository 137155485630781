import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import styles from './InfoCustomer.module.scss';


const cx = classNames.bind(styles);

function InfoCustomer() {
  

    return (
        <Box className={cx('InfoCustomer','container')}>
            <Breadcumb title="Tài khoản" />
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ flexGrow: 1 }}
                className={cx('wrapper')}
            >
                <Grid pb={2} pr={2} xs={8}>
                    <TitleUser className={cx('title-wp')} title="Thông tin của bạn" />
                    <Box className={cx('info')}>
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '16px' }}>Địa chỉ</Typography>
                        <Typography sx={{ fontSize: '16px' }}>Lê Phú 0916743545</Typography>
                        <Typography sx={{ fontSize: '16px' }}>Tăng Nhơn Phú B - Quận 9 - TPHCM</Typography>
                        <Typography sx={{ fontSize: '16px' }}>TPHCM</Typography>
                    </Box>
                    <Box className={cx('payment')}>
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '16px' }}>Phương thức thanh toán</Typography>
                        <Typography sx={{ fontSize: '14px', color: '#27AB60' }}>Thanh toán tại nhà</Typography>
                    </Box>
                    <TitleUser title="Danh sách sản phẩm" />
                    <Box className={cx('ListProduct','container')}>
                        <Box className={cx('item')}>
                            <img style={{ width: '162px', height: '83px' }} src={images.Item} alt="" />
                            <Box className={cx('content')}>
                                <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '16px' }}>Khóa học Social</Typography>
                                <Box className={cx('price')}>
                                    <Typography sx={{ color: '#F9AB5C', fontSize: '16px' }}>100 xu</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>x2</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid pb={2} pr={2} xs={4}>
                    <TitleUser className={cx('title-wp')} title="Thông tin đơn hàng" />
                    <Box className={cx('infoOrder-list')}>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Mã đơn hàng</Typography><Typography sx={{ fontSize: '16px' }}>123123</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Trạng thái</Typography><Typography sx={{ fontSize: '16px' }}>
                        Đang giao</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Tổng tiền sản phẩm</Typography><Typography sx={{ fontSize: '16px' }}>100 xu</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Phí vận chuyển</Typography><Typography sx={{ fontSize: '16px' }}>0 xu</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Tổng tiền</Typography><Typography sx={{ fontSize: '16px', color: '#27AB60' }}>100 xu</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Chiết khấu cho hệ thống</Typography><Typography sx={{ fontSize: '16px', color: '#27AB60' }}>100 xu</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Thuế</Typography><Typography sx={{ fontSize: '16px', color: '#27AB60' }}>100 xu</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Typography sx={{ fontSize: '16px' }}>Doanh thu</Typography><Typography sx={{ fontSize: '16px', color: '#27AB60' }}>100 xu</Typography></Box>
                        <Box className={cx('infoOrder-item')}><Button variant='contained' className={cx('cancel-order')}>XÁC NHẬN ĐƠN HÀNG</Button></Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default InfoCustomer;