import React from 'react';
import { Box } from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import classNames from 'classnames/bind';
import styles from './MyOrder.module.scss';
import All from './All';
import Waiting from './Waiting';
import Deliver from './Deliver';
import Complete from './Complete';
import Cancel from './Cancel';

const cx = classNames.bind(styles);

function MyOrder() {
    
    return (
        <Box className={cx('MyOrder','container')}>
            <Tabs>
                <TabList className={cx('MyOrder-tab')}>
                    <Tab>Tất cả</Tab>
                    <Tab>Đang chờ</Tab>
                    <Tab>Đang giao</Tab>
                    <Tab>Hoàn thành</Tab>
                    <Tab>Đã hủy</Tab>
                </TabList>
                <TabPanel>
                    <All />
                </TabPanel>
                <TabPanel>
                    <Waiting />
                </TabPanel>
                <TabPanel>
                    <Deliver />
                </TabPanel>
                <TabPanel>
                    <Complete />
                </TabPanel>
                <TabPanel>
                    <Cancel />
                </TabPanel>
            </Tabs>
        </Box>
    );
}

export default MyOrder;