import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Breadcumb from '~/components/Breadcrumb';
import Pagination from '~/components/Pagination';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { bookCategory, bookCategoryId } from '~/Store/Action/categoryActions';
import { bookPrint, bookPdf } from '~/Store/Action/bookActions';
import { add_cart, get_cart } from '~/Store/Action/cartActions';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Collapse from 'react-bootstrap/Collapse';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Courseware = () => {
    const { slug } = useParams();
    const [idIndex, setIdIndex] = useState(1);
    const listPrint = useSelector((state) => state?.book?.listBookPrint?.data);
    const listPdf = useSelector((state) => state?.book?.listBookPdf?.data);
    let title = '';
    let titleArr = ['print_book', 'pdf'];
    let page = '';
    let totalPage = '';
    if (slug === 'sach-pdf') {
        title = 'Sách pdf';
        page = listPdf?.current_page;
        // setIdIndex(1)
        totalPage = listPdf?.total;
    } else {
        // title = 'Sách - Giáo trình / Sách giấy';
        title = 'Sách giấy';
        page = listPrint?.current_page;
        // setIdIndex(2)
        totalPage = listPrint?.total;
    }
    const [currentPage, setCurrentPage] = useState(page || 1);
    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    const dispatch = useDispatch();
    const listBookCategory = useSelector((state) => state.category.listBookCategory);
    const listBookCategoryId = useSelector((state) => state.category.listBookCategoryId);
    // console.log(listBookCategory);
    const [open, setOpen] = useState(window.innerWidth > 800);

    useEffect(() => {
        const handleResize = () => {
            setOpen(window.innerWidth > 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(bookCategory()); // Dispatch action để lấy dữ liệu khi component được mount
        dispatch(bookPrint());
        dispatch(bookPdf());
    }, [title]);

    useEffect(() => {
        dispatch(bookCategoryId(slug === 'sach-pdf' ? 1 : 2)); // Dispatch action để lấy dữ liệu khi component được mount
    }, [slug, title]);

    const [checkedItems, setCheckedItems] = useState([]);
    const [targetCategory, setCategory] = useState(null);

    const handleCheckboxChange = async (event, itemId) => {
        if (event.target.checked) {
            await setCheckedItems((prevCheckedItems) => [...prevCheckedItems, itemId]);
        } else {
            await setCheckedItems((prevCheckedItems) => prevCheckedItems.filter((id) => id !== itemId));
        }

        const updatedCheckedItems = event.target.checked
            ? [...checkedItems, itemId]
            : checkedItems.filter((id) => id !== itemId);

        const params = {
            type: slug == 'sach-pdf' ? titleArr[1] : titleArr[0],
            specialization_id: updatedCheckedItems.join(','),
            ...(targetCategory && { book_category_id: targetCategory.id }),
        };

        dispatch(bookPrint(params));
        dispatch(bookPdf(params));
    };

    const handleCategoryChange = async (event, item) => {
        console.log(slug);

        if (targetCategory && item.id == targetCategory.id) {
            setCategory(null);

            const params = {
                type: slug == 'sach-pdf' ? titleArr[1] : titleArr[0],
                specialization_id: checkedItems.join(','),
            };

            dispatch(bookPrint(params));
            dispatch(bookPdf(params));
            return;
        }
        setCategory(item);

        const params = {
            type: slug == 'sach-pdf' ? titleArr[1] : titleArr[0],
            specialization_id: checkedItems.join(','),
            book_category_id: item.id,
        };

        dispatch(bookPrint(params));
        dispatch(bookPdf(params));
    };

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(bookPrint(null, prevPage));
            dispatch(bookPdf(null, prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(bookPdf(null, nextPage));
            dispatch(bookPrint(null, nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);

        const params = {
            type: slug == 'sach-pdf' ? titleArr[1] : titleArr[0],
            specialization_id: checkedItems.join(','),
            page: pageNumber,
            ...(targetCategory && { book_category_id: targetCategory.id }),
        };

        dispatch(bookPrint(params, pageNumber));
        dispatch(bookPdf(params, pageNumber));
    };

    const handleSearch = async (event) => {
        if (event.key === 'Enter') {
            const keyword = event.target.value;

            const params = {
                type: slug == 'sach-pdf' ? titleArr[1] : titleArr[0],
                specialization_id: checkedItems.join(','),
                ...(targetCategory && { book_category_id: targetCategory.id }),
                keyword: keyword,
            };

            dispatch(bookPrint(params));
            dispatch(bookPdf(params));
            console.log('Enter key was pressed!');
        }
    };

    const handleFilter = async () => {
        var keyw = document.getElementById('search');

        const params = {
            type: slug == 'sach-pdf' ? titleArr[1] : titleArr[0],
            specialization_id: checkedItems.join(','),
            ...(targetCategory && { book_category_id: targetCategory.id }),
            keyword: keyw.value ?? '',
        };

        await dispatch(bookPrint(params, currentPage ?? 1));
        await dispatch(bookPdf(params, currentPage ?? 1));
    };

    const addCart = async (id) => {
        let cart = {
            product_type: 'book',
            book_id: id,
            quantity: 1,
            payment_method: 'vnd',
        };
        localStorage.setItem('cart', JSON.stringify(cart));
        let fieldCart = {
            product_type: JSON.parse(localStorage.getItem('cart')).product_type,
            book_id: JSON.parse(localStorage.getItem('cart')).book_id,
            quantity: JSON.parse(localStorage.getItem('cart')).quantity,
            payment_method: JSON.parse(localStorage.getItem('cart')).payment_method,
        };
        await dispatch(add_cart(auth.token, fieldCart));
        dispatch(get_cart(auth.token));
    };

    return (
        <div className="container">
            <Breadcumb title={title} />

            <div className="row mt-3">
                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="mt-4">
                        <h3 className="text-dark fw-bold mb-4" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                            Danh mục
                        </h3>
                        <div className="">
                            {listBookCategory?.data.book_categories.map((item, index) => (
                                <div
                                    className="mb-3"
                                    key={index}
                                    onClick={(event) => handleCategoryChange(event, item)}
                                >
                                    <Link
                                        className={`w-100 d-flex align-items-center category card-hover ${
                                            targetCategory && targetCategory.id == item.id ? 'target' : ''
                                        }`}
                                        to=""
                                    >
                                        <div className="category_book">
                                            <img src={item.image} />
                                        </div>
                                        <div className="ms-3">
                                            <p className="m-0 p-0">{item.name}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                            <div className="mt-4 mb-5 position-relative">
                                <input
                                    placeholder="Tìm kiếm"
                                    id="search"
                                    className="form-control p-3 fs-4 border-input-search"
                                    onKeyDown={(event) => handleSearch(event)}
                                />
                                <span className="position-absolute btn-g-search">
                                    <i className="fas fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h3
                            className="text-dark fw-bold c-s"
                            style={{ fontFamily: 'sans-serif', marginBottom: '20px' }}
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            Lọc chuyên ngành
                            <FontAwesomeIcon className="ms-3" icon={faAngleDown} />
                        </h3>
                        <div>
                            <Collapse in={open}>
                                <div id="example-collapse-text">
                                    {listBookCategory?.data?.specializations.map((item, index) => (
                                        <div className="form-check mb-4" key={index}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${item.id}`}
                                                onChange={(event) => handleCheckboxChange(event, item.id)}
                                                checked={checkedItems.includes(item.id)}
                                            />
                                            <label
                                                style={{ cursor: 'pointer' }}
                                                htmlFor={`checkbox-${item.id}`}
                                                className="form-check-label text-dark fw-bold"
                                            >
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <button
                                className="btn border rounded-pill w-100 bg-main btn-filter"
                                onClick={() => handleFilter()}
                            >
                                Lọc ngay
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                    <h3 className="text-dark fw-bold mt-4 mb-4" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                        {targetCategory ? targetCategory.name + ' / ' : ''} {title}
                    </h3>
                    <div>
                        <div className="row">
                            {slug === 'sach-pdf' ? (
                                listPdf?.data?.length > 0 ? (
                                    <>
                                        {listPdf?.data?.map((item, index) => (
                                            <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                                                <div className="wow animate__animated animate__zoomIn">
                                                    <div className="card card-hover">
                                                        <Link
                                                            className="w-100"
                                                            to={'/sach-pdf/' + slugify(item?.name) + '/' + item?.id}
                                                        >
                                                            <LazyLoadImage
                                                                src={item?.image}
                                                                style={{ height: '300px' }}
                                                                effect="blur"
                                                                className="card-img-top"
                                                                alt="Image Alt"
                                                            />
                                                        </Link>
                                                        <div className="card-body">
                                                            <Link
                                                                className="w-100"
                                                                to={'/sach-pdf/' + slugify(item?.name) + '/' + item?.id}
                                                                style={{ display: 'block', height: '70px' }}
                                                            >
                                                                <p
                                                                    className="card-title text-dark fw-bold"
                                                                    style={{
                                                                        textDecoration: 'none',
                                                                        WebkitLineClamp: 2,
                                                                        WebkitBoxOrient: 'vertical',
                                                                        display: '-webkit-box',
                                                                        overflow: 'hidden',
                                                                    }}
                                                                >
                                                                    {item?.name}
                                                                </p>
                                                            </Link>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    width="30"
                                                                    height="30"
                                                                    src={item?.owner?.image}
                                                                    className="border border-2 rounded-circle"
                                                                    alt="..."
                                                                />
                                                                <span className="ms-2 fs-4">{item?.owner.name}</span>
                                                            </div>
                                                        </div>
                                                        <div className="position-absolute add-card">
                                                            <img
                                                                onClick={() => addCart(item?.id)}
                                                                src={images.CartPer}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="card-footer" style={{ border: 'none' }}>
                                                            <div className="d-flex justify-content-between">
                                                                <span className="text-dark fs-5">
                                                                    {item?.view} đã học
                                                                </span>
                                                                <span className="text-dark fs-5">
                                                                    {item?.created_at
                                                                        ? format(
                                                                              new Date(item?.created_at),
                                                                              'dd/M/yyyy',
                                                                          )
                                                                        : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <Pagination
                                            handlePrevPage={handlePrevPage}
                                            handleNextPage={handleNextPage}
                                            handlePageChange={handlePageChange}
                                            page={listPdf?.current_page}
                                            totalPage={listPdf?.total}
                                        />
                                    </>
                                ) : (
                                    <h2>Danh sách trống!</h2>
                                )
                            ) : listPrint?.data?.length > 0 ? (
                                <>
                                    {listPrint?.data?.map((item, index) => (
                                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                                            <div className="wow animate__animated animate__zoomIn">
                                                <div className="card card-hover">
                                                    <Link
                                                        className="w-100"
                                                        to={'/sach-giay/' + slugify(item?.name) + '/' + item?.id}
                                                    >
                                                        <LazyLoadImage
                                                            src={item?.image}
                                                            style={{ height: '300px' }}
                                                            effect="blur"
                                                            className="card-img-top"
                                                            alt="Image Alt"
                                                        />
                                                    </Link>
                                                    <div className="card-body">
                                                        <Link
                                                            className="w-100"
                                                            to={'/sach-giay/' + slugify(item?.name) + '/' + item?.id}
                                                            style={{ display: 'block', height: '70px' }}
                                                        >
                                                            <p
                                                                className="card-title text-dark fw-bold"
                                                                style={{
                                                                    textDecoration: 'none',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    display: '-webkit-box',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </p>
                                                        </Link>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                width="30"
                                                                height="30"
                                                                src={item?.owner?.image}
                                                                className="border border-2 rounded-circle"
                                                                alt="..."
                                                            />
                                                            <span className="ms-2 fs-4">{item?.owner.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="position-absolute add-card">
                                                        <img
                                                            onClick={() => addCart(item?.id)}
                                                            src={images.CartPer}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="card-footer" style={{ border: 'none' }}>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="text-dark fs-5">{item?.view} đã học</span>
                                                            <span className="text-dark fs-5">
                                                                {item?.created_at
                                                                    ? format(new Date(item?.created_at), 'dd/M/yyyy')
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <Pagination
                                        handlePrevPage={handlePrevPage}
                                        handleNextPage={handleNextPage}
                                        handlePageChange={handlePageChange}
                                        page={listPrint?.current_page}
                                        totalPage={listPrint?.total}
                                    />
                                </>
                            ) : (
                                <h2>Danh sách trống!</h2>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Courseware;
