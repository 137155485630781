import { Box, Typography, Stack, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import styles from './About.module.scss';
import { getInformation } from '~/Store/Action/informationActions';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

function About() {
    // const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    // const information = useSelector((state) => state.information.get);

    useEffect(() => {
        // dispatch(getInformation(3));
    }, []);

    return (
        <Box className={cx('About', 'container')}>
            <Breadcumb title="Chính sách bảo mật" />
            <Box className={cx('content')}>
                <div className="mt-3 w-100 d-block content-web">
                    <h1 className="fw-bold mb-1">CHÍNH SÁCH BẢO MẬT THÔNG TIN KHÁCH HÀNG</h1>
                    <p className="mb-2">
                        <i>
                            Chúng tôi cam kết sẽ bảo mật những thông tin mang tính riêng tư của khách hàng. Quý khách
                            vui lòng đọc bản “Chính sách bảo mật” dưới đây để hiểu hơn những cam kết mà chúng tôi thực
                            hiện, nhằm tôn trọng và bảo vệ quyền lợi của người truy cập:
                        </i>
                    </p>
                    <div>
                        <div>
                            <strong className="my-2">1. Mục đích thu thập thông tin cá nhân:</strong>
                        </div>
                        <div>– Các thông tin thu thập thông qua website sẽ giúp chúng tôi:</div>
                        <ul className="ms-4">
                            <li>• Hỗ trợ khách hàng khi mua sản phẩm</li>
                            <li>• Giải đáp thắc mắc khách hàng</li>
                            <li>• Cung cấp cho bạn thông tin mới nhất trên website của chúng tôi</li>
                            <li>• Xem xét và nâng cấp nội dung và giao diện của website</li>
                            <li>• Thực hiện các bản khảo sát khách hàng</li>
                            <li>
                                • Thực hiện các hoạt động quảng bá liên quan đến các sản phẩm và dịch vụ của hệ thống
                                sản phẩm cao cấp.
                            </li>
                        </ul>
                        <div>
                            – Để truy cập và sử dụng một số dịch vụ tại website này, quý khách có thể sẽ được yêu cầu
                            đăng ký với chúng tôi thông tin cá nhân (Email, Họ tên, Số điện thoại liên lạc,…). Mọi thông
                            tin khai báo phải đảm bảo tính chính xác và hợp pháp. Chúng tôi không chịu mọi trách nhiệm
                            liên quan đến pháp luật của thông tin khai báo.
                        </div>

                        <div>
                            – Chúng tôi cũng có thể thu thập thông tin về số lần viếng thăm, bao gồm số trang quý khách
                            xem, số links (liên kết) bạn click và những thông tin khác liên quan đến việc kết nối đến
                            website này. Chúng tôi cũng thu thập các thông tin mà trình duyệt Web (Browser) quý khách sử
                            dụng mỗi khi truy cập vào website này bao gồm: địa chỉ IP, loại Browser, ngôn ngữ sử dụng,
                            thời gian và những địa chỉ mà Browser truy xuất đến.
                        </div>

                        <div>
                            <strong className="my-2">2. Phạm vi sử dụng thông tin cá nhân:</strong>
                        </div>

                        <div>
                            – Chúng tôi thu thập và sử dụng thông tin cá nhân quý khách với mục đích phù hợp và hoàn
                            toàn tuân thủ nội dung của “Chính sách bảo mật” này.
                        </div>
                        <div>
                            – Khi cần thiết, chúng tôi có thể sử dụng những thông tin này để liên hệ trực tiếp với bạn
                            dưới các hình thức như: gửi thư ngỏ, đơn đặt hàng, thư cảm ơn, thông tin về kỹ thuật và bảo
                            mật, quý khách có thể nhận được thư định kỳ cung cấp thông tin sản phẩm, dịch vụ mới, thông
                            tin về các sự kiện sắp tới hoặc thông tin tuyển dụng nếu quý khách đăng ký nhận email thông
                            báo.
                        </div>

                        <div>
                            <strong className="my-2">3. Thời gian lưu trữ thông tin</strong>
                        </div>
                        <div>1 năm kể từ khi khách hàng không liên lạc nữa.</div>

                        <div>
                            <strong className="my-2">4. Những người hoặc tổ chức có thể được tiếp cận với thông tin:</strong>
                        </div>
                        <div>
                            – Ngoại trừ các trường hợp về Sử dụng thông tin cá nhân như đã nêu trong chính sách này,
                            chúng tôi cam kết sẽ không tiết lộ thông tin cá nhân bạn ra ngoài.
                        </div>
                        <div>
                            – Trong một số trường hợp, chúng tôi có thể thuê một đơn vị độc lập để tiến hành các dự án
                            nghiên cứu thị trường và khi đó thông tin của bạn sẽ được cung cấp cho đơn vị này để tiến
                            hành dự án. Bên thứ ba này sẽ bị ràng buộc bởi một thỏa thuận về bảo mật mà theo đó họ chỉ
                            được phép sử dụng những thông tin được cung cấp cho mục đích hoàn thành dự án.
                        </div>
                        <div>
                            – Chúng tôi có thể tiết lộ hoặc cung cấp thông tin cá nhân của bạn trong các trường hợp thật
                            sự cần thiết như sau: (a) khi có yêu cầu của các cơ quan pháp luật; (b) trong trường hợp mà
                            chúng tôi tin rằng điều đó sẽ giúp chúng tôi bảo vệ quyền lợi chính đáng của mình trước pháp
                            luật; (c) tình huống khẩn cấp và cần thiết để bảo vệ quyền an toàn cá nhân của các thành
                            viên khác.
                        </div>
                        <div>
                            <strong className="my-2">5 - Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</strong>
                        </div>
                        <strong>Địa chỉ: 51 Quốc Hương - Phường Thảo Điền - Tp. Thủ Đức - TP. Hồ Chí Minh</strong>
                        <strong>Hotline: 0983634482</strong>
                        <div>
                            <strong className="my-2">
                                6. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình.
                            </strong>
                        </div>
                        <div>
                            – Bất cứ thời điểm nào quý khách cũng có thể yêu cầu chỉnh sửa những thông tin cá nhân của
                            mình theo các liên kết (website’s links) thích hợp mà chúng tôi cung cấp hoặc email về
                            lienhe@sociallife.vn hoặc số điện thoại hotline: 0983634482
                        </div>
                        <div>
                            <strong className="my-2">7. Bảo mật thông tin cá nhân:</strong>
                        </div>
                        <div>
                            – Khi bạn gửi thông tin cá nhân của bạn cho chúng tôi, bạn đã đồng ý với các điều khoản mà
                            chúng tôi đã nêu ở trên, Chúng tôi cam kết bảo mật thông tin cá nhân của quý khách bằng mọi
                            cách thức có thể. Chúng tôi sẽ sử dụng nhiều công nghệ bảo mật thông tin khác nhau như:
                            chuẩn quốc tế PCI, SSL,… nhằm bảo vệ thông tin này không bị truy lục, sử dụng hoặc tiết lộ
                            ngoài ý muốn.
                        </div>
                        <div>
                            – Tuy nhiên do hạn chế về mặt kỹ thuật, không một dữ liệu nào có thể được truyền trên đường
                            truyền internet mà có thể được bảo mật 100%. Do vậy, chúng tôi không thể đưa ra một cam kết
                            chắc chắn rằng thông tin quý khách cung cấp cho chúng tôi sẽ được bảo mật một cách tuyệt đối
                            an toàn, và chúng tôi không thể chịu trách nhiệm trong trường hợp có sự truy cập trái phép
                            thông tin cá nhân của quý khách như các trường hợp quý khách tự ý chia sẻ thông tin với
                            người khác. Nếu quý khách không đồng ý với các điều khoản như đã mô tả ở trên, chúng tôi
                            khuyên quý khách không nên gửi thông tin đến cho chúng tôi.
                        </div>
                        <div>
                            – Chúng tôi cũng khuyến cáo quý khách nên bảo mật các thông tin liên quan đến mật khẩu truy
                            xuất của quý khách và không nên chia sẻ với bất kỳ người nào khác (trong trường hợp website
                            của chúng tôi yêu cầu mật khẩu).
                        </div>
                        <div>
                            – Nếu sử dụng máy tính chung nhiều người, quý khách nên đăng xuất, hoặc thoát hết tất cả cửa
                            sổ Website đang mở.
                        </div>

                        <div>
                            <strong className="my-2">8. Thay đổi về chính sách:</strong>
                        </div>
                        <div>
                            – Chúng tôi hoàn toàn có thể thay đổi nội dung trong trang này mà không cần phải thông báo
                            trước, để phù hợp với các nhu cầu của website cũng như nhu cầu và sự phản hồi từ khách hàng
                            nếu có. Khi cập nhật nội dung chính sách này, chúng tôi sẽ chỉnh sửa lại thời gian “Cập nhật
                            lần cuối” bên dưới.
                        </div>
                        <div>
                            – Nội dung “Chính sách bảo mật” này chỉ áp dụng tại website này, không bao gồm hoặc liên
                            quan đến các bên thứ ba đặt quảng cáo hay có link tại site này. Chúng tôi khuyến khích bạn
                            đọc kỹ chính sách An toàn và Bảo mật của các trang web của bên thứ ba trước khi cung cấp
                            thông tin cá nhân cho các trang web đó. Chúng tôi không chịu trách nhiệm dưới bất kỳ hình
                            thức nào về nội dung và tính pháp lý của trang web thuộc bên thứ ba.
                        </div>
                        <div>
                            – Vì vậy, bạn đã đồng ý rằng, khi bạn sử dụng website của chúng tôi sau khi chỉnh sửa nghĩa
                            là bạn đã thừa nhận, đồng ý tuân thủ cũng như tin tưởng vào sự chỉnh sửa này. Do đó, chúng
                            tôi đề nghị bạn nên xem trước nội dung trang này trước khi truy cập các nội dung khác trên
                            website cũng như bạn nên đọc và tham khảo kỹ nội dung “Chính sách bảo mật” của từng website
                            mà bạn đang truy cập.
                        </div>
                        <div>
                            <strong className="my-2">9. Thông tin liên hệ:</strong>
                        </div>
                        <div>
                            – Chúng tôi luôn hoan nghênh các ý kiến đóng góp, liên hệ và phản hồi thông tin từ bạn về
                            “Chính sách bảo mật” này. Nếu bạn có những thắc mắc liên quan xin vui lòng liên hệ theo địa
                            chỉ Email:{' '}
                            <a href="mailto:lienhe@sociallife.vn" className="text-primary">
                                lienhe@sociallife.vn
                            </a>
                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default About;
