import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Grid, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import TitleUser from '~/components/TitleUser';
import ListProduct from '../../components/ListProduct';
import styles from './DetailOrderUser.module.scss';
import { historyDetail } from '~/Store/Action/historyDetailActions';
import { cancel_order, confirm_order } from '~/Store/Action/historyActions';

const cx = classNames.bind(styles);

function DetailOrderUser() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [item, setItem] = useState(1);
    let navigate = useNavigate();

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(historyDetail(id, auth.token)); // Dispatch action để lấy dữ liệu khi component được mount
    }, [auth, item]);
    const data = useSelector((state) => state.historyDetail?.data?.data);

    const handleCancelOrder = async (id) => {
        const status = await dispatch(cancel_order(id, auth.token));
        await dispatch(historyDetail(id, auth.token));
        if (status) {
            navigate('/tai-khoan/quan-ly-don-hang');
        }
    };

    const handleComfigOrder = async (id) => {
        const status = await dispatch(confirm_order(id, auth.token));
        await dispatch(historyDetail(id, auth.token));
        if (status) {
            navigate('/tai-khoan/quan-ly-don-hang');
        }
    };

    return (
        <Box className={cx('DetailOrderUser', 'container')}>
            <Breadcumb title="Tài khoản" />
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ flexGrow: 1 }}
                className={cx('wrapper')}
            >
                <Grid pb={2} pr={2} xs={8}>
                    <TitleUser className={cx('title-wp')} title="Thông tin khách hàng" />
                    <Box className={cx('info')}>
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '16px' }}>
                            Địa chỉ
                        </Typography>
                        <Typography sx={{ fontSize: '16px' }}>
                            {data?.user_name} - {data?.user_phone_number}
                        </Typography>
                        <Typography sx={{ fontSize: '16px' }}>
                            {data?.province} - {data?.district} - {data?.ward}
                        </Typography>
                        <Typography sx={{ fontSize: '16px' }}>{data?.address}</Typography>
                        <Typography sx={{ fontSize: '16px' }}>{data?.des ? `Note: ` + data?.des : ''}</Typography>
                    </Box>
                    <Box className={cx('payment')}>
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '16px' }}>
                            Phương thức thanh toán
                        </Typography>
                        <Typography sx={{ fontSize: '14px', color: '#27AB60' }}>
                            {data?.payment?.payment_method == 'cod' ? 'Thanh toán tại nhà' : 'Thanh toán online'}
                        </Typography>
                    </Box>
                    <TitleUser title="Danh sách sản phẩm" />
                    <ListProduct list={data?.order_items} />
                </Grid>
                <Grid pb={2} pr={2} xs={4}>
                    <TitleUser className={cx('title-wp')} title="Thông tin đơn hàng" />
                    <Box className={cx('infoOrder-list')}>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Mã đơn hàng</Typography>
                            <Typography sx={{ fontSize: '16px' }}>{data?.ref}</Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Trạng thái</Typography>
                            <Typography className="text-primary fw-bold" sx={{ fontSize: '16px' }}>
                                {data?.status === 'pending' && 'Đang chờ'}
                                {data?.status === 'cancelled' && 'Đã hủy'}
                                {data?.status === 'confirmed' && 'Đang giao'}
                                {data?.status === 'completed' && 'Hoàn thành'}
                            </Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Tổng tiền sản phẩm</Typography>
                            <Typography sx={{ fontSize: '16px' }}>{data?.sub_total} xu</Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Phí vận chuyển</Typography>
                            <Typography sx={{ fontSize: '16px' }}>{data?.shipping_fee} xu</Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Tổng tiền</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#27AB60' }}>{data?.total} xu</Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Chiết khấu cho hệ thống</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#27AB60' }}>{data?.tax_fee}</Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Thuế</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#27AB60' }}>{data?.commission_fee}</Typography>
                        </Box>
                        <Box className={cx('infoOrder-item')}>
                            <Typography sx={{ fontSize: '16px' }}>Danh thu</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#27AB60' }}>{data?.sub_total}</Typography>
                        </Box>
                        <Box className={cx('infoOrder-item') + ' w-100 mb-3 d-block'}>
                            {data?.status !== 'completed' && (
                                <>
                                    {/* {data?.status === 'confirmed' && ( */}
                                    <Button
                                        variant="contained"
                                        className={cx('cancel-order') + ' w-100 mb-3 d-block fw-bold'}
                                        onClick={() => handleComfigOrder(data?.id)}
                                    >
                                        XÁC NHẬN ĐƠN HÀNG
                                    </Button>
                                    {/* // )} */}
                                    {/* {data?.status === 'pending' && ( */}
                                    <Button
                                        variant="contained"
                                        className={cx('cancel-order') + ' w-100 mb-3 d-block fw-bold'}
                                        onClick={() => handleCancelOrder(data?.id)}
                                    >
                                        HỦY ĐƠN HÀNG
                                    </Button>
                                    {/* )} */}
                                </>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DetailOrderUser;
