import React, { useEffect, useState } from 'react';
import { Box, Stack, Grid, Typography, Button } from '@mui/material';
import classNames from 'classnames/bind';
import TitleUser from '~/components/TitleUser';
import styles from './HistoryService.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from '~/components/Pagination';
import { get_history_service } from '~/Store/Action/serviceAction';
import { format } from 'date-fns';
import CompleteServiceHistory from '~/components/Service/CompleteServiceHistory';
import { ReceiveIdService, receiveSesvice } from '~/Store/Action/serviceAction';

const cx = classNames.bind(styles);

const HistoryService = () => {
    const auth = useSelector((state) => state.auth);
    const [visible, setVisible] = useState(false);
    const [tabIndex, setTabIndex] = useState(1);

    const [idServiceComplete, setIdServiceComplete] = useState();

    const [active, setActive] = useState(false);
    const [idSerivce, setIdSerivce] = useState(null);

    const handleTab = (tab) => {
        setTabIndex(tab);
    };

    const [modalAuthState, setModalAuthState] = useState({
        visible: false,
    });

    const [modalUserState, setModalUserState] = useState({
        visible: false,
    });

    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const item = useSelector((state) => state.service.item);

    const services = useSelector((state) => state.service.list);
    const list = useSelector((state) => state.service.list);

    // console.log(services?.data);

    useEffect(() => {
        dispatch(get_history_service(auth.token, tabIndex === 1 ? 'maker' : 'taker', 1));
    }, [tabIndex]);

    useEffect(() => {
        async function load() {
            if (auth.user) {
                await setUser(JSON.parse(auth.user));
            }
            if (idSerivce == null) {
                return;
            }
            const data = await dispatch(ReceiveIdService(auth.token, idSerivce));
            console.log(data);
            if (data.taker_id && data.taker_id != null) {
                await setActive(true);
            }
        }
        load();
    }, [auth, idSerivce]);

    let page = list?.current_page;
    let totalPage = list?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    const handleOpenModalUser = (id, defaultKey) => {
        setVisible(true);
        setModalUserState({ ...modalUserState, visible: true, defaultKey });
        setIdServiceComplete(id);
        setActive(true);
    };

    const handleOpenModalAuth = (defaultKey) => {
        setVisible(true);
        setModalAuthState({ ...modalAuthState, visible: true, defaultKey });
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(get_history_service(JSON.parse(auth.user).id, auth.token, null, prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(get_history_service(auth.token, tabIndex === 1 ? 'maker' : 'taker', nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(get_history_service(auth.token, tabIndex === 1 ? 'maker' : 'taker', pageNumber));
    };

    return (
        <>
            {active ? (
                <CompleteServiceHistory idServiceComplete={idServiceComplete} />
            ) : (
                <Box className={cx('Service', 'container')}>
                    <TitleUser title="Lịch sử dịch vụ" />

                    <div>
                        <Button
                            className=""
                            variant="contained"
                            sx={{
                                background: tabIndex === 1 ? '#27AB60' : 'rgba(217, 217, 217, 1)',
                                color: tabIndex === 1 ? '#fff' : '#333',
                                fontSize: '14px',
                                borderRadius: '31px',
                                padding: '10px 20px',
                                margin: '10px 0px',
                                fontWeight: 'bold',
                                marginRight: '10px',
                            }}
                            onClick={() => handleTab(1)}
                        >
                            Lịch sử đặt dịch vụ
                        </Button>
                        {JSON.parse(auth.user).account_type != 'CUSTOMER' && (
                            <Button
                                className=""
                                variant="contained"
                                sx={{
                                    background: tabIndex === 2 ? '#27AB60' : 'rgba(217, 217, 217, 1)',
                                    color: tabIndex === 2 ? '#fff' : '#333',
                                    fontSize: '14px',
                                    borderRadius: '31px',
                                    padding: '10px 20px',
                                    margin: '10px 0px',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => handleTab(2)}
                            >
                                Lịch sử nhận dịch vụ
                            </Button>
                        )}
                    </div>
                    {tabIndex === 1 ? (
                        <>
                            <Grid
                                width="100%"
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ flexGrow: 1 }}
                                className={cx('wrapper')}
                            >
                                {services?.data?.data?.length === 0 ? (
                                    <div className="alert alert-info ms-5 w-100 mt-5">Danh sách trống</div>
                                ) : (
                                    services?.data?.data?.map((item, index) => (
                                        <Grid
                                            className="w-100 m-m-0 p-p-0"
                                            pb={1}
                                            pr={2}
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            key={index}
                                            onClick={() => handleOpenModalUser(item?.id)}
                                        >
                                            <Link to="" className={cx('item')}>
                                                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
                                                    {item?.service?.title}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        margin: '5px 0px',
                                                    }}
                                                >
                                                    <Typography sx={{ color: '#27AB60', fontSize: '16px' }}>
                                                        {item?.user_name}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '16px' }}>
                                                        {item?.estimate_complete_date} ngày
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: '16px' }}>
                                                        {item?.budget} vnđ
                                                    </Typography>
                                                    <Typography
                                                        sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize: '16px' }}
                                                    >
                                                        {item?.status === 'pending' && 'Đang chờ'}
                                                        {item?.status === 'doing' && 'Đang thực hiện'}
                                                        {item?.status === 'completed' && 'Hoàn thành'}
                                                        {item?.status === 'cancelled' && 'Đã huỷ bỏ'}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ fontSize: '14px', paddingTop: '7px' }}>
                                                    {item?.created_at
                                                        ? format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy')
                                                        : ''}
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                            <Pagination
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                                handlePageChange={handlePageChange}
                                page={list?.current_page}
                                totalPage={list?.total}
                            />
                        </>
                    ) : (
                        <Grid
                            width="100%"
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            sx={{ flexGrow: 1 }}
                            className={cx('wrapper')}
                        >
                            {Array.isArray(services?.data?.data) && services?.data?.data?.length === 0 ? (
                                <div className="alert alert-info ms-5 w-100 mt-5">Danh sách trống</div>
                            ) : (
                                // Mapping over services.data.data when it's an array
                                services?.data?.data?.map((item, index) => (
                                    <Grid
                                        className="w-100 m-m-0 p-p-0"
                                        pb={1}
                                        pr={2}
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        key={index}
                                        onClick={() => handleOpenModalUser(item?.id)}
                                    >
                                        <Link to="" className={cx('item')}>
                                            <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
                                                {item?.service?.title}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    margin: '5px 0px',
                                                }}
                                            >
                                                <Typography sx={{ color: '#27AB60', fontSize: '16px' }}>
                                                    {item?.user_name}
                                                </Typography>
                                                <Typography sx={{ fontSize: '16px' }}>
                                                    {item?.estimate_complete_date} ngày
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography sx={{ fontSize: '16px' }}>{item?.budget} vnđ</Typography>
                                                <Typography
                                                    sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize: '16px' }}
                                                >
                                                    {item?.status === 'pending' && 'Đang chờ'}
                                                    {item?.status === 'doing' && 'Đang thực hiện'}
                                                    {item?.status === 'completed' && 'Hoàn thành'}
                                                    {item?.status === 'cancelled' && 'Đã huỷ bỏ'}
                                                </Typography>
                                            </Box>
                                            <Typography sx={{ fontSize: '14px', paddingTop: '7px' }}>
                                                {/* Format the date */}
                                                {item?.created_at
                                                    ? format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy')
                                                    : ''}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                ))
                            )}

                            <Pagination
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                                handlePageChange={handlePageChange}
                                page={list?.current_page}
                                totalPage={list?.total}
                            />
                        </Grid>
                    )}
                </Box>
            )}
        </>
    );
};

export default HistoryService;
