import { Box, Typography, Stack } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './Section.module.scss';

const cx = classNames.bind(styles);

const Section = ({ sectionTitle, buttonTitle, categoryTitle, onClickButton, children, className }) => {
    return (
        <Box className={className}>
            <Stack direction="row" justifyContent="space-between">
                {sectionTitle && (
                    <Typography className={cx('sectionTitle')} sx={{ fontFamily: 'sans-serif', fontWeight: 600, fontSize: 32, lineHeight: '40px' }}>{sectionTitle}</Typography>
                )}
                {categoryTitle ? (
                     <Box  className={cx('category')} sx={{ display: 'flex', alignItems: 'center', marginRight: '135px' }}>
                        {buttonTitle && (
                            <Box
                                component="button"
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: '20px',
                                    textAlign: 'center',
                                    borderRadius: '5px',
                                    p: '8px 16px',
                                }}
                                className={cx('button')}
                                onClick={onClickButton}
                            >
                                {buttonTitle}
                            </Box>
                        )}
                        {categoryTitle && (
                            <Typography sx={{ fontSize: '32px', marginLeft: 0, fontFamily: 'sans-serif' }}>{categoryTitle}</Typography>
                        )}
                     </Box>
                ): (
                    <>
                         {buttonTitle && (
                            <Box
                                component="button"
                                className="button"
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: '20px',
                                    textAlign: 'center',
                                    borderRadius: '5px',
                                    p: '8px 16px',
                                }}
                                onClick={onClickButton}
                            >
                                {buttonTitle}
                            </Box>
                        )}
                    </>
                )}
            </Stack>
            <Box>{children}</Box>
        </Box>
    );
};

export default Section;
