import { Box, FormControl, TextField, Stack, MenuItem, InputLabel, Select, Button, Typography } from '@mui/material';
import FormInput from './FormInput';
import TitleUser from '~/components/TitleUser';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './PersonalInformation.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';


const cx = classNames.bind(styles);

const PersonalInformation = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    const profile = useSelector((state) => state.profile);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    // console.log(profile);

    return (
        <Box>
            <TitleUser title="Thông tin cá nhân" />
            {user ? <FormInput profile={profile.list?.data} user={JSON.parse(auth.user)} /> : ''}
        </Box>
    );
};

export default PersonalInformation;
