import { Box, Typography, TextField, Button } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import classNames from 'classnames/bind';
import styles from './ExpertRegister.module.scss';
import CloseIcon from '@mui/icons-material/Cancel';
import Alert from '~/components/Alert';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register_professional } from '~/Store/Action/authActions';

const cx = classNames.bind(styles);

const ExpertRegister = () => {
    const [visible, setVisible] = useState(false);

    const [account_type, setAccountType] = useState('EXPERT');

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const [selectedImages, setSelectedImages] = useState([]);

    const [selectedImg, setSelectedImg] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [selectedFile, setSelectedFile] = useState([]);

    useEffect(() => {
        handleOpenAlert();
    }, []);

    const handleImageChange = (event) => {
        event.preventDefault();

        const files = event.target.files;
        console.log(files);
        let file = event.target.files[0];
        const imageArray = Array.from(files).map((file) => URL.createObjectURL(file));
        setSelectedImages((prevImages) => [...prevImages, ...imageArray]);
        setSelectedImg((prevImages) => [...prevImages, ...files]);
    };

    const handleFileChange = (event) => {
        event.preventDefault();
        const files = event.target.files;
        console.log('file', files);
        let file = event.target.files[0];
        const imageArray = Array.from(files).map((file) => URL.createObjectURL(file));
        setSelectedFiles((prevImages) => [...prevImages, ...imageArray]);
        setSelectedFile((prevImages) => [...prevImages, ...files]);
    };

    const handleCancelImage = (index) => {
        const newImages = [...selectedImages];
        newImages.splice(index, 1);
        setSelectedImages(newImages);

        const newImages1 = [...selectedImg];
        newImages1.splice(index, 1);
        setSelectedImg(newImages1);
    };

    const handleCancelFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedImages(newFiles);

        const newFile = [...selectedFile];
        newFile.splice(index, 1);
        setSelectedImg(newFile);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        selectedImg.forEach((image, index) => {
            if (index == 0) {
                formData.append(`front_citizen_identity`, image);
                formData.append(`certificate_file`, image);
            } else if (index == 1) {
                formData.append(`back_citizen_identity`, image);
            } else {
                formData.append(`image_${index}`, image);
            }
        });

        selectedImg.forEach((image, index) => {
            formData.append(`certificate_file`, image);
        });
        // Gửi formData đi hoặc thực hiện các tác vụ khác với formData

        // Sau khi hoàn thành, reset selectedImages
        setSelectedImages([]);
        setSelectedFiles([]);

        formData.append(`account_type`, account_type);

        dispatch(register_professional(auth.token, formData));

        for (const pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }
    };

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleAccountType = (data) => {
        setAccountType(data);
        setVisible(false);
    };

    const handleAccountTypeText = () => {
        return account_type == 'EXPERT' ? 'chuyên gia' : 'đối tác';
    };

    return (
        <Box>
            <TitleUser title={'Đăng ký trở thành ' + handleAccountTypeText()} />
            <Typography sx={{ fontSize: '16px', color: '#313131', fontWeight: '300!important', padding: '10px 0px' }}>
                Xin vui lòng cung cấp hình ảnh chứng minh thư hoặc CCCD và cv của bạn
            </Typography>
            <Box>
                <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>Chọn hình ảnh</Typography>
                <Box sx={{ display: 'flex' }}>
                    <label className={cx('content')} htmlFor="upload-img-btn">
                        <TextField
                            label="Chọn ảnh"
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e)}
                            onClick={(event) => {
                                event.target.value = null;
                            }}
                            style={{ display: 'none' }}
                            id="upload-img-btn"
                        />
                        <Box className={cx('wp-file')}>
                            <img src={images.camera} alt="chọn ảnh" />
                        </Box>
                    </label>
                    {selectedImages.map((image, index) => (
                        <Box className={cx('img-wrapper')}>
                            <CloseIcon className={cx('close-icon')} onClick={() => handleCancelImage(index)} />
                            <img
                                key={index}
                                className={cx('img-preview img-selected')}
                                src={image}
                                alt={`ảnh chọn ${index + 1}`}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box>
                <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>Tải file</Typography>
                <Box sx={{ display: 'flex' }}>
                    {/* <label className={cx('content')} htmlFor="upload-img-btn">
                        <TextField label="Tải file" type="file" style={{ display: 'none' }} id="upload-img-btn" />
                        <Box className={cx('wp-file')}>
                            <img src={images.file} alt="Tải file" />
                        </Box>
                    </label> */}
                    {/* <Box className={cx('img-wrapper')}>
                        <CloseIcon className={cx('close-icon')} />
                        <img
                            className={cx('img-preview')}
                            style={{ paddingLeft: '10px' }}
                            src={images.chooseFile}
                            alt=""
                        />
                    </Box> */}
                    <label className={cx('content')} htmlFor="upload-img-btn1">
                        <TextField
                            label="Tải file"
                            type="file"
                            onChange={(e) => handleFileChange(e)}
                            onClick={(event) => {
                                event.target.value = null;
                            }}
                            style={{ display: 'none' }}
                            id="upload-img-btn1"
                        />
                        <Box className={cx('wp-file')}>
                            <img src={images.camera} alt="chọn file" />
                        </Box>
                    </label>
                    {selectedFiles.map((image, index) => (
                        <Box className={cx('img-wrapper')}>
                            <CloseIcon className={cx('close-icon')} onClick={() => handleCancelFile(index)} />
                            <img
                                key={index}
                                className={cx('img-preview img-selected')}
                                src={image}
                                alt={`file chọn ${index + 1}`}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Button className={cx('btn_submit')} onClick={() => handleSubmit()} variant="contained">
                GỬI YÊU CẦU
            </Button>

            <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                    Bạn muốn trở thành
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                    <div>
                        <span onClick={() => handleAccountType('EXPERT')} className="btn btn-success me-2">
                            Chuyên gia
                        </span>
                        <span onClick={() => handleAccountType('PARTNER')} className="btn btn-primary">
                            Đối tác
                        </span>
                    </div>
                </Typography>
                <div className="row position-relative pt-4"></div>
            </Alert>
        </Box>
    );
};

export default ExpertRegister;
