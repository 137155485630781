import api from './api';

// Thành phố
export const cty = () => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/cities', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'CITY_SUCCESS', payload: response.data });
            // console.log(response);
            return response.data.data.cities;
        } catch (error) {
            dispatch({ type: 'CITY_FAILURE', payload: error.message });
        }
    };
};

export const city = () => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/addresses/vtp/provinces', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'CITY1_SUCCESS', payload: response.data });
            // console.log(response);
            return response.data.data;
        } catch (error) {
            dispatch({ type: 'CITY_FAILURE', payload: error.message });
        }
    };
};

export const getDistrict = (id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/addresses/vtp/districts?province_id=' + id, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'DISTRICT_SUCCESS', payload: response.data });
            // console.log(response);
            return response.data.data;
        } catch (error) {
            dispatch({ type: 'DISTRICT_FAILURE', payload: error.message });
        }
    };
};

export const getWard = (id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/addresses/vtp/wards?district_id=' + id, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'WARD_SUCCESS', payload: response.data });
            console.log(response);
        } catch (error) {
            dispatch({ type: 'WARD_FAILURE', payload: error.message });
        }
    };
};

// Quận huyện
export const district = () => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/districts', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'DISTRICT_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'DISTRICT_FAILURE', payload: error.message });
        }
    };
};

// Phường xã
export const ward = () => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/wards', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'WARD_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'WARD_FAILURE', payload: error.message });
        }
    };
};
