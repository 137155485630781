import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import { Link } from 'react-router-dom';
import Pagination from '~/components/Pagination';
import classNames from 'classnames/bind';
import styles from './MySurvey.module.scss';
import { my_survey, survey } from '~/Store/Action/surveyActions';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import slugify from 'slugify';

const cx = classNames.bind(styles);

const MySurvey = () => {
    let navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/tai-khoan/quan-ly-khao-sat/them-moi-khao-sat');
    };

    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const list = useSelector((state) => state.survey.list);

    let page = list?.data?.current_page;
    // console.log(page);
    let totalPage = list?.data?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(my_survey(auth.token, 1));
    }, [auth]);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(my_survey(auth.token, prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(my_survey(auth.token, nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(my_survey(auth.token, pageNumber));
    };

    return (
        <Box className={cx('MySurvey')}>
            <div className={cx('title-user')}>
                <TitleUser title="Quản lý khảo sát" />
                <Button className="wow animate__animated animate__lightSpeedInRight" onClick={handleRedirect}>
                    Thêm mới
                </Button>
            </div>
            <div className="row mt-3">
                {list?.data?.data?.length > 0 ? (
                    <>
                        {list?.data?.data?.map((item, index) => (
                            <div className="col-12 col-sm-6 col-lg-6 mb-4">
                                <Link className="card" to={'/khao-sat/' + slugify(item.title) + '/' + item.id}>
                                    {/* <Link className="card" to="/tai-khoan/quan-ly-khao-sat/chi-tiet-khao-sat/123"> */}
                                    <div className="card-body">
                                        <h2
                                            className="text-dark fw-bold"
                                            style={{ fontSize: '16px', fontFamily: 'sans-serif' }}
                                        >
                                            {item?.title}
                                        </h2>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '5px 0px',
                                            }}
                                        >
                                            <span className="p">{item?.total_questions} câu hỏi</span>
                                            <span className="p">{item?.answered} người khảo sát</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span className="p">{format(new Date(item?.created_at), 'dd/M/yyyy')}</span>
                                            {item?.status == 'active' ? (
                                                <span className="text-success">Đang diễn ra</span>
                                            ) : (
                                                <span className="text-warning">Đang chờ duyệt</span>
                                            )}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                        <Pagination
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            handlePageChange={handlePageChange}
                            page={list?.data?.current_page}
                            totalPage={list?.data?.total}
                        />
                    </>
                ) : (
                    <div>
                        <h3 className="fw-bold">Danh sách trống</h3>
                    </div>
                )}
            </div>
        </Box>
    );
};

export default MySurvey;
