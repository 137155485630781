import { Input } from 'antd';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import React, { useState, useEffect, useRef } from 'react';
import HistorySearch from './HistorySearch';
import { useDispatch, useSelector } from 'react-redux';
import { search } from '~/Store/Action/homeActions';
import Collapse from 'react-bootstrap/Collapse';
import styles from './Search.module.scss';

const cx = classNames.bind(styles);

function Search() {
    const [showOpenSearch, setShowOpenSearch] = useState(false);

    const dispatch = useDispatch();
    const searchs = useSelector((state) => state.home.search);
    const history_keyword = useSelector((state) => state.home.history_keyword);
    const [searchTerm, setSearchTerm] = useState('');
    const contentRef = useRef(null);

    const handleClickOutside = (event) => {
        if (contentRef.current && !contentRef.current.contains(event.target)) {
            setShowOpenSearch(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            const keyword = event.target.value;
            dispatch(search(keyword));
        }
    };

    const handleShowOpenSearch = (event) => {
        event.stopPropagation();
        setShowOpenSearch(!showOpenSearch);
    };

    const handleHideSearch = () => {
        setShowOpenSearch(false);
    };

    return (
        <div className={cx('Search') + ' div-search'}>
            <div className={cx('search')}>
                <img src={images.iconSearch} onClick={handleShowOpenSearch} alt="" />
            </div>
            {showOpenSearch && (
                <div ref={contentRef} className={cx('search-input') + ' search-i'} tabIndex="-1">
                    <div className='search-bar'>
                        <Input type="text" placeholder="Tìm kiếm" />

                        <HistorySearch searchs={searchs} history_keyword={history_keyword ?? []} />
                        <div className={cx('search-input')} tabIndex="-1">
                            <Input type="text" placeholder="Tìm kiếm" onKeyDown={(e) => handleSearch(e)} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Search;
