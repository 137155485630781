import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ChangePasswordForm.module.scss';
import { Stack, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { reset_password } from '../../../Store/Action/authActions.js';

const cx = classNames.bind(styles);

function ChangePasswordForm({ handleCancel }) {
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const email = localStorage.getItem('email');
    const dispatch = useDispatch();
    
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePassword_confirmationChange = (e) => {
        setPassword_confirmation(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const status = await dispatch(reset_password(email, password, password_confirmation));
        // console.log(status);
        if (status) {
            handleCancel();
        }
    };

    return (
        <div className={cx('ForgotPasswordForm')}>
            <p>Xin vui lòng nhập mật khẩu mới để đăng nhập tài khoản</p>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <TextField 
                        className={cx('input-change')} 
                        fullWidth
                        type='password'
                        id="password" 
                        label="Mật khẩu" 
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px',
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <TextField 
                        className={cx('input-change')} 
                        fullWidth 
                        id="re-password"
                        type='password' 
                        label="Nhập lại mật khẩu" 
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px',
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                        value={password_confirmation}
                        onChange={handlePassword_confirmationChange}
                    />
                    <button className={cx('btn-SignIn')} type='submit'>HOÀN THÀNH</button>
                </Stack>
            </form>
        </div>
    );
}

export default ChangePasswordForm;