import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import slugify from 'slugify';
import { useSelector, useDispatch } from 'react-redux';
import Breadcumb from '~/components/Breadcrumb';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import { surveyDetail, surveyDetailFile, pause } from '~/Store/Action/surveyDetailActions';
import { format } from 'date-fns';
import SunEditor from 'suneditor-react';

const SurvayDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(surveyDetail(id, auth.token)); // Dispatch action để lấy dữ liệu khi component được mount
    }, [auth, id]);

    useEffect(() => {
        dispatch(surveyDetailFile(id, auth.token));
    }, [id]);
    const data = useSelector((state) => state.surveyDetail?.data?.data);
    const file = useSelector((state) => state.surveyDetail?.file?.data);
    console.log(file);

    const pauseSurvay = async (e) => {
        const d = await dispatch(pause(id, auth.token));
        // if (await d) {
        // await dispatch(surveyDetail(id, auth.token));
        // }
    };
    return (
        <div className="container">
            <Breadcumb title="Khảo sát" slug={data?.title} />

            <div className="row mt-3">
                <div className=" col- col-sm- col-md- col-lg-4 col-xl-3 col-xxl-3">
                    <div className="mt-4">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="text-dark fw-bold mb-4" style={{ fontFamily: 'sans-serif' }}>
                                    {data?.title}
                                </h3>
                                <div className="d-flex justify-content-between" style={{ paddingBottom: '10px' }}>
                                    <span className="p">Số câu hỏi:</span>
                                    <span className="p">{data?.questions?.length} câu hỏi</span>
                                </div>
                                <div className="d-flex justify-content-between" style={{ paddingBottom: '10px' }}>
                                    <span className="p">Thời gian đăng: </span>
                                    <span className="p">
                                        {data?.created_at ? format(new Date(data?.created_at), 'dd/M/yyyy') : ''}
                                    </span>
                                </div>
                                {data?.user_id == user?.id && (
                                    <>
                                        <div
                                            className="d-flex justify-content-between"
                                            style={{ paddingBottom: '10px' }}
                                        >
                                            <span className="p">Số người tham gia: </span>
                                            <span className="p">{file?.num_joined}</span>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between"
                                            style={{ paddingBottom: '10px' }}
                                        >
                                            <span className="p">Trạng thái: </span>
                                            <span className="p">
                                                {file?.survey_status == 'stop' && 'Đã dừng'}
                                                {file?.survey_status == 'pending' && 'Chờ duyệt'}
                                                {file?.survey_status == 'active' && 'Đang diễn ra'}
                                            </span>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between"
                                            style={{ paddingBottom: '10px' }}
                                        >
                                            <span className="p">Thống kê tống quan: </span>
                                            <span className="p">
                                                <a className="text-success" href={file?.common_report} target="_blank">
                                                    Tải file
                                                </a>
                                            </span>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between"
                                            style={{ paddingBottom: '10px' }}
                                        >
                                            <span className="p">Thống kê chi tiết: </span>
                                            <span className="p">
                                                <a className="text-success" href={file?.detail_report} target="_blank">
                                                    Tải file
                                                </a>
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                            {data?.user_id == user?.id && file?.survey_status != 'stop' && (
                                <div className="card-footer">
                                    <button className="btn-survay-pause w-100" onClick={() => pauseSurvay()}>
                                        Dừng khảo sát
                                    </button>
                                </div>
                            )}
                            <div className="card-footer">
                                <Link
                                    className="btn-survay"
                                    to={'/khao-sat/do/' + slugify(data?.title ? data?.title : '') + '/' + data?.id}
                                >
                                    Bắt đầu khảo sát
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" col- col-sm- col-md- col-lg-8 col-xl-9 col-xxl-9">
                    <h2 className="text-dark fw-bold mt-4 mb-4 h22" style={{ fontFamily: 'sans-serif' }}>
                        Thông tin khảo sát
                    </h2>
                    <div className="w-100">
                        <img src={data?.img ?? images.survay} className="img-fluid w-100 img-survay" />
                    </div>
                    <div className="mt-4 mb-4">
                        <p className="p" dangerouslySetInnerHTML={{ __html: data?.description }}>
                            {/* {data?.description} */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SurvayDetail;
