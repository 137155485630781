import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import images from '~/assets/images';
import PostFeatered from './PostFeatered/PostFeatered';
import Event from './Event/Event';
import Lesson from './Lesson/Lesson';
import ConnectExperts from './ConnectExperts/ConnectExperts';
import CourseData from './CourseData/CourseData';
import SocialMemory from './SocialMemory/SocialMemory';
import { useNavigate } from 'react-router-dom';
import { home } from '~/Store/Action/homeActions';
import { socialMemory } from '~/Store/Action/bookActions';
import { Link, NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// Import Swiper styles
import 'swiper/css';
import Section from '~/components/Section/Section';

const cx = classNames.bind(styles);

function Home() {
    const [modalShow, setModalShow] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const handleRedirect = () => {
        navigate('/su-kien');
    };
    const list = useSelector((state) => state.home.list);
    const listSocialMemory = useSelector((state) => state.book.listSocialMemory);
    // console.log(list);
    useEffect(() => {
        dispatch(home()); // Dispatch action để lấy dữ liệu khi component được mount
        dispatch(socialMemory());
    }, []);

    const change_link = () => {
        const isAndroid = navigator.userAgent.includes('Android');
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        if (isAndroid) {
            // Mở liên kết tới trang ứng dụng trên Google Play Store
            window.open('https://play.google.com/store/apps/details?id=com.social.sociallife', '_blank');
        } else if (isIOS) {
            // Mở liên kết tới trang ứng dụng trên App Store
            window.open('https://apps.apple.com/vn/app/social-life/id6449656491?l=vi', '_blank');
        } else {
            // Xử lý trường hợp khác (nếu cần)
            alert('Không phát hiện thiết bị di động.');
        }
    };

    useEffect(() => {
        if (window.innerWidth < 800) {
            setTimeout(() => {
                setModalShow(true);
            }, 1500);
        }
    }, []);
    return (
        <Box className={cx('wrapper')}>
            <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Thông báo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-2 mb-3 text-center">
                        <img
                            width="180"
                            className={cx('image-banner', 'img-fluid')}
                            src={images.logo}
                            alt="Viện Đại học SocialLife"
                            title="Viện Đại học SocialLife"
                        />
                    </div>
                    <b className="fs-4">Tải App Social Life để thuận tiện hơn cho việc trải nghiệm</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        <b>Huỷ</b>
                    </Button>
                    <Button onClick={() => change_link()}>
                        <b>Tải ngay</b>
                    </Button>
                </Modal.Footer>
            </Modal>

            <PostFeatered featers={list} />
            <Event events={list} />
            <ConnectExperts />
            <Box className={cx('course')}>
                {list?.data?.banners && (
                    <Link
                        className={cx('item-sub-menu')}
                        target="_blank"
                        to={list?.data?.banners ? list?.data?.banners[1].link : '#'}
                    >
                        <img
                            className={cx('image-banner', 'img-fluid')}
                            src={list?.data?.banners ? list?.data?.banners[1].image : images.Banner}
                            alt="Viện Đại học SocialLife"
                            title="Viện Đại học SocialLife"
                        />
                    </Link>
                )}
                <Lesson courses={list} />
                <CourseData book_categories={list} />
                <Section
                    className={cx('SocialMemory')}
                    // buttonTitle="Xem thêm"
                    // sectionTitle="Sự kiện nổi bật"
                    onClickButton={handleRedirect}
                >
                    {list?.data?.banners && (
                        <Link
                            className={cx('item-sub-menu')}
                            target="_blank"
                            to={list?.data?.banners ? list?.data?.banners[1].link : '#'}
                        >
                            <img
                                className={cx('image-banner', 'img-fluid')}
                                src={list?.data?.banners ? list?.data?.banners[2].image : images.Banner}
                                alt="Viện Đại học SocialLife"
                                title="Viện Đại học SocialLife"
                            />
                        </Link>
                    )}
                </Section>
                <SocialMemory book_categories={listSocialMemory} />
            </Box>
        </Box>
    );
}

export default Home;
