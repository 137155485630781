import { Box, Typography } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './Section.module.scss';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const cx = classNames.bind(styles);

const Section = ({ copy, title, children }) => {
    return (
        <Box sx={{ marginBottom: '20px' }} className={cx('wrapper')}>
            <Typography sx={{ fontSize: '16px' }}>{title}</Typography>
            <Box className={cx('content')}>
                <Box>{children}</Box>
                <Typography sx={{ color: '#27AB60', cursor: 'pointer' }}>{copy ? (
                    <>
                        <FileCopyIcon />
                        {copy}
                    </>
                ):(
                    ''
                )}</Typography>
            </Box>
        </Box>
    );
};

export default Section;
