import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, Stack, TextField } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import classNames from 'classnames/bind';
import styles from './GetServiceInfo.module.scss';
import images from '~/assets/images';
import CompleteService from '~/components/Service/CompleteService';
import { ReceiveIdService, receiveSesvice } from '~/Store/Action/serviceAction';
import { format } from 'date-fns';

const cx = classNames.bind(styles);

const GetServiceInfo = ({ idSerivce }) => {
    const [visible, setVisible] = useState(false);
    const [idServiceComplete, setIdServiceComplete] = useState();

    const [active, setActive] = useState(false);

    const [modalUserState, setModalUserState] = useState({
        visible: false,
    });

    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const item = useSelector((state) => state.service.item);

    console.log(item);

    useEffect(() => {
        async function load() {
            if (auth.user) {
                await setUser(JSON.parse(auth.user));
            }
            const data = await dispatch(ReceiveIdService(auth.token, idSerivce));
            console.log(data);
            if (data.taker_id && data.taker_id != null) {
                await setActive(true);
            }
        }
        load();
    }, [auth]);

    const handleOpenModalUser = (id, defaultKey) => {
        const data = dispatch(receiveSesvice(auth.token, id));

        if (data) {
            setVisible(true);
            setModalUserState({ ...modalUserState, visible: true, defaultKey });
            setIdServiceComplete(idSerivce);
            setActive(true);
        }
    };

    return (
        <>
            {active ? (
                <CompleteService idServiceComplete={idServiceComplete} />
            ) : (
                <Box className={cx('GetServiceInfo')}>
                    <TitleUser title="Thông tin của bạn" />
                    <Box className={cx('box-wp')}>
                        <Box className={cx('box-info')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Tên khách hàng</Typography>
                            <Typography>{item?.user_name}</Typography>
                        </Box>
                        <Box className={cx('box-info')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Đơn vị công tác</Typography>
                            <Typography>{item?.organization}</Typography>
                        </Box>
                        <Box className={cx('box-info')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Mã giao dịch</Typography>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>{item?.ref}</Typography>
                        </Box>
                        <Box className={cx('box-info')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Thời gian đăng</Typography>
                            <Typography>
                                {item?.created_at ? format(new Date(item?.created_at), 'HH:mm:ss - dd/M/yyyy') : ''}
                            </Typography>
                        </Box>
                        <Box className={cx('box-info')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Trạng thái dịch vụ</Typography>
                            <Typography sx={{ color: '#F9AB5C' }}>
                                {item?.status === 'pending' ? 'Đang chờ' : 'Thành công'}
                            </Typography>
                        </Box>
                        <Box className={cx('box-info')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Số tiền</Typography>
                            <Typography sx={{ color: '#27AB60' }}>+ {item?.budget} vnđ</Typography>
                        </Box>
                        <Box className={cx('box-info')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Loại dịch vụ</Typography>
                            <Typography>{item?.service?.title}</Typography>
                        </Box>
                        <Box className={cx('box-des')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>Mô tả dịch vụ</Typography>
                            <Typography sx={{ fontWeight: '300!important' }}>{item?.service?.description}</Typography>
                        </Box>
                        <Box className={cx('box-file')}>
                            <Typography sx={{ fontFamily: 'sans-serif' }}>File đính kèm</Typography>
                            <Box className={cx('list-file')}>
                                {item?.attachments?.length > 0 ? (
                                    <Box className={cx('file')}>
                                        <img src={images.pdf} alt="" />
                                        <Typography>file 1</Typography>
                                    </Box>
                                ) : (
                                    <h2 style={{ fontSize: '13px', paddingTop: '7px' }}>
                                        Hiện tại không có file đính kèm nào!
                                    </h2>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Button
                        onClick={() => handleOpenModalUser(item?.id)}
                        className={cx('btn_submit')}
                        variant="contained"
                    >
                        Nhận dịch vụ
                    </Button>
                    {/* <ModalUser modalUserState = {modalUserState} visible={visible} handleCancel={handleCancel}>
                        <div className={cx('header-modal-back')}>
                            <button className={cx('btn-back')}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <h4>Xác thực OTP</h4>
                            <FontAwesomeIcon className={cx('close')} icon={faClose} />
                        </div>
                        <div className={cx('content-modal')}>
                            <div className={cx('star')}>
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <Typography sx={{ fontSize: '16px' }}>Chọn mức đánh giá của bạn</Typography>
                            <Stack spacing={3} m={3}>
                                <TextField
                                    multiline
                                    rows={5}
                                    maxRows={16}
                                    required
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                    type="text"
                                    label="Nội dung"
                                    // {...getFieldProps('content')}
                                    // error={Boolean(touched.content && errors.content)}
                                    // helperText={touched.content && errors.content}
                                />
                            </Stack>
                            <Box sx={{ padding: '16px 11px' }}>
                                <label className={cx('content')} htmlFor="upload-img-btn">
                                    <TextField
                                        label="Họ và tên"
                                        type="file"
                                        style={{ display: 'none' }}
                                        id="upload-img-btn"
                                    />
                                    <Button sx={{ borderRadius: '8px' }} className={cx('choose-image')} variant="outlined" component="span">Tải hình ảnh</Button>
                                </label>
                            </Box>
                            <Box className={cx('list-img')}>
                                <img src={images.reviewImg} alt="" />
                                <img src={images.reviewImg} alt="" />
                                <img src={images.reviewImg} alt="" />
                                <img src={images.reviewImg} alt="" />
                            </Box>
                            <Button sx={{ marginBottom: '10px', width: '470px' }} className={cx('btn_submit')} variant='contained'>GỬI ĐÁNH GIÁ</Button>
                        </div>
                    </ModalUser> */}
                </Box>
            )}
        </>
    );
};

export default GetServiceInfo;
