import React, { useState, useEffect } from 'react';
import { Typography, MenuList, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './AccountItem.module.scss';
import images from '~/assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Store/Action/authActions.js';
import Cookies from 'js-cookie';
import Collapse from 'react-bootstrap/Collapse';

const cx = classNames.bind(styles);

const AccountItem = ({ data, filteredMenuItems, handleClickHideProfile, itemLink, check_account }) => {
    let { any } = useParams();
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile?.list?.data);
    
    useEffect(() => {
        console.log(auth);
        // if (auth.user) {
            // setUser(JSON.parse(auth.user));
            setUser(profile);
        // }
    }, [profile]);

    const handleLogout = async () => {
        await Cookies.remove('token');
        await Cookies.remove('user');
        await dispatch(logout(auth.token));
    };

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    return (
        <>
            {itemLink != 'dat-dich-vu' ? (
                <MenuList sx={{ padding: 0 }}>
                    {user?.account_type == 'CUSTOMER' &&
                        // filteredMenuItems?.map((item, index) => (
                        //     <Link
                        //         key={index}
                        //         to={item.to == 'quan-ly-chuyen-gia-doi-tac' ? '#' : '/tai-khoan/' + `${item.to}`}
                        //         onClick={handleClickHideProfile}
                        //     >
                        //         <MenuItem
                        //             className={cx('account-item')}
                        //             sx={{
                        //                 padding: '14px 8px',
                        //                 borderBottom: '3px solid #D8D9DB',
                        //                 background: any === item.to ? '#27AB60' : '',
                        //                 color: any === item.to ? '#fff' : '',
                        //             }}
                        //         >
                        //             {item?.parent != true && <img src={item.icon} alt="" />}
                        //             <Typography
                        //                 pl={item?.parent == true ? 3 : 1}
                        //                 sx={{ fontSize: '16px', color: item.activeColor }}
                        //             >
                        //                 {item.title}
                        //             </Typography>
                        //         </MenuItem>
                        //     </Link>
                        // ))}
                        data
                            ?.filter((item) => item.parent !== true)
                            .map((item, index) => (
                                <>
                                    {item?.to != 'dang-ky-chuyen-gia-doi-tac' && (
                                        <div>
                                            {/* {item?.hasChild && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen(!open)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )} */}

                                            {item?.hasChild1 && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen1(!open1)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open1}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent1 != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open1 ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild2 && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen2(!open2)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open2}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent2 != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent2 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open1 ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild3 && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen3(!open3)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open3}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent3 != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent3 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open3 ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild && (
                                                <Collapse in={open}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-chuyen-gia-doi-tac'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}

                                            {item?.hasChild1 && (
                                                <Collapse in={open1}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent1 == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-tai-khoan'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent1 != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}

                                            {item?.hasChild2 && (
                                                <Collapse in={open2}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent2 == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-tai-khoan'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent2 != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent2 == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}

                                            {item?.hasChild3 && (
                                                <Collapse in={open3}>
                                                    <div id="example-collapse-text1">
                                                        {data
                                                            ?.filter((item) => item.parent3 == true)
                                                            .map((item1, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={item1.to}
                                                                        data-url={item1.to}
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item1.to ? '#27AB60' : '',
                                                                                color: any === item1.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item1?.parent3 != true && (
                                                                                <img src={item1.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item1?.parent3 == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item1.activeColor,
                                                                                }}
                                                                            >
                                                                                {item1.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}
                                        </div>
                                    )}
                                    {item?.to == 'dang-ky-chuyen-gia-doi-tac' && (
                                        <Link
                                            key={index}
                                            to={
                                                item.to == 'quan-ly-chuyen-gia-doi-tac'
                                                    ? '#'
                                                    : '/tai-khoan/' + `${item.to}`
                                            }
                                            onClick={handleClickHideProfile}
                                        >
                                            <MenuItem
                                                className={cx('account-item')}
                                                sx={{
                                                    padding: '14px 8px',
                                                    borderBottom: '3px solid #D8D9DB',
                                                    background: any === item.to ? '#27AB60' : '',
                                                    color: any === item.to ? '#fff' : '',
                                                }}
                                            >
                                                {item?.parent != true && <img src={item.icon} alt="" />}
                                                <Typography
                                                    pl={item?.parent == true ? 3 : 1}
                                                    sx={{ fontSize: '16px', color: item.activeColor }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </MenuItem>
                                        </Link>
                                    )}
                                </>
                            ))}
                    {/* } */}
                    {user?.account_type == 'PARTNER' &&
                        data
                            ?.filter((item) => item.parent !== true)
                            .map((item, index) => (
                                <>
                                    {item?.to != 'dang-ky-chuyen-gia-doi-tac' && (
                                        <div>
                                            {item?.hasChild && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen(!open)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild1 && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen1(!open1)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open1}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent1 != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open1 ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild2 && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen2(!open2)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open2}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent2 != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent2 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open1 ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild && (
                                                <Collapse in={open}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-chuyen-gia-doi-tac'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}

                                            {item?.hasChild1 && (
                                                <Collapse in={open1}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent1 == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-tai-khoan'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent1 != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}

                                            {item?.hasChild2 && (
                                                <Collapse in={open2}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent2 == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-tai-khoan'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent2 != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent2 == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}
                                        </div>
                                    )}
                                </>
                            ))}
                    {user?.account_type == 'EXPERT' &&
                        data
                            ?.filter((item) => item.parent !== true)
                            .map((item, index) => (
                                <>
                                    {item?.to != 'dang-ky-chuyen-gia-doi-tac' && (
                                        <div>
                                            {/* {item?.hasChild ? (
                                            // có child
                                            <div
                                                className={cx('title')}
                                                onClick={() => setOpen(!open)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={open}
                                            >
                                                <MenuItem
                                                    className={cx('account-item') + ' dropdown-menu'}
                                                    sx={{
                                                        padding: '14px 8px',
                                                        borderBottom: '3px solid #D8D9DB',
                                                        background: any === item.to ? '#27AB60' : '',
                                                        color: any === item.to ? '#fff' : '',
                                                    }}
                                                >
                                                    {item?.parent != true && <img src={item.icon} alt="" />}
                                                    <div className="d-flex justify-content-between w-100 align-items-center">
                                                        <Typography
                                                            pl={item?.parent1 == true ? 3 : 1}
                                                            sx={{ fontSize: '16px', color: item.activeColor }}
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                        {!open ? (
                                                            <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                        ) : (
                                                            <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                        )}
                                                    </div>
                                                </MenuItem>
                                            </div>
                                        ) : (
                                            // ko có child
                                            <Link
                                                key={index}
                                                to={
                                                    item.to == 'quan-ly-chuyen-gia-doi-tac'
                                                        ? '#'
                                                        : '/tai-khoan/' + `${item.to}`
                                                }
                                                onClick={handleClickHideProfile}
                                            >
                                                <MenuItem
                                                    className={cx('account-item') + ' dropdown-menu'}
                                                    sx={{
                                                        padding: '14px 8px',
                                                        borderBottom: '3px solid #D8D9DB',
                                                        background: any === item.to ? '#27AB60' : '',
                                                        color: any === item.to ? '#fff' : '',
                                                    }}
                                                >
                                                    {item?.parent != true && <img src={item.icon} alt="" />}
                                                    <Typography
                                                        pl={item?.parent == true ? 3 : 1}
                                                        sx={{ fontSize: '16px', color: item.activeColor }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                </MenuItem>
                                            </Link>
                                        )} */}
                                            {item?.hasChild && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen(!open)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild1 && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen1(!open1)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open1}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent1 != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open1 ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild2 && (
                                                <div
                                                    className={cx('title')}
                                                    onClick={() => setOpen2(!open2)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open2}
                                                >
                                                    <MenuItem
                                                        className={cx('account-item') + ' dropdown-menu'}
                                                        sx={{
                                                            padding: '14px 8px',
                                                            borderBottom: '3px solid #D8D9DB',
                                                            background: any === item.to ? '#27AB60' : '',
                                                            color: any === item.to ? '#fff' : '',
                                                        }}
                                                    >
                                                        {item?.parent2 != true && <img src={item.icon} alt="" />}
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <Typography
                                                                pl={item?.parent2 == true ? 3 : 1}
                                                                sx={{ fontSize: '16px', color: item.activeColor }}
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            {!open1 ? (
                                                                <i className="fs-2 me-2 fas fa-caret-down"></i>
                                                            ) : (
                                                                <i className="fs-2 me-2 fas fa-caret-up"></i>
                                                            )}
                                                        </div>
                                                    </MenuItem>
                                                </div>
                                            )}

                                            {item?.hasChild && (
                                                <Collapse in={open}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-chuyen-gia-doi-tac'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}

                                            {item?.hasChild1 && (
                                                <Collapse in={open1}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent1 == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-tai-khoan'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent1 != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent1 == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}

                                            {item?.hasChild2 && (
                                                <Collapse in={open2}>
                                                    <div id="example-collapse-text">
                                                        {data
                                                            ?.filter((item) => item.parent2 == true)
                                                            .map((item, index) => (
                                                                <>
                                                                    <Link
                                                                        key={index}
                                                                        to={
                                                                            item.to == 'quan-ly-tai-khoan'
                                                                                ? '#'
                                                                                : '/tai-khoan/' + `${item.to}`
                                                                        }
                                                                        onClick={handleClickHideProfile}
                                                                    >
                                                                        <MenuItem
                                                                            className={
                                                                                cx('account-item') + ' dropdown-menu'
                                                                            }
                                                                            sx={{
                                                                                padding: '14px 8px',
                                                                                borderBottom: '3px solid #D8D9DB',
                                                                                background:
                                                                                    any === item.to ? '#27AB60' : '',
                                                                                color: any === item.to ? '#fff' : '',
                                                                            }}
                                                                        >
                                                                            {item?.parent2 != true && (
                                                                                <img src={item.icon} alt="" />
                                                                            )}
                                                                            <Typography
                                                                                pl={item?.parent2 == true ? 3 : 1}
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    color: item.activeColor,
                                                                                }}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </Link>
                                                                </>
                                                            ))}
                                                    </div>
                                                </Collapse>
                                            )}
                                        </div>
                                    )}
                                </>
                            ))}
                    <Link to="/tai-khoan/ma-gioi-thieu">
                        <MenuItem
                            className={cx('account-item')}
                            sx={{
                                padding: '14px 8px',
                                borderBottom: '1px solid #D8D9DB',
                                background: '',
                                color: '',
                            }}
                        >
                            <img src={images.code_share} alt="" />
                            <Typography pl={1} sx={{ fontSize: '16px' }}>
                                Mã giới thiệu
                            </Typography>
                        </MenuItem>
                    </Link>
                    <Link to="/tai-khoan/khoa-hoc-cua-toi">
                        <MenuItem
                            className={cx('account-item')}
                            sx={{
                                padding: '14px 8px',
                                borderBottom: '1px solid #D8D9DB',
                                background: '',
                                color: '',
                            }}
                        >
                            <img src={images.course_profile} alt="" />
                            <Typography pl={1} sx={{ fontSize: '16px' }}>
                                Khóa học của tôi
                            </Typography>
                        </MenuItem>
                    </Link>
                    <Link to="/tai-khoan/thu-vien-cua-toi">
                        <MenuItem
                            className={cx('account-item')}
                            sx={{
                                padding: '14px 8px',
                                borderBottom: '1px solid #D8D9DB',
                                background: '',
                                color: '',
                            }}
                        >
                            <img src={images.book_profile} alt="" />
                            <Typography pl={1} sx={{ fontSize: '16px' }}>
                                Thư viện của tôi
                            </Typography>
                        </MenuItem>
                    </Link>
                    <Link to="/" onClick={handleLogout}>
                        <MenuItem
                            className={cx('account-item')}
                            sx={{
                                padding: '14px 8px',
                                borderBottom: '1px solid #D8D9DB',
                                background: '',
                                color: '',
                            }}
                        >
                            <img src={images.icon_logout} alt="" />
                            <Typography pl={1} sx={{ fontSize: '16px', color: '#27AB60' }}>
                                Đăng xuất
                            </Typography>
                        </MenuItem>
                    </Link>
                </MenuList>
            ) : (
                <MenuList sx={{ padding: 0 }}>
                    <Link to="/tai-khoan/dat-dich-vu" onClick={handleClickHideProfile}>
                        <MenuItem
                            className={cx('account-item')}
                            sx={{
                                padding: '14px 8px',
                                borderBottom: '3px solid #D8D9DB',
                                //   background: any === item.to ? '#27AB60' : '',
                                //   color: any === item.to ? '#fff' : '',
                            }}
                        >
                            <img src={images.icon_info} alt="" />
                            <Typography pl={1} sx={{ fontSize: '16px' }}>
                                Thông tin dịch vụ
                            </Typography>
                        </MenuItem>
                    </Link>
                    <Link to="/tai-khoan/dat-dich-vu" onClick={handleClickHideProfile}>
                        <MenuItem
                            className={cx('account-item')}
                            sx={{
                                padding: '14px 8px',
                                borderBottom: '3px solid #D8D9DB',
                                background: any === 'dat-dich-vu' ? '#27AB60' : '',
                                color: any === 'dat-dich-vu' ? '#fff' : '',
                            }}
                        >
                            <img src={images.service_profile} alt="Dịch vụ" />
                            <Typography pl={1} sx={{ fontSize: '16px' }}>
                                Dịch vụ
                            </Typography>
                        </MenuItem>
                    </Link>
                </MenuList>
            )}
        </>
    );
};

export default AccountItem;
