import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const getExperts = (page, params) => {
    return async (dispatch) => {
        try {
            if (!page || page == null) {
                page = 1;
            }
            if (!params || params == null) {
                params = {};
            }
            // Gọi API trang chủ
            const response = await api.get(`api/partner-expert-list?page=${page}&limit=30`, params ?? {}, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'EXPERTS_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'HOME_FAILURE', payload: error.message });
        }
    };
};

export const home = () => {
    return async (dispatch) => {
        try {
            // Gọi API trang chủ
            const response = await api.get('api/home', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'HOME_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'HOME_FAILURE', payload: error.message });
        }
    };
};

export const search = (keyword) => {
    return async (dispatch) => {
        try {
            console.log(keyword);
            dispatch({ type: 'KEYWORD_SEARCH_SUCCESS', payload: keyword });
            // Gọi API trang chủ
            const response = await api.get(`api/home/filters?keyword=${keyword}&page=1`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'HOME_SEARCH_SUCCESS', payload: response.data });
            console.log(response);
        } catch (error) {
            dispatch({ type: 'HOME_SEARCH_FAILURE', payload: error.message });
            console.log(error);
        }
    };
};

export const deleteKey = (index) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'KEYWORD_SEARCH_DELETE', payload: index });
        } catch (error) {}
    };
};
