import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const auth_gg = (code) => {
    return async (dispatch) => {
        try {
            const response = await api.get('oauth2/google?token=' + code?.access_token, {
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + token,
            });

            console.log(response);
            console.log(response.data);
            console.log(response.data.status);
            console.log(response.data.data);
            console.log(12314);
            console.log("abc");

            if (response.data.status == 200) {
                const token = response.data.data.token;

                console.log(token);

                // showToast(response.data.messages);
                await dispatch({ type: 'LOGIN_SUCCESS', payload: token });

                const getUser = await api.get(
                    'api/profile',
                    {},
                    {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                );

                // console.log(getUser.data.data);

                await dispatch({ type: 'SET_USER', payload: JSON.stringify(getUser.data.data) });

                showToast('Đăng nhập thành công');

                // showToast(response.data.messages);
                return true;
            } else {
                const message = 'Đã có lỗi xảy ra vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const postCode = (code, token) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append('code', code);

            const response = await api.post('api/referral-by', form, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status == 200) {
                showToast(response.data.messages);
                return true;
            } else {
                const message = 'Đã có lỗi xảy ra vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const login = (email, password) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append('email', email);
            form.append('password', password);
            // Gọi API đăng nhập
            const response = await api.post('api/login', form, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status == 200) {
                const data = await response.data;
                const token = data.data.token;

                // Lưu token vào Redux store
                await dispatch({ type: 'LOGIN_SUCCESS', payload: token });

                const getUser = await api.get(
                    'api/profile',
                    {},
                    {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                );

                // console.log(getUser.data.data);

                await dispatch({ type: 'SET_USER', payload: JSON.stringify(getUser.data.data) });
                // Cookies.set('user', JSON.stringify(getUser.data.data), { expires: 1.5 });

                showToast('Đăng nhập thành công');
                return true;
            } else {
                const message = 'Đăng nhập thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
            return false;
        }
    };
};

export const logout = (token) => {
    return async (dispatch) => {
        console.log(token);
        if (!token || token == null) {
            return;
        }
        try {
            const response = await api.get(
                'api/logout',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            console.log(response);

            if (response.data.status == 200) {
                dispatch({ type: 'LOGOUT_SUCCESS' });
                showToast('Đăng xuất thành công');
                window.location.reload();
                return true;
            } else {
                const message = 'Đăng xuất thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'LOGOUT_FAILURE', payload: error.message });
            return false;
        }
    };
};

export const get_vip_benefits = (token) => {
    return async (dispatch) => {
        console.log(token);
        try {
            const response = await api.get(
                'api/vip-benefits',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            const response1 = await api.get(
                'api/vip-packages',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                console.log(response1.data.data);
                dispatch({ type: 'GET_VIP_SUCCESS', payload: response.data.data });
                dispatch({ type: 'GET_PACKGET_SUCCESS', payload: response1.data.data });
                return true;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const register_professional = (token, data) => {
    return async (dispatch) => {
        console.log(token, data);
        for (const pair of data.entries()) {
            console.log(pair[0], pair[1]);
        }
        try {
            const response = await api.post('api/register-professional', data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã gửi yêu cầu đăng ký thành công đang chờ duyệt');
                return true;
            } else {
                const message = 'Gửi yêu cầu đăng ký thất bại vui lòng kiểm tra lại thông tin';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const register_vip_member = (token, id) => {
    return async (dispatch) => {
        try {
            const response = await api.post(
                'api/my/register-vip-member/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status === 200) {
                showToast('Đã gửi yêu cầu nâng cấp thành công');
                return true;
            } else {
                const message = 'Gửi yêu cầu nâng cấp thất bại vui lòng thủ lại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const change_password = (token, data) => {
    return async (dispatch) => {
        console.log(token, data);
        try {
            const form = new FormData();
            form.append('password', data.password);
            form.append('password_confirmation', data.password_confirmation);
            form.append('old_password', data.old_password);
            // Gọi API quên mật khẩu
            const response = await api.post('api/change-password', form, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                dispatch({ type: 'FORGOT_PASSWORD_SUCCESS', payload: response.data });
                showToast('Đã đổi mật khẩu thành công');
                return true;
            } else {
                const message = 'Đổi mật khẩu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'FORGOT_PASSWORD_FAILURE', payload: error.message });
            return false;
        }
    };
};

export const forgot_password = (email) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append('email', email);
            // Gọi API quên mật khẩu
            const response = await api.post('api/forgot-password', form, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status === 200) {
                dispatch({ type: 'FORGOT_PASSWORD_SUCCESS', payload: response.data });
                showToast('Đã gửi OTP vào gmail');
                return true;
            } else {
                const message = 'Gửi OTP thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'FORGOT_PASSWORD_FAILURE', payload: error.message });
            return false;
        }
    };
};

export const check_otp = (email, otp) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append('email', email);
            form.append('otp', otp);
            // Gọi API check otp
            const response = await api.post('api/check-otp', form, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status === 200) {
                showToast('OTP chính xác!');
                return true;
            } else {
                const message = 'OTP đã sai vui lòng nhập lại!';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'CHECK_OTP_FAILURE', payload: error.message });
            return false;
        }
    };
};

export const reset_password = (email, password, password_confirmation) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append('email', email);
            form.append('password', password);
            form.append('password_confirmation', password_confirmation);
            // Gọi API check otp
            const response = await api.post('api/reset-password-forgort', form, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status === 200) {
                showToast('Đặt lại mật khẩu thành công!');
                return true;
            } else {
                const message = 'Đặt lại mật khẩu thất bại!';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'RESET_PASSWORD_FAILURE', payload: error.message });
            return false;
        }
    };
};

export const register = (name, email, password, password_confirmation, account_type) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append('name', name);
            form.append('email', email);
            form.append('password', password);
            form.append('password_confirmation', password_confirmation);
            form.append('account_type', account_type);
            // Gọi API đăng ký
            const response = await api.post('api/register', form, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status == 200) {
                showToast('Đã gửi OTP đến email!');
                return true;
            } else {
                const message = 'Đăng ký thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'REGISTER_FAILURE', payload: error.message });
            return false;
        }
    };
};
