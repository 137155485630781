const initialState = {
    list: null,
    types: null,
    item: null
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_HISTORY_SERVICE':
            return {
                ...state,
                list: action.payload,
            };
        case 'GET_TYPE_SERVICE':
            return {
                ...state,
                types: action.payload,
            };
        case 'LIST_RECEIVE_SERVICE':
            return {
                ...state,
                list: action.payload,
            };
        case 'RECEIVE_ID_SERVICE':
            return {
                ...state,
                item: action.payload,
            };
        default:
            return state;
    }
};

export default orderReducer;
