import { Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './LessonItem.module.scss';
import slugify from 'slugify';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { add_cart, get_cart } from '~/Store/Action/cartActions';

const cx = classNames.bind(styles);

const LessonItem = ({ className, data }) => {
    const [check, setData] = useState(data.course ? true : false);
    const dispatch = useDispatch();

    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);
    const addCart = async (id) => {
        let cart = {
            product_type: 'course',
            course_id: id,
            quantity: 1,
            payment_method: 'vnd',
        };
        localStorage.setItem('cart', JSON.stringify(cart));
        let fieldCart = {
            product_type: JSON.parse(localStorage.getItem('cart')).product_type,
            course_id: JSON.parse(localStorage.getItem('cart')).course_id,
            quantity: JSON.parse(localStorage.getItem('cart')).quantity,
            payment_method: JSON.parse(localStorage.getItem('cart')).payment_method,
        };
        await dispatch(add_cart(auth.token, fieldCart));
        dispatch(get_cart(auth.token));
    };

    function formatDate(input) {
        // Check if the input is a valid date string
        const date = new Date(input);
        if (isNaN(date)) {
            return '0/0/0000';
        }

        // Format the valid date
        const formattedDate = format(date, 'dd/M/yyyy');
        return formattedDate;
    }

    function formatCurrency(number) {
        if (number == null || number == '') {
            return '0 đ';
        }
        return number.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
    }

    return (
        <>
            {check ? (
                <Box className={cx('item', className)}>
                    <Link
                        to={
                            (data?.course?.course_type_id == 1 ? '/khoa-hoc-online/' : '/khoa-hoc-video/') +
                            slugify(data?.course?.title ? data?.course?.title : '') +
                            '/' +
                            data?.course.id
                        }
                    >
                        <img
                            className="img-fluid"
                            style={{ width: '100%', height: '146px' }}
                            src={data?.course?.image}
                            alt=""
                        />
                    </Link>
                    <Box className={cx('lesson-content')}>
                        <Box className={cx('lesson-content-top')}>
                            <Link
                                to={
                                    (data?.course?.course_type_id == 1 ? '/khoa-hoc-online/' : '/khoa-hoc-video/') +
                                    slugify(data?.course?.title ? data?.course?.title : '') +
                                    '/' +
                                    data?.course.id
                                }
                                style={{
                                    height: '50px',
                                    display: 'block',
                                    textDecoration: 'none',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                }}
                            >
                                {data?.course.title}
                            </Link>
                            <Box className={cx('avatar')}>
                                <img
                                    style={{ width: '28px', height: '28px', borderRadius: '50%' }}
                                    className="img-fluid"
                                    src={data.course?.owner.image}
                                    alt=""
                                />
                                <Typography sx={{ paddingLeft: '10px' }}>
                                    {data?.owner?.name ?? data?.courses?.owner?.name}
                                </Typography>
                            </Box>
                            <Box className="my-2 d-flex">
                                <Typography className="text-success fw-bold">
                                    {formatCurrency(data?.discount_price ?? data?.courses?.discount_price)}
                                </Typography>
                                <Typography className="text-secondary text-decoration-line-through fs-5 fw-bold ms-3">
                                    {formatCurrency(data?.price ?? data?.courses?.price)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={cx('cart')}>
                            <img onClick={() => addCart(data?.id)} src={images.CartPer} alt="" />
                        </Box>
                        <Box className={cx('count_lesson')}>
                            <Typography>{data?.course?.view} bài học</Typography>
                            {/* <Typography>{format(new Date(data.course.opening_date), 'dd/M/yyyy')}</Typography> */}
                            <Typography>
                                {data?.course?.opening_date ? formatDate(data.course.opening_date) : ''}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box className={cx('item', className)}>
                    <Link
                        to={
                            (data?.course?.course_type_id == 1 ? '/khoa-hoc-online/' : '/khoa-hoc-video/') +
                            slugify(data?.title ? data?.title : '') +
                            '/' +
                            data?.id
                        }
                    >
                        <img className="img-fluid" style={{ width: '100%', height: '146px' }} src={data.image} alt="" />
                    </Link>
                    <Box className={cx('lesson-content')}>
                        <Box className={cx('lesson-content-top')}>
                            <Link
                                to={
                                    (data?.course?.course_type_id == 1 ? '/khoa-hoc-online/' : '/khoa-hoc-video/') +
                                    slugify(data?.title ? data?.title : '') +
                                    '/' +
                                    data?.id
                                }
                                style={{ height: '50px', display: 'block' }}
                            >
                                {data.title ?? <span className="text-danger">Sản phẩm đã bị xoá</span>}
                            </Link>
                            <Box className={cx('avatar')}>
                                <img
                                    style={{ width: '28px', height: '28px', borderRadius: '50%' }}
                                    className="img-fluid"
                                    src={data?.owner?.image}
                                    alt=""
                                />
                                <Typography sx={{ paddingLeft: '10px' }}>
                                    {data?.owner?.name ?? data?.courses?.owner?.name}
                                </Typography>
                            </Box>
                            <Box className="my-2 d-flex">
                                <Typography className="text-success fw-bold">
                                    {formatCurrency(data?.discount_price ?? data?.courses?.discount_price)}
                                </Typography>
                                <Typography className="text-secondary text-decoration-line-through fs-5 fw-bold ms-3">
                                    {formatCurrency(data?.price ?? data?.courses?.price)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={cx('cart')}>
                            <img onClick={() => addCart(data?.id)} src={images.CartPer} alt="" />
                        </Box>
                        <Box className={cx('count_lesson')}>
                            <Typography>{data?.view} bài học</Typography>
                            <Typography>{data.opening_date ? formatDate(data.opening_date) : ''}</Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default LessonItem;
