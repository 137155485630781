import { Box, Button } from '@mui/material';
import { Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './InfoUser.module.scss';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import images from '~/assets/images';
import AvartarName from '~/components/AvatarName';
import { useSelector, useDispatch } from 'react-redux';
import { get_user_by_id } from '~/Store/Action/profileActions';
import { format } from 'date-fns';
import slugify from 'slugify';

const cx = classNames.bind(styles);

const User = () => {
    const { slug } = useParams();
    const { type } = useParams();

    const dispatch = useDispatch();

    const data = useSelector((state) => state.profile?.user_data?.data);

    useEffect(() => {
        dispatch(get_user_by_id(slug, type));
    }, [slug, type]);

    const formatDate = (dateString) => {
        if (!dateString || dateString === '0000-00-00 00:00:00') {
            return ''; // Or any default value you prefer for invalid dates
        }

        const year = parseInt(dateString.substring(0, 4), 10);
        const month = parseInt(dateString.substring(5, 7), 10) - 1; // Month is 0-based in JavaScript
        const day = parseInt(dateString.substring(8, 10), 10);
        const hour = parseInt(dateString.substring(11, 13), 10);
        const minute = parseInt(dateString.substring(14, 16), 10);
        const second = parseInt(dateString.substring(17, 19), 10);

        const dateObj = new Date(year, month, day, hour, minute, second);

        return format(dateObj, 'dd/M/yyyy');
    };

    return (
        <div className="container">
            {console.log(data)}
            {data && (
                <div className="row mt-3">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="mt-4">
                            <Box className={cx('AvatarName')} textAlign="center">
                                <Box>
                                    <img
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            border: '2px solid #dee2e6',
                                            borderRadius: '50%',
                                        }}
                                        src={data?.user?.image ? data?.user?.image : images.avatarDefault}
                                        alt=""
                                    />
                                </Box>
                                <Typography sx={{ fontSize: '20px' }}>
                                    {data?.user ? data?.user.name : 'Chưa cập nhật'}
                                </Typography>
                                <Typography sx={{ fontSize: '16px', color: '#777777' }}>
                                    {data?.user?.account_type === 'CUSTOMER' && 'Tài khoản thường'}
                                    {data?.user?.account_type === 'PARTNER' && 'Đối tác'}
                                    {data?.user?.account_type === 'EXPERT' && 'Chuyên gia'}
                                </Typography>
                            </Box>
                        </div>
                        <ul className={cx('list', 'mt-4')}>
                            <li>
                                <a href={`/thong-tin-nguoi-dang/${data?.user.id}/videos`} className={cx('item')}>
                                    Khóa học video
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`/thong-tin-nguoi-dang/${data?.user.id}/online_courses`}
                                    className={cx('item')}
                                >
                                    Khóa học online
                                </a>
                            </li>
                            <li>
                                <a href={`/thong-tin-nguoi-dang/${data?.user.id}/books`} className={cx('item')}>
                                    Tài liệu
                                </a>
                            </li>
                            <li>
                                <a href={`/thong-tin-nguoi-dang/${data?.user.id}/event`} className={cx('item')}>
                                    Sự kiện
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                        <h3
                            className="text-dark fw-bold mt-4 mb-4"
                            style={{ fontFamily: 'sans-serif', fontSize: '22px' }}
                        >
                            {type == 'online_courses' && 'Tất cả khóa học online '}
                            {type == 'videos' && 'Tất cả khóa học videos '}
                            {type == 'books' && 'Tất cả tài liệu của ' + data?.user.name}
                            {type == 'event' && 'Tất cả sự kiện của ' + data?.user.name}
                        </h3>
                        <div>
                            <div className="row">
                                {type == 'videos' &&
                                    data?.resources?.data.map((item, index) => (
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                                            <div className="wow animate__animated animate__flipInY">
                                                <div className="card card-hover">
                                                    <Link
                                                        className="w-100"
                                                        to={'/khoa-hoc-video/' + slugify(item.title) + '/' + item.id}
                                                    >
                                                        <LazyLoadImage
                                                            src={item?.image ?? images.Item}
                                                            style={{ height: '144px' }}
                                                            effect="blur"
                                                            className="card-img-top"
                                                            alt="Image Alt"
                                                        />
                                                    </Link>
                                                    <div className="card-body" style={{ paddingBottom: 0 }}>
                                                        <Link
                                                            className="w-100"
                                                            to={
                                                                '/khoa-hoc-video/' + slugify(item.title) + '/' + item.id
                                                            }
                                                        >
                                                            <p
                                                                className="card-title text-dark fw-bold"
                                                                style={{
                                                                    fontFamily: 'sans-serif',
                                                                    height: '50px',
                                                                    textDecoration: 'none',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    display: '-webkit-box',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item?.title}
                                                            </p>
                                                        </Link>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                width="30"
                                                                height="30"
                                                                src={item?.owner?.image ?? images.avatarDefault}
                                                                className="border border-2 rounded-circle"
                                                                alt="..."
                                                            />
                                                            <span className="ms-2 fs-4">{item?.owner?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-footer"
                                                        style={{
                                                            background: '#ffffff',
                                                            border: 'none',
                                                            padding: '14px 10px',
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-between">
                                                            <span className="text-dark fs-5">{item?.sold} đã học</span>
                                                            <span className="text-dark fs-5">
                                                                {item?.opening_date
                                                                    ? formatDate(item?.opening_date)
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {type == 'online_courses' &&
                                    data?.resources?.data.map((item, index) => (
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                                            <div className="wow animate__animated animate__flipInY">
                                                <div className="card card-hover">
                                                    <Link
                                                        className="w-100"
                                                        to={'/khoa-hoc-online/' + slugify(item.title) + '/' + item.id}
                                                    >
                                                        <LazyLoadImage
                                                            src={item?.image ?? images.Item}
                                                            style={{ height: '144px' }}
                                                            effect="blur"
                                                            className="card-img-top"
                                                            alt="Image Alt"
                                                        />
                                                    </Link>
                                                    <div className="card-body" style={{ paddingBottom: 0 }}>
                                                        <Link
                                                            className="w-100"
                                                            to={
                                                                '/khoa-hoc-online/' +
                                                                slugify(item.title) +
                                                                '/' +
                                                                item.id
                                                            }
                                                        >
                                                            <p
                                                                className="card-title text-dark fw-bold"
                                                                style={{
                                                                    fontFamily: 'sans-serif',
                                                                    height: '50px',
                                                                    textDecoration: 'none',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    display: '-webkit-box',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item?.title}
                                                            </p>
                                                        </Link>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                width="30"
                                                                height="30"
                                                                src={item?.owner?.image ?? images.avatarDefault}
                                                                className="border border-2 rounded-circle"
                                                                alt="..."
                                                            />
                                                            <span className="ms-2 fs-4">{item?.owner?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-footer"
                                                        style={{
                                                            background: '#ffffff',
                                                            border: 'none',
                                                            padding: '14px 10px',
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-between">
                                                            <span className="text-dark fs-5">{item?.sold} đã học</span>
                                                            <span className="text-dark fs-5">
                                                                {item?.opening_date
                                                                    ? formatDate(item?.opening_date)
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {type == 'books' &&
                                    data?.resources?.data.map((item, index) => (
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                                            <div className="wow animate__animated animate__flipInY">
                                                <div className="card card-hover">
                                                    <Link
                                                        className="w-100"
                                                        to={
                                                            (item.type == 'print_book' ? '/sach-giay/' : '/sach-pdf/') +
                                                            slugify(item.name) +
                                                            '/' +
                                                            item.id
                                                        }
                                                    >
                                                        <LazyLoadImage
                                                            src={item?.image ?? images.Item}
                                                            style={{ height: '144px' }}
                                                            effect="blur"
                                                            className="card-img-top"
                                                            alt="Image Alt"
                                                        />
                                                    </Link>
                                                    <div className="card-body" style={{ paddingBottom: 0 }}>
                                                        <Link
                                                            className="w-100"
                                                            to={
                                                                (item.type == 'print_book'
                                                                    ? '/sach-giay/'
                                                                    : '/sach-pdf/') +
                                                                slugify(item.name) +
                                                                '/' +
                                                                item.id
                                                            }
                                                        >
                                                            <p
                                                                className="card-title text-dark fw-bold"
                                                                style={{
                                                                    fontFamily: 'sans-serif',
                                                                    height: '50px',
                                                                    textDecoration: 'none',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    display: '-webkit-box',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </p>
                                                        </Link>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                width="30"
                                                                height="30"
                                                                src={item?.owner?.image ?? images.avatarDefault}
                                                                className="border border-2 rounded-circle"
                                                                alt="..."
                                                            />
                                                            <span className="ms-2 fs-4">{item?.owner?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-footer"
                                                        style={{
                                                            background: '#ffffff',
                                                            border: 'none',
                                                            padding: '14px 10px',
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-between">
                                                            <span className="text-dark fs-5">{item?.sold} đã bán</span>
                                                            <span className="text-dark fs-5">
                                                                {item?.created_at ? formatDate(item?.created_at) : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {type == 'event' &&
                                    data?.resources?.data.map((item, index) => (
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                                            <div className="wow animate__animated animate__flipInY">
                                                <div className="card card-hover">
                                                    <Link
                                                        className="w-100"
                                                        to={'/su-kien/' + slugify(item.title) + '/' + item.id}
                                                    >
                                                        <LazyLoadImage
                                                            src={item?.image ?? images.Item}
                                                            style={{ height: '144px' }}
                                                            effect="blur"
                                                            className="card-img-top"
                                                            alt="Image Alt"
                                                        />
                                                    </Link>
                                                    <div className="card-body" style={{ paddingBottom: 0 }}>
                                                        <Link
                                                            className="w-100"
                                                            to={'/su-kien/' + slugify(item.title) + '/' + item.id}
                                                        >
                                                            <p
                                                                className="card-title text-dark fw-bold"
                                                                style={{
                                                                    fontFamily: 'sans-serif',
                                                                    height: '50px',
                                                                    textDecoration: 'none',
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    display: '-webkit-box',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item?.title}
                                                            </p>
                                                        </Link>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                width="30"
                                                                height="30"
                                                                src={item?.owner?.image ?? images.avatarDefault}
                                                                className="border border-2 rounded-circle"
                                                                alt="..."
                                                            />
                                                            <span className="ms-2 fs-4">{item?.owner?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-footer"
                                                        style={{
                                                            background: '#ffffff',
                                                            border: 'none',
                                                            padding: '14px 10px',
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-between">
                                                            <span className="text-dark fs-5">{item?.sold} đã học</span>
                                                            <span className="text-dark fs-5">
                                                                {item?.opening_date
                                                                    ? formatDate(item?.opening_date)
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default User;
