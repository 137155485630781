import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const receiveService = (token, formData) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/services/app/request', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                showToast('Đã đặt dịch vụ thành công!');
                return true;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const getTypeService = (token) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get(
                'api/services-types',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                dispatch({ type: 'GET_TYPE_SERVICE', payload: response.data.data });
                return true;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            return false;
        }
    };
};

export const ListReceiveService = (token) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get(
                'api/services/app/available',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                dispatch({ type: 'LIST_RECEIVE_SERVICE', payload: response.data.data });
                return true;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            return false;
        }
    };
};

export const ReceiveIdService = (token, id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get(
                'api/services/app/request/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                dispatch({ type: 'RECEIVE_ID_SERVICE', payload: response.data.data });
                return response.data.data;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            return false;
        }
    };
};
export const ReceiveIdServiceComfirm = (token, id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.post(
                'api/services/app/' + id + '/completed',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                showToast('Đã xác nhận hoàn thành dịch vụ');
                // dispatch({ type: 'RECEIVE_ID_SERVICE', payload: response.data.data });
                return response.data.data;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            return false;
        }
    };
};
export const receiveSesvice = (token, id) => {
    return async (dispatch) => {
        try {
            console.log(id);
            console.log(token);
            if (id == '' || !id) {
                return;
            }
            // Gọi API
            const response = await api.post(
                'api/services/app/take/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                // dispatch({ type: 'RECEIVE_USER_SERVICE', payload: response.data.data });
                showToast('Đã nhận dịch vụ thành công.');
                return response.data;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            return false;
        }
    };
};

export const get_history_service = (token, type, page) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get(
                'api/services/app/histories?type=' + type + '&page=' + page ?? 1,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            if (response.data.status == 200) {
                console.log(response.data.data);
                dispatch({ type: 'GET_HISTORY_SERVICE', payload: response.data });
                return true;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            return false;
        }
    };
};
