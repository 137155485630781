import { Box, Typography, Stack, Button } from '@mui/material';
import ContentLeft from './ContentLeft';
import ContentRight from './ContentRight';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import images from '~/assets/images';
import styles from './AboutUs.module.scss';

const cx = classNames.bind(styles);

const AboutUs = () => {
    return (
        <Box className={cx('AboutUs','container')}>
            <Breadcumb title="Giới thiệu" slug="Về chúng tôi" />
            <Box className={cx('content')}>
                <ContentLeft />
                <ContentRight />
            </Box>
            <Box>
                <Typography sx={{ fontSize: '16px', paddingTop: '10px' }}>
                    Công ty TNHH Viện SocialLife (17/01/2017) – hoạt động theo luật doanh nghiệp và theo định hướng phi lợi nhuận trong các lĩnh vực xuất bản, đào tạo ngắn hạn,…
                    Viện nghiên cứu Đời sống xã hội hay còn được gọi là Viện SocialLife.vn ra đời theo quyết định của Giám đốc Sở Khoa học Công nghệ Thành phố Hồ Chí Minh, hoạt động theo luật khoa học công nghệ, có tư cách pháp nhân, con dấu và tài khoản riêng (12/6/2018),
                    Hiện Viện SocialLife hoạt động 10 nhân sự chính nhiệm và liên kết mạng lưới với hơn 50 nhà khoa học có uy tín trong và ngoài nước và cũng kết ký kết hợp tác về nghiên cứu, xuất bản, truyền thông, phụng sự cộng đồng với các tổ chức: USM-Universiti Saints Malaysia; Đại học Hùng Quang (Hungkuang University), Đài Loan LIN, Viện Goethe (Liên Bang Đức), Unicef, Save The Children, Hội bảo vệ Quyền trẻ em, Chance to Grow (Liên Bang Đức), Oxfam, CFLI- Canada…
                </Typography>
            </Box>
            <img style={{ width: '100%', paddingTop: '20px' }} src={images.AboutUs} alt='' />
            <Box sx={{ paddingTop: '10px' }}>
                <Box>
                    <h2 style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>Sứ mệnh</h2>
                    <Typography sx={{ fontSize: '16px', marginBottom: '10px' }}>
                        Nghiên cứu, ứng dụng và phổ biến tri thức khoa học nhằm nêu bật các vấn đề thực tiễn xã hội, đời sống nhân sinh qua đó thúc đẩy sự tiến bộ xã hội.
                    </Typography>
                </Box>
                <Box>
                    <h2 style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>Tầm nhìn</h2>
                    <Typography sx={{ fontSize: '16px' }}>
                        Đến 2030, social life trở thành một tổ chức khoa học, giáo dục hoạt động theo tôn chỉ độc lập có uy tín trong lĩnh vực khoa học xã hội và nhân văn ở Việt Nam và khu vực Đông Nam Á.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default AboutUs;