import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import { Link } from 'react-router-dom';
import Pagination from '~/components/Pagination';
import images from '~/assets/images';
import classNames from 'classnames/bind';
import styles from './MyBookPdf.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import slugify from 'slugify';
import { mybookPdf } from '~/Store/Action/bookActions';

const cx = classNames.bind(styles);

const MyBookPdf = () => {
    const dispatch = useDispatch();

    let navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/tai-khoan/quan-ly-sach-pdf/them-moi-sach');
    };

    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    const listPdf = useSelector((state) => state?.book?.listmyBookPdf?.data);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    useEffect(() => {
        dispatch(mybookPdf(JSON.parse(auth.user)));
    }, []);

    return (
        <Box className={cx('MyBookPdf')}>
            <div className={cx('title-user')}>
                <TitleUser title="Quản lý sách pdf" />
                <Button className="wow animate__animated animate__wobble" onClick={handleRedirect}>
                    Thêm mới
                </Button>
            </div>
            <div className="row">
                {listPdf?.data?.length > 0 ? (
                    listPdf?.data?.map((item, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div className="wow animate__animated animate__flipInY">
                                <div className="card card-hover">
                                    <Link className="w-100" to={'/sach-pdf/' + slugify(item?.name) + '/' + item?.id}>
                                        <LazyLoadImage
                                            src={item?.image}
                                            style={{ height: '144px' }}
                                            effect="blur"
                                            className="card-img-top"
                                            alt="Image Alt"
                                        />
                                    </Link>
                                    <div className="card-body" style={{ paddingBottom: 0 }}>
                                        <Link
                                            className="w-100"
                                            to={'/sach-pdf/' + slugify(item?.name) + '/' + item?.id}
                                        >
                                            <p
                                                className="card-title text-dark fw-bold"
                                                style={{
                                                    fontFamily: 'sans-serif',
                                                    height: '50px',
                                                    textDecoration: 'none',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {item?.name}
                                            </p>
                                        </Link>
                                        <div className="d-flex align-items-center">
                                            <span className="fs-4" style={{ color: 'rgba(39, 171, 96, 1)' }}>
                                                Doanh thu {item?.revenue}đ
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="card-footer"
                                        style={{ background: '#ffffff', border: 'none', padding: '14px 10px' }}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <span className="text-dark fs-5">{item?.view} đã học</span>
                                            <span className="text-dark fs-5">
                                                {item?.opening_date
                                                    ? format(new Date(item?.opening_date), 'dd/M/yyyy')
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <h2>Hiện tại không có cuốn sách nào!</h2>
                )}
            </div>
        </Box>
    );
};

export default MyBookPdf;
