import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import { Link } from 'react-router-dom';
import Pagination from '~/components/Pagination';
import images from '~/assets/images';
import classNames from 'classnames/bind';
import styles from './MyEvent.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import slugify from 'slugify';
import { myEvent, delete_event } from '~/Store/Action/eventActions';

const cx = classNames.bind(styles);

const MyEvent = () => {
    let navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/tai-khoan/quan-ly-su-kien/them-moi-su-kien');
    };

    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const list = useSelector((state) => state.event.myEvent);

    const handleOpenDelete = async (id) => {
        // await dispatch(add_cart(auth.token, fieldCart));
        // dispatch(get_cart(auth.token));
        if (window.confirm('Xác nhận xoá lựa chọn này.')) {
            await dispatch(delete_event(auth.token, id));
            await dispatch(myEvent(JSON.parse(auth.user)));
        } else {
            return;
        }
    };

    const handleOpenEdit = (id) => {
        navigate(`/tai-khoan/quan-ly-khoa-hoc/sua-sua-kien/` + id);
    }

    // console.log(list);

    useEffect(() => {
        dispatch(myEvent(JSON.parse(auth.user))); // Dispatch action để lấy dữ liệu khi component được mount
    }, []);

    return (
        <Box className={cx('MyEvent')}>
            <div className={cx('title-user')}>
                <TitleUser title="Quản lý sự kiện" />
                <Button className="wow animate__animated animate__backInRight" onClick={handleRedirect}>
                    Thêm mới
                </Button>
            </div>
            <div className="row">
                {list?.data.length > 0 ? (
                    list?.data.map((item, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div className="wow animate__animated animate__flipInY">
                                <div className="card card-hover">
                                    <Link className="w-100" to={'/su-kien/' + slugify(item.title) + '/' + item.id}>
                                        <LazyLoadImage
                                            src={item?.image}
                                            style={{ height: '144px' }}
                                            effect="blur"
                                            className="card-img-top"
                                            alt="Image Alt"
                                        />
                                    </Link>
                                    <div className="card-body" style={{ paddingBottom: 0 }}>
                                        <Link className="w-100" to={'/su-kien/' + slugify(item.title) + '/' + item.id}>
                                            <p
                                                className="card-title text-dark fw-bold"
                                                style={{
                                                    fontFamily: 'sans-serif',
                                                    height: '50px',
                                                    textDecoration: 'none',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {item?.title}
                                            </p>
                                        </Link>

                                        <div className="d-flex align-items-center">
                                            {/* <span className="fs-4" style={{ color: 'rgba(39, 171, 96, 1)' }}>
                                                Doanh thu 120.000.000đ
                                            </span> */}
                                            <div>
                                                <button onClick={() => handleOpenEdit(item?.id)} type="button" className="btn btn-primary fw-bold me-2">
                                                    Sửa
                                                </button>
                                                <button
                                                    onClick={() => handleOpenDelete(item?.id)}
                                                    type="button"
                                                    className="btn btn-danger fw-bold"
                                                >
                                                    Xoá
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="card-footer"
                                        style={{ background: '#ffffff', border: 'none', padding: '14px 10px' }}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <span className="text-dark fs-5">{item?.view ? item?.view : 0} đã học</span>
                                            {/* <span className="text-dark fs-5">{item?.view} đã học</span> */}
                                            <span className="text-dark fs-5">
                                                {item?.opening_time
                                                    ? format(new Date(item?.opening_time), 'dd/M/yyyy')
                                                    : ''}
                                            </span>
                                            <span className="text-dark fs-5">
                                                {item?.created_at
                                                    ? 'Ngày tạo: ' + format(new Date(item?.created_at), 'dd/M/yyyy')
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <h2>Hiện tại không có sự kiện nào!</h2>
                )}
            </div>
        </Box>
    );
};

export default MyEvent;
