import { Box, Typography, Button, Stack } from '@mui/material';
import images from '~/assets/images';
import classNames from 'classnames/bind';
import styles from './ReviewItem.module.scss';
import StarIcon from '@mui/icons-material/Star';

const cx = classNames.bind(styles);

const ReviewItem = ({ data }) => {
    return (
        <Box className={cx('wrapper')}>
            <img
                className={cx('avatar me-2 rounded-circle border border-2')}
                width="50"
                height="50"
                src={data.owner.image}
                alt=""
            />
            <Box className={cx('item-left')}>
                <Box className={cx('item-left-top')}>
                    <Typography sx={{ fontSize: '14px' }}>{data.owner.name}</Typography>
                    <Typography sx={{ fontSize: '14px', color: '#F9AB5C' }}>10/01/2023</Typography>
                    <Box className={cx('star')}>
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                    </Box>
                </Box>
                <Typography className={cx('des')}>{data.reviews}</Typography>
                {data?.attachments.map((item, index) => (
                    <img className="me-1 border border-1" key={index} src={item.image} alt="" />
                ))}
            </Box>
        </Box>
    );
};

export default ReviewItem;
