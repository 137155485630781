import classNames from 'classnames/bind';
import styles from './ModalUser.module.scss';
import { Modal as AntdModal } from 'antd';

const cx = classNames.bind(styles);

const ModalUser = ({visible, handleCancel, children}) => {
    
    return (
        <AntdModal
            visible={visible}
            title={null}
            onCancel={handleCancel}
            className={cx('ModalUser')}
        >
            <div className={cx('ModalUser-wrapper')}>
                {children}
            </div>
        </AntdModal>
    );
}

export default ModalUser;