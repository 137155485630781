import classNames from 'classnames/bind';
import styles from './HistorySearch.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteKey } from '~/Store/Action/homeActions';
import slugify from 'slugify';

const cx = classNames.bind(styles);

function HistorySearch({ searchs, history_keyword }) {
    const dispatch = useDispatch();

    let keyword = [];

    try {
        // Try to parse the JSON string
        keyword = history_keyword ? JSON.parse(history_keyword) : [];
    } catch (error) {
        console.error('Invalid JSON string:', error);
        // If parsing fails, set keyword to an empty array
        keyword = [];
    }

    const handleRemoveItem = (index) => {
        console.log(index);
        // Create a new array without the item at the specified index
        dispatch(deleteKey(index));

        // You might want to update the localStorage here
        // localStorage.setItem('history_keyword', JSON.stringify(updatedKeyword));
    };

    return (
        // <div className={searchs?.data?.data.length > 0 ? ' d-none' : ''}>
        <div>
            {searchs?.data?.data.length > 0 ? (
                <div className={cx('HistorySearch')}>
                    {searchs &&
                        searchs?.data?.data.map((item, index) => (
                            <div className="w-100 mt-3" key={index}>
                                <div className="d-flex">
                                    {item.course_type.id == 2 ? (
                                        <Link
                                            to={'/khoa-hoc-video/' + slugify(item.title) + '/' + item.id}
                                            className="d-block"
                                        >
                                            <img width="80" className=" me-2" src={item.image} alt="Course thumbnail" />
                                        </Link>
                                    ) : (
                                        <Link
                                            to={'/khoa-hoc-online/' + slugify(item.title) + '/' + item?.id}
                                            className="d-block"
                                        >
                                            <img width="80" className=" me-2" src={item.image} alt="Course thumbnail" />
                                        </Link>
                                    )}
                                    {item.course_type.id == 2 ? (
                                        <Link
                                            to={'/khoa-hoc-video/' + slugify(item.title) + '/' + item.id}
                                            className="d-block"
                                        >
                                            <h3 className="fw-bold text-dark">{item.title}</h3>
                                        </Link>
                                    ) : (
                                        <Link
                                            to={'/khoa-hoc-online/' + slugify(item.title) + '/' + item?.id}
                                            className="d-block"
                                        >
                                            <h3 className="fw-bold text-dark">{item.title}</h3>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <div className={cx('HistorySearch')}>
                    <h2 className={cx('HistorySearch-title')}>Lịch sử tìm kiếm</h2>
                    {keyword.length > 0 &&
                        keyword
                            .slice()
                            .reverse()
                            .map((item, index) => (
                                <div className={cx('HistorySearch-content')} key={index}>
                                    <Link className={cx('HistorySearch-name')} to="">
                                        {item} {/* Use {item} to display the keyword */}
                                    </Link>
                                    <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleRemoveItem(index)} />
                                </div>
                            ))}
                </div>
            )}
        </div>
    );
}

export default HistorySearch;
