import api from './api';

export const detailCourseOnline = (id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/courses/' + id);
            dispatch({ type: 'DETAIL_COURSE_ONLINE_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'DETAIL_COURSE_ONLINE_FAILURE', payload: error.message });
        }
    };
};

export const detailCourseVideo = (id) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get('api/courses/' + id, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'DETAIL_COURSE_VIDEO_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'DETAIL_COURSE_VIDEO_FAILURE', payload: error.message });
        }
    };
};

export const checkBuyCourse = (id, token) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.get(
                'api/courses/' + id + '/is_bought',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'DETAIL_COURSE_CHECK_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'DETAIL_BOOK_FAILURE', payload: error.message });
        }
    };
};
