import { Box } from '@mui/material';
import LessonItem from '~/components/LessonItem';
import TitleUser from '~/components/TitleUser';
import Pagination from '~/components/Pagination';
import classNames from 'classnames/bind';
import styles from './MyCourse.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

const showToast = (message, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    toast.error(message, toastOptions);
};

const cx = classNames.bind(styles);

const MyCourse = () => {
    const [data, setData] = useState([]);
    const auth = useSelector((state) => state.auth);
    const [list, setList] = useState(null);

    useEffect(() => {
        fetchData(1);
    }, [auth]);

    let page = list?.current_page;
    // console.log(page);
    let totalPage = list?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            fetchData(prevPage);
        }
    };

    const fetchData = async (page = 1) => {
        try {
            const data = await axios.get(api_url + 'api/my/courses?page=' + page ?? 1, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                },
            });
            await setList(data.data.data);
            console.log(data.data.data.data);
            await setData(data.data.data.data);
            if (data.data.status != 200) {
                throw new Error(data.data.message);
            }
            return data;
        } catch (e) {
            console.log(e);
            showToast('Đã có lỗi xảy ra vui lòng thử lại');

            throw e;
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            fetchData(nextPage);
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchData(pageNumber);
    };

    return (
        <Box>
            <TitleUser title="Tất cả khóa học" />
            <Box className={cx('list')}>
                {data?.length > 0 ? (
                    <>
                        {data?.map((item, index) => (
                            <LessonItem key={index} data={item} className={cx('myCourse')} />
                        ))}
                        <Pagination
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            handlePageChange={handlePageChange}
                            page={list?.current_page}
                            totalPage={list?.total}
                        />
                    </>
                ) : (
                    <h2>Hiện tại không có khóa học nào!</h2>
                )}
            </Box>
        </Box>
    );
};

export default MyCourse;
