import { Box, Typography, Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import images from '~/assets/images';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import TitleUser from '~/components/TitleUser';
import Section from './Section';
import classNames from 'classnames/bind';
import styles from './Banking.module.scss';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import { money } from '../../Store/Action/profileActions';
import { useSelector, useDispatch } from 'react-redux';
import { get_payment } from '~/Store/Action/orderActions';

const cx = classNames.bind(styles);

const Banking = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const payment_data = useSelector((state) => state.order?.payment?.data);

    let [searchParams, setSearchParams] = useSearchParams();

    const pay = searchParams.get('t');

    const [type, setType] = useState(searchParams.get('t') ?? 'bank');
    const [amount, setAmount] = useState(0);
    const [amount1, setAmount1] = useState('');
    const [status, setStatus] = useState(false);

    useEffect(() => {
        console.log(payment_data);
        dispatch(get_payment(auth.token));
    }, []);

    useEffect(() => {
        setType(pay);

        console.log(pay);
    }, [pay]);

    const handlePaymentChange = (event) => {
        setType(event.target.value);
    };

    const handleMoneyChange = async (event) => {
        await setAmount(event.target.value);
        const formattedAmount = await formatCurrency(event.target.value);
        await setAmount1(formattedAmount);
    };

    const formatCurrency = (amount) => {
        return amount.toLocaleString('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
    };

    const onSubmit = async () => {
        setStatus(true);
        console.log(window.location.origin);
        const form = new FormData();
        form.append('amount', amount);
        form.append('platform', 'web');
        form.append('redirect_url_success', window.location.origin ?? 'http://localhost:3001/');
        form.append('redirect_url_detail', window.location.origin ?? 'http://localhost:3001/');

        const data = await dispatch(money(form, auth.token));
        if (await data) {
            const url = data?.data?.payment_url;
            if (url) {
                const width = 1000;
                const height = 600;
                const left = window.screen.width / 2 - width / 2;
                const top = window.screen.height / 2 - height / 2;
                window.open(url, '_blank');
                // const newWindow = window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
                // window.addEventListener('message', (event) => {
                //     if (event.origin !== 'http://localhost:3001/') return;
                //     let message = event.data;
                //     console.log(message);
                //     const url = new URL(message);
                //     const searchParams = url.searchParams;

                //     const vnp_ResponseCode = searchParams.get('vnp_ResponseCode');
                //     const vnp_TxnRef = searchParams.get('vnp_TxnRef');
                //     if (vnp_ResponseCode == '00') {
                //         this.$swal(
                //             'Đặt hàng!',
                //             'Bạn đã thanh toán thành công với mã đơn hàng: ' +
                //                 vnp_TxnRef +
                //                 ', tư vấn viên sẽ liên hệ lại bạn sớm nhất để xác nhận đơn hàng.',
                //             'success',
                //         );
                //     } else {
                //         this.$swal(
                //             'Đặt hàng!',
                //             'Giao dịch thất bại, bạn vui lòng thử lại hoặc liên hệ 038912038 để tư vấn',
                //             'error',
                //         );
                //     }
                //     newWindow.close();
                // });
            }
            console.log(data);
        }
    };

    return (
        <Box sx={{ paddingLeft: '10px' }}>
            <TitleUser title="Nạp tiền" />
            <Section title="Phương thức thanh toán">
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className={cx('form-radio')}
                        onChange={handlePaymentChange}
                        value={type}
                    >
                        <FormControlLabel
                            sx={{ marginRight: '200px' }}
                            value="bank"
                            control={<Radio />}
                            label="Chuyển khoản ngân hàng"
                        />
                        <FormControlLabel value="baokim" control={<Radio />} label="Thanh toán Bảo Kim" />
                    </RadioGroup>
                </FormControl>
            </Section>
            {type == 'bank' ? (
                <div>
                    <TitleUser title="Thông tin chuyển khoản" />
                    <Section title="Tên ngân hàng" copy="Sao chép">
                        <Box sx={{ display: 'flex' }} alignItems="center">
                            <Typography className={cx('name')}>
                                {payment_data?.bank?.bank_name ?? 'Loading...'}
                            </Typography>
                        </Box>
                    </Section>
                    <Section title="Số tài khoản" copy="Sao chép">
                        <Box sx={{ display: 'flex' }} alignItems="center">
                            <Typography className={cx('name')}>
                                {payment_data?.bank?.bank_account_number ?? 'Loading...'}
                            </Typography>
                        </Box>
                    </Section>
                    <Section title="Chủ tài khoản" copy="Sao chép">
                        <Box sx={{ display: 'flex' }} alignItems="center">
                            <Typography className={cx('name')}>
                                {payment_data?.bank?.bank_account_holder ?? 'Loading...'}
                            </Typography>
                        </Box>
                    </Section>
                    <Section title="Nội dung chuyển khoản" copy="Sao chép">
                        <Box sx={{ display: 'flex' }} alignItems="center">
                            <Typography className={cx('name')}>{payment_data?.description ?? 'Loading...'}</Typography>
                        </Box>
                    </Section>
                    <Typography className={cx('warning')} sx={{ fontSize: '13px' }}>
                        <GppMaybeIcon /> Lưu ý
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                        Xin vui lòng chuyển khoản theo đúng số tài khoản và nội dung hiển thị bên trên, để biết thêm
                        thông tin vui lòng liên hệ đến hotline của chúng tôi
                    </Typography>
                </div>
            ) : (
                <div>
                    <TitleUser title="Thông tin nạp tiền" />
                    <Section title="Nhập số tiền cần nạp">
                        <Box alignItems="center">
                            <input
                                style={{ width: '500px' }}
                                onChange={handleMoneyChange}
                                className="form-control mt-3 px-3 fs-3 fw-bold"
                                type="number"
                                placeholder="VD: 150000"
                                min="0"
                            />
                            {/* <span className="text-danger fw-bold">{formatCurrency(amount1)}</span> */}
                        </Box>
                    </Section>
                    <Typography className={cx('warning')} sx={{ fontSize: '13px' }}>
                        <GppMaybeIcon /> Lưu ý
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                        Xin vui lòng chuyển khoản theo đúng số tài khoản và nội dung hiển thị bên trên, để biết thêm
                        thông tin vui lòng liên hệ đến hotline của chúng tôi
                    </Typography>
                    <Button variant="contained" className={cx('pay-success')} onClick={onSubmit}>
                        {status ? (
                            <div class="spinner-border text-light my-2" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            'Xác nhận'
                        )}
                    </Button>
                </div>
            )}
        </Box>
    );
};

export default Banking;
