const initialState = {
    data: null,
    check: null,
};

const detailCourseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DETAIL_COURSE_ONLINE_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'DETAIL_COURSE_CHECK_SUCCESS':
            return {
                ...state,
                check: action.payload,
            };
        case 'DETAIL_COURSE_ONLINE_FAILURE':
            return state;
        case 'DETAIL_COURSE_VIDEO_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'DETAIL_COURSE_VIDEO_FAILURE':
            return state;
        default:
            return state;
    }
};

export default detailCourseReducer;
