import { Box, Typography, Stack, Button } from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb'
import styles from './Event.module.scss';
import AllEvent from './AllEvent';
import RegisterEvent from './RegisterEvent';
import InterestEvent from './InterestEvent';

const cx = classNames.bind(styles);

function Event() {
    return (
        <Box className={cx('Event','container')}>
            <Breadcumb title="Sự kiện" />
            <h1 className={cx('title')}>Danh sách sự kiện</h1>
            <Tabs>
                <TabList className={cx('Event-tab')}>
                    <Tab>Tất cả</Tab>
                    <Tab>Đã đăng ký</Tab>
                    <Tab>Quan tâm</Tab>
                </TabList>
                <TabPanel>
                    <AllEvent />
                </TabPanel>
                <TabPanel>
                    <RegisterEvent />
                </TabPanel>
                <TabPanel>
                    <InterestEvent />
                </TabPanel>
            </Tabs>
        </Box>
    );
}

export default Event;