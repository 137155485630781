import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Breadcumb from '~/components/Breadcrumb';
import Pagination from '~/components/Pagination';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import slugify from 'slugify';
import { category } from '~/Store/Action/categoryActions';
import { courseVideo } from '~/Store/Action/courseActions';
import { add_cart, get_cart } from '~/Store/Action/cartActions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Collapse from 'react-bootstrap/Collapse';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getExperts } from '~/Store/Action/homeActions';

const CourseVideo = () => {
    const dispatch = useDispatch();

    // console.log(listCourseVideo.data.current_page);
    const list = useSelector((state) => state.category.list);

    const experts = useSelector((state) => state.home.expert);

    let page = experts?.data?.current_page;
    // console.log(page);
    let totalPage = experts?.data?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);
    const [user, setUser] = useState(null);

    const auth = useSelector((state) => state.auth);

    const [open, setOpen] = useState(window.innerWidth > 800);

    useEffect(() => {
        const handleResize = () => {
            setOpen(window.innerWidth > 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(courseVideo(prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(courseVideo(nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        await setCurrentPage(pageNumber);
        const params = {
            specialize: checkedItems.join(','),
        };

        await dispatch(getExperts(pageNumber ?? 1, params));
    };

    const [checkedItems, setCheckedItems] = useState([]);

    const handleCheckboxChange = async (event, itemId, text) => {
        if (event.target.checked) {
            await setCheckedItems((prevCheckedItems) => [...prevCheckedItems, text]);
        } else {
            await setCheckedItems((prevCheckedItems) => prevCheckedItems.filter((id) => id !== text));
        }

        const updatedCheckedItems = event.target.checked
            ? [...checkedItems, text]
            : checkedItems.filter((id) => id !== text);

        const params = {
            specialize: updatedCheckedItems.join(','),
        };

        console.log(params);

        dispatch(getExperts(currentPage ?? 1, params));
    };

    const handleFilter = async () => {
        const params = {
            specialize: checkedItems.join(','),
        };

        await dispatch(getExperts(currentPage ?? 1, params));
    };

    useEffect(() => {
        dispatch(category());
        dispatch(getExperts(1));
    }, []);

    const formatDate = (dateString) => {
        if (!dateString || dateString === '0000-00-00 00:00:00') {
            return '';
        }

        const year = parseInt(dateString.substring(0, 4), 10);
        const month = parseInt(dateString.substring(5, 7), 10) - 1; // Month is 0-based in JavaScript
        const day = parseInt(dateString.substring(8, 10), 10);
        const hour = parseInt(dateString.substring(11, 13), 10);
        const minute = parseInt(dateString.substring(14, 16), 10);
        const second = parseInt(dateString.substring(17, 19), 10);

        const dateObj = new Date(year, month, day, hour, minute, second);

        return format(dateObj, 'dd/M/yyyy');
    };

    return (
        <div className="container">
            <Breadcumb title="Kết nối chuyên gia" />

            <div className="row mt-3">
                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="mt-4">
                        <h3
                            className="text-dark fw-bold c-s"
                            style={{ fontFamily: 'sans-serif', marginBottom: '20px' }}
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            Lọc chuyên ngành
                            <FontAwesomeIcon className="ms-3" icon={faAngleDown} />
                        </h3>
                        <div>
                            <Collapse in={open}>
                                <div id="example-collapse-text">
                                    {list?.data?.specializations.map((item, index) => (
                                        <div className="form-check mb-4" key={index}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${item.id}`}
                                                onChange={(event) => handleCheckboxChange(event, item.id, item.name)}
                                                checked={checkedItems.includes(item.name)}
                                            />
                                            <label
                                                style={{ cursor: 'pointer' }}
                                                htmlFor={`checkbox-${item.id}`}
                                                className="form-check-label text-dark fw-bold"
                                            >
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <button
                                className="btn border rounded-pill w-100 bg-main btn-filter"
                                onClick={() => handleFilter()}
                            >
                                Lọc ngay
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                    <h3 className="text-dark fw-bold mt-4 mb-4" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                        Kết nối chuyên gia
                    </h3>
                    <div>
                        <div className="row">
                            {experts?.data?.data?.length > 0 ? (
                                <>
                                    {experts &&
                                        experts?.data?.data?.map((item, index) => (
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-4">
                                                <a
                                                    href={'/user/' + item.id}
                                                    titlt={item.name}
                                                    className="d-block a-experts overflow-hidden rounded-3 wow animate__animated animate__flipInY"
                                                >
                                                    <div className="card pt-2 pb-3 border border-white">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-center align-items-center w-100">
                                                                <div className="rounded-circle border border-2 overflow-hidden img-experts">
                                                                    <img
                                                                        className="img-thumbnail"
                                                                        alt={item.name}
                                                                        src={item.image ?? images.avatarDefault}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="text-center mt-3">
                                                                <h3 className="font-family-system-ui">
                                                                    {item.name ?? 'Chưa cập nhật'}
                                                                </h3>
                                                            </div>
                                                            <div className="text-center">
                                                                <h4>
                                                                    {item.account_type !== 'EXPERT'
                                                                        ? 'Đối tác'
                                                                        : ' Chuyên gia'}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    <Pagination
                                        handlePrevPage={handlePrevPage}
                                        handleNextPage={handleNextPage}
                                        handlePageChange={handlePageChange}
                                        page={experts?.data?.current_page}
                                        totalPage={experts?.data?.total}
                                    />
                                </>
                            ) : (
                                <h2>Danh sách trống</h2>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseVideo;
