import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TitleUser from '~/components/TitleUser';
import 'react-tabs/style/react-tabs.css';
import classNames from 'classnames/bind';
import OrderXu from '~/components/HistoryEvaluate/OrderXu';
import OrderVNĐ from '~/components/HistoryEvaluate/OrderVNĐ';
import styles from './HistoryEvaluate.module.scss';
import { history_evaluate } from '~/Store/Action/historyActions';

const cx = classNames.bind(styles);

function HistoryEvaluate() {
    const [user, setUser] = useState(null);
    const [tabIndex, setTabIndex] = useState(1);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const list = useSelector(state => state.history.listEvaluate?.data);
    
    const handleTab = (tab) => {
        setTabIndex(tab)
    }

    let currency = "coin";

    if(tabIndex === 1){
        currency = "coin";
    }else{
        currency = "vnd";
    }

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(history_evaluate(auth.token, currency));
    }, [auth, tabIndex]);
    return (
        <Box className={cx('HistoryEvaluate','container')}>
            <TitleUser title="Lịch sử giao dịch" />
            <Tabs>
                <TabList className={cx('HistoryEvaluate-tab')}>
                    <Tab onClick={() => handleTab(1)}>Giao dịch xu</Tab>
                    <Tab onClick={() => handleTab(2)}>Giao dịch VNĐ</Tab>
                </TabList>
                <TabPanel>
                    <OrderXu list = {list} />
                </TabPanel>
                <TabPanel>
                    <OrderVNĐ list = {list} />
                </TabPanel>
            </Tabs>
        </Box>
    );
}

export default HistoryEvaluate;