import { Box, Typography, Grid } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './ListProduct.module.scss';
import images from "~/assets/images";

const cx = classNames.bind(styles);

function ListProduct({ list }) {
    return (
        <Box className={cx('ListProduct','container')}>
            {list?.length > 0 ? (
                list?.map((item, index) => (
                    <Box key={index} className={cx('item')}>
                        <img style={{ width: '162px', height: '83px' }} src={item?.book?.image || item?.course?.image} alt="" />
                        <Box className={cx('content')}>
                            <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '16px' }}>{item?.book?.name || item?.course?.title}</Typography>
                            <Box className={cx('price')}>
                                <Typography sx={{ color: '#F9AB5C', fontSize: '16px' }}>{item?.order_price} xu</Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>x{item?.quantity}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ))
            ) : (
                <h2>Hiện tại không có sản phẩm nào</h2>
            )}
        </Box>
    );
}

export default ListProduct;