const initialState = {
    listCourseOnline: null,
    listCourseVideo: null,
    listMyCourseVideo: null,
    listMyCourseOnline: null,
};

const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COURSE_ONLINE_SUCCESS':
            return {
                ...state,
                listCourseOnline: action.payload,
            };
        case 'COURSE_ONLINE_FAILURE':
            return state;
        case 'COURSE_VIDEO_SUCCESS':
            return {
                ...state,
                listCourseVideo: action.payload,
            };
        case 'MY_COURSE_VIDEO_SUCCESS':
            return {
                ...state,
                listMyCourseVideo: action.payload,
            };
        case 'MY_COURSE_ONLINE_SUCCESS':
            return {
                ...state,
                listMyCourseOnline: action.payload,
            };
        case 'COURSE_VIDEO_FAILURE':
            return state;
        case 'RATING_SUCCESS':
            return {
                ...state,
                rating: action.payload,
            };
        case 'RATING_FAILURE':
            return state;
        default:
            return state;
    }
};

export default courseReducer;
