import { Box, Typography, Stack, Button } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './TitleUser.module.scss';

const cx = classNames.bind(styles);

const TitleUser = ({ title, className }) => {
    return (
        <Box className={cx('title', className)}>{title}</Box>
    );
};

export default TitleUser;
