import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Stack,
} from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import styles from './AddCourseOnline.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { add_course_online } from '~/Store/Action/courseActions';
import { useNavigate } from 'react-router-dom';
import { category } from '~/Store/Action/categoryActions';
import SunEditor from 'suneditor-react';

const cx = classNames.bind(styles);

function AddCourseOnline() {
    const [img, setImg] = useState(null);
    const [img1, setImg1] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const listCategory = useSelector((state) => state.category.list);

    const [textColor, setTextColor] = useState('black');
    const [backgroundColor, setBackgroundColor] = useState('white');

    const handleTextColorChange = (event) => {
        setTextColor(event.target.value);
    };

    const handleBackgroundColorChange = (event) => {
        setBackgroundColor(event.target.value);
    };

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(category());
    }, []);

    const onAddCourseOnline = async (event) => {
        event.preventDefault();
        var formData = new FormData(event.target);

        // xem trong form có những gì
        for (const [key, value] of formData) {
            console.log(`${key}:`, value);
        }

        if (await dispatch(add_course_online(auth.token, formData))) {
            navigate(`/tai-khoan/quan-ly-khoa-hoc-online`);
        }
    };

    const handleImg = (event) => {
        const files = event.target.files;
        console.log(files);
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImg(image);
    };

    const handleImg1 = (event) => {
        const files = event.target.files;
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImg1(image);
    };

    return (
        <Box className={cx('AddCourseOnline', 'container')}>
            <Box py="10px" className={cx('Breadcumb')}>
                <Typography sx={{ paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/">Trang chủ</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khoa-hoc-online">Tài khoản</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khoa-hoc-online">Quản lý chuyên gia đối tác</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="/tai-khoan/quan-ly-khoa-hoc-online">Quản lý khóa học online</Link>
                </Typography>
                ›
                <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                    <Link to="#">Thêm mới khóa học online</Link>
                </Typography>
            </Box>
            <form id="form_event" method="post" enctype="multipart/form-data" onSubmit={onAddCourseOnline}>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ flexGrow: 1 }}
                    className={cx('wrapper')}
                >
                    <Grid pb={2} pr={2} xs={6}>
                        <TitleUser className={cx('title-wp')} title="Thêm mới khóa học online" />
                        <Box
                            className={cx('content-wp') + ' wpp'}
                            style={{
                                backgroundImage: `url(${img})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                        >
                            <label className={cx('content')} htmlFor="upload-img-btn">
                                <TextField
                                    label="Chọn ảnh"
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    onChange={(event) => handleImg(event)}
                                    style={{ display: 'none' }}
                                    id="upload-img-btn"
                                />
                                <Box className={cx('wp-file')}>
                                    <img src={images.camera} alt="chọn ảnh" />
                                </Box>
                            </label>
                            <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>Tải ảnh lên (4 x 2)</Typography>
                        </Box>
                    </Grid>
                    <Grid pt={8} pb={2} pr={2} xs={6} className="wow animate__animated animate__zoomInDown">
                        <FormControl sx={{ paddingTop: '10px' }} fullWidth>
                            <Stack spacing={2}>
                                <input name="course_type_id" value="1" type="hidden" />
                                <TextField
                                    label="Tiêu đề"
                                    variant="outlined"
                                    name="title"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Tên giảng viên"
                                    variant="outlined"
                                    name="teacher_name"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <Box
                                    className={cx('content-wp1') + ' wpp'}
                                    style={{
                                        backgroundImage: `url(${img1})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    <label className={cx('content')} htmlFor="upload-img-btn1">
                                        <TextField
                                            label="Ảnh giảng viên"
                                            type="file"
                                            accept="image/*"
                                            name="img_teacher"
                                            onChange={(event) => handleImg1(event)}
                                            style={{ display: 'none' }}
                                            id="upload-img-btn1"
                                        />
                                        <Box className={cx('wp-file')}>
                                            <img src={images.camera} alt="chọn ảnh" />
                                        </Box>
                                    </label>
                                    <Typography sx={{ fontSize: '16px', padding: '10px 0px' }}>
                                        Tải ảnh giảng viên
                                    </Typography>
                                </Box>
                                <FormControl fullWidth>
                                    <InputLabel
                                        sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                        id="demo-simple-select-label"
                                    >
                                        Chọn danh mục
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={age}
                                        name="course_category_id"
                                        label="Chọn danh mục"
                                        // onChange={handleChange}
                                        sx={{
                                            '& .MuiSelect-root': {
                                                color: 'blue',
                                            },
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                padding: '14px',
                                                background: '#fff',
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-3%',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                //   borderColor: 'orange',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                span: {
                                                    fontSize: '12px',
                                                },
                                            },
                                        }}
                                    >
                                        {listCategory?.data?.course_categories.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Tiền (vnđ)"
                                    variant="outlined"
                                    name="price"
                                    placeholder="100000"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Tiền sau giảm giá (vnđ)"
                                    variant="outlined"
                                    name="discount_price"
                                    placeholder="80000"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Tiền xu"
                                    name="coin_price"
                                    variant="outlined"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Tiền sau giảm giá xu (xu)"
                                    variant="outlined"
                                    name="discount_coin_price"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel
                                        sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                        id="demo-simple-select-label"
                                    >
                                        Chọn chuyên nghành
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="specialization_id"
                                        label="Chọn chuyên nghành"
                                        sx={{
                                            '& .MuiSelect-root': {
                                                color: 'blue',
                                            },
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                padding: '14px',
                                                display: 'flex',
                                                alignContent: 'center',
                                                background: '#fff',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-3%',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                //   borderColor: 'orange',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                span: {
                                                    fontSize: '12px',
                                                },
                                            },
                                        }}
                                    >
                                        {listCategory?.data?.specializations.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel
                                        sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                        id="demo-simple-select-label"
                                    >
                                        Ứng dụng
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="platform"
                                        label="Ứng dụng"
                                        sx={{
                                            '& .MuiSelect-root': {
                                                color: 'blue',
                                            },
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                padding: '14px',
                                                display: 'flex',
                                                alignContent: 'center',
                                                background: '#fff',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#333',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                top: '-3%',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {},
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                span: {
                                                    fontSize: '12px',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="Google Meet">Google Meet</MenuItem>
                                        <MenuItem value="Zoom">Zoom</MenuItem>
                                        <MenuItem value="Microsoft Teams">Microsoft Teams</MenuItem>
                                        <MenuItem value="Nền tảng khác">Nền tảng khác</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Mật khẩu tham gia"
                                    variant="outlined"
                                    name="passcode"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Link tham gia"
                                    variant="outlined"
                                    name="link"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                />
                                {/* <TextField
                                    label="Mô tả"
                                    variant="outlined"
                                    name="description"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            background: '#fff',
                                            fontWeight: 'bold',
                                            padding: '11.5px 14px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-10%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                fontSize: '16px',
                                            },
                                        },
                                    }}
                                /> */}

                                <SunEditor
                                    name="description"
                                    defaultValue="Mô tả"
                                    height="500"
                                    setOptions={{
                                        buttonList: [
                                            ['undo', 'redo'],
                                            ['font', 'fontSize', 'formatBlock'],
                                            // ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['bold', 'underline', 'italic', 'strike'],
                                            ['removeFormat'],
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'table'],
                                            ['fontColor', 'hiliteColor'],
                                            ['link', 'image'],
                                            ['fullScreen', 'showBlocks', 'codeView'],
                                            // ['preview', 'print'],
                                        ],
                                    }}
                                />
                            </Stack>
                        </FormControl>
                    </Grid>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button className={cx('btn_submit')} type="submit" variant="contained">
                            THÊM MỚI
                        </Button>
                    </div>
                </Grid>
            </form>
        </Box>
    );
}

export default AddCourseOnline;
