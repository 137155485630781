import { FormControl, TextField, Stack, MenuItem, InputLabel, Select, Button } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './FormInput.module.scss';
import images from '~/assets/images';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { receiveService } from '~/Store/Action/serviceAction';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);
const FormInput = () => {
    const dispatch = useDispatch();
    const [fileText, setFileText] = useState('');
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);
    const types = useSelector((state) => state.service.types);

    const handleFile = (event) => {
        const files = event.target.files;
        setFileText(files[0].name);
    };

    const onReceiveServive = async (event) => {
        event.preventDefault();
        var formData = new FormData(event.target);
        if (await dispatch(receiveService(auth.token, formData))) {
            event.target.reset();
        }
    }

    return (
        <form id="form_service" method="post" enctype="multipart/form-data" onSubmit={onReceiveServive}>
            <FormControl sx={{ paddingTop: '10px' }} fullWidth>
                <Stack spacing={2}>
                    <TextField
                        label="Họ và tên"
                        name="user_name"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Số điện thoại"
                        name="user_phone_number"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                        <TextField
                        label="Email"
                        name="email"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                        <TextField
                        label="Địa chỉ"
                        name="address"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Đơn vị công tác"
                        name="organization"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }} id="demo-simple-select-label">Loại dịch vụ</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="service_id"
                            label="Loại dịch vụ"
                            // onChange={handleChange}
                            sx={{
                                '& .MuiSelect-root': {
                                    color: 'blue',
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    padding: '14px',
                                    display: 'flex',
                                    alignItems: 'center'
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-3%'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                //   borderColor: 'orange',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    'span': {
                                        fontSize: '12px',
                                    },
                                },
                            }}
                        >
                            {types?.map((item, index) => (
                                <MenuItem value={item?.id}>{item?.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Thời gian mong muốn hoàn thành"
                        name="estimate_complete_date"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }} id="demo-simple-select-label">Loại tiền thanh toán</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={age}
                            name="payment_currency"
                            label="Loại tiền thanh toán"
                            // onChange={handleChange}
                            sx={{
                                '& .MuiSelect-root': {
                                    color: 'blue',
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    padding: '14px',
                                    display: 'flex',
                                    alignItems: 'center'
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-3%'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                //   borderColor: 'orange',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    'span': {
                                        fontSize: '12px',
                                    },
                                },
                            }}
                        >
                            <MenuItem value="vnd">VND</MenuItem>
                            <MenuItem value="coin">COIN</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Mô tả dịch vụ"
                        name="description"
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <input onChange={(event) => handleFile(event)} name="attachments[]" id="upload-img-btn" style={{ display: 'none' }} type="file" />
                    <label className={cx('file-upload')} htmlFor="upload-img-btn">
                        <span>{fileText ? fileText : "File đính kèm"}</span>
                        <div className={cx('upload')}>
                            <img src={images.Upload} alt="upload file" />
                        </div>
                    </label>
                </Stack>
                <Button className={cx('btn_submit')} type="submit" variant='contained'>GỬI YÊU CẦU</Button>
            </FormControl>
        </form>
    );
}

export default FormInput;