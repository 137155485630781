import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './Breadcumb.module.scss';
import slugify from 'slugify';

const cx = classNames.bind(styles);

const Breadcumb = ({ title, slug }) => {
    return (
        <Box py="10px" className={cx('Breadcumb')}>
            <Typography sx={{ paddingRight: '10px' }} className={cx('title')}>
                <Link to="/">Trang chủ</Link>
            </Typography>{' '}
            ›
            <Typography sx={{ paddingLeft: '10px', paddingRight: '10px' }} className={cx('title')}>
                <Link to={'/'+slugify(title)}>{title}</Link>
            </Typography>{' '}
            {slug && (
                <>
                    {' '}
                    ›
                    <Typography sx={{ paddingLeft: '10px' }} className={cx('title')}>
                        <Link to="">{slug}</Link>
                    </Typography>
                </>
            )}
        </Box>
    );
};

export default Breadcumb;
