import classNames from 'classnames/bind';
import styles from './SignAuth.module.scss';
import { Form, Input, Button, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { EKeyStateModalAuth } from '~/components/ModalAuth/ModalAuth.enums';
import SignInForm from '../SignInForm';
import SignUpForm from '../SignUpForm';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import ForgotPasswordForm from '../ForgotPasswordForm';
import VerifyOtpForm from '../VerifyOtpForm';
import ChangePasswordForm from '../ChangePasswordForm';
import { faArrowLeft, faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { auth_gg } from '../../../Store/Action/authActions';

const cx = classNames.bind(styles);

function SignAuth({ modalAuthState, handleCancel }) {
    const dispatch = useDispatch();

    const responseMessage = (response) => {
        console.log('success', response);
    };
    const errorMessage = (error) => {
        console.log('error', error);
    };

    const [tabIndex, setTabIndex] = useState(2);
    const [num, setNum] = useState();

    const [activeAuth, setActiveAuth] = useState(1);

    const [stateModalAuth, setStateModalAuth] = useState({
        key: EKeyStateModalAuth.SIGN_IN,
    });

    const handleTabAuth = (tab, active) => {
        setTabIndex(tab);
        setActiveAuth(active);
    };

    const handleChangeStateModalAuth = (tab, tabNum = null) => {
        setTabIndex(tab);
        setNum(tabNum);
    };

    const handleBack = (tab) => {
        setTabIndex(tabIndex - 1);
    };

    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState([]);

    const check = async (mess) => {
        console.log(mess);
        
        if(await dispatch(auth_gg(mess))) {
            await setTimeout(function () {
                window.location.reload();
            }, 2000);
        }
        // setUser(mess);
    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => check(codeResponse),
        onError: (error) => console.log('Login Failed:', error),
    });

    return (
        <>
            {modalAuthState == 3 ||
                (tabIndex == 3 && (
                    <div className={cx('header-modal-back')}>
                        <Button className={cx('btn-back')} onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <h4>Quên mật khẩu</h4>
                        <FontAwesomeIcon className={cx('close')} icon={faClose} />
                    </div>
                ))}
            {modalAuthState == 4 ||
                (tabIndex == 4 && (
                    <div className={cx('header-modal-back')}>
                        <Button className={cx('btn-back')} onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <h4>Xác thực OTP</h4>
                        <FontAwesomeIcon className={cx('close')} icon={faClose} />
                    </div>
                ))}
            {modalAuthState == 5 ||
                (tabIndex == 5 && (
                    <div className={cx('header-modal-back')}>
                        <Button className={cx('btn-back')} onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <h4>Quên mật khẩu</h4>
                        <FontAwesomeIcon className={cx('close')} icon={faClose} />
                    </div>
                ))}
            <div className={cx('SignAuth')}>
                <div className={cx('bg-content')}>
                    {modalAuthState === 1 ||
                        (tabIndex == 1 && (
                            <>
                                <div className={cx('SignAuth-button')}>
                                    <button
                                        style={{ color: activeAuth === 1 ? '#fff' : '' }}
                                        onClick={() => handleTabAuth(EKeyStateModalAuth.SIGN_UP, 1)}
                                    >
                                        Đăng nhập
                                    </button>
                                    <button
                                        style={{ color: activeAuth === 2 ? '#fff' : '' }}
                                        onClick={() => handleTabAuth(EKeyStateModalAuth.SIGN_IN, 2)}
                                    >
                                        Đăng ký
                                    </button>
                                </div>
                            </>
                        ))}
                    {modalAuthState === 2 ||
                        (tabIndex == 2 && (
                            <>
                                <div className={cx('SignAuth-button')}>
                                    <button
                                        style={{ color: activeAuth === 1 ? '#fff' : '' }}
                                        onClick={() => handleTabAuth(EKeyStateModalAuth.SIGN_UP, 1)}
                                    >
                                        Đăng nhập
                                    </button>
                                    <button
                                        style={{ color: activeAuth === 2 ? '#fff' : '' }}
                                        onClick={() => handleTabAuth(EKeyStateModalAuth.SIGN_IN, 2)}
                                    >
                                        Đăng ký
                                    </button>
                                </div>
                            </>
                        ))}
                    <div className={cx('content-form')}>
                        {modalAuthState === 1 ||
                            (tabIndex == 1 && (
                                <SignUpForm
                                    onAuthForgotSuccess={() =>
                                        handleChangeStateModalAuth(EKeyStateModalAuth.VERIFY_OTP_FORGOT, 1)
                                    }
                                    handleCancel={handleCancel}
                                />
                            ))}
                        {modalAuthState === 2 ||
                            (tabIndex == 2 && (
                                <SignInForm
                                    onClickForgotPassword={() =>
                                        handleChangeStateModalAuth(EKeyStateModalAuth.FORGOT_PASSWORD)
                                    }
                                    handleCancel={handleCancel}
                                />
                            ))}
                        {modalAuthState === 3 ||
                            (tabIndex == 3 && (
                                <ForgotPasswordForm
                                    onAuthForgotSuccess={() =>
                                        handleChangeStateModalAuth(EKeyStateModalAuth.VERIFY_OTP_FORGOT)
                                    }
                                />
                            ))}
                        {modalAuthState === 4 ||
                            (tabIndex == 4 && (
                                <VerifyOtpForm
                                    num={num}
                                    onClickSignIn={() => handleChangeStateModalAuth(EKeyStateModalAuth.SIGN_IN)}
                                    onClickSignUp={() => handleChangeStateModalAuth(EKeyStateModalAuth.SIGN_UP)}
                                    onVerifyForgotSuccess={() =>
                                        handleChangeStateModalAuth(EKeyStateModalAuth.CHANGE_PASSWORD)
                                    }
                                />
                            ))}
                        {modalAuthState === 5 || (tabIndex == 5 && <ChangePasswordForm handleCancel={handleCancel} />)}
                    </div>
                    {modalAuthState === 1 ||
                        (tabIndex == 1 && (
                            <>
                                <div className={cx('login-choose')}>
                                    <span>Hoặc</span>
                                    <span>Đăng nhập bằng</span>
                                </div>
                                {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                                <div className={cx('login-choose-icon')}>
                                    {/* <span>
                                        <FontAwesomeIcon icon={faFacebook} /> Facebook
                                    </span> */}
                                    <span onClick={() => login()}>
                                        <FontAwesomeIcon icon={faGoogle} /> Google
                                    </span>
                                </div>
                            </>
                        ))}
                    {modalAuthState === 2 ||
                        (tabIndex == 2 && (
                            <>
                                <div className={cx('login-choose')}>
                                    <span>Hoặc</span>
                                    <span>Đăng nhập bằng</span>
                                </div>
                                {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                                <div className={cx('login-choose-icon')}>
                                    {/* <span>
                                        <FontAwesomeIcon icon={faFacebook} /> Facebook
                                    </span> */}
                                    <span onClick={() => login()}>
                                        <FontAwesomeIcon icon={faGoogle} /> Google
                                    </span>
                                </div>
                            </>
                        ))}
                </div>
            </div>
        </>
    );
}

export default SignAuth;
