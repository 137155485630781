import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './SignUpForm.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../../Store/Action/authActions.js';
import { Stack, TextField, FormGroup, Button, Select, Input, FormControl, InputLabel, MenuItem } from '@mui/material';

const cx = classNames.bind(styles);

const SignUpForm = ({ handleCancel, onAuthForgotSuccess }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [account, setAccount] = useState('');

    const handleNameChange = e => {
        setName(e.target.value)
    }

    const handleEmailChange = e => {
        setEmail(e.target.value)
    }

    const handlePasswordConfirmChange = e => {
        setPasswordConfirm(e.target.value)
    }

    const handlePasswordChange = e => {
        setPassword(e.target.value)
    }

    const handleAccountChange = e => {
        setAccount(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const onRegister = await dispatch(register(name,email, password,password_confirm, account));
        if (onRegister) {
            setName('');
            setEmail('');
            setPassword('');
            setPasswordConfirm('');
            setAccount('');
            // handleCancel();
            localStorage.setItem('email', email);
            onAuthForgotSuccess();
        }
    };

    return (
        <>
            <p>Xin vui lòng đăng ký tài khoản của bạn để trải nghiệm những dịch vụ tốt nhất của chúng tôi</p>
            <Stack spacing={2}>
                <TextField 
                    className={cx('input-change')} 
                    fullWidth id="fullname" 
                    label="Tên của bạn"
                    value={name}
                    onChange={handleNameChange}
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%',
                        },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '40px',

                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <TextField 
                    className={cx('input-change')} 
                    fullWidth 
                    id="email" 
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '40px',
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }} 
                />
                <TextField 
                    className={cx('input-change')} 
                    fullWidth
                    type="password" 
                    id="password" 
                    label="Mật khẩu"
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '40px',
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <TextField 
                    className={cx('input-change')} 
                    fullWidth
                    type="password"
                    id="re-password" 
                    label="Nhập lại mật khẩu"
                    value={password_confirm}
                    onChange={handlePasswordConfirmChange}
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '11.5px 14px'
                        },
                        '& .MuiInputLabel-root': {
                            fontWeight: 'bold',
                            fontSize: '16px',
                            top: '-10%'
                        },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '40px',
                            '& fieldset': {
                                fontSize: '16px',
                            },
                        },
                    }}
                />
                <select onChange={handleAccountChange} style={{ padding: '13px', borderRadius: '40px' }}>
                    <option>Loại tài khoản</option>
                    <option value="CUSTOMER">Tài khoản khách hàng</option>
                    <option value="CUSTOMER">Tài khoản đối tác</option>
                    <option value="CUSTOMER">Tài khoản chuyên gia</option>
                </select>
                <Button onClick={handleSubmit} className={cx('button-change')} variant="contained">ĐĂNG KÝ</Button>
            </Stack>
        </>
    );
}

export default SignUpForm;