import classNames from 'classnames/bind';
import styles from './Footer.module.scss';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import Copyright from '../Copyright';
import { getTypeService } from '~/Store/Action/serviceAction';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const cx = classNames.bind(styles);

function Footer() {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const types = useSelector((state) => state.service.types);

    // console.log(types);

    useEffect(() => {
        dispatch(getTypeService(auth.token));
    }, [auth]);

    return (
        <div className={cx('wrapper', 'mt-5')}>
            <div className={cx('wrapper-bottom')}>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-3">
                            <h3 className="text-dark fw-bolder" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                                Giới thiệu
                            </h3>
                            <ul className="mt-4">
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/lich-su-hinh-thanh">Lịch sử hình thành</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/tam-nhin-su-mang">Tầm nhìn - sứ mạng</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/ton-chi-hoat-dong">Tôn chỉ hoạt động</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/bo-quy-tac-dao-duc">Bộ quy tắc đạo đức</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/cam-ket-trach-nghiem-xa-hoi">Cam kết trách nhiệm xã hội</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/linh-vuc-hoat-dong">Lĩnh vực hoạt động</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-3">
                            <h3 className="text-dark fw-bolder" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                                Về chúng tôi
                            </h3>
                            <ul className="mt-4">
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/ve-chung-toi">Về chúng tôi</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/dieu-khoan-chinh-sach">Điều khoản và chính sách</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/chinh-sach-bao-mat">Chính sách bảo mật</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/chinh-sach-van-chuyen">Chính sách vận chuyển</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/chinh-sach-doi-tra">Chính sách đổi trả</Link>
                                </li>
                                <li className={cx('item')}>
                                    <Link to="/gioi-thieu/phuong-thuc-thanh-toan">Phương thức thanh toán</Link>
                                </li>
                                {/* <li className={cx('item')}>
                                    <Link to="/">Chính sách sử dụng</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-3">
                            <h3 className="text-dark fw-bolder" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                                Dịch vụ
                            </h3>
                            <ul className="mt-4">
                                {types?.map((item, index) => (
                                    <li className={cx('item')} key={index}>
                                        <Link to="/tai-khoan/dich-vu">{item.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-3">
                            <h3 className="text-dark fw-bolder" style={{ fontFamily: 'sans-serif', fontSize: '22px' }}>
                                Phương thức thanh toán
                            </h3>
                            <div className="mt-2 mb-4 d-flex align-items-center">
                                <Link className="d-block w-100" to="/tai-khoan/nap-tien?t=bank">
                                    <img className="w-100" src={images.footerPayment} />
                                </Link>
                                <Link className="d-block w-100" to="/tai-khoan/nap-tien?t=baokim">
                                    <img className="w-100" src={images.footerPayment2} />
                                </Link>
                            </div>
                            <h3 className={cx('download')}>Tải ứng dụng</h3>
                            <div className="row">
                                <div className="col-6">
                                    <Link className="d-block w-100" to="/">
                                        <img className="w-100" src={images.qr} />
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <a
                                        className="d-block w-100"
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.social.sociallife"
                                    >
                                        <img className="w-100" src={images.googlePlay} />
                                    </a>
                                    <a
                                        className="d-block w-100 mt-4"
                                        target="_blank"
                                        href="https://apps.apple.com/vn/app/social-life/id6449656491?l=vi"
                                    >
                                        <img className="w-100" src={images.appStore} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Copyright />
        </div>
    );
}

export default Footer;
