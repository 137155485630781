import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { city, cty, getDistrict, getWard } from '~/Store/Action/addressActions';
import { FormControl, TextField, Stack, MenuItem, InputLabel, Select, Button, Grid } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './FormInput.module.scss';

const cx = classNames.bind(styles);
const FormInput = ({ user }) => {
    const user_data = user;
    const dispatch = useDispatch();
    const list = useSelector((state) => state.address.city);
    const district_data = useSelector((state) => state.address.district);
    const [province, setProvince] = useState(user?.province || '');
    const [district, setDistrict] = useState(user?.district || '');
    const [ward, setWard] = useState(user?.ward || '');

    const ward_data = useSelector((state) => state.address.ward);

    useEffect(() => {
        // dispatch(district());
        async function load() {
            const cityList = await dispatch(city());

            if (cityList) {
                console.log(user_data);
                const city = await cityList.find((city) => city.PROVINCE_NAME === user_data?.province);

                const p = await dispatch(getDistrict(city.PROVINCE_ID));
                console.log(p);
                const d = await p.find((x) => x.DISTRICT_NAME === user_data?.district);
                console.log(d);

                await dispatch(getWard(d.DISTRICT_ID));
            }
        }
        load();
    }, []);

    const handleProvinceChange = (e) => {
        const name = e.target.value;
        console.log(name);
        setProvince(e.target.value);

        const city = list?.data?.find((city) => city.PROVINCE_NAME === name);
        console.log(city);
        setWard('');

        dispatch(getDistrict(city.PROVINCE_ID));
    };

    const handleDistrictChange = (e) => {
        const name = e.target.value;
        console.log(name);
        setDistrict(e.target.value);

        const d = district_data?.data?.find((x) => x.DISTRICT_NAME === name);
        console.log(d);
        setWard('');

        dispatch(getWard(d.DISTRICT_ID));
    };

    const handleWardChange = (e) => {
        setWard(e.target.value);
    };

    return (
        <FormControl sx={{ paddingTop: '10px' }} fullWidth>
            <Stack spacing={2}>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ flexGrow: 1 }}
                    className={cx('wrapper')}
                >
                    <Grid className={cx('item-wp')} pb={2} pr={2} item xs={12} sm={12} md={6}>
                        <Stack spacing={2}>
                            <TextField
                                fullWidth
                                label="Họ và tên"
                                variant="outlined"
                                defaultValue={user?.name}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        padding: '11.5px 14px',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-10%',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '40px',
                                        '& fieldset': {
                                            fontSize: '16px',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Số điện thoại"
                                variant="outlined"
                                defaultValue={user?.phone}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        padding: '11.5px 14px',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-10%',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '40px',
                                        '& fieldset': {
                                            fontSize: '16px',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                defaultValue={user?.email}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        padding: '11.5px 14px',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-10%',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '40px',
                                        '& fieldset': {
                                            fontSize: '16px',
                                        },
                                    },
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid className={cx('item-wp')} pb={2} pr={2} item xs={12} sm={12} md={6}>
                        <Stack spacing={2}>
                            <FormControl fullWidth>
                                <InputLabel
                                    sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold', top: '-4px' }}
                                    id="demo-simple-select-label"
                                >
                                    Tỉnh thành
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={province}
                                    label="Tỉnh thành"
                                    onChange={handleProvinceChange}
                                    sx={{
                                        '& .MuiSelect-root': {
                                            color: 'blue',
                                        },
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            padding: '11px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-3%',
                                        },
                                        '&.MuiInputBase-root': {
                                            borderRadius: '40px!important',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            span: {
                                                fontSize: '12px',
                                            },
                                        },
                                    }}
                                >
                                    {list?.data?.map((item, index) => (
                                        <MenuItem key={index} value={item?.PROVINCE_NAME}>
                                            {item?.PROVINCE_NAME}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel
                                    sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold', top: '-4px' }}
                                    id="demo-simple-select-label"
                                >
                                    Quận huyện
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={district}
                                    label="Quận huyện"
                                    onChange={handleDistrictChange}
                                    sx={{
                                        '& .MuiSelect-root': {
                                            color: 'blue',
                                        },
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            padding: '11px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            top: '-3%',
                                        },
                                        '&.MuiInputBase-root': {
                                            borderRadius: '40px!important',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            span: {
                                                fontSize: '12px',
                                            },
                                        },
                                    }}
                                >
                                    {district_data?.data?.map((item, index) => (
                                        <MenuItem key={index} value={item?.DISTRICT_NAME}>
                                            {item?.DISTRICT_NAME}
                                        </MenuItem>
                                    ))}
                                    {/* {list?.data?.districts.map((item, index) => (
                                        <MenuItem key={index} value={item?.name}>
                                            {item?.name}
                                        </MenuItem>
                                    ))} */}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel
                                    sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold', top: '-4px' }}
                                    id="demo-simple-select-label"
                                >
                                    Phường xã
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={ward}
                                    label="Phường xã"
                                    onChange={handleWardChange}
                                    sx={{
                                        '& .MuiSelect-root': {
                                            color: 'blue',
                                        },
                                        '& .MuiInputBase-input': {
                                            fontSize: '16px',
                                            padding: '11px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#333',
                                            fontWeight: 'bold',
                                            fontSize: '10px',
                                            top: '-4%',
                                        },
                                        '&.MuiInputBase-root': {
                                            borderRadius: '40px!important',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            span: {
                                                fontSize: '12px',
                                            },
                                        },
                                    }}
                                >
                                    {ward_data?.data?.map((item, index) => (
                                        <MenuItem key={index} value={item?.WARDS_NAME}>
                                            {item?.WARDS_NAME}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </FormControl>
    );
};

export default FormInput;
