import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import images from '~/assets/images';
import { Link, NavLink } from 'react-router-dom';
import Search from '~/components/Search';
import DropCart from '~/components/DropCart';
import Notification from '~/components/Notification';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import ToggleMenu from '~/components/ToggleMenu/ToggleMenu';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

const cx = classNames.bind(styles);

function Header() {
    const [menuId, setMenuId] = useState();

    const [open, setOpen] = useState(false);

    function handleMouseEnter(id) {
        setMenuId(id);
    }

    function handleMouseLeave() {
        setMenuId();
    }

    function handleOff() {
        if (open == true) {
            setOpen(false);
        }
    }

    return (
        <header className={cx('wrapper')}>
            <div className={cx('wrapper-bottom')}>
                <Link
                    className="icon-menu-tg"
                    to="#"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                >
                    {!open ? (
                        <FontAwesomeIcon className="fs-1" icon={faBars} />
                    ) : (
                        <i className="fas fa-times fs-1 pb-5 text-danger"></i>
                    )}
                </Link>

                <div className={cx('inner')}>
                    <Link to="/" className={cx('logo-link')}>
                        <img src={images.logo} alt="" />
                    </Link>
                </div>
                <div className={cx('menu')}>
                    <ul className={cx('list-menu')}>
                        <li>
                            <NavLink className={cx('item-menu')} to="/">
                                Trang chủ
                            </NavLink>
                        </li>
                        <li style={{ position: 'relative' }}>
                            <NavLink
                                style={{
                                    background: menuId == 1 ? '#27AB60' : '',
                                    borderRadius: menuId == 1 ? '7px' : '',
                                }}
                                className={cx('item-menu')}
                                to="/gioi-thieu"
                                // to="#"
                                onMouseEnter={() => handleMouseEnter(1)}
                                onMouseLeave={handleMouseLeave}
                            >
                                Giới thiệu <FontAwesomeIcon icon={faCaretDown} />
                            </NavLink>
                            {menuId == 1 && (
                                <ul
                                    className={cx('sub-menu')}
                                    onMouseEnter={() => handleMouseEnter(1)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {/* <Link className={cx('item-sub-menu')} to="/gioi-thieu/ve-chung-toi">
                                        Lịch sử hình thành
                                    </Link> */}
                                    <Link className={cx('item-sub-menu')} to="/gioi-thieu/lich-su-hinh-thanh">
                                        Lịch sử hình thành
                                    </Link>
                                    <Link className={cx('item-sub-menu')} to="/gioi-thieu/tam-nhin-su-mang">
                                        Tầm nhìn - sứ mạng
                                    </Link>
                                    <Link className={cx('item-sub-menu')} to="/gioi-thieu/ton-chi-hoat-dong">
                                        Tôn chỉ hoạt động
                                    </Link>
                                    <Link className={cx('item-sub-menu')} to="/gioi-thieu/bo-quy-tac-dao-duc">
                                        Bộ quy tắc đạo đức
                                    </Link>
                                    {/* <Link className={cx('item-sub-menu')} to="/gioi-thieu/so-do-to-chuc">
                                        Sơ đồ tổ chức
                                    </Link> */}
                                    <Link className={cx('item-sub-menu')} to="/gioi-thieu/linh-vuc-hoat-dong">
                                        Lĩnh vực hoạt động
                                    </Link>
                                    <Link className={cx('item-sub-menu')} to="/gioi-thieu/du-an-da-hoan-thanh">
                                        Dự án đã hoàn thành
                                    </Link>
                                </ul>
                            )}
                        </li>
                        <li style={{ position: 'relative' }}>
                            <Link
                                style={{
                                    background: menuId == 2 ? '#27AB60' : '',
                                    borderRadius: menuId == 2 ? '7px' : '',
                                }}
                                className={cx('item-menu')}
                                to="#"
                                onMouseEnter={() => handleMouseEnter(2)}
                                onMouseLeave={() => handleMouseLeave(2)}
                            >
                                Sản phẩm <FontAwesomeIcon icon={faCaretDown} />
                            </Link>
                            {menuId == 2 && (
                                <ul
                                    className={cx('sub-menu')}
                                    onMouseEnter={() => handleMouseEnter(2)}
                                    onMouseLeave={() => handleMouseLeave(2)}
                                >
                                    <Link className={cx('item-sub-menu')} to="/khoa-hoc-online">
                                        Khóa học trực tiếp – trực tuyến
                                    </Link>
                                    <Link className={cx('item-sub-menu')} to="/khoa-hoc-video">
                                        Khóa học video
                                    </Link>
                                    <a className={cx('item-sub-menu')} href="/sach-pdf">
                                        Sách PDF
                                    </a>
                                    <a className={cx('item-sub-menu')} href="/sach-giay">
                                        Sách giấy
                                    </a>
                                </ul>
                            )}
                        </li>
                        <li>
                            <NavLink className={cx('item-menu')} to="/dich-vu">
                                Dịch vụ
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={cx('item-menu')} to="/su-kien">
                                Sự kiện
                            </NavLink>
                        </li>
                        <li>
                            <a target="_blank" className={cx('item-menu')} href="https://slo.vn">
                                Tin tức
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={cx('actions')}>
                    <Search />
                    <DropCart />
                    <Notification />
                </div>
            </div>
            <Collapse in={open}>
                <div id="example-collapse-text">
                    <ToggleMenu action={handleOff} />
                </div>
            </Collapse>
        </header>
    );
}

export default Header;
