import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import AvartarName from '~/components/AvatarName';
import AccountItem from '~/components/AccountItem';
import Infomation from '~/components/Infomation';
import ChangePass from '~/components/ChangePass';
import MyDocument from '~/components/MyDocument';
import UpgradeUser from '~/components/UpgradeUser';
import MyCourse from '~/components/MyCourse';
import MyCourseVideo from '~/components/MyCourseVideo';
import MyCourseOnline from '~/components/MyCourseOnline';
import MyBookPdf from '~/components/MyBookPdf';
import MyBookPrint from '~/components/MyBookPrint';
import MySocialMemoryPdf from '~/components/MySocialMemoryPdf';
import MySocialMemoryPrint from '~/components/MySocialMemoryPrint';
import MyEvent from '~/components/MyEvent';
import MySurvey from '~/components/MySurvey';
import MyOrder from '~/components/MyOrder';
import ReviewHistory from '~/components/ReviewHistory';
import CodeShare from '~/components/CodeShare';
import HistoryEvaluate from '~/components/HistoryEvaluate';
import HistoryOrder from '~/components/HistoryOrder';
import HistoryService from '~/components/HistoryService';
import PersonalInformation from '~/components/PersonalInformation';
import ExpertRegister from '~/components/ExpertRegister';
import Banking from '~/components/Banking';
import Service from '~/components/Service';
import BookService from '~/components/BookService';
import GetService from '~/components/GetService';
import GetServiceInfo from '~/components/Service/GetServiceInfo';
import images from '~/assets/images';
import styles from './User.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Store/Action/authActions.js';
import { get_cart } from '../../Store/Action/cartActions.js';
import Cookies from 'js-cookie';

const cx = classNames.bind(styles);

const User = () => {
    const [user, setUser] = useState(null);
    const [tabIndex, setTabIndex] = useState('');
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const handleTabIndex = (tab) => {
        setTabIndex(tab);
    };

    useEffect(() => {
        console.log(auth);
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    const MENU_ITEMS = [
        {
            icon: images.user_profile,
            title: 'Quản lý tài khoản',
            to: 'quan-ly-tai-khoan',
            hasChild1: true,
        },
        {
            // icon: images.user_profile,
            title: 'Thông tin cá nhân',
            to: 'thong-tin-ca-nhan',
            parent1: true,
            // hasChild: true,
        },
        {
            // icon: images.upgra_profile,
            title: 'Nâng cấp tài khoản',
            to: 'nang-cap-tai-khoan',
            user: true,
            parent1: true,
        },
        // {
        //     icon: images.history_profile,
        //     title: 'Khảo sát',
        //     to: 'khao-sat',
        //     activeColor: '',
        //     parent1: true,
        // },
        {
            icon: images.lock_profile,
            title: 'Đổi mật khẩu',
            to: 'doi-mat-khau',
            parent1: true,
        },
        {
            icon: images.manage_profile,
            title: 'Đăng ký chuyên gia - đối tác',
            to: 'dang-ky-chuyen-gia-doi-tac',
            activeColor: '',
            user: true,
        },
        {
            icon: images.manage_profile,
            title: 'Quản lý tài nguyên',
            to: 'quan-ly-tai-nguyen',
            hasChild: true,
        },
        {
            title: 'Quản lý khóa học video',
            to: 'quan-ly-khoa-hoc-video',
            parent: true,
        },
        {
            title: 'Quản lý khóa học online',
            to: 'quan-ly-khoa-hoc-online',
            parent: true,
        },
        {
            title: 'Quản lý sách pdf',
            to: 'quan-ly-sach-pdf',
            parent: true,
        },
        {
            title: 'Quản lý sách giấy',
            to: 'quan-ly-sach-giay',
            parent: true,
        },
        {
            title: 'Quản lý kí ức xã hội pdf',
            to: 'quan-ly-ki-uc-xa-hoi-pdf',
            parent: true,
        },
        {
            title: 'Quản lý kí ức xã hội giấy',
            to: 'quan-ly-ki-uc-xa-hoi-giay',
            parent: true,
        },
        {
            title: 'Quản lý đơn hàng',
            to: 'quan-ly-don-hang',
            parent: true,
        },
        {
            title: 'Quản lý sự kiện',
            to: 'quan-ly-su-kien',
            parent: true,
        },
        {
            title: 'Quản lý khảo sát',
            to: 'quan-ly-khao-sat',
            parent: true,
        },
        {
            icon: images.service_profile,
            title: 'Dịch vụ học thuật',
            to: 'dich-vu-hoc-thuat',
            hasChild2: true,
        },
        {
            icon: images.history_profile,
            title: 'Lịch sử đơn hàng',
            to: 'lich-su-don-hang',
            activeColor: '',
            parent2: true,
        },
        {
            icon: images.evaluate_profile,
            title: 'Lịch sử đánh giá',
            to: 'lich-su-danh-gia',
            activeColor: '',
            parent2: true,
        },
        // {
        //     icon: images.service_profile,
        //     title: 'Dịch vụ',
        //     to: 'dich-vu',
        //     activeColor: '',
        //     user: true,
        //     parent2: true,
        // },
        {
            icon: images.book_profile,
            title: 'Mã giới thiêu',
            to: 'ma-gioi-thieu',
            activeColor: '',
            // user: true,
        },
        {
            icon: images.course_profile,
            title: 'Khóa học của tôi',
            to: 'khoa-hoc-cua-toi',
            activeColor: '',
            user: true,
        },
        {
            icon: images.book_profile,
            title: 'Thư viện của tôi',
            to: 'thu-vien-cua-toi',
            activeColor: '',
            user: true,
        },
    ];

    const filteredMenuItems = MENU_ITEMS.filter((item) => item.parent !== true && item.account_type !== 'CUSTOMER');

    // console.log(filteredMenuItems);

    const handleLogout = async () => {
        await Cookies.remove('token');
        await Cookies.remove('user');
        await dispatch(logout(auth.token));
        await dispatch(get_cart(auth.token));
    };

    let { any } = useParams();

    const [value, setValue] = useState('');

    useEffect(() => {
        // Trích xuất thông tin từ URL
        const path = window.location.pathname;
        const urlParts = path.split('/');

        if (urlParts.length >= 4) {
            const extractedValue = urlParts[3];
            setValue(extractedValue);
        }
    }, []);

    console.log(value);

    return (
        <Box className={cx('User', 'container')}>
            <Breadcumb title="Tài khoản" />
            <Box className={cx('wrapper')} mt={2} sx={{ display: 'flex' }}>
                {any != 'dat-dich-vu' ? (
                    <Box className={cx('sidebar')}>
                        <Box>
                            <AvartarName className="info-sidebar" />
                        </Box>
                        <Box py={2}>
                            <Infomation className="info-sidebar" />
                        </Box>
                        <Box sx={{ border: '1px solid #D9D9D9' }}>
                            <AccountItem
                                handleTabIndex={handleTabIndex}
                                tabIndex={tabIndex}
                                check_account={user?.account_type}
                                filteredMenuItems={filteredMenuItems}
                                data={MENU_ITEMS}
                            />
                        </Box>
                        <Link to="/" variant="contained" className={cx('logout')} onClick={handleLogout}>
                            Đăng xuất
                        </Link>
                    </Box>
                ) : (
                    <Box className={cx('sidebar')}>
                        <Box>
                            <AvartarName className="info-sidebar" />
                        </Box>
                        <Box py={2}>
                            <Infomation className="info-sidebar" />
                        </Box>
                        <Box sx={{ border: '1px solid #D9D9D9' }}>
                            <AccountItem
                                itemLink={any}
                                handleTabIndex={handleTabIndex}
                                tabIndex={tabIndex}
                                data={MENU_ITEMS}
                            />
                        </Box>
                    </Box>
                )}
                <Box className={cx('content')}>
                    {any === 'thong-tin-ca-nhan' && <PersonalInformation />}
                    {any === 'dang-ky-chuyen-gia-doi-tac' && <ExpertRegister />}
                    {any === 'quan-ly-chuyen-gia-doi-tac'}

                    {any === 'quan-ly-khoa-hoc-video' && <MyCourseVideo />}
                    {any === 'quan-ly-khoa-hoc-online' && <MyCourseOnline />}
                    {any === 'quan-ly-sach-pdf' && <MyBookPdf />}
                    {any === 'quan-ly-sach-giay' && <MyBookPrint />}
                    {any === 'quan-ly-ki-uc-xa-hoi-pdf' && <MySocialMemoryPdf />}
                    {any === 'quan-ly-ki-uc-xa-hoi-giay' && <MySocialMemoryPrint />}
                    {any === 'quan-ly-su-kien' && <MyEvent />}
                    {any === 'quan-ly-khao-sat' && <MySurvey />}
                    {any === 'quan-ly-don-hang' && <MyOrder />}

                    {any === 'doi-mat-khau' && <ChangePass />}
                    {any === 'thu-vien-cua-toi' && <MyDocument />}
                    {any === 'nang-cap-tai-khoan' && <UpgradeUser />}
                    {any === 'khoa-hoc-cua-toi' && <MyCourse />}
                    {any === 'lich-su-danh-gia' && <ReviewHistory />}
                    {any === 'dich-vu' && <Service />}
                    {any === 'dat-dich-vu' && <BookService />}
                    {any === 'nhan-dich-vu' && <GetService />}
                    {any === 'nhan-thong-tin-dich-vu' && <GetServiceInfo />}
                    {any === 'lich-su-dich-vu' && <HistoryService />}
                    {any === 'nap-tien' && <Banking />}
                    {any === 'lich-su-giao-dich' && <HistoryEvaluate />}
                    {any === 'lich-su-don-hang' && <HistoryOrder />}
                    {any === 'ma-gioi-thieu' && <CodeShare />}
                </Box>
            </Box>
        </Box>
    );
};

export default User;
