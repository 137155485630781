import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const survey = (token, page = 1) => {
    return async (dispatch) => {
        try {
            // Gọi API chi tiết sự kiện
            const response = await api.get(
                'api/surveys/take/my-surveys?page=' + page ?? 1,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'SURVEY_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'SURVEY_FAILURE', payload: error.message });
        }
    };
};

export const my_survey = (token, page) => {
    return async (dispatch) => {
        try {
            // Gọi API chi tiết sự kiện
            const response = await api.get(
                'api/partners/surveys?limit=40',
                {
                    page: page,
                },
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'SURVEY_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'SURVEY_FAILURE', payload: error.message });
        }
    };
};

export const add_survey = (token, formData) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/surveys', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                // showToast('Đã thêm 1 khoá học online');
                return response.data;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const add_question_survey = (token, formData, id) => {
    return async (dispatch) => {
        try {
            const response = await api.post('api/surveys/' + id + '/questions', formData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status === 200) {
                // showToast('Đã thêm 1 khoá học online');
                return response.data;
            } else {
                const message = 'Gửi yêu cầu thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    };
};

export const show_alert = () => {
    return async (dispatch) => {
        showToast('Đã tạo khảo sát thành công. Vui lòng chờ duyệt');

        return true;
    };
};
