import api from './api';

export const eventDetail = (id) => {
    return async (dispatch) => {
        try {
            // Gọi API chi tiết sự kiện
            const response = await api.get('api/events/'+id, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            dispatch({ type: 'DETAIL_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'DETAIL_FAILURE', payload: error.message });
        }
    };
};
