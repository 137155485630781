import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import NotificationTippy from '@tippyjs/react/headless';
import styles from './Notification.module.scss';
import images from '~/assets/images';
import Item from './Item';
import { notification } from '~/Store/Action/notificationActions';

const cx = classNames.bind(styles);

function Notification() {
    const [showOpenNotification, setShowOpenNotification] = useState(false);
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const list = useSelector((state) => state.notification.list);

    // console.log(list);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        // console.log(JSON.parse(auth.user));
        if (user != null) {
            // console.log(user);
            dispatch(notification(auth.token));
        }
    }, [auth]);

    const handleShowOpenNotification = () => {
        setShowOpenNotification(!showOpenNotification);
    };

    const handleHideNotification = () => {
        setShowOpenNotification(false);
    };

    return (
        <div className={cx('Notification') + ' div-notification'}>
            <NotificationTippy
                className="tippy-n div-notification"
                interactive
                visible={showOpenNotification}
                placement="bottom-end"
                render={(attrs) => (
                    <div className={cx('Notification-list')} tabIndex="-1" {...attrs}>
                        <div className={cx('Notification-top')}>
                            <h2>Thông báo</h2>
                        </div>
                        <div className={cx('Notification-body')}>
                            <Item list={list} />
                        </div>
                    </div>
                )}
                onClickOutside={handleHideNotification}
            >
                <div className={cx('notification')}>
                    <img
                        className="wow animate__animated animate__swing"
                        src={images.iconNotification}
                        alt="Thông báo"
                        onClick={handleShowOpenNotification}
                    />
                    {list?.data?.data?.length > 0 && (
                        <span className={cx('number-notificate')}>{list?.data?.data?.length}</span>
                    )}
                </div>
            </NotificationTippy>
        </div>
    );
}

export default Notification;
