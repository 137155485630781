import { Box, Typography, Stack, Button } from '@mui/material';
import classNames from 'classnames/bind';
import Breadcumb from '~/components/Breadcrumb';
import styles from './About.module.scss';
import { getInformation } from '~/Store/Action/informationActions';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

function PayMethodAbout() {
    // const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    // const information = useSelector((state) => state.information.get);

    useEffect(() => {
        // dispatch(getInformation(3));
    }, []);

    return (
        <Box className={cx('About', 'container')}>
            <Breadcumb title="Phương thức thanh toán" />
            <Box className={cx('content')}>
                <div className="mt-3 w-100 d-block content-web">
                    <h1 className="fw-bold mb-2">PHƯƠNG THỨC THANH TOÁN</h1>
                    <div>
                        <div>
                            <strong className="my-2">Hình thức thanh toán</strong>
                        </div>
                        <div>
                            <strong>
                                1. Thanh toán tiền mặt tại nhà khi nhận hàng thông qua hình thức giao hàng trực tiếp,
                                chuyển phát nhanh hoặc COD:
                            </strong>
                            Khi nhân viên giao hàng giao phát, khách hàng kiểm tra sản phẩm về hình thức đảm bảo, khách
                            hàng nhận hàng và thanh toán trực tiếp cho nhân viên giao hàng theo giá trị tiền trên hóa
                            đơn. Ngoài ra khách hàng không phải thanh toán bất kỳ 1 chi phí nào khác.
                        </div>

                        <div>
                            <strong>2. Nạp tiền vào ví thanh toán:</strong>
                            <div>
                                Đầu tiên quý khách hàng sẽ đăng ký tài khoản trên app hoặc website sociallife.vn, sau đó
                                nộp tiền vào ví cá nhân thông qua 2 cách:
                            </div>
                        </div>
                        <div>
                            <strong>Cách 1: Chuyển khoản qua ngân hàng với thông tin bên dưới:</strong>
                        </div>
                        <ul className="ms-3">
                            <li>• Số tài khoản: 231307869</li>
                            <li>• Ngân hàng: Ngân hàng Á Châu (ACB) Phòng giao dịch hàng xanh</li>
                            <li>• Tên tài khoản: Công ty TNHH Viện Đại học Social Life</li>
                        </ul>
                        <div>
                            <strong>Cách 2: Nạp tiền thông qua cổng thanh toán trực tuyến Bảo Kim</strong>
                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default PayMethodAbout;
