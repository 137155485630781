import classNames from 'classnames/bind';
import styles from '../Home.module.scss';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import Section from '~/components/Section/Section';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function PostFeatered({ featers }) {
    // console.log(featers);
    let navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/tin-tuc');
    };
    return (
        <Section
            className={cx('PostFeatered')}
            // buttonTitle="Xem thêm"
            categoryTitle="Khoa học - Nhân bản - Khai phóng"
            // sectionTitle="Tin tức nổi bật"
            onClickButton={handleRedirect}
        >
            <div className={cx('featered_wrapper')}>
                <Swiper
                    className="w-100"
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={15}
                    autoplay={{ delay: 3500 }}
                    // style={{ maxWidth: '400px' }}
                    // slidesPerView={1}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                        300: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                    }}
                >
                    {featers?.data?.features.map((item) => (
                        <SwiperSlide>
                            <a
                                href={
                                    item?.name === 'Dữ liệu'
                                        ? '/sach-giay'
                                        : item?.name === 'Trắc nghiệm'
                                        ? '/tai-khoan/khao-sat'
                                        : item?.name === 'Dịch vụ'
                                        ? '/dich-vu'
                                        : item?.name === 'Đào tạo'
                                        ? '/khoa-hoc-online'
                                        : item?.link
                                }
                                className={cx('item')}
                                // target="_blank"
                                key={item?.id}
                            >
                                <img src={item?.image} className='img-brr' alt="" />
                                <p>{item?.name}</p>
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </Section>
    );
}

export default PostFeatered;
