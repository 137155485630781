import classNames from 'classnames/bind';
import CourseDataItem from '~/components/CourseDataItem';
import 'bootstrap/dist/css/bootstrap.css';
import styles from '../Home.module.scss';
import images from '~/assets/images';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { getExperts } from '~/Store/Action/homeActions';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Import Swiper styles
import 'swiper/css';
import Section from '~/components/Section/Section';
import { Grid, Autoplay } from 'swiper';

const cx = classNames.bind(styles);

function ConnectExperts() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    // console.log(book_categories?.data?.book_categories);

    const handleRedirect = () => {
        navigate('/experts');
    };

    const experts = useSelector((state) => state.home.expert);

    useEffect(() => {
        dispatch(getExperts());
    }, []);

    return (
        <div className="row">
            <Section
                className={cx('CourseData')}
                buttonTitle="Xem thêm"
                sectionTitle="Đối tác - Chuyên gia"
                onClickButton={handleRedirect}
            >
                <div className="wow animate__animated animate__bounceInRight">
                    <div className={cx('list-CourseData')} style={{ paddingTop: '25px' }}>
                        <Swiper
                            spaceBetween={15}
                            modules={[Grid, Autoplay]}
                            autoplay={{ delay: 2500 }}
                            breakpoints={{
                                300: {
                                    slidesPerView: 2,
                                    rows: 2,
                                },
                                640: {
                                    slidesPerView: 3,
                                    rows: 2,
                                },
                                768: {
                                    slidesPerView: 6,
                                    rows: 1,
                                },
                            }}
                            // grid={{
                            //     rows: 2,
                            // }}
                            className="pt-3"
                        >
                            {experts &&
                                experts?.data?.data?.map((item, index) => (
                                    <SwiperSlide className="swiper-slide-ex">
                                        <a href={'/user/' + item.id} titlt={item.name} className="d-block a-experts">
                                            <div className="card bg-express pt-2 pb-3">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-center align-items-center w-100">
                                                        <div className="rounded-circle border border-2 overflow-hidden img-experts">
                                                            <img
                                                                className="img-thumbnail"
                                                                alt={item.name}
                                                                src={item.image ?? images.avatarDefault}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <h3 className="font-family-system-ui">
                                                            {item.name ?? 'Chưa cập nhật'}
                                                        </h3>
                                                    </div>
                                                    <div className="text-center">
                                                        <h4>
                                                            {item.account_type !== 'EXPERT' ? 'Đối tác' : ' Chuyên gia'}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </div>
                </div>
            </Section>
        </div>
    );
}

export default ConnectExperts;
