import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const add_cart = (token, form) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.post('api/carts', form, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });
            dispatch({ type: 'ADD_CART_SUCCESS', payload: response.data });
            showToast('Thêm giỏ hàng thành công');
            return true;
        } catch (error) {
            dispatch({ type: 'ADD_CART_FAILURE', payload: error.message });
        }
    };
};

export const update_cart = (token, quantity, id) => {
    // console.log(quantity);
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.post(
                'api/carts/' + id,
                {
                    quantity: quantity,
                },
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'UPDATE_CART_SUCCESS', payload: response.data });
            return true;
        } catch (error) {
            dispatch({ type: 'UPDATE_CART_FAILURE', payload: error.message });
        }
    };
};

export const delete_cart = (ids, token) => {
    return async (dispatch) => {
        try {
            // Gọi API
            const response = await api.post(
                'api/carts/items/delete',
                {
                    ids: ids,
                },
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'DELETE_CART_SUCCESS', payload: response.data });
            showToast('Xóa giỏ hàng thành công');
            return true;
        } catch (error) {
            dispatch({ type: 'DELETE_CART_FAILURE', payload: error.message });
        }
    };
};

export const get_cart = (token) => {
    console.log(token);
    return async (dispatch) => {
        if (!token || token == null) {
            return;
        }
        try {
            if (token == null) {
                return;
            }
            // Gọi API chi tiết sự kiện
            const response = await api.get(
                'api/carts',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'GET_CART_SUCCESS', payload: response.data });
            // console.log(response);
        } catch (error) {
            dispatch({ type: 'GET_CART_FAILURE', payload: error.message });
        }
    };
};
