import images from '~/assets/images';
import {
    FormControl,
    TextField,
    Stack,
    MenuItem,
    InputLabel,
    Select,
    Button,
    Grid,
    Typography,
    Box,
} from '@mui/material';
import classNames from 'classnames/bind';
import styles from './FormInput.module.scss';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { city, cty, getDistrict, getWard } from '~/Store/Action/addressActions';
import { save_profile, get_profile } from '../../../Store/Action/profileActions';
import { category } from '~/Store/Action/categoryActions';
import SunEditor from 'suneditor-react';
import { logout } from '~/Store/Action/authActions.js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function FormInput() {
    const divRef = useRef(null);
    const navigate = useNavigate();

    const profile = useSelector((state) => state.profile?.list?.data);
    const profile1 = useSelector((state) => state.profile);
    const listCategory = useSelector((state) => state.category.list);
    console.log(profile);

    useEffect(() => {
        dispatch(category());
    }, []);

    const [user, setUser] = useState(null);
    const [name, setName] = useState(profile?.name || '');
    const [email, setEmail] = useState(profile?.email || '');
    const [image, setImage] = useState('');
    const [imgLoad, setImgLoad] = useState('');
    const [phone, setPhone] = useState(profile?.phone || '');
    const [sex, setSex] = useState(profile?.sex || '');
    const [province, setProvince] = useState(profile?.province || '');
    const [district, setDistrict] = useState(profile?.district || '');
    const [ward, setWard] = useState(profile?.ward || '');
    const [address, setAddress] = useState(profile?.address || '');
    const [specialize, setSpecialize] = useState(profile?.specialize || '');
    const [qualification, setQualification] = useState(profile?.qualification || '');
    const [interest_topics, setInterest_topics] = useState(profile?.interest_topics || '');
    const [account_level, setaccount_level] = useState(profile?.account_level || '');
    const [img, setImg] = useState(null);
    const dispatch = useDispatch();

    const district_data = useSelector((state) => state.address.district);

    const ward_data = useSelector((state) => state.address.ward);

    const list = useSelector((state) => state.address.city);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        console.log(profile1);

        dispatch(city());
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    useEffect(() => {
        async function load() {
            let user_data;

            if (auth.user) {
                if (profile) {
                    user_data = profile;
                } else {
                    user_data = JSON.parse(auth.user);
                }
                console.log(user_data);
                await setName(user_data?.name || '');
                await setEmail(user_data?.email || '');
                await setPhone(user_data?.phone || '');
                await setSex(user_data?.sex || '');
                await setProvince(user_data?.province || '');
                await setDistrict(user_data?.district || '');
                await setWard(user_data?.ward || '');
                await setAddress(user_data?.address || '');
                await setSpecialize(user_data?.specialize || '');
                await setQualification(user_data?.qualification || '');
                await setInterest_topics(user_data?.interest_topics || '');
                await setaccount_level(user_data?.account_level || '');
            }
            const actionResult = await dispatch(city());

            const cityList = actionResult;
            console.log(cityList);

            if (cityList) {
                const city = await cityList.find((city) => city.PROVINCE_NAME === user_data?.province);
                console.log(city);
                console.log(province);

                const p = await dispatch(getDistrict(city?.PROVINCE_ID ?? 0));
                console.log(p);
                const d = await p.find((x) => x.DISTRICT_NAME === user_data?.district);
                console.log(d);

                await dispatch(getWard(d?.DISTRICT_ID ?? 1));
            }
        }

        load();
    }, []);

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        const files = e.target.files;
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImg(image);
        handleImg(e);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleSexChange = (e) => {
        setSex(e.target.value);
    };

    const handleProvinceChange = (e) => {
        const name = e.target.value;
        console.log(name);
        setProvince(e.target.value);

        const city = list?.data?.find((city) => city.PROVINCE_NAME === name);
        console.log(city);
        setWard('');

        dispatch(getDistrict(city.PROVINCE_ID));
    };

    const handleDistrictChange = (e) => {
        const name = e.target.value;
        console.log(name);
        setDistrict(e.target.value);

        const d = district_data?.data?.find((x) => x.DISTRICT_NAME === name);
        console.log(d);
        setWard('');

        dispatch(getWard(d.DISTRICT_ID));
    };

    const handleWardChange = (e) => {
        setWard(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleSpecializeChange = (e) => {
        setSpecialize(e.target.value);
    };

    const handleQualificationChange = (e) => {
        setQualification(e.target.value);
        setaccount_level(e.target.value);
    };

    const handleInterest_topicsChange = (e) => {
        console.log(e);
        setInterest_topics(e);
    };

    const handleImg = (event) => {
        const files = event.target.files;
        console.log(files);
        const image = Array.from(files).map((file) => URL.createObjectURL(file));
        setImgLoad(image);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const city = await list?.data?.find((city) => city.PROVINCE_NAME === province);
        console.log(city);
        const district_id = await district_data?.data?.find((x) => x.DISTRICT_NAME === district);
        console.log(district_id);
        console.log(ward_data);

        const ward_id = await ward_data?.data?.find((x) => x.WARDS_NAME === ward);
        // return

        try {
            await dispatch(
                save_profile(
                    name,
                    image,
                    phone,
                    email,
                    sex,
                    city.PROVINCE_ID,
                    district_id.DISTRICT_ID,
                    ward_id.WARDS_ID,
                    address,
                    specialize,
                    qualification,
                    interest_topics,
                    auth.token,
                ),
            );
        } catch (e) {
            alert('Bạn vui lòng nhập đủ thông tin');
        }
        // if(onSaveProfile){
        if (auth.token) {
            const data = await dispatch(get_profile(auth.token));
            console.log(profile);
            console.log('123123123');
            console.log(data.data.data);
            await dispatch({ type: 'SET_USER', payload: JSON.stringify(await data.data.data) });
            await alert('Đăng nhập lại để hiển thị thông tin cập nhật!');
            await Cookies.remove('token');
            await Cookies.remove('user');
            await navigate('/');
            await dispatch(logout(auth.token));
        }
        // }
    };

    return (
        <FormControl sx={{ paddingTop: '10px' }} fullWidth>
            <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                <Box
                // sx={{
                //     backgroundImage: `url(${img})`,
                //     backgroundRepeat: 'no-repeat',
                //     backgroundSize: 'cover',
                // }}
                >
                    <img
                        className="wow animate__animated animate__zoomIn"
                        style={{ width: '150px', height: '150px', border: '2px solid #dee2e6', borderRadius: '50%' }}
                        src={imgLoad ?? img ? img : profile?.image}
                        alt=""
                    />
                </Box>
                <Box sx={{ padding: '16px 11px' }}>
                    <label
                        className={cx('content')}
                        htmlFor="upload-img-btn"
                        // style={{
                        //     backgroundImage: `url(${imgLoad})`,
                        //     backgroundRepeat: 'no-repeat',
                        //     backgroundSize: 'cover',
                        // }}
                    >
                        <TextField
                            label="Họ và tên"
                            type="file"
                            style={{ display: 'none' }}
                            id="upload-img-btn"
                            onChange={handleImageChange}
                        />
                        <Button
                            sx={{ borderRadius: '40px' }}
                            className={cx('choose-image')}
                            variant="outlined"
                            // onChange={(event) => handleImg(event)}
                            component="span"
                        >
                            Chọn ảnh
                        </Button>
                    </label>
                    <Typography sx={{ fontSize: '14px' }}>
                        Dụng lượng file tối đa 1 MB
                        <br /> Định dạng:.JPEG, .PNG
                    </Typography>
                </Box>
            </Box>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                sx={{ flexGrow: 1 }}
                className={cx('wrapper') + ' wow animate__animated animate__fadeInRightBig'}
            >
                <Grid className={cx('item-wp')} pr={2} pb={2} item xs={12} sm={6} md={6}>
                    <Stack spacing={2}>
                        <TextField
                            label="Tên của bạn"
                            variant="outlined"
                            value={name}
                            onChange={handleNameChange}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                        <TextField
                            // label="Email"
                            // className="d-none"
                            variant="outlined"
                            // defaultValue={profile ? profile?.email : ''}
                            value={email}
                            // type="hidden"
                            disabled
                            onChange={handleEmailChange}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                id="demo-simple-select-label"
                            >
                                Tỉnh thành
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Tỉnh thành"
                                value={province}
                                onChange={handleProvinceChange}
                                // onChange={handleChange}
                                sx={{
                                    '& .MuiSelect-root': {
                                        color: 'blue',
                                    },
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        padding: '14px',
                                        transform: 'translateY(10%)',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-3%',
                                    },
                                    '&.MuiInputBase-root': {
                                        borderRadius: '40px!important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        span: {
                                            fontSize: '12px',
                                        },
                                    },
                                }}
                            >
                                {list?.data?.map((item, index) => (
                                    <MenuItem key={index} value={item.PROVINCE_NAME} data-id={item.PROVINCE_ID}>
                                        {item?.PROVINCE_NAME}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                id="demo-simple-select-label"
                            >
                                Phường xã
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Phường xã"
                                value={ward}
                                onChange={handleWardChange}
                                sx={{
                                    '& .MuiSelect-root': {
                                        color: 'blue',
                                    },
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        padding: '14px',
                                        transform: 'translateY(10%)',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-3%',
                                    },
                                    '&.MuiInputBase-root': {
                                        borderRadius: '40px!important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        span: {
                                            fontSize: '12px',
                                        },
                                    },
                                }}
                            >
                                {ward_data?.data?.map((item, index) => (
                                    <MenuItem key={index} value={item?.WARDS_NAME}>
                                        {item?.WARDS_NAME}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* <TextField
                            label="Lĩnh vực chuyên môn"
                            variant="outlined"
                            value={specialize}
                            onChange={handleSpecializeChange}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        /> */}
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                id="demo-simple-select-label"
                            >
                                Chuyên ngành
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Chuyên ngành"
                                value={specialize}
                                onChange={handleSpecializeChange}
                                sx={{
                                    '& .MuiSelect-root': {
                                        color: 'blue',
                                    },
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        padding: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-3%',
                                    },
                                    '&.MuiInputBase-root': {
                                        borderRadius: '40px!important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        span: {
                                            fontSize: '12px',
                                        },
                                    },
                                }}
                            >
                                {listCategory?.data?.specializations.map((item, index) => (
                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* <TextField
                            label="Chủ đề quan tâm nghiên cứu"
                            variant="outlined"
                            value={interest_topics}
                            onChange={handleInterest_topicsChange}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        /> */}
                    </Stack>
                </Grid>
                <Grid className={cx('item-wp')} pr={2} pb={2} item xs={12} sm={6} md={6}>
                    <Stack spacing={2}>
                        <TextField
                            label="Số điện thoại"
                            variant="outlined"
                            value={phone}
                            onChange={handlePhoneChange}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                id="demo-simple-select-label"
                            >
                                Giới tính
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Giới tính"
                                value={sex}
                                onChange={handleSexChange}
                                sx={{
                                    '& .MuiSelect-root': {
                                        color: 'blue',
                                    },
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        padding: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-3%',
                                    },
                                    '&.MuiInputBase-root': {
                                        borderRadius: '40px!important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        span: {
                                            fontSize: '12px',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="MALE">Nam</MenuItem>
                                <MenuItem value="FEMALE">Nữ</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                id="demo-simple-select-label"
                            >
                                Quận huyện
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Quận huyện"
                                value={district}
                                onChange={handleDistrictChange}
                                sx={{
                                    '& .MuiSelect-root': {
                                        color: 'blue',
                                    },
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        padding: '14px',
                                        transform: 'translateY(10%)',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-3%',
                                    },
                                    '&.MuiInputBase-root': {
                                        borderRadius: '40px!important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        span: {
                                            fontSize: '12px',
                                        },
                                    },
                                }}
                            >
                                {district_data?.data?.map((item, index) => (
                                    <MenuItem key={index} value={item?.DISTRICT_NAME}>
                                        {item?.DISTRICT_NAME}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Địa chỉ cụ thể"
                            variant="outlined"
                            value={address}
                            onChange={handleAddressChange}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}
                                id="demo-simple-select-label"
                            >
                                Trình độ chuyên môn
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Trình độ chuyên môn"
                                value={account_level}
                                onChange={handleQualificationChange}
                                sx={{
                                    '& .MuiSelect-root': {
                                        color: 'blue',
                                    },
                                    '& .MuiInputBase-input': {
                                        fontSize: '16px',
                                        padding: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        top: '-3%',
                                    },
                                    '&.MuiInputBase-root': {
                                        borderRadius: '40px!important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        span: {
                                            fontSize: '12px',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="gs.ts">GS.TS</MenuItem>
                                <MenuItem value="pgs.ts">PGS.TS</MenuItem>
                                <MenuItem value="ts">Tiến Sĩ</MenuItem>
                                <MenuItem value="ts">TS</MenuItem>
                                <MenuItem value="ths">ThS</MenuItem>
                                <MenuItem value="gv">Giảng viên</MenuItem>
                                <MenuItem value="cg">Chuyên gia</MenuItem>
                                <MenuItem value="cn">Cử nhân</MenuItem>
                                <MenuItem value="sv">Sinh viên</MenuItem>
                                <MenuItem value="hs">Học sinh</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </Grid>

                <div class="mb-3 mt-3 w-100">
                    <label class="form-label">Chủ đề quan tâm nghiên cứu (250 từ)</label>
                    {/* <textarea
                        class="form-control"
                        value={interest_topics}
                        onChange={handleInterest_topicsChange}
                        rows="5"
                    ></textarea> */}
                    {/* {interest_topics && ( */}
                    <SunEditor
                        name="description"
                        defaultValue={interest_topics ?? 'Đang tải'}
                        // setContents={interest_topics ?? 'Đang tải'}
                        onChange={handleInterest_topicsChange}
                        height="300"
                        setOptions={{
                            buttonList: [
                                ['undo', 'redo'],
                                ['font', 'fontSize', 'formatBlock'],
                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                ['bold', 'underline', 'italic', 'strike'],
                                ['removeFormat'],
                                ['outdent', 'indent'],
                                ['align', 'horizontalRule', 'list', 'table'],
                                ['fontColor', 'hiliteColor'],
                                ['link'],
                                // ['link', 'image'],
                                // ['fullScreen', 'showBlocks', 'codeView'],
                                ['preview', 'print'],
                            ],
                        }}
                    />
                    {/* )} */}
                </div>
                {/* <div class="mb-3 w-100">
                    <label class="form-label">Mối quan tâm nghiên cứu (250 từ)</label>
                    <textarea class="form-control" rows="5"></textarea>
                </div> */}

                <div className="d-flex justify-content-center mt-3 w-100">
                    <Button
                        style={{ maxWidth: '400px' }}
                        onClick={handleSubmit}
                        className={cx('btn_submit')}
                        variant="contained"
                    >
                        LƯU LẠI
                    </Button>
                </div>
            </Grid>
        </FormControl>
    );
}

export default FormInput;
