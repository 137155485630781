import { useState, useEffect } from 'react';
import { Box, Typography, Stack, Button, FormGroup, TextField } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import classNames from 'classnames/bind';
import Alert from '~/components/Alert';
import styles from './ChangePass.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { change_password } from '~/Store/Action/authActions';

const cx = classNames.bind(styles);

const ChangePass = () => {
    const [visible, setVisible] = useState(false);

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [password, setPassword] = useState('');
    const [old_password, setOldPassword] = useState('');

    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const handlePasswordConfirmationChange = (e) => {
        setPasswordConfirmation(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleOldPasswordChange = (e) => {
        setOldPassword(e.target.value);
    };

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onChangePass = async () => {
        const data = {
            password_confirmation: password_confirmation,
            password: password,
            old_password: old_password,
        };
        await dispatch(change_password(auth.token, data));
    };

    return (
        <div className="wow animate__animated animate__backInRight">
            <Box className={cx('ChangePass')}>
                <TitleUser title="Đổi mật khẩu" />
                <FormGroup>
                    <TextField
                        className={cx('input-change')}
                        fullWidth
                        id="password"
                        label="Mật khẩu cũ"
                        variant="outlined"
                        value={old_password}
                        onChange={handleOldPasswordChange}
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px',
                            },
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px',

                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <div className="=">
                        <TextField
                            className={cx('input-change')}
                            fullWidth
                            id="re-password"
                            label="Mật khẩu mới"
                            value={password}
                            variant="outlined"
                            onChange={handlePasswordChange}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    padding: '11.5px 14px',
                                },
                                '& .MuiInputLabel-root': {
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    top: '-10%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    '& fieldset': {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                    </div>
                    <TextField
                        className={cx('input-change')}
                        fullWidth
                        id="new-password"
                        value={password_confirmation}
                        label="Nhập lại mật khẩu mới"
                        onChange={handlePasswordConfirmationChange}
                        variant="outlined"
                        sx={{
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 'bold',
                                padding: '11.5px 14px',
                            },
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                fontSize: '16px',
                                top: '-10%',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px',
                                '& fieldset': {
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                    <Button onClick={handleOpenAlert} className={cx('button-change')} variant="contained">
                        ĐỔI MẬT KHẨU
                    </Button>
                </FormGroup>
                <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                        Thông báo
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }}>
                        Xác nhận đổi mật khẩu của bạn tài khoản của bạn sẽ được đăng xuất
                    </Typography>
                    <div className="row position-relative pt-4">
                        <button
                            onClick={() => handleCancel()}
                            style={{
                                display: 'block',
                                background: '#D9D9D9!important',
                                color: '#333',
                                fontFamily: 'sans-serif',
                                fontWeight: 700,
                            }}
                            className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                        >
                            Hủy bỏ
                        </button>
                        <button
                            onClick={() => onChangePass()}
                            style={{
                                display: 'block',
                                right: 0,
                                background: '#27AB60!important',
                                color: '#fff',
                                fontFamily: 'sans-serif',
                                fontWeight: 700,
                            }}
                            className="col-6 position-absolute btn btn-g-reverse p-3"
                        >
                            Đồng ý
                        </button>
                    </div>
                </Alert>
            </Box>
        </div>
    );
};

export default ChangePass;
