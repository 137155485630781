import api from './api';

import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const surveyDetail = (id, token) => {
    return async (dispatch) => {
        try {
            // Gọi API chi tiết khảo sát
            const response = await api.get(
                'api/surveys/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            const checkSurvay = await api.get(
                'api/surveys/taken/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            await dispatch({ type: 'RESET_DETAIL_SUCCESS' });

            await dispatch({ type: 'DETAIL_SUCCESS', payload: response.data });

            await dispatch({ type: 'CHECK_DETAIL_SUCCESS', payload: checkSurvay.data });

            await dispatch({ type: 'CHECK_DETAIL1_SUCCESS', payload: checkSurvay.data });
            console.log(checkSurvay);
        } catch (error) {
            dispatch({ type: 'DETAIL_FAILURE', payload: error.message });
        }
    };
};

export const surveyDetailFile = (id, token) => {
    return async (dispatch) => {
        try {
            // Gọi API chi tiết khảo sát
            const response = await api.get(
                'api/partners/surveys/result/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );

            await dispatch({ type: 'DETAIL_FILE_SUCCESS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'DETAIL_FAILURE', payload: error.message });
        }
    };
};

export const pause = (id, token) => {
    return async (dispatch) => {
        try {
            // Gọi API chi tiết khảo sát
            const response = await api.post(
                'api/partners/surveys/change-status/' + id,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            showToast('Đã dừng khảo sát thành công');
            // await dispatch({ type: 'DETAIL_FILE_SUCCESS', payload: response.data });
            if (response) {
                window.location.reload();
            }
        } catch (error) {
            dispatch({ type: 'DETAIL_FAILURE', payload: error.message });
        }
    };
};

export const doSurvey = (id, token, surveyData) => {
    return async (dispatch) => {
        try {
            // Gọi API chi tiết khảo sát
            const response = await api.post('api/surveys/take/my-surveys/' + id, surveyData, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });
            // dispatch({ type: 'DETAIL_SUCCESS', payload: response.data });
            console.log(response.data);

            if (response.data.status == 200) {
                console.log(response.data.data);
                showToast('Đã gửi khảo sát bạn làm thành công');
                // dispatch({ type: 'RECEIVE_ID_SERVICE', payload: response.data.data });
                return response.data.data;
            } else {
                const message = 'Có lỗi vui lòng thử lại';

                showToast(message, 1);
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
            // dispatch({ type: 'DETAIL_FAILURE', payload: error.message });
            // if (response.data.status == 400) {
            showToast(error.message, 1);
            // }
        }
    };
};
