import { useState } from "react";
import { Box, Typography, Grid } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import Dropdown from '~/components/Service/Dropdown';
import GetServiceInfo from './GetServiceInfo';
import FormInput from './FormInput';
import List from './List';
import classNames from 'classnames/bind';
import styles from './BookService.module.scss';

const cx = classNames.bind(styles);

const BookService = () => {

    const [active, setActive] = useState(false);
    const [idSerivce, setIdService] = useState();

    const handleDetailService = (id) => {
        setIdService(id);
        setActive(true);
    }

    // console.log(active);


    // console.log(active);

    return (
        <Box className={cx('BookService','container')}>
             {active ? (
                <GetServiceInfo idSerivce = {idSerivce} />
            ) : (
                <>
                    <Dropdown />
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ flexGrow: 1 }}
                        className={cx('wrapper')}
                    >
                        <Grid className={cx('item-wp')} pb={2} pl={0} pr={2} item xs={12} sm={12} md={6}>
                            <TitleUser title="Đặt dịch vụ" />
                            <FormInput />
                        </Grid>
                        <Grid className={cx('item-wp')} item xs={12} sm={12} md={6}>
                            <TitleUser title="Nhận dịch vụ" />
                            <List handleDetailService = {(id) => handleDetailService(id)} />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
}

export default BookService;