import { Box, Typography, Grid, Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TitleUser from '~/components/TitleUser';
import images from '~/assets/images';
import classNames from 'classnames/bind';
import styles from './UpgradeUser.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { get_vip_benefits, register_vip_member } from '~/Store/Action/authActions';
import { get_profile } from '~/Store/Action/profileActions';
import Alert from '~/components/Alert';

const cx = classNames.bind(styles);

function formatMoney(money) {
    return money.toLocaleString('vi-VN');
}

function UpgradeUser() {
    const profile = useSelector((state) => state.profile.list);

    const [visible, setVisible] = useState(false);
    const [data_user, setDataUser] = useState(profile ?? null);

    const [alertState, setAlertState] = useState({
        visible: false,
    });

    const [user, setUser] = useState(null);

    const [vip_id, setTargetVip] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const vip_benefits = useSelector((state) => state.vip_benefits);

    useEffect(() => {
        console.log(JSON.parse(auth.user));
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }

        dispatch(get_vip_benefits(auth.token));
        dispatch(get_profile(auth.token));

        console.log(vip_benefits);
        console.log(profile);
    }, [auth]);

    useEffect(() => {
        setDataUser(profile);
    }, [profile]);

    const handleOpenAlert = (defaultKey) => {
        setVisible(true);
        setAlertState({ ...alertState, visible: true, defaultKey });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onUpdateAccount = async () => {
        console.log(vip_id);
        if (vip_id == null) {
            return;
        }
        await dispatch(register_vip_member(auth.token, vip_id));
    };

    return (
        <Box className={cx('UpgradeUser', 'container')}>
            <TitleUser title="Nâng cấp tài khoản của bạn" />
            <Grid container rowSpacing={1} sx={{ flexGrow: 1 }} className={cx('wrapper')}>
                <Grid pb={2} pr={2} item xs={12} sm={12} md={6}>
                    <Button className={cx('btn_sale')} variant="contained">
                        Ưu đãi khi nâng cấp
                    </Button>
                    <Box className={cx('menu-upgrade')}>
                        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                            <Typography sx={{ fontSize: '24px', lineHeight: 0, paddingRight: '10px' }}>
                                &middot;
                            </Typography>
                            {/* Kho sách miễn phí */}
                            {vip_benefits.list ? vip_benefits.list.content : 'đang load...'}
                        </Box>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                            <Typography sx={{ fontSize: '24px', lineHeight: 0, paddingRight: '10px' }}>
                                &middot;
                            </Typography>
                            Kho khóa học miễn phí
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                            <Typography sx={{ fontSize: '24px', lineHeight: 0, paddingRight: '10px' }}>
                                &middot;
                            </Typography>
                            Đăng ký sử dụng dịch vụ
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                            <Typography sx={{ fontSize: '24px', lineHeight: 0, paddingRight: '10px' }}>
                                &middot;
                            </Typography>
                            Đăng ký nhận dịch vụ
                        </Box> */}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box className={cx('banner_upgrade')}>
                        <img className={cx('banner')} src={images.upgrade} alt="" />
                        <Box className={cx('banner_upgrade-content')}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: '14px', color: '#FFFFFF' }}>Nâng cấp vip</Typography>
                                <img className={cx('icon')} src={images.upgrade_icon} alt="" />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={cx('vnđ')}>Vnđ</Typography>
                                <Typography sx={{ fontSize: '16px', color: '#FFFFFF', paddingLeft: '4px' }}>
                                    {vip_benefits.list ? vip_benefits.list.title : 'đang load...'}
                                </Typography>
                            </Box>
                            <Typography className={cx('name')}>{user ? user.name : 'User'}</Typography>
                        </Box>
                    </Box>
                    <TitleUser title="Chọn gói nâng cấp" />
                    {data_user && data_user?.data?.vip_member_package ? (
                        <div>
                            <h4 className="fw-bold f-family-san">Đã nâng cấp</h4>
                            <h5>
                                <span className="fw-bold f-family-san">Hết hạn lúc: </span>
                                <span>{data_user?.data?.vip_member_package?.expired_at}</span>
                            </h5>
                        </div>
                    ) : (
                        ''
                    )}
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className={cx('form-radio')}
                            sx={{ alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            {/* {data_user && data_user?.data?.vip_member_package
                                ? vip_benefits.data?.map((item, index) => (
                                      <Box key={index} className={cx('box')}>
                                          <FormControlLabel
                                              sx={{
                                                  '& .MuiFormControlLabel-label': {
                                                      fontSize: '16px',
                                                  },
                                              }}
                                              checked={
                                                  data_user?.data?.vip_member_package?.latest_purchased_package.id ===
                                                  item.id
                                              }
                                              value={item.id}
                                              control={<Radio />}
                                              label={item.name}
                                              onClick={() => setTargetVip(item.id)}
                                          />
                                          <Typography sx={{ fontSize: '16px', color: '#27AB60' }}>
                                              {formatMoney(item.price)} đ
                                          </Typography>
                                      </Box>
                                  ))
                                : 
                                vip_benefits.data?.map((item, index) => (
                                      <Box key={index} className={cx('box')}>
                                          <FormControlLabel
                                              sx={{
                                                  '& .MuiFormControlLabel-label': {
                                                      fontSize: '16px',
                                                  },
                                              }}
                                              value={item.id}
                                              control={<Radio />}
                                              label={item.name}
                                              onClick={() => setTargetVip(item.id)}
                                          />
                                          <Typography sx={{ fontSize: '16px', color: '#27AB60' }}>
                                              {formatMoney(item.price)} đ
                                          </Typography>
                                      </Box>
                                  ))} */}
                            {vip_benefits.data?.map((item, index) => (
                                <Box key={index} className={cx('box')}>
                                    <FormControlLabel
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '16px',
                                            },
                                        }}
                                        value={item.id}
                                        control={<Radio />}
                                        label={item.name}
                                        onClick={() => setTargetVip(item.id)}
                                    />
                                    <Typography sx={{ fontSize: '16px', color: '#27AB60' }}>
                                        {formatMoney(item.price)} đ
                                    </Typography>
                                </Box>
                            ))}
                        </RadioGroup>
                    </FormControl>
                    {/* {profile.name} */}
                    {data_user && data_user?.data?.vip_member_package ? (
                        <Button className={cx('btn_submit')} onClick={handleOpenAlert} variant="contained">
                            Gia hạn
                        </Button>
                    ) : (
                        <Button className={cx('btn_submit')} onClick={handleOpenAlert} variant="contained">
                            Nâng cấp ngay
                        </Button>
                    )}
                </Grid>

                <Alert alertState={alertState} visible={visible} handleCancel={handleCancel}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif', paddingBottom: '10px' }}>
                        Thông báo
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }}>Nâng cấp tài khoản của bạn</Typography>
                    <div className="row position-relative pt-4">
                        <button
                            onClick={() => handleCancel()}
                            style={{
                                display: 'block',
                                background: '#D9D9D9!important',
                                color: '#333',
                                fontFamily: 'sans-serif',
                                fontWeight: 700,
                            }}
                            className={cx('col-7', 'btn', 'cancel', 'start-0', 'p-3')}
                        >
                            Hủy bỏ
                        </button>
                        <button
                            onClick={() => onUpdateAccount()}
                            style={{
                                display: 'block',
                                right: 0,
                                background: '#27AB60!important',
                                color: '#fff',
                                fontFamily: 'sans-serif',
                                fontWeight: 700,
                            }}
                            className="col-6 position-absolute btn btn-g-reverse p-3"
                        >
                            Đồng ý
                        </button>
                    </div>
                </Alert>
            </Grid>
        </Box>
    );
}

export default UpgradeUser;
