import { useState } from "react";
import { Box, Typography, Grid } from '@mui/material';
import TitleUser from '~/components/TitleUser';
import Dropdown from '~/components/Service/Dropdown';
import DetailInformation from './DetailInformation';
import FormInput from './FormInput';
import List from './List';
import classNames from 'classnames/bind';
import styles from './GetService.module.scss';

const cx = classNames.bind(styles);

const GetService = () => {

    const [active, setActive] = useState(false);

    const handleDetailGetService = () => {
        setActive(true);
    }

    // console.log(active);

    return (
        <Box className={cx('GetService','container')}>
            {active ? (
                <DetailInformation />
            ) : (
                <>
                    <Dropdown />
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ flexGrow: 1 }}
                        className={cx('wrapper')}
                    >
                        <Grid className={cx('item-wp')} pb={2} pl={0} pr={2} item xs={12} sm={12} md={6}>
                            <TitleUser title="Đăng ký nhận dịch vụ" />
                            <FormInput />
                        </Grid>
                        <Grid className={cx('item-wp')} item xs={12} sm={12} md={6}>
                            <TitleUser title="Danh sách dịch vụ" />
                            <List />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
}

export default GetService;