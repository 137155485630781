import { Box, Typography, Stack, Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './ContentLeft.module.scss';
import { getInformation } from '~/Store/Action/informationActions';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

const ContentLeft = () => {
    const dispatch = useDispatch();
    const information = useSelector((state) => state.information.get);

    useEffect(() => {
        dispatch(getInformation(1));
    }, []);

    return (
        <Box className={cx('content-left')}>
            <div className="mt-3 w-100 d-block"
                    dangerouslySetInnerHTML={{ __html: information ? information.content : '' }}></div>
            {/* <img
                className={cx('img-fluid')}
                src={images.Item}
                alt="Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo"
            />
            <Typography className={cx('title')}>
                Hội thảo khoa học “Giải pháp đa dạng hoạt động phong trào đổi mới sáng tạo
            </Typography>
            <Box className={cx('date')}>
                <Typography sx={{ fontSize: '16px' }}>20/11/2022</Typography>
                <Button>
                    <ShareIcon /> Chia sẻ
                </Button>
            </Box>
            <Box className={cx('body')}>
                <Typography>
                    Sở KH-CN vừa tổ chức họp Hội đồng nghiệm thu đánh giá kết quả thực hiện đề tài KH-CN cấp tỉnh: “Giải
                    pháp nâng cao năng lực nghề nghiệp của người lao động tỉnh Vĩnh Long đáp ứng nhu cầu cách mạng công
                    nghiệp 4.0” do Viện Nghiên cứu Đời sống Xã hội làm cơ quan chủ trì, TS. Trương Minh Huy Vũ làm chủ
                    nhiệm đề tài.
                </Typography>
                <Box className={cx('text-center')}>
                    <img src={images.Item1} alt="" />
                </Box>
                <Typography>
                    Đề tài được thực hiện trong 21 tháng, từ tháng 5/2021 đến tháng 2/2023, hoàn thành các nội dung tìm
                    hiểu hiện trạng năng lực nghề nghiệp của người lao động hiện nay; xây dựng mô hình năng lực nghề
                    nghiệp của người lao động tỉnh Vĩnh Long trong bối cảnh cách mạng công nghiệp 4.0 và đề xuất giải
                    pháp nâng cao năng lực nghề nghiệp của người lao động tỉnh Vĩnh Long đáp ứng nhu cầu cách mạng công
                    nghiệp 4.0.
                </Typography>
                <Box className={cx('text-center')}>
                    <img src={images.Item2} alt="" />
                </Box>
                <Typography>
                    Hội đồng đã đánh giá điểm mạnh và giá trị của đề tài nêu lên thực trạng và xu hướng chuyển dịch cơ
                    cấu lao động- việc làm ở Việt Nam hiện nay và đặc điểm kinh tế- xã hội tỉnh Vĩnh Long trong xu hướng
                    chuyển dịch cơ cấu lao động- việc làm trong bối cảnh Cách mạng công nghiệp 4.0; thu thập đầy đủ
                    thông tin về các yếu tố năng lực nghề nghiệp của người lao động tỉnh.
                </Typography>
                <Typography>
                    Bên cạnh đó, đưa ra những yêu cầu về năng lực nghề nghiệp của doanh nghiệp, khả năng đáp ứng yêu cầu
                    của người lao động và chính sách đào tạo việc làm cho người lao động trong bối cảnh chuyển dịch cơ
                    cấu lao động- việc làm…
                </Typography>
            </Box> */}
        </Box>
    );
};

export default ContentLeft;
