import { Box, Typography, Button, Stack } from '@mui/material';
import images from '~/assets/images';
import classNames from 'classnames/bind';
import styles from './CourseItem.module.scss';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const cx = classNames.bind(styles);

const CourseItem = ({ data }) => {
    const [item, setItem] = useState([]);
    useEffect(() => {
        if (data.course) {
            setItem(data.course);
        } else {
            setItem(data.book);
        }
    }, []);

    return (
        <Box className={cx('wrapper')}>
            <Link to="">
                <img className={cx('avatar')} src={item.image} alt="" />
            </Link>
            <Box pl="8px">
                <Link to="" style={{ fontSize: '16px', fontFamily: 'sans-serif' }}>
                    {item.title}
                </Link>
                <Box sx={{ display: 'flex', paddingTop: '5px' }} alignItems="center">
                    <img className={cx('author', 'img-fluid')} src={item.owner?.image} alt="" />
                    <Typography sx={{ fontSize: '14px' }} pl={2}>
                        {item.owner?.name}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CourseItem;
