import { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './Profile.module.scss';
import ProfileTippy from '@tippyjs/react/headless';
import AccountItem from '~/components/AccountItem';
import Infomation from '~/components/Infomation';
import images from '~/assets/images';
// import Icon from '~/components/AccountItem/Icon/Icon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { get_profile } from '~/Store/Action/profileActions';
import AvartarName from '~/components/AvatarName';

const cx = classNames.bind(styles);

const Profile = ({ className }) => {
    const dispatch = useDispatch();
    const [showOpenProfile, setShowOpenProfile] = useState(false);
    const [user, setUser] = useState(null);
    const profile = useSelector((state) => state.profile?.list?.data);
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        console.log(auth);
        
        if (auth.token) {
            dispatch(get_profile(auth.token));
        }
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    const handleShowOpenProfile = () => {
        setShowOpenProfile(!showOpenProfile);
    };

    const handleHideProfile = () => {
        setShowOpenProfile(false);
    };

    const MENU_ITEMS = [
        {
            icon: images.user_profile,
            title: 'Quản lý tài khoản',
            to: 'quan-ly-tai-khoan',
            hasChild1: true,
        },
        {
            // icon: images.user_profile,
            title: 'Thông tin cá nhân',
            to: 'thong-tin-ca-nhan',
            parent1: true,
            // hasChild: true,
        },
        {
            // icon: images.upgra_profile,
            title: 'Nâng cấp tài khoản',
            to: 'nang-cap-tai-khoan',
            user: true,
            parent1: true,
        },
        {
            icon: images.history_profile,
            title: 'Khảo sát',
            to: 'khao-sat',
            activeColor: '',
            parent1: true,
        },
        {
            icon: images.lock_profile,
            title: 'Đổi mật khẩu',
            to: 'doi-mat-khau',
            parent1: true,
        },
        {
            icon: images.manage_profile,
            title: 'Đăng ký chuyên gia - đối tác',
            to: 'dang-ky-chuyen-gia-doi-tac',
            activeColor: '',
            user: true,
        },
        {
            icon: images.manage_profile,
            title: 'Quản lý tài nguyên',
            to: 'quan-ly-tai-nguyen',
            hasChild: true,
        },
        {
            title: 'Quản lý khóa học video',
            to: 'quan-ly-khoa-hoc-video',
            parent: true,
        },
        {
            title: 'Quản lý khóa học online',
            to: 'quan-ly-khoa-hoc-online',
            parent: true,
        },
        {
            title: 'Quản lý sách pdf',
            to: 'quan-ly-sach-pdf',
            parent: true,
        },
        {
            title: 'Quản lý sách giấy',
            to: 'quan-ly-sach-giay',
            parent: true,
        },
        {
            title: 'Quản lý kí ức xã hội pdf',
            to: 'quan-ly-ki-uc-xa-hoi-pdf',
            parent: true,
        },
        {
            title: 'Quản lý kí ức xã hội giấy',
            to: 'quan-ly-ki-uc-xa-hoi-giay',
            parent: true,
        },
        {
            title: 'Quản lý đơn hàng',
            to: 'quan-ly-don-hang',
            parent: true,
        },
        {
            title: 'Quản lý sự kiện',
            to: 'quan-ly-su-kien',
            parent: true,
        },
        {
            title: 'Quản lý khảo sát',
            to: 'quan-ly-khao-sat',
            parent: true,
        },
        {
            icon: images.service_profile,
            title: 'Dịch vụ học thuật',
            to: 'dich-vu-hoc-thuat',
            hasChild2: true,
        },
        {
            icon: images.history_profile,
            title: 'Lịch sử đơn hàng',
            to: 'lich-su-don-hang',
            activeColor: '',
            parent2: true,
        },
        {
            icon: images.evaluate_profile,
            title: 'Lịch sử đánh giá',
            to: 'lich-su-danh-gia',
            activeColor: '',
            parent2: true,
        },
        {
            icon: images.service_profile,
            title: 'Dịch vụ',
            to: 'dich-vu',
            activeColor: '',
            user: true,
        },
        {
            icon: images.course_profile,
            title: 'Khóa học của tôi',
            to: 'khoa-hoc-cua-toi',
            activeColor: '',
            user: true,
        },
        {
            icon: images.book_profile,
            title: 'Thư viện của tôi',
            to: 'thu-vien-cua-toi',
            activeColor: '',
            user: true,
        },
        {
            icon: images.manage_profile,
            title: 'Về My SocailLife',
            to: '',
            hasChild3: true,
        },
        {
            icon: images.service_profile,
            title: 'Về chúng tôi',
            to: '/gioi-thieu',
            activeColor: '',
            parent3: true,
        },
        {
            icon: images.history_profile,
            title: 'Điều khoản chính sách',
            to: '/gioi-thieu/dieu-khoan-chinh-sach',
            activeColor: '',
            parent3: true,
        },
    ];
    const [tabIndex, setTabIndex] = useState('');

    const filteredMenuItems = MENU_ITEMS.filter((item) => item.parent !== true && item.account_type !== 'CUSTOMER');

    const handleTabIndex = (tab) => {
        setTabIndex(tab);
    };

    const handleClickHideProfile = () => {
        setShowOpenProfile(false);
    };

    return (
        <ProfileTippy
            interactive
            visible={showOpenProfile}
            placement="bottom-end"
            render={(attrs) => (
                <Box className={cx('profile') + ' wow animate__animated animate__zoomIn'}>
                    <Box
                        sx={{
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                            padding: '10px',
                            background: '#FFFFFF',
                            textAlign: 'center',
                            borderTopRightRadius: '10px',
                            borderTopLeftRadius: '10px',
                        }}
                    >
                        Tài khoản
                    </Box>
                    <Box style={{ margin: '23px' }}>
                        <AvartarName className="info-sidebar" />
                    </Box>
                    <Box className={cx('info')}>
                        <Infomation className="info-header" />
                    </Box>
                    <Box sx={{ borderTop: '1px solid #D8D9DB' }}>
                        <Stack mb={0} ml={3} mr={3}>
                            <AccountItem
                                handleClickHideProfile={handleClickHideProfile}
                                handleTabIndex={handleTabIndex}
                                tabIndex={tabIndex}
                                data={MENU_ITEMS}
                                filteredMenuItems={filteredMenuItems}
                            />
                        </Stack>
                    </Box>
                </Box>
            )}
            onClickOutside={handleHideProfile}
        >
            <Box onClick={handleShowOpenProfile} className={className}>
                <img
                    style={{
                        width: '30px',
                        height: '30px',
                        border: '2px solid #dee2e6',
                        borderRadius: '50%',
                    }}
                    className="img-img-thumbnail"
                    src={profile?.image ? profile?.image : images.avatarDefault}
                    alt=""
                />
                <Typography sx={{ fontSize: '14px', color: '#fff' }} pl={2} pr={2}>
                    {profile ? profile.name : 'user'}
                </Typography>
                <ArrowDropDownIcon />
            </Box>
        </ProfileTippy>
    );
};

export default Profile;
