import { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import classNames from 'classnames/bind';
import { bookPrint, bookPdf } from '~/Store/Action/bookActions';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Pagination.module.scss';

const cx = classNames.bind(styles);

const Pagination = ({ totalPage, page, handleNextPage, handlePrevPage, handlePageChange }) => {
    const [currentPage, setCurrentPage] = useState(page || 1);
    const dispatch = useDispatch();

    // const handlePageChange = async (pageNumber) => {
    //     setCurrentPage(pageNumber);
    //     await dispatch(bookPrint(null, pageNumber));
    //     await dispatch(bookPdf(null, pageNumber)); // Gọi action bookPdf với trang mới
    // };

    // const handlePrevPage = async () => {
    //   const prevPage = currentPage - 1;
    //   if (prevPage >= 1) {
    //     setCurrentPage(prevPage);
    //     await dispatch(bookPrint(null, prevPage));
    //     await dispatch(bookPdf(null, prevPage));
    //   }
    // };
    
    // const handleNextPage = async () => {
    //   const totalPages = Math.ceil(totalPage / 9);
    //   if (currentPage < totalPages) {
    //     const nextPage = currentPage + 1;
    //     setCurrentPage(nextPage);
    //     await dispatch(bookPrint(null, nextPage));
    //     await dispatch(bookPdf(null, nextPage));
    //   }
    // };

    const renderPageNumbers = () => {
        const pageNumbers = [];
      
        const totalPages = Math.ceil(totalPage / 9); // Tổng số trang (9 item/trang)
        let itemCount = totalPage;
      
        for (let i = 1; i <= totalPages; i++) {
          const itemsPerPage = i === totalPages ? itemCount : 9;
          itemCount -= itemsPerPage;
      
          pageNumbers.push(
            <Typography
              key={i}
              className={cx('number', { 'number-active': i === page })}
              onClick={() => handlePageChange(i)} // Xử lý sự kiện khi người dùng chọn số trang
            >
              {i}
            </Typography>
          );
      
          if (itemCount <= 0) {
            break;
          }
        }
      
        return pageNumbers;
      };
    return (
        <Stack pt={2} sx={{ width: '100%' }} direction="row" justifyContent="end">
            <Box sx={{ display: 'flex' }} alignItems="center" className={cx('pagination')}>
                <Box className={cx('icon')} onClick={handlePrevPage}><ArrowBackIcon className={cx('arrowLeft')} /></Box>
                {renderPageNumbers()}
                <Box className={cx('icon')} onClick={handleNextPage}><ArrowForwardIcon className={cx('arrowRight')} /></Box>
            </Box>
        </Stack>
    );
};

export default Pagination;
