import { Box, Grid, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import Pagination from '~/components/Pagination';
import classNames from 'classnames/bind';
import styles from './List.module.scss';

const cx = classNames.bind(styles);

const List = ({ handleDetailService }) => {

    return (
        <Grid
            width="100%"
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ flexGrow: 1 }}
            className={cx('wrapper')}
        >
            <Grid xs={12}>
                <Link to="" className={cx('item')} onClick={handleDetailService}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>Dịch vụ ABC</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                        <Typography sx={{ color: '#27AB60', fontSize:'16px' }}>Hoang Huy</Typography>
                        <Typography sx={{ fontSize:'16px' }}>2 ngày</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize:'16px' }}>120.000 vnđ</Typography>
                        <Typography sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize:'16px' }}>Đang chờ</Typography>
                    </Box>
                    <Typography sx={{ fontSize:'14px', paddingTop: '7px' }}>08:00- 20/12/2022</Typography>
                </Link>
            </Grid>
            <Grid xs={12}>
                <Link to="" className={cx('item')} onClick={handleDetailService}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>Dịch vụ ABC</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                        <Typography sx={{ color: '#27AB60', fontSize:'16px' }}>Hoang Huy</Typography>
                        <Typography sx={{ fontSize:'16px' }}>2 ngày</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize:'16px' }}>120.000 vnđ</Typography>
                        <Typography sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize:'16px' }}>Đang chờ</Typography>
                    </Box>
                    <Typography sx={{ fontSize:'14px', paddingTop: '7px' }}>08:00- 20/12/2022</Typography>
                </Link>
            </Grid>
            <Grid xs={12}>
                <Link to="" className={cx('item')} onClick={handleDetailService}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>Dịch vụ ABC</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                        <Typography sx={{ color: '#27AB60', fontSize:'16px' }}>Hoang Huy</Typography>
                        <Typography sx={{ fontSize:'16px' }}>2 ngày</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize:'16px' }}>120.000 vnđ</Typography>
                        <Typography sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize:'16px' }}>Đang chờ</Typography>
                    </Box>
                    <Typography sx={{ fontSize:'14px', paddingTop: '7px' }}>08:00- 20/12/2022</Typography>
                </Link>
            </Grid>
            <Grid xs={12}>
                <Link to="" className={cx('item')} onClick={handleDetailService}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>Dịch vụ ABC</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                        <Typography sx={{ color: '#27AB60', fontSize:'16px' }}>Hoang Huy</Typography>
                        <Typography sx={{ fontSize:'16px' }}>2 ngày</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize:'16px' }}>120.000 vnđ</Typography>
                        <Typography sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize:'16px' }}>Đang chờ</Typography>
                    </Box>
                    <Typography sx={{ fontSize:'14px', paddingTop: '7px' }}>08:00- 20/12/2022</Typography>
                </Link>
            </Grid>
            <Grid xs={12}>
                <Link to="" className={cx('item')} onClick={handleDetailService}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'sans-serif' }}>Dịch vụ ABC</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                        <Typography sx={{ color: '#27AB60', fontSize:'16px' }}>Hoang Huy</Typography>
                        <Typography sx={{ fontSize:'16px' }}>2 ngày</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize:'16px' }}>120.000 vnđ</Typography>
                        <Typography sx={{ color: '#F9AB5C', fontWeight: 'bold', fontSize:'16px' }}>Đang chờ</Typography>
                    </Box>
                    <Typography sx={{ fontSize:'14px', paddingTop: '7px' }}>08:00- 20/12/2022</Typography>
                </Link>
            </Grid>
            {/* <Pagination /> */}
        </Grid>
    );
};

export default List;
