import classNames from 'classnames/bind';
import styles from './Alert.module.scss';
import { Modal as AntdModal, Row, Col } from 'antd';

const cx = classNames.bind(styles);

function Alert({visible, handleCancel, children}) {
    
    return (
        <AntdModal
            visible={visible}
            title={null}
            onCancel={handleCancel}
            className={cx('Alert')}
        >
            <div className={cx('Alert-wrapper')}>
                {children}
            </div>
        </AntdModal>
    );
}

export default Alert;