import React, { useState, useEffect } from 'react';
import Breadcumb from '~/components/Breadcrumb';
import slugify from 'slugify';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { survey } from '~/Store/Action/surveyActions';
import { format } from 'date-fns';
import Pagination from '~/components/Pagination';

const Survay = () => {
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const list = useSelector((state) => state.survey.list);

    useEffect(() => {
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
        dispatch(survey(auth.token, 1));
    }, [auth]);
    // console.log(list?.data?.data);

    let page = list?.current_page;
    // console.log(page);
    let totalPage = list?.total;
    const [currentPage, setCurrentPage] = useState(page || 1);

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (prevPage >= 1) {
            setCurrentPage(prevPage);
            dispatch(survey(auth.token, prevPage));
        }
    };

    const handleNextPage = async () => {
        const totalPages = Math.ceil(totalPage / 9);
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(survey(auth.token, nextPage));
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(survey(auth.token, pageNumber));
    };

    return (
        <div className="container">
            <Breadcumb title="Khảo sát" />

            <div className="row mt-3">
                {list?.data?.data?.length > 0 ? (
                    <>
                        {list?.data?.data?.map((item, index) => (
                            <div key={index} className="col-12 col-sm-6 col-lg-6 mb-4">
                                <Link className="card" to={'/khao-sat/' + slugify(item.title) + '/' + item.id}>
                                    <div className="card-body">
                                        <h2
                                            className="text-dark fw-bold"
                                            style={{ fontSize: '16px', fontFamily: 'sans-serif' }}
                                        >
                                            {item?.title}
                                        </h2>
                                        <div>
                                            <span className="p">{item?.total_questions} câu hỏi</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span className="p">{format(new Date(item?.created_at), 'dd/M/yyyy')}</span>
                                            {item?.taken ? (
                                                <span className="text-success">Đã làm</span>
                                            ) : (
                                                <span className="text-warning">Chưa làm</span>
                                            )}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                        <Pagination
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            handlePageChange={handlePageChange}
                            page={list?.data?.current_page}
                            totalPage={list?.data?.total}
                        />
                    </>
                ) : (
                    <div>
                        <h3 className="fw-bold">Danh sách trống</h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Survay;
