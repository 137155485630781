const initialState = {
    error: null,
    list: null,
    user_data: null,
    infor_user_data: null,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PROFILE_SUCCESS':
            return {
                ...state,
                error: null,
            };
        case 'PROFILE_FAILURE':
            return {
                ...state,
                error: action.payload,
            };
        case 'GET_INFOR_USER_BY_ID_SUCCESS':
            return {
                ...state,
                infor_user_data: action.payload,
            };
        case 'GET_PROFILE_SUCCESS':
            return {
                ...state,
                list: action.payload,
            };
        case 'GET_USER_BY_ID_SUCCESS':
            return {
                ...state,
                user_data: action.payload,
            };
        case 'GET_PROFILE_FAILURE':
            return state;
        default:
            return state;
    }
};

export default profileReducer;
