import { Box, Typography, Stack, Button } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './AvatarName.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const cx = classNames.bind(styles);

const AvartarName = ({ className }) => {
    const [user, setUser] = useState(null);

    const profile = useSelector((state) => state.profile?.list?.data);
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        console.log(auth);
        if (auth.user) {
            setUser(JSON.parse(auth.user));
        }
    }, [auth]);

    return (
        <Box className={cx(className)} textAlign="center">
            {/* <Box>
                <img
                    style={{ width: '100px', height: '100px', border: '2px solid #dee2e6', borderRadius: '50%' }}
                    src={profile?.image ? profile?.image : images.avatarDefault}
                    alt=""
                />
            </Box>
            <Typography sx={{ fontSize: '20px' }}>{user ? user.name : 'Chưa cập nhật'}</Typography>
            <Typography sx={{ fontSize: '16px', color: '#777777' }}>
                {user?.account_type === 'CUSTOMER' && 'Tài khoản thường'}
                {user?.account_type === 'PARTNER' && 'Đối tác'}
                {user?.account_type === 'EXPERT' && 'Chuyên gia'}
            </Typography> */}
            <Box className={cx('top')}>
                <img
                    style={{
                        width: '60px',
                        height: '60px',
                        border: '2px solid #dee2e6',
                        borderRadius: '50%',
                    }}
                    src={profile?.image ? profile?.image : images.avatarDefault}
                    alt=""
                />
                <Box className={cx('content')}>
                    <Box className={cx('name')}>{profile ? profile?.name : 'Chưa cập nhật'}</Box>
                    <Box
                        component="button"
                        sx={{
                            background: '#F9AB5C',
                            borderRadius: '12px',
                            padding: '0 15px',
                            fontSize: '12px',
                            color: '#fff',
                        }}
                    >
                        {profile?.account_type == 'CUSTOMER' && 'Tài khoản thường'}
                        {profile?.account_type == 'PARTNER' && 'Tài khoản đối tác'}
                        {profile?.account_type == 'EXPERT' && 'Tài khoản chuyên gia'}
                    </Box>
                </Box>
            </Box>

            <div className="row mt-4">
                <div className="col-12 col-sm-6">
                    <div className='t-w-text'>Người theo dõi</div>
                    <div className='t-w-text-1'>{profile?.followers_count ?? 0}</div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className='t-w-text'>Đang theo dõi</div>
                    <div className='t-w-text-1'>{profile?.followings_count ?? 0}</div>
                </div>
            </div>
        </Box>
    );
};

export default AvartarName;
