import api from './api';
import { toast } from 'react-toastify';

const showToast = (message, type = 0, options = {}) => {
    const defaultOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    };

    const toastOptions = { ...defaultOptions, ...options };

    if (type == 0) {
        toast.success(message, toastOptions);
    } else {
        toast.error(message, toastOptions);
    }
};

export const get_user_by_id = (id, type, token) => {
    return async (dispatch) => {
        try {
            const response = await api.get(
                `api/creator/${id}/${type}`,
                {},
                {
                    'Content-Type': 'application/json',
                    ...(token && { Authorization: 'Bearer ' + token }),
                    // Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'GET_USER_BY_ID_SUCCESS', payload: response.data });
            console.log(response.data);

            return response;
        } catch (error) {
            dispatch({ type: 'GET_PROFILE_FAILURE', payload: error.message });
        }
    };
};

export const get_infor_user_by_id = (id, token) => {
    return async (dispatch) => {
        try {
            const response = await api.get(
                `api/creator/${id}/videos`,
                {},
                {
                    'Content-Type': 'application/json',
                    ...(token && { Authorization: 'Bearer ' + token }),
                    // Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'GET_INFOR_USER_BY_ID_SUCCESS', payload: response.data });
            console.log(response.data);

            return response;
        } catch (error) {
            dispatch({ type: 'GET_PROFILE_FAILURE', payload: error.message });
        }
    };
};

export const follow = (id, token) => {
    return async (dispatch) => {
        try {
            if (!token || token == null) {
                showToast('Vui lòng đăng nhập để tiếp tục', 1);
                return;
            }
            const response = await api.post(
                `api/my/follow-unfollow/${id}`,
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            // dispatch({ type: 'GET_INFOR_USER_BY_ID_SUCCESS', payload: response.data });
            console.log(response.data);

            if (response.data.status == 200) {
                showToast(response.data.message);
                return true;
            } else {
                const message = 'Đã có lỗi xảy ra';

                showToast(message, 1);
                throw new Error(message);
            }

            return response;
        } catch (error) {
            // dispatch({ type: 'GET_PROFILE_FAILURE', payload: error.message });
        }
    };
};

export const money = (form, token) => {
    return async (dispatch) => {
        try {
            if (!token || token == null) {
                showToast('Vui lòng đăng nhập để tiếp tục', 1);
                return;
            }
            const response = await api.post(`api/payment/baokim-create`, form, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });
            console.log(response.data);

            if (response.data.status == 200) {
                showToast(response.data.message);
                return response.data;
            } else {
                const message = 'Đã có lỗi xảy ra';

                showToast(message, 1);
                throw new Error(message);
            }

            return false;
        } catch (error) {}
    };
};

export const get_profile = (token) => {
    return async (dispatch) => {
        try {
            if (!token || token == null) {
                console.log('token :', token);
                return;
            }
            // Gọi API
            const response = await api.get(
                'api/profile',
                {},
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            );
            dispatch({ type: 'GET_PROFILE_SUCCESS', payload: response.data });
            console.log(response.data);

            return response;
        } catch (error) {
            dispatch({ type: 'GET_PROFILE_FAILURE', payload: error.message });
        }
    };
};

export const save_profile = (
    name,
    image,
    phone,
    email,
    sex,
    province,
    district,
    ward,
    address,
    specialize,
    account_level,
    interest_topics,
    token,
) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append('name', name);
            if (image) {
                form.append('image', image);
            }
            form.append('email', email);
            form.append('phone', phone);
            form.append('sex', sex);
            form.append('province_id', province);
            form.append('district_id', district);
            form.append('ward_id', ward);
            form.append('address', address);
            form.append('specialize', specialize);
            form.append('account_level', account_level);
            form.append('interest_topics', interest_topics);
            // Gọi API
            const response = await api.post('api/profile', form, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            });

            if (response.data.status == 200) {
                showToast('Cập nhập thông tin thành công');
                return true;
            } else {
                const message = 'Cập nhập thông tin thất bại';

                showToast(message, 1);
                throw new Error(message);
            }
            return true;
        } catch (error) {
            dispatch({ type: 'PROFILE_FAILURE', payload: error.message });
            return false;
        }
    };
};
